import React from 'react'
import { forceVisible } from 'react-lazyload'
import {
	ADD_CARD,
	REMOVE_CARD,
	SET_DECK_NAME,
	SET_DECK_NAME_COMMIT,
	SET_DECK_NAME_ROLLBACK,
	SET_ARCHIVED,
	SET_DECK_CARDS,
	setDeck,
	SAVE_DECK,
	SAVE_DECK_COMMIT,
	SAVE_DECK_ROLLBACK,
	REMOVE_EVENT_PLAYERS,
	setMode,
	SET_DECK_CARD_CHECKED, discardChanges, clearDeck
} from '../actions/deckbuilder'
import { patch, post, remove } from '../../helper/api'
import history from '../../helper/history'
import unique from '../../helper/unique'
import { sortDeckCards } from '../../helper/sort'
import { events, trackEvent } from '../../helper/track'
import Marker from '../../components/deck/Marker'
import { hideToast, setConfirm, showToast } from '../actions/app'
import formats from '../../data/formats'
import { initialValueDeckSortAlgorithmExtra, initialValueDeckSortAlgorithmMain } from '../reducers/persist'
import { isExtraDeckCard, isMonster, isSpell, isTrap, isSkill } from '../../helper/card'

export const addCard = (card, place, artworkId = null, haves = null) => (dispatch, getState) => {
	dispatch({
		type: ADD_CARD,
		card,
		place,
		haves,
		artworkId
	})

	if (getState().persist.user.settings['app.autoSort'] === true) {
		dispatch(sortCards())
	}
}

export const setDeckCardChecked = (deckCardId, checked) => (dispatch, getState) => {
	dispatch({
		type: SET_DECK_CARD_CHECKED,
		checked,
		deckCardId,
		userId: getState().persist.user.id
	})
}

export const sortCards = (manual = false, t) => (dispatch, getState) => {
	const mainDeckAlgorithm = getState().persist.user.settings['app.deckSortAlgorithmMain'] || initialValueDeckSortAlgorithmMain
	const extraDeckAlgorithm = getState().persist.user.settings['app.deckSortAlgorithmExtra'] || initialValueDeckSortAlgorithmExtra
	const cardNameLanguage = getState().persist.user.settings['app.cardNameLanguage'] || 'en'

	const prevCardsOrder = getCardSortOrderStr(getState().deckbuilder.deck.cards.sort((a, b) => a.order - b.order))

	const cards = sortDeckCards(
		getState().deckbuilder.deck.cards,
		getState().deckbuilder.deck.archetypes,
		mainDeckAlgorithm,
		extraDeckAlgorithm,
		cardNameLanguage
	)

	const newCardsOrder = getCardSortOrderStr(cards)

	if (prevCardsOrder !== newCardsOrder) {
		dispatch({
			type: SET_DECK_CARDS,
			cards,
			userId: getState().persist.user.id
		})

		if (manual) {
			dispatch(showToast('success', t('general.deckSorted')))
		}
	}
}

export const sortMainDeckCardsByType = () => (dispatch, getState) => {
	const deckCards = getState().deckbuilder.deck.cards

	const mainDeck = [
		...deckCards.filter(item => item.place === 'main' && item.card.type === 'monster').sort((a, b) => a.order - b.order),
		...deckCards.filter(item => item.place === 'main' && item.card.type === 'spell').sort((a, b) => a.order - b.order),
		...deckCards.filter(item => item.place === 'main' && item.card.type === 'trap').sort((a, b) => a.order - b.order)
	].map((item, index) => ({
		...item,
		order: index + 1
	}))

	dispatch({
		type: SET_DECK_CARDS,
		cards: [
			...mainDeck,
			...deckCards.filter(item => item.place !== 'main').sort((a, b) => a.order - b.order)
		],
		userId: getState().persist.user.id
	})
}

export const getCardSortOrderStr = cards => cards.reduce((accumulator, current) => {
	accumulator += current.id

	return accumulator
}, '')

export const sortPlaceCards = (view, format, place, placeCards, t) => (dispatch, getState) => {
	const deckCards = getState().deckbuilder.deck.cards

	let cards = []

	// validate
	let valid = true

	placeCards.forEach((deckCard) => {
		if (
			(place === 'monster' && (!isMonster(deckCard.card) || isExtraDeckCard(deckCard.card)))
			|| (place === 'spell' && (!isSpell(deckCard.card) || isExtraDeckCard(deckCard.card)))
			|| (place === 'trap' && (!isTrap(deckCard.card) || isExtraDeckCard(deckCard.card)))
			|| (place === 'main' && isExtraDeckCard(deckCard.card))
			|| (place === 'extra' && !isExtraDeckCard(deckCard.card))
			|| (place === 'skill' && !isSkill(deckCard.card))
		) {
			valid = false
		}
	})

	if (!valid) {
		window.setTimeout(() => {
			dispatch({
				type: SET_DECK_CARDS,
				cards: deckCards,
				userId: getState().persist.user.id
			})
		}, 200)

		dispatch(showToast('error', t('general.deckBuilderError_cantMove')))
	} else {
		if (view === 'tile') {
			cards = formats[format].places.tile.reduce((accumulator, currentPlace) => {
				if (currentPlace === place) {
					accumulator = [
						...accumulator,
						...placeCards.map((deckCard, index) => ({
							...deckCard,
							order: index + 1,
							place: currentPlace
						}))
					]
				} else {
					accumulator = [
						...accumulator,
						...deckCards.filter(item => item.place === currentPlace)
					]
				}

				return accumulator
			}, [])
		} else if (view === 'list') {
			cards = formats[format].places.list.reduce((accumulator, currentPlace) => {
				if (currentPlace === place) {
					const degroupedCards = []
					let order = 1

					placeCards.forEach((groupedCard) => {
						groupedCard.ids.forEach((id) => {
							degroupedCards.push({
								id,
								card: groupedCard.card,
								order,
								place: place === 'monster' || place === 'spell' || place === 'trap' ? 'main' : place
							})

							order += 1
						})
					})

					accumulator = [
						...accumulator,
						...degroupedCards
					]
				} else {
					accumulator = [
						...accumulator,
						...deckCards.filter((item) => {
							if (currentPlace === 'monster' || currentPlace === 'spell' || currentPlace === 'trap') {
								return item.place === 'main' && item.card.type === currentPlace
							}

							return item.place === currentPlace
						})
					]
				}

				return accumulator
			}, [])
		}

		dispatch({
			type: SET_DECK_CARDS,
			cards,
			userId: getState().persist.user.id
		})
	}
}

export const clearPlace = place => (dispatch, getState) => {
	let cardTypeFilter = null

	if (place === 'monster' || place === 'spell' || place === 'trap') {
		cardTypeFilter = place
		place = 'main'
	}

	dispatch({
		type: SET_DECK_CARDS,
		cards: getState().deckbuilder.deck.cards.filter(item => item.place !== place || (cardTypeFilter && item.place === place && item.card.type !== cardTypeFilter)),
		userId: getState().persist.user.id
	})
}

export const moveAllToPlace = (from, to) => (dispatch, getState) => {
	const toCards = getState().deckbuilder.deck.cards.filter(item => item.place === to)
	let order = toCards.length > 0 ? toCards[toCards.length - 1].order : 0
	let cardTypeFilter = null

	if (from === 'monster' || from === 'spell' || from === 'trap') {
		cardTypeFilter = from
		from = 'main'
	}

	dispatch({
		type: SET_DECK_CARDS,
		cards: [
			...getState().deckbuilder.deck.cards.filter(item => item.place !== from || (cardTypeFilter && item.place === from && item.card.type !== cardTypeFilter)),
			...getState().deckbuilder.deck.cards.filter(item => item.place === from && (!cardTypeFilter || item.card.type === cardTypeFilter)).map((item) => {
				order += 1

				return {
					...item,
					place: to === 'main' ? (isExtraDeckCard(item.card) ? 'extra' : 'main') : to,
					order
				}
			})
		],
		userId: getState().persist.user.id
	})
}

export const removeCard = deckCard => (dispatch) => {
	dispatch({
		type: REMOVE_CARD,
		deckCard
	})
}

export const removeLastCard = (card, place) => (dispatch, getState) => {
	const deckCard = getState().deckbuilder.deck.cards.find((deckCard) => {
		if (deckCard.place === place && deckCard.card.slug === card.slug) {
			return true
		}

		return false
	})

	if (deckCard) {
		dispatch(removeCard(deckCard))
	}
}

export const removeAllCopies = (card, place) => (dispatch, getState) => {
	getState().deckbuilder.deck.cards.forEach((deckCard) => {
		if (deckCard.place === place && deckCard.card.slug === card.slug) {
			dispatch(removeCard(deckCard))
		}
	})
}

export const saveDeck = (t, callback = null) => (dispatch, getState) => {
	const deck = getState().deckbuilder.shelvedDeck || getState().deckbuilder.deck
	const subscribed = getState().persist.user.subscribed
	const deckSlug = deck.slug

	const placeMapping = {
		main: 1,
		extra: 2,
		side: 3,
		note: 4,
		skill: 5
	}

	const changes = Object.keys(deck.changes).reduce((accumulator, property) => {
		if (property === 'cards') {
			accumulator.cards = deck.cards.sort((a, b) => placeMapping[a.place] - placeMapping[b.place]).map((deckCard) => {
				if (!Number.isNaN(Number(deckCard.id))) {
					return {
						id: deckCard.id,
						place: deckCard.place,
						artworkId: deckCard.artworkId,
						...(subscribed ? { checked: deckCard.checked } : {})
					}
				}

				return {
					cardSlug: deckCard.card.slug,
					place: deckCard.place,
					artworkId: deckCard.artworkId,
					...(subscribed ? { checked: deckCard.checked } : {})
				}
			})
		} else if (property === 'coverCard') {
			accumulator[property] = deck[property] ? deck[property].slug : null
		} else if (property === 'coverArtwork') {
			accumulator[property] = deck[property] ? deck[property].id : null
		} else if (property === 'archetypes') {
			accumulator[property] = deck[property].map(archetype => archetype.id)
		} else if (property === 'season' && deck[property]) {
			accumulator[property] = deck[property].id
		} else if (property === 'tags') {
			accumulator[property] = deck[property].map(item => item.value)
		} else {
			accumulator[property] = deck[property]
		}

		return accumulator
	}, {})

	dispatch({
		type: SAVE_DECK
	})

	patch(`decks/${deckSlug}`, changes, (data) => {
		dispatch({
			type: SAVE_DECK_COMMIT,
			deckSlug,
			...data
		})

		dispatch(setMode('info'))
		dispatch(hideToast())

		forceVisible()

		trackEvent(events.SAVE_DECK)

		if (callback) {
			callback()
		}
	}, (status, error) => {
		let errorMessage = t('general.actionFailed')

		if (error) {
			if (error.card) {
				errorMessage = t(`general.deckBuilderError_${error.message}`, { cardName: error.card.name })
			} else if (error.archetypes) {
				errorMessage = t('general.deckBuilderError_archetypes')
			} else {
				errorMessage = t(`general.deckBuilderError_${error.message}`)
			}
		}

		dispatch({
			type: SAVE_DECK_ROLLBACK,
			deckSlug,
		})

		dispatch(showToast('error', errorMessage, null))

		trackEvent(events.SAVE_DECK_ERROR, errorMessage)
	})
}

export const createDeckSafely = (formatId = null, t) => (dispatch, getState) => {
	const deck = getState().deckbuilder.shelvedDeck || getState().deckbuilder.deck

	if (deck && !deck.saved) {
		dispatch(setConfirm({
			text: t('general.deckUnsavedWarning', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
			submitText: t('general.save'),
			submitRed: false,
			secondaryText: t('general.discardChanges'),
			secondaryRed: true,
			cancelText: t('general.cancel'),
			width: '600px',
			success: () => {
				dispatch(saveDeck(t, () => {
					dispatch(createDeck(formatId, t))
				}))
			},
			secondary: () => {
				dispatch(discardChanges())
				dispatch(createDeck(formatId, t))
			}
		}))
	} else {
		dispatch(createDeck(formatId, t))
	}
}

export const forkDeckSafely = (deckSlug, location, t, callback = null) => (dispatch, getState) => {
	const deck = getState().deckbuilder.shelvedDeck || getState().deckbuilder.deck

	if (deck && !deck.saved && !location.pathname.startsWith('/mydecks')) {
		dispatch(setConfirm({
			text: t('general.deckUnsavedWarning', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
			submitText: t('general.save'),
			submitRed: false,
			secondaryText: t('general.discardChanges'),
			secondaryRed: true,
			cancelText: t('general.cancel'),
			width: '600px',
			success: () => {
				dispatch(saveDeck(t, () => {
					dispatch(forkDeck(deckSlug, location, t, callback))
				}))
			},
			secondary: () => {
				dispatch(discardChanges())
				dispatch(forkDeck(deckSlug, location, t, callback))
			}
		}))
	} else {
		dispatch(forkDeck(deckSlug, location, t, callback))
	}
}

export const createDeck = (formatId, t) => (dispatch) => {
	post('decks', { formatId }, (deckSlug) => {
		history.push(`/deck-builder/${deckSlug}`)

		trackEvent(events.CREATE_DECK)
	}, (status, error) => {
		dispatch(showToast('error', status === 400 ? error.name[0] : t('general.actionFailed'), null))
	})
}

export const forkDeck = (deckSlug, location, t, callback = null) => (dispatch) => {
	post(`decks/${deckSlug}`, null, ({ data: deck }) => {
		if (location.pathname.startsWith('/mydecks')) {
			history.push('/mydecks')
		} else {
			dispatch(clearDeck())
			history.push(`/deck-builder/${deck.slug}`)
		}

		dispatch(showToast('success', t('general.deckForked')))

		trackEvent(events.FORK_DECK)

		if (callback) {
			callback()
		}
	}, (status, error) => {
		dispatch(showToast('error', status === 400 ? error.name[0] : t('general.actionFailed'), null))
	})
}

export const removeDeck = (deckSlug, isArchived, location, t, callback = null) => (dispatch) => {
	remove(`decks/${deckSlug}`, () => {
		dispatch(setDeck(null))

		if (location.pathname.startsWith(`/deck-builder/${deckSlug}`)) {
			history.push('/mydecks')
		}

		dispatch(showToast('success', t('general.deckRemoved')))

		trackEvent(events.REMOVE_DECK)

		if (callback) {
			callback()
		}
	}, (status, error) => {
		dispatch(showToast('error', status === 400 ? error.name[0] : t('general.actionFailed'), null))
	})
}

export const renameDeck = (deckSlug, name, t) => (dispatch) => {
	const loadingId = unique()

	dispatch({
		type: SET_DECK_NAME,
		deckSlug,
		name,
		loadingId
	})

	patch(`decks/${deckSlug}`, { name }, () => {
		dispatch({
			type: SET_DECK_NAME_COMMIT,
			deckSlug,
			loadingId
		})

		trackEvent(events.RENAME_DECK)
	}, (status, error) => {
		dispatch({
			type: SET_DECK_NAME_ROLLBACK,
			deckSlug,
			loadingId,
			errorMessage: status === 400 ? error.name[0] : t('general.actionFailed')
		})
	})
}

export const setArchived = (deckSlug, archived, t, callback) => (dispatch) => {
	patch(`decks/${deckSlug}`, { archived }, () => {
		dispatch({
			type: SET_ARCHIVED,
			deckSlug,
			archived
		})

		if (archived === true) {
			trackEvent(events.ARCHIVE_DECK)
			dispatch(showToast('success', t('general.deckArchived')))
		} else {
			trackEvent(events.UNARCHIVE_DECK)
			dispatch(showToast('success', t('general.deckUnarchived')))
		}

		if (callback) {
			callback()
		}
	}, (status, error) => {
		dispatch(showToast('error', status === 400 ? error.name[0] : t('general.actionFailed'), null))
	})
}

export const removeEventPlayer = (deck, t) => (dispatch) => {
	remove(`decks/${deck.slug}/eventPlayers`, () => {
		dispatch({
			type: REMOVE_EVENT_PLAYERS,
			deckSlug: deck.slug
		})

		dispatch(showToast('success', t('general.eventPlayerRemoved')))
	}, () => {
		dispatch(showToast('error', t('general.eventPlayerRemovedError')))
	})
}

export const starDeck = (deck, t, callback = null) => (dispatch, getState) => {
	if (getState().deckbuilder.deck && getState().deckbuilder.deck.slug === deck.slug) {
		const baseDeck = getState().deckbuilder.deck

		dispatch(setDeck({
			...baseDeck,
			stars: deck.user.id !== getState().persist.user.id ? deck.stars + 1 : deck.stars,
			userStar: !deck.userStar
		}))
	}

	post(`decks/${deck.slug}/star`, null, () => {
		trackEvent(events.STAR_DECK)
		dispatch(showToast('success', t('general.deckStarred', { name: deck.name })))

		if (callback) {
			callback()
		}
	}, () => {
		dispatch(showToast('error', t('general.actionFailed'), null))
	})
}

export const unstarDeck = (deck, t, callback = null) => (dispatch, getState) => {
	if (getState().deckbuilder.deck && getState().deckbuilder.deck.slug === deck.slug) {
		const baseDeck = getState().deckbuilder.deck

		dispatch(setDeck({
			...baseDeck,
			stars: deck.user.id !== getState().persist.user.id ? deck.stars - 1 : deck.stars,
			userStar: !deck.userStar
		}))
	}

	remove(`decks/${deck.slug}/star`, () => {
		trackEvent(events.UNSTAR_DECK)
		dispatch(showToast('success', t('general.deckUnstarred', { name: deck.name })))

		if (callback) {
			callback()
		}
	}, () => {
		dispatch(showToast('error', t('general.actionFailed'), null))
	})
}

export const voteDeck = (deck, vote, t) => (dispatch, getState) => {
	const deckBeforeVote = { ...deck }

	let userVote = vote
	let voteDiff = vote
	let upvotesDiff = vote === 1 ? 1 : 0
	let downvotesDiff = vote === -1 ? 1 : 0

	if (deck.userVote !== 0 && deck.userVote !== vote) {
		if (vote === -1) {
			voteDiff = -2
			upvotesDiff = -1
			downvotesDiff = 1
		} else {
			voteDiff = 2
			upvotesDiff = 1
			downvotesDiff = -1
		}
	} else if (deck.userVote === vote) {
		voteDiff = vote * -1
		userVote = 0

		if (vote === 1) {
			upvotesDiff = -1
		} else {
			downvotesDiff = -1
		}
	}

	if (getState().deckbuilder.deck && getState().deckbuilder.deck.slug === deck.slug) {
		const baseDeck = getState().deckbuilder.deck

		dispatch(setDeck({
			...baseDeck,
			votes: Number(baseDeck.votes) + voteDiff,
			userVote,
			upvotes: Number(baseDeck.upvotes) + upvotesDiff,
			downvotes: Number(baseDeck.downvotes) + downvotesDiff
		}))
	}

	post(`decks/${deck.slug}/vote`, { vote }, () => {
		if (vote === 1) {
			trackEvent(events.UPVOTE_DECK)
		} else {
			trackEvent(events.DOWNVOTE_DECK)
		}
	}, (status, data) => {
		if (data.message === 'authorBlockedMe') {
			dispatch(showToast('error', t('general.voteErrorBlocked'), null))
		} else {
			dispatch(showToast('error', t('general.communityDisabled'), null))
		}

		dispatch(setDeck(deckBeforeVote))
	})
}
