import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import themeDark from './theme-dark'
import themeLight from './theme-light'
import { browserPrefersDarkScheme } from './media'

const ThemeProvider = ({ children, settings }) => {
	const appearance = settings['app.appearance'] || 'light'
	const dark = appearance === 'dark' || (appearance === 'system' && browserPrefersDarkScheme())

	return (
		<StyledComponentsThemeProvider theme={dark ? themeDark : themeLight}>
			{children}
		</StyledComponentsThemeProvider>
	)
}

ThemeProvider.propTypes = {
	children: PropTypes.any.isRequired,
	settings: PropTypes.object.isRequired
}

const enhance = connect(state => ({
	settings: state.persist.user.settings
}))

export default enhance(ThemeProvider)
