import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Unread from '../../components/general/Unread'
import { VIEWPORT_DESKTOP } from '../../styles/media'

const AccountNotifications = ({ authToken, avatarInappropriate, emailIsVerified, small, subscriptionPastDue, subscriptionPriceMismatch, subscriptionNewPaymentMethodRequired, type, unreadAdmin, unreadComments, viewport }) => {
	let warning = false
	let gray = false
	let red = false
	let sum = 0

	if (type === 'account' || type === 'settings') {
		if (authToken && !emailIsVerified) {
			sum += 1
			warning = true
		}

		if (avatarInappropriate) {
			sum += 1
			warning = true
		}

		if (subscriptionPastDue) {
			sum += 1
			warning = true
		}

		if (subscriptionPriceMismatch) {
			sum += 1
			warning = true
		}

		if (subscriptionNewPaymentMethodRequired) {
			sum += 1
			warning = true
		}

		if (subscriptionNewPaymentMethodRequired) {
			sum += 1
		}
	} else if (type === 'comments') {
		sum += unreadComments
		red = true
	}

	if ((type === 'admin' || (type === 'account' && viewport === VIEWPORT_DESKTOP )) && unreadAdmin && unreadAdmin > 0) {
		sum += unreadAdmin
		gray = true
	}

	return sum > 0 ? (
		<Unread gray={gray} warning={warning} red={red} small={small}>{sum}</Unread>
	) : null
}

AccountNotifications.propTypes = {
	authToken: PropTypes.string,
	avatarInappropriate: PropTypes.bool,
	emailIsVerified: PropTypes.bool,
	small: PropTypes.bool,
	subscriptionPastDue: PropTypes.bool,
	subscriptionPriceMismatch: PropTypes.bool,
	subscriptionNewPaymentMethodRequired: PropTypes.bool,
	type: PropTypes.string.isRequired,
	unreadAdmin: PropTypes.number,
	unreadComments: PropTypes.number,
	viewport: PropTypes.string.isRequired
}

AccountNotifications.defaultProps = {
	authToken: null,
	avatarInappropriate: false,
	emailIsVerified: null,
	small: false,
	subscriptionPastDue: null,
	subscriptionPriceMismatch: null,
	subscriptionNewPaymentMethodRequired: null,

	unreadAdmin: null,
	unreadComments: null
}

const enhance = connect(state => ({
	authToken: state.persist.authToken,
	avatarInappropriate: state.persist.user.avatarInappropriate,
	emailIsVerified: state.persist.user.emailIsVerified,
	subscriptionPastDue: state.persist.user.subscriptionPastDue,
	subscriptionPriceMismatch: state.persist.user.subscriptionPriceMismatch,
	subscriptionNewPaymentMethodRequired: state.persist.user.subscriptionNewPaymentMethodRequired,
	unreadAdmin: state.persist.unreadAdmin,
	unreadComments: state.persist.unreadComments,
	viewport: state.app.viewport
}))

export default enhance(AccountNotifications)
