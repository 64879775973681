import { combineReducers } from 'redux'
import app from './app'
import deckbuilder from './deckbuilder'
import persist from './persist'
import collection from './collection'

export default combineReducers({
	app,
	collection,
	deckbuilder,
	persist
})
