import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ActionButton, { StyledActionButton } from '../components/button/ActionButton'
import themeLight from '../styles/theme-light'
import translate from '../helper/translate'
import { discordLink } from '../../config'

class DiscordWidget extends Component {
	static propTypes = {
		t: PropTypes.func.isRequired
	}

	render() {
		const { t } = this.props

		return (
			<StyledDiscordWidget>
				<ActionButton
					as="a"
					color={themeLight.primary}
					textColor="#FFF"
					icon="social-discord"
					href={discordLink}
					external
				>
					{t('general.join')}
				</ActionButton>
			</StyledDiscordWidget>
		)
	}
}

const StyledDiscordWidget = styled.div`
	${StyledActionButton} {
		width: fit-content;
	}
`

const enhance = translate('general')

export default enhance(DiscordWidget)
