import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { matchPath, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Input, { InputInlineButton, StyledInputContainer } from '../../components/form/Input'
import Icon, { StyledIcon } from '../../components/general/Icon'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import MenuLink from '../../components/nav/MenuLink'
import { get } from '../../helper/api'
import history from '../../helper/history'
import translate from '../../helper/translate'
import { decodeSearchString, encodeSearchString } from '../../helper/string'

class SiteSearch extends Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		mobileHome: PropTypes.bool,
		searchHasFocus: PropTypes.bool,
		setSearchHasFocus: PropTypes.func,
		t: PropTypes.func.isRequired,
		transparent: PropTypes.string,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		mobileHome: false,
		searchHasFocus: null,
		setSearchHasFocus: () => {},
		transparent: null
	}

	constructor(props) {
		super(props)

		this.state = {
			q: '',
			open: false,
			match: false,
			randomCardLoading: false
		}

		this.inputRef = React.createRef()
		this.randomButtonRef = React.createRef()

		this.unlisten = this.props.history.listen(this.resetState)
	}

	componentDidMount() {
		this.resetState(this.props.history.location)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/search') {
			this.resetState(this.props.history.location)
		}
	}

	componentWillUnmount() {
		this.unlisten()
	}

	resetState = (location) => {
		const { viewport } = this.props
		const { open } = this.state

		const match = matchPath(location.pathname, {
			path: '/search/:q'
		})

		this.setState({
			match: !!match,
			q: match ? decodeSearchString(match.params.q) : ''
		}, () => {
			if (location.pathname === '/search' && viewport === VIEWPORT_DESKTOP) {
				this.inputRef.current.focus()
			} else {
				if (this.state.q !== '' && viewport === VIEWPORT_DESKTOP) {
					this.inputRef.current.select()
				} else {
					this.inputRef.current.blur()
				}

				this.props.setSearchHasFocus(!!this.state.q)
			}
		})

		if (open && !match) {
			this.setState({
				open: false
			})
		} else if (match) {
			this.setState({
				open: true
			})
		}
	}

	handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			this.handleSubmit(event)
		}
	}

	handleSubmit = (event) => {
		const { history, viewport } = this.props
		const q = encodeSearchString(this.state.q)

		event.preventDefault()

		if (q === null) {
			return
		}

		history.push(this.getSearchUrl(q))

		if (viewport === VIEWPORT_MOBILE) {
			this.inputRef.current.blur()
		} else {
			this.inputRef.current.select()
		}
	}

	handleClear = () => {
		this.setState({
			q: ''
		}, this.inputRef.current.focus())
	}

	handleFocus = () => {
		this.props.setSearchHasFocus(true)
	}

	handleBlur = () => {
		this.props.setSearchHasFocus(!!this.state.q)
	}

	handleOpen = () => {
		const { history } = this.props

		if (!this.state.open) {
			this.setState({
				open: true
			}, () => {
				this.inputRef.current.focus()
			})
		} else if (!history.location.pathname.startsWith('/search/')) {
			this.setState({
				open: false
			})
		}
	}

	getSearchUrl = (q) => {
		const { pathname } = this.props.location

		if (pathname.startsWith('/search/')) {
			if (pathname.endsWith('/themes')) {
				return `/search/${q}/themes`
			}

			if (pathname.endsWith('/sets')) {
				return `/search/${q}/sets`
			}

			if (pathname.endsWith('/cards')) {
				return `/search/${q}/cards`
			}

			if (pathname.endsWith('/decks')) {
				return `/search/${q}/decks`
			}

			if (pathname.endsWith('/tournaments')) {
				return `/search/${q}/tournaments`
			}

			if (pathname.endsWith('/players')) {
				return `/search/${q}/players`
			}

			if (pathname.endsWith('/users')) {
				return `/search/${q}/users`
			}
		}

		return `/search/${q}`
	}

	goToRandomCard = () => {
		this.setState({
			randomCardLoading: true
		})

		get('cards/random', (slug) => {
			this.setState({
				randomCardLoading: false
			})
			history.push(`/card/${slug}?ref=random`)
		})
	}

	render() {
		const { mobileHome, searchHasFocus, t, transparent, viewport } = this.props
		const { q, open, match, randomCardLoading } = this.state

		return (
			<>
				{viewport === VIEWPORT_MOBILE && !mobileHome && (
					<SearchButton onClick={this.handleOpen} active={open || match}>
						<Icon
							name={open && !match ? 'x' : 'search'}
							size={20}
						/>
					</SearchButton>
				)}

				<Wrapper
					hasFocus={searchHasFocus}
					isEmpty={q === ''}
					mobileHome={mobileHome}
					open={open || mobileHome}
					onSubmit={this.handleSubmit}
					transparent={transparent}
				>
					<Input
						clearable
						icon="search"
						value={q}
						onChange={q => this.setState({ q })}
						onKeyDown={this.handleKeyDown}
						onClear={this.handleClear}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						inputRef={this.inputRef}
						placeholder={t('general.search')}
					/>
					<RandomButton
						onClick={this.goToRandomCard}
						visible={q === ''}
						disabled={randomCardLoading}
						data-tooltip={t('general.randomCard')}
						data-tooltip-location="bottom"
						ref={this.randomButtonRef}
					>
						<Icon name="shuffle" />
					</RandomButton>
				</Wrapper>
			</>
		)
	}
}

const SearchButton = styled(MenuLink)`
	height: 100%;

	${props => props.active && `
		background: #444;
	`}
`

const Wrapper = styled.form`
	display: flex;
	flex: 1;
	position: relative;
	width: 100%;

	${desktop`
		${InputInlineButton} {
			right: 12px;
			top: 8px;
			bottom: 8px;
			width: 32px;
		}
	`}

	${mobile`
		padding: 0.5rem;
		margin: 0;
		display: ${props => props.open ? 'flex' : 'none'};
		width: 100%;
		transition: all 0.1s;

		${StyledInputContainer} > svg {
			height: 20px;
			width: 20px;
			margin-top: -10px !important;
		}

		${props => !props.mobileHome && `
			background: ${props.theme.headerMobile};
			position: absolute;
			top: 44px;
		`}

		${props => props.mobileHome && `
			margin: 1rem 1rem 0 1rem;
			padding: 0;
			width: calc(100% - 2rem);

			${StyledInputContainer} > input {
				background: #556b7b;

				&:focus {
					background: ${props.theme.backgroundLight} !important;
				}
			}
		`}

		${StyledInputContainer} {
			flex: 1;
		}

		a, a button {
			height: 34px;
			width: 34px;
		}

		${InputInlineButton} {
			width: 36px;
		}
	`}

	${StyledInputContainer} {
		flex: 1;
		height: 100%;
		width: auto !important;
	}

	${StyledInputContainer} > input {
		border-radius: 24px;
		transition: 0.2s;
		width: 100%;

		${desktop`
			background: ${props => props.theme.id === 'dark' ? props.theme.backgroundLight : props.theme.background};
			border: 2px solid transparent;
			font-size: 1.1rem;
			height: 100%;
			padding: 0.75rem 0.75rem 0.75rem 2.75rem;

			${props => props.transparent === 'light' && `
				background: rgba(0, 0, 0, 0.05);
			`}

			${props => props.transparent === 'dark' && props.isEmpty && `
				background: rgba(255, 255, 255, 0.2);
			`}

			${props => !props.transparent && `
				box-shadow: ${props.theme.shadowSearch};
			`}

			& + ${StyledIcon} {
				left: 0.9rem;
				margin-top: -10px;

				height: 20px;
				width: 20px;

				${props => props.isEmpty && `
					color: ${props.theme.text};

					${props.transparent === 'dark' && `
						color: rgba(255, 255, 255, 0.5);
					`}
				`}
			}

			&::placeholder {
				color: ${props => props.theme.textLight};
				font-size: 1rem;
				text-align: left;

				${props => props.transparent === 'dark' && `
					color: rgba(255, 255, 255, 0.3);
				`}
			}

			&:hover {
				border-color: transparent;
			}

			&:hover:not(:focus) {
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

				& + ${StyledIcon} {
					color: ${props => props.theme.text} !important;

					${props => props.transparent === 'dark' && `
						color: rgba(255, 255, 255, 0.5) !important;
					`}
				}

				${props => props.transparent === 'light' && `
					background: rgba(0, 0, 0, 0.1);
					box-shadow: none;
				`}

				${props => props.transparent === 'dark' && props.isEmpty && `
					background: rgba(255, 255, 255, 0.1);

					& + ${StyledIcon}, &::placeholder {
						color: rgba(255, 255, 255, 0.7) !important;
					}
				`}
			}
		`}

		&:focus {
			background: ${props => props.theme.inputBackground};
			border-color: ${props => props.transparent === 'dark' || props.theme.id === 'dark' ? 'transparent !important' : props.theme.primary};
			box-shadow: none;
		}

		${props => props.hasFocus && `
			background: ${props.theme.background} !important;
		`}

		${mobile`
			background: ${props => props.theme.backgroundVeryLight};
			border: 0;
			border-radius: 10px;
			font-size: 1.1rem;
			font-weight: 500;
			padding: 0.75rem 0.75rem 0.75rem 2.5rem !important;

			&:focus {
				background: ${props => props.theme.background};
			}
		`}
	}
`

const RandomButton = styled.button`
	background: transparent;
	border: 0;
	padding: 0;

	border-radius: 4px;
	cursor: pointer;
	position: absolute;

	opacity: ${props => props.visible ? 1 : 0};
	pointer-events: ${props => props.visible ? 'auto' : 'none'};
	transition: all 0.1s;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
		min-width: 16px;
	}

	color: ${props => props.theme.textPrimaryVeryLight};

	${desktop`
		right: 12px;
		top: 8px;
		bottom: 8px;
		width: 32px;

		&:hover {
			background: rgba(0, 0, 0, 0.05);
			color: ${props => props.theme.textPrimaryVeryLightHover};
		}
	`}

	${mobile`
		top: 50%;
		right: 12px;
		transform: translate(0, -50%);

		height: 36px;
		width: 36px;

		svg {
			height: 16px;
			width: 16px;
			min-width: 16px;
		}

		&:active {
			background: rgba(0, 0, 0, 0.05);
			color: ${props => props.theme.textPrimaryVeryLightHover};
		}
	`}
`

const mapStateToProps = state => ({
	viewport: state.app.viewport
})

const enhance = compose(
	connect(mapStateToProps, null),
	withRouter,
	translate('general')
)

export default enhance(SiteSearch)
