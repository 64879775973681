export const getMoveElementIntoViewCoordinates = (hoveredElement, infoCardElement, xOffset = 0, placement = 'side') => {
	const hoverCard = infoCardElement.getBoundingClientRect()

	const overlapX = window.innerWidth - hoveredElement.left - hoveredElement.width - hoverCard.width
	const overlapY = window.innerHeight - hoveredElement.top - hoverCard.height

	let x
	let y

	if (placement === 'side') {
		if (overlapX < 0) {
			x = `${Math.floor(hoveredElement.left - hoverCard.width - xOffset)}px`
		} else {
			x = `${hoveredElement.width + hoveredElement.left + xOffset}px`
		}

		if (overlapY < 0) {
			y = `${Math.round(hoveredElement.top + overlapY)}px`
		} else {
			y = `${hoveredElement.top}px`
		}
	} else if (placement === 'bottom') {
		y = `${hoveredElement.top}px`

		if (overlapX < 0) {
			x = `${Math.floor(hoveredElement.left - hoverCard.width + hoveredElement.width - xOffset)}px`
		} else {
			x = `${hoveredElement.left + xOffset}px`
		}
	}

	return { x, y, overlapX: overlapX < 0, overlapY: overlapY < 0 }
}
