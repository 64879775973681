import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Flag = ({ country }) => {
	let flagIcon

	try {
		flagIcon = require(`flag-icon-css/flags/1x1/${country.toLowerCase()}.svg`)
	} catch (e) {

	}

	return <StyledFlag src={flagIcon} alt="" />
}

Flag.propTypes = {
	country: PropTypes.string.isRequired
}

export const StyledFlag = styled.img`
	box-shadow: 0 0 2px ${props => props.theme.shadowLight};

	border-radius: 1px;
	height: 14px;
	width: 14px;
`

export default Flag
