import styled from 'styled-components'
import { desktop } from '../../styles/media'
import Link from '../router/Link'

const EndOfListAction = styled(Link)`
	align-items: center;
	display: flex;

	border-radius: 4px;
	height: 40px;
	padding: 0.75rem 0.5rem;
	margin: ${props => props.noIndent ? '0' : '0 -0.5rem'};

	color: ${props => props.theme.primary};
	font-size: 0.8rem;
	font-weight: bold;
	text-decoration: none;

	svg {
		margin-right: 0.5rem;
		height: 12px;
		width: 12px;
	}

	${desktop`
		&:hover {
			background: ${props => props.theme.backgroundVeryLight};
		}
	`}
`

export default EndOfListAction
