import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom'
import Icon from '../../general/Icon'
import { desktop, isPrerender, isStandalone, mobile } from '../../../styles/media'
import { events, trackEvent } from '../../../helper/track'
import { StyledArtwork } from '../Artwork'
import EmptyCardImage from './EmptyCardImage'
import NotFound from '../../../images/notFound.svg'
import Image from '../../../pages/deck/components/Image'
import translate from '../../../helper/translate'
import Link from '../../router/Link'

class ZoomableImage extends Component {
	static propTypes = {
		button: PropTypes.bool,
		hover: PropTypes.bool,
		image: PropTypes.string,
		imageIsPlaceholder: PropTypes.bool,
		onLoadHeight: PropTypes.func,
		onZoomedChange: PropTypes.func,
		size: PropTypes.number,
		t: PropTypes.func.isRequired,
		zoomable: PropTypes.bool,
		zoomableOverlay: PropTypes.bool,
		zoomed: PropTypes.bool
	}

	static defaultProps = {
		button: false,
		hover: false,
		image: '',
		imageIsPlaceholder: false,
		onLoadHeight: null,
		onZoomedChange: () => {},
		size: 30,
		zoomable: false,
		zoomableOverlay: false,
		zoomed: null
	}

	constructor(props) {
		super(props)

		this.state = {
			loaded: false,
			isZoomed: false
		}

		this.imageRef = React.createRef()
		this.hoverImageRef = React.createRef()
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown, false)
		this.attachErrorListener()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.image !== this.props.image) {
			this.setState({
				loaded: false
			}, () => {
				this.attachErrorListener()
			})
		}

		if (prevProps.zoomed !== this.props.zoomed) {
			this.setState({
				isZoomed: this.props.zoomed
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown, false)
	}

	handleKeyDown = (event) => {
		const { isZoomed } = this.state

		if (event.keyCode === 27 && isZoomed) {
			this.toggleZoom(event)
		}
	}

	handleMouseEnter = () => {
		const { hover } = this.props

		if (hover) {
			const hoverImage = this.hoverImageRef.current.getBoundingClientRect()
			const hoveredImage = this.imageRef.current.getBoundingClientRect()
			const overlapY = window.innerHeight - hoveredImage.top - hoverImage.height

			if (overlapY < 0) {
				this.setState({
					flipHoverImage: true
				})
			} else {
				this.setState({
					flipHoverImage: false
				})
			}
		}
	}

	getHeight = () => {
		const { size } = this.props

		if (size === 30) return 30
		if (size === 160) return 233
		if (size === 250) return 365

		return 0
	}

	toggleZoom = (event) => {
		const { image, onZoomedChange, zoomed, zoomable, zoomableOverlay } = this.props

		event.preventDefault()
		event.stopPropagation()

		if (image !== '' && (zoomable || zoomableOverlay || zoomed !== null)) {
			this.setState(prevState => ({
				isZoomed: !prevState.isZoomed
			}), () => {
				onZoomedChange(this.state.isZoomed)

				if (this.state.isZoomed) {
					trackEvent(events.ZOOM_IN)
				} else {
					trackEvent(events.ZOOM_OUT)
				}
			})
		}
	}

	attachErrorListener = () => {
		if (this.imageRef) {
			const { current } = this.imageRef

			if (current) {
				current.addEventListener('error', this.onError, {
					once: true
				})
			}
		}
	}

	onError = () => {
		const { current } = this.imageRef

		if (current) {
			current.src = NotFound
			current.removeAttribute('srcSet')
		}
	}

	render() {
		const { button, hover, image, imageIsPlaceholder, onLoadHeight, size, t, zoomed, zoomable, zoomableOverlay } = this.props
		const { flipHoverImage, loaded, isZoomed } = this.state

		const hasImage = image !== '' && image !== null

		let element

		if (hasImage || isPrerender()) {
			const imagePath = `${image}${!isZoomed ? `-${size}` : ''}.jpg`
			const imagePath2x = `${image}${!isZoomed ? `-${size * 2}` : ''}.jpg`

			element = (
				<ImageElement
					src={imagePath}
					srcSet={`${imagePath} 1x, ${imagePath2x} 2x`}
					draggable={false}
					alt=""
					ref={this.imageRef}
					loaded={loaded || (this.imageRef.current && this.imageRef.current.complete)}
					onLoad={() => {
						if (this.imageRef.current && onLoadHeight !== null && !loaded) {
							window.setTimeout(() => {
								if (this.imageRef.current) {
									onLoadHeight(this.imageRef.current.height)
								}
							}, 100)
						}

						this.setState({ loaded: true })
					}}
				/>
			)
		} else {
			element = (
				<EmptyCardImage />
			)
		}

		const children = (
			<StyledImage
				button={button}
				size={size}
				isZoomed={isZoomed}
				zoomableOverlay={zoomableOverlay}
				hasImage={hasImage}
				canZoom={zoomable}
				hover={hover}
				onClick={(zoomable && zoomed === null) || zoomed === true || isZoomed === true ? this.toggleZoom : undefined}
				onMouseEnter={hasImage ? this.handleMouseEnter : undefined}
			>
				{isZoomed && (
					<>
						<Helmet>
							<html className="offscreen" />
							{isStandalone() && <meta name="viewport" content="width=device-width,initial-scale=1.0" />}
						</Helmet>
						<CloseIcon><Icon name="x" /></CloseIcon>
					</>
				)}

				{element}

				{hasImage && imageIsPlaceholder && (size >= 160 || isZoomed) && (
					<Placeholder isZoomed={isZoomed}>
						{t('general.placeholder')}
					</Placeholder>
				)}

				{hover && (
					<HoverImage flip={flipHoverImage} height={this.getHeight()} ref={this.hoverImageRef}>
						<Image size={250} image={image} />
						{imageIsPlaceholder && <Placeholder>{t('general.placeholder')}</Placeholder>}
					</HoverImage>
				)}

				{zoomableOverlay && !isZoomed && (
					<ZoomButton
						onClick={this.toggleZoom}
					>
						<Icon name="zoom-in" />
					</ZoomButton>
				)}
			</StyledImage>
		)

		return isZoomed ? ReactDOM.createPortal(
			children,
			document.getElementById('root-modal')
		) : children
	}
}

const Placeholder = styled.div`
	background: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.4rem;
	font-weight: bold;
	padding: 0.15rem;
	text-align: center;
	text-transform: uppercase;
	width: fit-content;

	pointer-events: none;

	position: absolute;
	left: 50%;
	bottom: 32%;
	transform: translate(-50%);
	z-index: ${props => props.theme.zLayer2};

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.isZoomed && `
		font-size: 0.9rem;
		padding: 0.25rem;
	`}
`

const HoverImage = styled.div`
	border-radius: 2px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	opacity: 0;
	transition: opacity 0.2s;
	pointer-events: none;

	position: absolute;
	left: calc(100% + 0.5rem);
	top: 0;
	z-index: -1;

	img {
		border-radius: 2px;
	}

	${props => props.flip && `
		transform: translate(0, calc(-100% + ${props.height}px));
	`}

	${mobile`
		display: none;
	`}
`

export const ZoomButton = styled(Link)`
	position: absolute;
	z-index: ${props => props.theme.zLayer2};

	background: rgba(0, 0, 0, 0.5);
	border: 0;
	border-radius: 50%;
	color: rgba(255, 255, 255, 0.9);
	display: none;
	padding: 0;
	transition: 0.1s;

	height: 36px;
	width: 36px;

	align-items: center;
	justify-content: center;

	${desktop`
		&:hover {
			background: rgba(0, 0, 0, 0.7);
			color: ${props => props.theme.white};
			cursor: default;
		}
	`}

	${mobile`
		display: flex;
	`}
`

export const StyledImage = styled.div`
	cursor: ${props => props.hasImage && props.canZoom ? 'zoom-in' : 'inherit'};
	${props => props.size === 30 && 'height: 30px;'}
	position: relative;
	user-select: none;

	${props => props.size === 30 && `
		height: 30px;
	`}

	${props => props.size === 160 && `
		height: 233px;
	`}

	${props => props.size === 250 && `
		height: 365px;
	`}

	> img, > picture img, ${StyledArtwork} {
		${props => props.size === 30 && `
			height: 30px;
			width: 21px;
			min-width: 21px;
		`}

		${props => props.size === 160 && `
			border-radius: 2px;
			height: 233px;
			width: 160px;
		`}

		${props => props.size === 250 && `
			border-radius: 2px;
			height: 365px;
			width: 250px;
		`}
	}

	${StyledArtwork} {
		border: 4px dashed ${props => props.theme.backgroundConcrete};

		${props => props.size === 30 && `
			border-width: 1px;
			padding: 0 4px;
		`}

		${props => props.size === 160 && `
			border-width: 3px;
		`}
	}

	${StyledArtwork} {
		align-items: center;
		display: flex;
		justify-content: center;

		font-size: 1rem;
		margin: 0;

		${props => props.size === 250 && `
			font-size: 1.2rem;
		`}

		svg {
			color: ${props => props.theme.backgroundConcrete};
			margin-right: 1rem;
			height: 50px;
			width: 50px;

			${props => props.size === 160 && `
				margin-right: 0.5rem;
				height: 40px;
				width: 40px;
			`}
		}
	}

	${ZoomButton} {
		top: 1rem;
		right: 1rem;
	}

	${props => props.isZoomed && `
		background: rgba(0, 0, 0, 0.95);
		cursor: zoom-out;

		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		width: 100% !important;
		z-index: ${props.theme.zLayer13};

		align-items: center;
		display: flex;
		justify-content: center;

		img {
			height: 100%;
			max-height: 100vh;
			max-height: calc(var(--vh, 1vh) * 100);
			width: auto;
		}
	`}

	${mobile`
		${props => props.isZoomed && `
			img {
				height: auto !important;
				object-fit: contain;
				width: 100% !important;
			}
		`}
	`}

	${desktop`
		${props => props.button && `
			cursor: pointer;
			transition: opacity 0.1s;

			&:hover {
				opacity: 0.8;
			}
		`}

		${props => props.hover && props.hasImage && `
			border-radius: 1px;

			&:hover {
				box-shadow: 0 0 0 1px ${props.theme.background}, 0 0 0 3px ${props.theme.primary};

				${HoverImage} {
					opacity: 1;
					transition: opacity 0.2s, z-index 0 0.2s;
					z-index: ${props.theme.zLayer4};
				}
			}

			&:not(:hover) ${HoverImage} img {
				height: 0;
			}
		`}

		${props => props.zoomableOverlay && `
			&:hover {
				${ZoomButton} {
					display: flex;
				}
			}
		`}
	`}
`

const ImageElement = styled.img`
	opacity: ${props => props.loaded ? 1 : 0};
	transition: opacity 0.05s;
	visibility: ${props => props.loaded ? 'visible' : 'hidden'};
`

export const CloseIcon = styled.span`
	position: absolute;
	top: 4rem;
	right: 4rem;

	color: rgba(255, 255, 255, 0.6) !important;
	cursor: pointer;
	transition: color 0.1s;

	svg {
		border: 0;
		color: currentColor !important;
		padding: 0;
		height: 25px;
		width: 25px;
	}

	&:hover {
		color: ${props => props.theme.white} !important;
	}

	${mobile`
		display: none;
	`}
`

const enhance = translate('general')

export default enhance(ZoomableImage)
