import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../general/Icon'
import { day } from '../../helper/date'
import translate from '../../helper/translate'
import Tooltip from '../general/Tooltip'

const Unreleased = ({ firstPrint, small, t }) => {
	const label = firstPrint ? t('general.firstPrintOn', { firstPrint: day(firstPrint) }, true) : t('general.cardUnreleased')

	return (
		<Tooltip title={small ? label : null}>
			<StyledUnreleased>
				<Icon name="sparkles" />
				{!small && <span>{label}</span>}
			</StyledUnreleased>
		</Tooltip>
	)
}

Unreleased.propTypes = {
	firstPrint: PropTypes.any,
	small: PropTypes.bool,
	t: PropTypes.func.isRequired
}

Unreleased.defaultProps = {
	firstPrint: null,
	small: false
}

export const StyledUnreleased = styled.div`
	align-items: center;
	display: flex;

	background: ${props => props.theme.yellowLight};
	border-radius: 4px;
	color: #000;
	font-size: 0.8rem;
	font-weight: 500;
	padding: 0.35rem;
	width: fit-content;

	svg {
		color: ${props => props.theme.yellow};
	}

	span {
        margin-left: 0.35rem;
	}
`

const enhance = translate('general')

export default enhance(Unreleased)
