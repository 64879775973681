import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import translate from '../../helper/translate'

const TrialLabel = ({ t }) => (
	<StyledTrialLabel>
		{t('general.cardclusterProTrial')}
	</StyledTrialLabel>
)

TrialLabel.propTypes = {
	t: PropTypes.func.isRequired
}

export const StyledTrialLabel = styled.div`
	align-items: center;
	display: flex;

	background: ${props => props.theme.primary};
	border-radius: 12px;
	padding: 0.15rem 0.5rem;
	color: #FFF;
	font-size: 0.7rem;
	font-weight: 600;
	text-decoration: none;
	transition: 0.2s;
	white-space: nowrap;
	width: fit-content;

	img:first-child {
		height: 16px;
		width: 16px;
		margin-right: 0.35rem;
	}

	svg {
		color: ${props => props.theme.textVeryLight};

		height: 16px;
		width: 16px;

		margin: 0 0.25rem;
		transition: 0.2s;
	}
`

const enhance = translate('general')

export default enhance(TrialLabel)
