const migrations = {
	1: (state) => {
		localStorage.removeItem('auth')
		localStorage.removeItem('setsView')
		localStorage.removeItem('setSortBy')
		localStorage.removeItem('setView')

		return state
	}
}

export default migrations
