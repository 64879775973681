import { BrowserMicroSentryClient } from '@micro-sentry/browser'

const client = new BrowserMicroSentryClient({
	dsn: 'https://d00b698239bf44d980f92ee459aea5bc@o468462.ingest.sentry.io/5496342',
	release: `cardcluster@${process.env.VERSION}`,
	autoSessionTracking: false,
	ignoreErrors: [
		'ChunkLoadError',
		'SecurityError: The operation is insecure.',
		'Unable to find node on an unmounted component.',
		'NotFoundError: Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node.',
		'Failed to register a ServiceWorker for scope',
		'Background Sync is disabled.',
		'Rejected',
		// old unsupported browsers
		'o.replaceAll is not a function'
	]
})

export default client
