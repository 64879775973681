import styled from 'styled-components'
import { desktop, mobile } from '../../styles/media'

const H1 = styled.h1`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;

	${props => props.marginBottom && 'margin-bottom: 1.5rem;'}

	${mobile`
		font-size: 1.5rem;

		small {
			margin-top: 1rem;
		}
	`}

	${desktop`
		small {
			margin-left: 1rem;
		}
	`}

	small {
		font-size: 1rem;
	}
`

export default H1
