const countries = {
	AC: {
		name_en: 'Ascension Island',
		name_de: 'Ascension'
	},
	AD: {
		name_en: 'Andorra',
		name_de: 'Andorra'
	},
	AE: {
		name_en: 'United Arab Emirates',
		name_de: 'Vereinigte Arabische Emirate'
	},
	AF: {
		name_en: 'Afghanistan',
		name_de: 'Afghanistan'
	},
	AG: {
		name_en: 'Antigua & Barbuda',
		name_de: 'Antigua und Barbuda'
	},
	AI: {
		name_en: 'Anguilla',
		name_de: 'Anguilla'
	},
	AL: {
		name_en: 'Albania',
		name_de: 'Albanien'
	},
	AM: {
		name_en: 'Armenia',
		name_de: 'Armenien'
	},
	AO: {
		name_en: 'Angola',
		name_de: 'Angola'
	},
	AQ: {
		name_en: 'Antarctica',
		name_de: 'Antarktis'
	},
	AR: {
		name_en: 'Argentina',
		name_de: 'Argentinien'
	},
	AS: {
		name_en: 'American Samoa',
		name_de: 'Amerikanisch-Samoa'
	},
	AT: {
		name_en: 'Austria',
		name_de: 'Österreich',
		region: 'europe'
	},
	AU: {
		name_en: 'Australia',
		name_de: 'Australien',
		region: 'oceania'
	},
	AW: {
		name_en: 'Aruba',
		name_de: 'Aruba'
	},
	AX: {
		name_en: 'Åland Islands',
		name_de: 'Ålandinseln'
	},
	AZ: {
		name_en: 'Azerbaijan',
		name_de: 'Aserbaidschan'
	},
	BA: {
		name_en: 'Bosnia & Herzegovina',
		name_de: 'Bosnien und Herzegowina'
	},
	BB: {
		name_en: 'Barbados',
		name_de: 'Barbados'
	},
	BD: {
		name_en: 'Bangladesh',
		name_de: 'Bangladesch'
	},
	BE: {
		name_en: 'Belgium',
		name_de: 'Belgien',
		region: 'europe'
	},
	BF: {
		name_en: 'Burkina Faso',
		name_de: 'Burkina Faso'
	},
	BG: {
		name_en: 'Bulgaria',
		name_de: 'Bulgarien',
		region: 'europe'
	},
	BH: {
		name_en: 'Bahrain',
		name_de: 'Bahrain'
	},
	BI: {
		name_en: 'Burundi',
		name_de: 'Burundi'
	},
	BJ: {
		name_en: 'Benin',
		name_de: 'Benin'
	},
	BL: {
		name_en: 'St. Barthélemy',
		name_de: 'St. Barthélemy'
	},
	BM: {
		name_en: 'Bermuda',
		name_de: 'Bermuda'
	},
	BN: {
		name_en: 'Brunei',
		name_de: 'Brunei Darussalam'
	},
	BO: {
		name_en: 'Bolivia',
		name_de: 'Bolivien'
	},
	BQ: {
		name_en: 'Caribbean Netherlands',
		name_de: 'Bonaire, Sint Eustatius und Saba'
	},
	BR: {
		name_en: 'Brazil',
		name_de: 'Brasilien'
	},
	BS: {
		name_en: 'Bahamas',
		name_de: 'Bahamas'
	},
	BT: {
		name_en: 'Bhutan',
		name_de: 'Bhutan'
	},
	BW: {
		name_en: 'Botswana',
		name_de: 'Botsuana'
	},
	BY: {
		name_en: 'Belarus',
		name_de: 'Belarus'
	},
	BZ: {
		name_en: 'Belize',
		name_de: 'Belize'
	},
	CA: {
		name_en: 'Canada',
		name_de: 'Kanada',
		states: {
			AB: {
				name: 'Alberta'
			},
			BC: {
				name: 'British Columbia'
			},
			MB: {
				name: 'Manitoba'
			},
			NB: {
				name: 'New Brunswick'
			},
			NL: {
				name: 'Newfoundland and Labrador'
			},
			NS: {
				name: 'Nova Scotia'
			},
			ON: {
				name: 'Ontario'
			},
			PE: {
				name: 'Prince Edward Island'
			},
			QC: {
				name: 'Quebec'
			},
			SK: {
				name: 'Saskatchewan'
			}
		}
	},
	CC: {
		name_en: 'Cocos (Keeling) Islands',
		name_de: 'Kokosinseln'
	},
	CD: {
		name_en: 'Congo - Kinshasa',
		name_de: 'Kongo-Kinshasa'
	},
	CF: {
		name_en: 'Central African Republic',
		name_de: 'Zentralafrikanische Republik'
	},
	CG: {
		name_en: 'Congo - Brazzaville',
		name_de: 'Kongo-Brazzaville'
	},
	CH: {
		name_en: 'Switzerland',
		name_de: 'Schweiz',
		region: 'europe'
	},
	CI: {
		name_en: 'Côte d’Ivoire',
		name_de: 'Côte d’Ivoire'
	},
	CK: {
		name_en: 'Cook Islands',
		name_de: 'Cookinseln'
	},
	CL: {
		name_en: 'Chile',
		name_de: 'Chile'
	},
	CM: {
		name_en: 'Cameroon',
		name_de: 'Kamerun'
	},
	CN: {
		name_en: 'China',
		name_de: 'China'
	},
	CO: {
		name_en: 'Colombia',
		name_de: 'Kolumbien'
	},
	CR: {
		name_en: 'Costa Rica',
		name_de: 'Costa Rica'
	},
	CU: {
		name_en: 'Cuba',
		name_de: 'Kuba'
	},
	CV: {
		name_en: 'Cape Verde',
		name_de: 'Cabo Verde'
	},
	CW: {
		name_en: 'Curaçao',
		name_de: 'Curaçao'
	},
	CX: {
		name_en: 'Christmas Island',
		name_de: 'Weihnachtsinsel'
	},
	CY: {
		name_en: 'Cyprus',
		name_de: 'Zypern',
		region: 'europe'
	},
	CZ: {
		name_en: 'Czechia',
		name_de: 'Tschechien'
	},
	DE: {
		name_en: 'Germany',
		name_de: 'Deutschland',
		region: 'europe'
	},
	DG: {
		name_en: 'Diego Garcia',
		name_de: 'Diego Garcia'
	},
	DJ: {
		name_en: 'Djibouti',
		name_de: 'Dschibuti'
	},
	DK: {
		name_en: 'Denmark',
		name_de: 'Dänemark',
		region: 'europe'
	},
	DM: {
		name_en: 'Dominica',
		name_de: 'Dominica'
	},
	DO: {
		name_en: 'Dominican Republic',
		name_de: 'Dominikanische Republik'
	},
	DZ: {
		name_en: 'Algeria',
		name_de: 'Algerien'
	},
	EA: {
		name_en: 'Ceuta & Melilla',
		name_de: 'Ceuta und Melilla'
	},
	EC: {
		name_en: 'Ecuador',
		name_de: 'Ecuador'
	},
	EE: {
		name_en: 'Estonia',
		name_de: 'Estland',
		region: 'europe'
	},
	EG: {
		name_en: 'Egypt',
		name_de: 'Ägypten'
	},
	EH: {
		name_en: 'Western Sahara',
		name_de: 'Westsahara'
	},
	ER: {
		name_en: 'Eritrea',
		name_de: 'Eritrea'
	},
	ES: {
		name_en: 'Spain',
		name_de: 'Spanien',
		region: 'europe'
	},
	ET: {
		name_en: 'Ethiopia',
		name_de: 'Äthiopien'
	},
	FI: {
		name_en: 'Finland',
		name_de: 'Finnland',
		region: 'europe'
	},
	FJ: {
		name_en: 'Fiji',
		name_de: 'Fidschi'
	},
	FK: {
		name_en: 'Falkland Islands',
		name_de: 'Falklandinseln'
	},
	FM: {
		name_en: 'Micronesia',
		name_de: 'Mikronesien'
	},
	FO: {
		name_en: 'Faroe Islands',
		name_de: 'Färöer'
	},
	FR: {
		name_en: 'France',
		name_de: 'Frankreich',
		region: 'europe'
	},
	GA: {
		name_en: 'Gabon',
		name_de: 'Gabun'
	},
	GB: {
		name_en: 'United Kingdom',
		name_de: 'Vereinigtes Königreich'
	},
	GD: {
		name_en: 'Grenada',
		name_de: 'Grenada'
	},
	GE: {
		name_en: 'Georgia',
		name_de: 'Georgien'
	},
	GF: {
		name_en: 'French Guiana',
		name_de: 'Französisch-Guayana'
	},
	GG: {
		name_en: 'Guernsey',
		name_de: 'Guernsey'
	},
	GH: {
		name_en: 'Ghana',
		name_de: 'Ghana'
	},
	GI: {
		name_en: 'Gibraltar',
		name_de: 'Gibraltar'
	},
	GL: {
		name_en: 'Greenland',
		name_de: 'Grönland'
	},
	GM: {
		name_en: 'Gambia',
		name_de: 'Gambia'
	},
	GN: {
		name_en: 'Guinea',
		name_de: 'Guinea'
	},
	GP: {
		name_en: 'Guadeloupe',
		name_de: 'Guadeloupe'
	},
	GQ: {
		name_en: 'Equatorial Guinea',
		name_de: 'Äquatorialguinea'
	},
	GR: {
		name_en: 'Greece',
		name_de: 'Griechenland',
		region: 'europe'
	},
	GS: {
		name_en: 'South Georgia & South Sandwich Islands',
		name_de: 'Südgeorgien und die Südlichen Sandwichinseln'
	},
	GT: {
		name_en: 'Guatemala',
		name_de: 'Guatemala'
	},
	GU: {
		name_en: 'Guam',
		name_de: 'Guam'
	},
	GW: {
		name_en: 'Guinea-Bissau',
		name_de: 'Guinea-Bissau'
	},
	GY: {
		name_en: 'Guyana',
		name_de: 'Guyana'
	},
	HK: {
		name_en: 'Hong Kong SAR China',
		name_de: 'Sonderverwaltungsregion Hongkong'
	},
	HN: {
		name_en: 'Honduras',
		name_de: 'Honduras'
	},
	HR: {
		name_en: 'Croatia',
		name_de: 'Kroatien',
		region: 'europe'
	},
	HT: {
		name_en: 'Haiti',
		name_de: 'Haiti'
	},
	HU: {
		name_en: 'Hungary',
		name_de: 'Ungarn',
		region: 'europe'
	},
	IC: {
		name_en: 'Canary Islands',
		name_de: 'Kanarische Inseln'
	},
	ID: {
		name_en: 'Indonesia',
		name_de: 'Indonesien'
	},
	IE: {
		name_en: 'Ireland',
		name_de: 'Irland',
		region: 'europe'
	},
	IL: {
		name_en: 'Israel',
		name_de: 'Israel'
	},
	IM: {
		name_en: 'Isle of Man',
		name_de: 'Isle of Man'
	},
	IN: {
		name_en: 'India',
		name_de: 'Indien'
	},
	IO: {
		name_en: 'British Indian Ocean Territory',
		name_de: 'Britisches Territorium im Indischen Ozean'
	},
	IQ: {
		name_en: 'Iraq',
		name_de: 'Irak'
	},
	IR: {
		name_en: 'Iran',
		name_de: 'Iran'
	},
	IS: {
		name_en: 'Iceland',
		name_de: 'Island'
	},
	IT: {
		name_en: 'Italy',
		name_de: 'Italien',
		region: 'europe'
	},
	JE: {
		name_en: 'Jersey',
		name_de: 'Jersey'
	},
	JM: {
		name_en: 'Jamaica',
		name_de: 'Jamaika'
	},
	JO: {
		name_en: 'Jordan',
		name_de: 'Jordanien'
	},
	JP: {
		name_en: 'Japan',
		name_de: 'Japan',
		region: 'asia'
	},
	KE: {
		name_en: 'Kenya',
		name_de: 'Kenia'
	},
	KG: {
		name_en: 'Kyrgyzstan',
		name_de: 'Kirgisistan'
	},
	KH: {
		name_en: 'Cambodia',
		name_de: 'Kambodscha'
	},
	KI: {
		name_en: 'Kiribati',
		name_de: 'Kiribati'
	},
	KM: {
		name_en: 'Comoros',
		name_de: 'Komoren'
	},
	KN: {
		name_en: 'St. Kitts & Nevis',
		name_de: 'St. Kitts und Nevis'
	},
	KP: {
		name_en: 'North Korea',
		name_de: 'Nordkorea'
	},
	KR: {
		name_en: 'South Korea',
		name_de: 'Südkorea'
	},
	KW: {
		name_en: 'Kuwait',
		name_de: 'Kuwait'
	},
	KY: {
		name_en: 'Cayman Islands',
		name_de: 'Kaimaninseln'
	},
	KZ: {
		name_en: 'Kazakhstan',
		name_de: 'Kasachstan'
	},
	LA: {
		name_en: 'Laos',
		name_de: 'Laos'
	},
	LB: {
		name_en: 'Lebanon',
		name_de: 'Libanon'
	},
	LC: {
		name_en: 'St. Lucia',
		name_de: 'St. Lucia'
	},
	LI: {
		name_en: 'Liechtenstein',
		name_de: 'Liechtenstein'
	},
	LK: {
		name_en: 'Sri Lanka',
		name_de: 'Sri Lanka'
	},
	LR: {
		name_en: 'Liberia',
		name_de: 'Liberia'
	},
	LS: {
		name_en: 'Lesotho',
		name_de: 'Lesotho'
	},
	LT: {
		name_en: 'Lithuania',
		name_de: 'Litauen',
		region: 'europe'
	},
	LU: {
		name_en: 'Luxembourg',
		name_de: 'Luxemburg',
		region: 'europe'
	},
	LV: {
		name_en: 'Latvia',
		name_de: 'Lettland',
		region: 'europe'
	},
	LY: {
		name_en: 'Libya',
		name_de: 'Libyen'
	},
	MA: {
		name_en: 'Morocco',
		name_de: 'Marokko'
	},
	MC: {
		name_en: 'Monaco',
		name_de: 'Monaco'
	},
	MD: {
		name_en: 'Moldova',
		name_de: 'Republik Moldau'
	},
	ME: {
		name_en: 'Montenegro',
		name_de: 'Montenegro'
	},
	MF: {
		name_en: 'St. Martin',
		name_de: 'St. Martin'
	},
	MG: {
		name_en: 'Madagascar',
		name_de: 'Madagaskar'
	},
	MH: {
		name_en: 'Marshall Islands',
		name_de: 'Marshallinseln'
	},
	MK: {
		name_en: 'North Macedonia',
		name_de: 'Nordmazedonien'
	},
	ML: {
		name_en: 'Mali',
		name_de: 'Mali'
	},
	MM: {
		name_en: 'Myanmar (Burma)',
		name_de: 'Myanmar'
	},
	MN: {
		name_en: 'Mongolia',
		name_de: 'Mongolei'
	},
	MO: {
		name_en: 'Macao SAR China',
		name_de: 'Sonderverwaltungsregion Macau'
	},
	MP: {
		name_en: 'Northern Mariana Islands',
		name_de: 'Nördliche Marianen'
	},
	MQ: {
		name_en: 'Martinique',
		name_de: 'Martinique'
	},
	MR: {
		name_en: 'Mauritania',
		name_de: 'Mauretanien'
	},
	MS: {
		name_en: 'Montserrat',
		name_de: 'Montserrat'
	},
	MT: {
		name_en: 'Malta',
		name_de: 'Malta',
		region: 'europe'
	},
	MU: {
		name_en: 'Mauritius',
		name_de: 'Mauritius'
	},
	MV: {
		name_en: 'Maldives',
		name_de: 'Malediven'
	},
	MW: {
		name_en: 'Malawi',
		name_de: 'Malawi'
	},
	MX: {
		name_en: 'Mexico',
		name_de: 'Mexiko'
	},
	MY: {
		name_en: 'Malaysia',
		name_de: 'Malaysia'
	},
	MZ: {
		name_en: 'Mozambique',
		name_de: 'Mosambik'
	},
	NA: {
		name_en: 'Namibia',
		name_de: 'Namibia'
	},
	NC: {
		name_en: 'New Caledonia',
		name_de: 'Neukaledonien'
	},
	NE: {
		name_en: 'Niger',
		name_de: 'Niger'
	},
	NF: {
		name_en: 'Norfolk Island',
		name_de: 'Norfolkinsel'
	},
	NG: {
		name_en: 'Nigeria',
		name_de: 'Nigeria'
	},
	NI: {
		name_en: 'Nicaragua',
		name_de: 'Nicaragua'
	},
	NL: {
		name_en: 'Netherlands',
		name_de: 'Niederlande',
		region: 'europe'
	},
	NO: {
		name_en: 'Norway',
		name_de: 'Norwegen',
		region: 'europe'
	},
	NP: {
		name_en: 'Nepal',
		name_de: 'Nepal'
	},
	NR: {
		name_en: 'Nauru',
		name_de: 'Nauru'
	},
	NU: {
		name_en: 'Niue',
		name_de: 'Niue'
	},
	NZ: {
		name_en: 'New Zealand',
		name_de: 'Neuseeland',
		region: 'oceania'
	},
	OM: {
		name_en: 'Oman',
		name_de: 'Oman'
	},
	PA: {
		name_en: 'Panama',
		name_de: 'Panama'
	},
	PE: {
		name_en: 'Peru',
		name_de: 'Peru'
	},
	PF: {
		name_en: 'French Polynesia',
		name_de: 'Französisch-Polynesien'
	},
	PG: {
		name_en: 'Papua New Guinea',
		name_de: 'Papua-Neuguinea'
	},
	PH: {
		name_en: 'Philippines',
		name_de: 'Philippinen'
	},
	PK: {
		name_en: 'Pakistan',
		name_de: 'Pakistan'
	},
	PL: {
		name_en: 'Poland',
		name_de: 'Polen',
		region: 'europe'
	},
	PM: {
		name_en: 'St. Pierre & Miquelon',
		name_de: 'St. Pierre und Miquelon'
	},
	PN: {
		name_en: 'Pitcairn Islands',
		name_de: 'Pitcairninseln'
	},
	PR: {
		name_en: 'Puerto Rico',
		name_de: 'Puerto Rico'
	},
	PS: {
		name_en: 'Palestinian Territories',
		name_de: 'Palästinensische Autonomiegebiete'
	},
	PT: {
		name_en: 'Portugal',
		name_de: 'Portugal',
		region: 'europe'
	},
	PW: {
		name_en: 'Palau',
		name_de: 'Palau'
	},
	PY: {
		name_en: 'Paraguay',
		name_de: 'Paraguay'
	},
	QA: {
		name_en: 'Qatar',
		name_de: 'Katar'
	},
	RE: {
		name_en: 'Réunion',
		name_de: 'Réunion'
	},
	RO: {
		name_en: 'Romania',
		name_de: 'Rumänien',
		region: 'europe'
	},
	RS: {
		name_en: 'Serbia',
		name_de: 'Serbien'
	},
	RU: {
		name_en: 'Russia',
		name_de: 'Russland'
	},
	RW: {
		name_en: 'Rwanda',
		name_de: 'Ruanda'
	},
	SA: {
		name_en: 'Saudi Arabia',
		name_de: 'Saudi-Arabien'
	},
	SB: {
		name_en: 'Solomon Islands',
		name_de: 'Salomonen'
	},
	SC: {
		name_en: 'Seychelles',
		name_de: 'Seychellen'
	},
	SD: {
		name_en: 'Sudan',
		name_de: 'Sudan'
	},
	SE: {
		name_en: 'Sweden',
		name_de: 'Schweden',
		region: 'europe'
	},
	SG: {
		name_en: 'Singapore',
		name_de: 'Singapur'
	},
	SH: {
		name_en: 'St. Helena',
		name_de: 'St. Helena'
	},
	SI: {
		name_en: 'Slovenia',
		name_de: 'Slowenien',
		region: 'europe'
	},
	SJ: {
		name_en: 'Svalbard & Jan Mayen',
		name_de: 'Spitzbergen und Jan Mayen'
	},
	SK: {
		name_en: 'Slovakia',
		name_de: 'Slowakei',
		region: 'europe'
	},
	SL: {
		name_en: 'Sierra Leone',
		name_de: 'Sierra Leone'
	},
	SM: {
		name_en: 'San Marino',
		name_de: 'San Marino'
	},
	SN: {
		name_en: 'Senegal',
		name_de: 'Senegal'
	},
	SO: {
		name_en: 'Somalia',
		name_de: 'Somalia'
	},
	SR: {
		name_en: 'Suriname',
		name_de: 'Suriname'
	},
	SS: {
		name_en: 'South Sudan',
		name_de: 'Südsudan'
	},
	ST: {
		name_en: 'São Tomé & Príncipe',
		name_de: 'São Tomé und Príncipe'
	},
	SV: {
		name_en: 'El Salvador',
		name_de: 'El Salvador'
	},
	SX: {
		name_en: 'Sint Maarten',
		name_de: 'Sint Maarten'
	},
	SY: {
		name_en: 'Syria',
		name_de: 'Syrien'
	},
	SZ: {
		name_en: 'Eswatini',
		name_de: 'Swasiland'
	},
	TA: {
		name_en: 'Tristan da Cunha',
		name_de: 'Tristan da Cunha'
	},
	TC: {
		name_en: 'Turks & Caicos Islands',
		name_de: 'Turks- und Caicosinseln'
	},
	TD: {
		name_en: 'Chad',
		name_de: 'Tschad'
	},
	TF: {
		name_en: 'French Southern Territories',
		name_de: 'Französische Süd- und Antarktisgebiete'
	},
	TG: {
		name_en: 'Togo',
		name_de: 'Togo'
	},
	TH: {
		name_en: 'Thailand',
		name_de: 'Thailand'
	},
	TJ: {
		name_en: 'Tajikistan',
		name_de: 'Tadschikistan'
	},
	TK: {
		name_en: 'Tokelau',
		name_de: 'Tokelau'
	},
	TL: {
		name_en: 'Timor-Leste',
		name_de: 'Timor-Leste'
	},
	TM: {
		name_en: 'Turkmenistan',
		name_de: 'Turkmenistan'
	},
	TN: {
		name_en: 'Tunisia',
		name_de: 'Tunesien'
	},
	TO: {
		name_en: 'Tonga',
		name_de: 'Tonga'
	},
	TR: {
		name_en: 'Turkey',
		name_de: 'Türkei'
	},
	TT: {
		name_en: 'Trinidad & Tobago',
		name_de: 'Trinidad und Tobago'
	},
	TV: {
		name_en: 'Tuvalu',
		name_de: 'Tuvalu'
	},
	TW: {
		name_en: 'Taiwan',
		name_de: 'Taiwan'
	},
	TZ: {
		name_en: 'Tanzania',
		name_de: 'Tansania'
	},
	UA: {
		name_en: 'Ukraine',
		name_de: 'Ukraine'
	},
	UG: {
		name_en: 'Uganda',
		name_de: 'Uganda'
	},
	UM: {
		name_en: 'U.S. Outlying Islands',
		name_de: 'Amerikanische Überseeinseln'
	},
	US: {
		name_en: 'United States',
		name_de: 'Vereinigte Staaten',
		region: 'northAmerica',
		states: {
			AL: {
				name: 'Alabama'
			},
			AK: {
				name: 'Alaska'
			},
			AR: {
				name: 'Arkansas'
			},
			AZ: {
				name: 'Arizona'
			},
			CA: {
				name: 'California'
			},
			CO: {
				name: 'Colorado'
			},
			CT: {
				name: 'Connecticut'
			},
			DE: {
				name: 'Delaware'
			},
			FL: {
				name: 'Florida'
			},
			GA: {
				name: 'Georgia'
			},
			HI: {
				name: 'Hawaii'
			},
			IA: {
				name: 'Iowa'
			},
			ID: {
				name: 'Idaho'
			},
			IL: {
				name: 'Illinois'
			},
			IN: {
				name: 'Indiana'
			},
			KS: {
				name: 'Kansas'
			},
			KY: {
				name: 'Kentucky'
			},
			LA: {
				name: 'Louisiana'
			},
			MA: {
				name: 'Massachusetts'
			},
			ME: {
				name: 'Maine'
			},
			MD: {
				name: 'Maryland'
			},
			MI: {
				name: 'Michigan'
			},
			MN: {
				name: 'Minnesota'
			},
			MO: {
				name: 'Missouri'
			},
			MS: {
				name: 'Mississippi'
			},
			MT: {
				name: 'Montana'
			},
			NE: {
				name: 'Nebraska'
			},
			NV: {
				name: 'Nevada'
			},
			NH: {
				name: 'New Hampshire'
			},
			NJ: {
				name: 'New Jersey'
			},
			NM: {
				name: 'New Mexico'
			},
			NY: {
				name: 'New York'
			},
			NC: {
				name: 'North Carolina'
			},
			ND: {
				name: 'North Dakota'
			},
			OH: {
				name: 'Ohio'
			},
			OK: {
				name: 'Oklahoma'
			},
			OR: {
				name: 'Oregon'
			},
			PA: {
				name: 'Pennsylvania'
			},
			RI: {
				name: 'Rhode Island'
			},
			SC: {
				name: 'South Carolina'
			},
			SD: {
				name: 'South Dakota'
			},
			TN: {
				name: 'Tennessee'
			},
			TX: {
				name: 'Texas'
			},
			UT: {
				name: 'Utah'
			},
			VT: {
				name: 'Vermont'
			},
			VA: {
				name: 'Virginia'
			},
			WA: {
				name: 'Washington'
			},
			WV: {
				name: 'West Virgina'
			},
			WI: {
				name: 'Wisconsin'
			},
			WY: {
				name: 'Wyoming'
			}
		}
	},
	UY: {
		name_en: 'Uruguay',
		name_de: 'Uruguay'
	},
	UZ: {
		name_en: 'Uzbekistan',
		name_de: 'Usbekistan'
	},
	VA: {
		name_en: 'Vatican City',
		name_de: 'Vatikanstadt'
	},
	VC: {
		name_en: 'St. Vincent & Grenadines',
		name_de: 'St. Vincent und die Grenadinen'
	},
	VE: {
		name_en: 'Venezuela',
		name_de: 'Venezuela'
	},
	VG: {
		name_en: 'British Virgin Islands',
		name_de: 'Britische Jungferninseln'
	},
	VI: {
		name_en: 'U.S. Virgin Islands',
		name_de: 'Amerikanische Jungferninseln'
	},
	VN: {
		name_en: 'Vietnam',
		name_de: 'Vietnam'
	},
	VU: {
		name_en: 'Vanuatu',
		name_de: 'Vanuatu'
	},
	WF: {
		name_en: 'Wallis & Futuna',
		name_de: 'Wallis und Futuna'
	},
	WS: {
		name_en: 'Samoa',
		name_de: 'Samoa'
	},
	XA: {
		name_en: 'Pseudo-Accents',
		name_de: 'Pseudo-Accents'
	},
	XB: {
		name_en: 'Pseudo-Bidi',
		name_de: 'Pseudo-Bidi'
	},
	XK: {
		name_en: 'Kosovo',
		name_de: 'Kosovo'
	},
	YE: {
		name_en: 'Yemen',
		name_de: 'Jemen'
	},
	YT: {
		name_en: 'Mayotte',
		name_de: 'Mayotte'
	},
	ZA: {
		name_en: 'South Africa',
		name_de: 'Südafrika'
	},
	ZM: {
		name_en: 'Zambia',
		name_de: 'Sambia'
	},
	ZW: {
		name_en: 'Zimbabwe',
		name_de: 'Simbabwe'
	}
}

export default countries
