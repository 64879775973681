import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Link from '../../../components/router/Link'
import { setHoverCard } from '../../../state/actions/app'
import { VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../../styles/media'
import { getCardImage } from '../../../helper/card'
import { hideHoverCard, loadHoverCard } from '../../../state/actionCreators/app'

const DeckGuideCardLink = ({ cardNameLanguage, deckCard, hideHoverCard, loadHoverCard, setHoverCard, text, viewport }) => {
	const linkRef = useRef(undefined)

	const onMouseOver = () => {
		const rect = linkRef.current.getBoundingClientRect()

		if (deckCard.card.cardEffect_en) {
			const image = getCardImage(deckCard, cardNameLanguage)

			const hoverCardData = {
				...deckCard.card,
				id: deckCard.id,
				image
			}

			setHoverCard(hoverCardData, rect, true)
		} else {
			loadHoverCard(deckCard.card.id, rect, true)
		}
	}

	const onMouseLeave = () => {
		hideHoverCard()
	}

	return (
		<Link
			to={viewport === VIEWPORT_DESKTOP ? `/card/${deckCard.card.slug}` : undefined}
			onClick={viewport === VIEWPORT_MOBILE ? onMouseOver : undefined}
			onMouseOver={viewport === VIEWPORT_DESKTOP ? onMouseOver : undefined}
			onMouseLeave={viewport === VIEWPORT_DESKTOP ? onMouseLeave : undefined}
			innerRef={linkRef}
		>
			{text}
		</Link>
	)
}

DeckGuideCardLink.propTypes = {
	cardNameLanguage: PropTypes.string,
	deckCard: PropTypes.object.isRequired,
	hideHoverCard: PropTypes.func.isRequired,
	loadHoverCard: PropTypes.func.isRequired,
	setHoverCard: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

DeckGuideCardLink.defaultProps = {
	cardNameLanguage: null
}

const enhance = connect(state => ({
	cardNameLanguage: state.persist.user.settings['app.cardNameLanguage'],
	viewport: state.app.viewport
}), dispatch => bindActionCreators({
	hideHoverCard,
	loadHoverCard,
	setHoverCard
}, dispatch))

export default enhance(DeckGuideCardLink)
