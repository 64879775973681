import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import SelectLabel from './SelectLabel'

const SelectOption = props => (
	<components.Option {...props}>
		<SelectLabel option data={props} />
	</components.Option>
)

SelectOption.propTypes = {
	data: PropTypes.any.isRequired,
	isDisabled: PropTypes.bool
}

SelectOption.defaultProps = {
	isDisabled: null
}

export default SelectOption
