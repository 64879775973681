import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { events, trackEvent } from '../../helper/track'
import { setError } from '../../state/actions/app'
import UpdateAvailable from '../UpdateAvailable'
import PageErrorFallback from './PageErrorFallback'
import sentry from '../sentry'

class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		deck: PropTypes.object,
		fallback: PropTypes.any,
		setError: PropTypes.func.isRequired,
		siteError: PropTypes.string
	}

	static defaultProps = {
		deck: null,
		fallback: null,
		siteError: null
	}

	constructor(props) {
		super(props)

		this.state = {
			hasError: false,
			type: null
		}
	}

	static getDerivedStateFromError(error) {
		// update state so the next render will show the fallback UI.
		return {
			hasError: true,
			type: JSON.stringify(error).includes('ChunkLoadError') ? 'update' : 'default',
			pathname: window.location.pathname
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.siteError !== this.props.siteError && this.props.siteError === null) {
			this.setState({
				hasError: false,
				type: null
			})
		}
	}

	componentDidCatch(error) {
		const { deck, setError } = this.props

		// a ChunkLoadError means that the user is requesting a part of the app that has been updated
		// In that case we trigger a reload to install the update
		if (JSON.stringify(error).includes('ChunkLoadError')) {
			trackEvent(events.INSTALL_UPDATE, error)

			if (!deck || deck.saved) {
				window.location.reload()
			}
		} else {
			sentry.report(error)
			trackEvent(events.JAVASCRIPT_ERROR, error)
			setError('javascriptError')
		}
	}

	render() {
		const { children, deck, fallback, siteError } = this.props
		const { hasError, pathname, type } = this.state

		if (siteError) {
			if (siteError === 'maintenance') {
				return <PageErrorFallback />
			}
		}

		if (hasError) {
			if (type === 'update' && (!deck || deck.saved)) {
				return null
			}

			if (type === 'update') {
				return <UpdateAvailable pathname={pathname} onCancel={() => this.setState({ hasError: false })} />
			}

			// You can render any custom fallback UI
			return fallback
		}

		return children
	}
}

const enhance = connect(state => ({
	deck: state.deckbuilder.deck,
	siteError: state.app.siteError
}), dispatch => bindActionCreators({
	setError
}, dispatch))

export default enhance(ErrorBoundary)
