import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import translate from '../../helper/translate'
import { desktop } from '../../styles/media'
import { sortByName } from '../../helper/sort'
import ArchetypeName from '../card/name/ArchetypeName'
import DeckName from './DeckName'
import DeckMeta from './DeckMeta'
import { Editing } from '../../layout/Menu/MainMenu'

const DeckLink = ({ browsePublicDecks, canEdit, deck, editing, excludedArchetypeIds, hideCommunity, isResult, nowrap, profile, showAddedToFavoritesAt, showArchivedAt, showArchetypes, showCreatedAt, showEvent, showEventDate, t, viewport }) => {
	const archetypes = showArchetypes && deck.archetypes ? deck.archetypes.filter(item => !excludedArchetypeIds || !excludedArchetypeIds.find(item2 => item2 === item.id)) : []

	return (
		<StyledDeckLink nowrap={nowrap}>
			{editing && <Editing>{t('general.yourEditing')}</Editing>}
			<DeckNameWrapper>
				<DeckName
					browsePublicDecks={browsePublicDecks}
					canEdit={canEdit}
					deck={deck}
					editing={editing}
					isResult={isResult}
					profile={profile}
					showEvent={showEvent}
				/>

				{archetypes.length > 0 && (
					<CardThemes>
						{sortByName(archetypes, 'name').map(archetype => (
							<ArchetypeName
								tiny
								hideNameMobile
								name={archetype.name}
								image={archetype.image}
								color={archetype.color}
								key={archetype.name}
							/>
						))}
					</CardThemes>
				)}
			</DeckNameWrapper>

			{!editing && (
				<DeckMeta
					browsePublicDecks={browsePublicDecks}
					canEdit={canEdit}
					deck={deck}
					hideCommunity={hideCommunity}
					isResult={isResult}
					profile={profile}
					showAddedToFavoritesAt={showAddedToFavoritesAt}
					showArchivedAt={showArchivedAt}
					showCreatedAt={showCreatedAt}
					showEventDate={showEventDate}
				/>
			)}
		</StyledDeckLink>
	)
}

DeckLink.propTypes = {
	browsePublicDecks: PropTypes.bool,
	canEdit: PropTypes.bool,
	deck: PropTypes.object.isRequired,
	editing: PropTypes.bool,
	excludedArchetypeIds: PropTypes.array,
	hideCommunity: PropTypes.bool,
	isResult: PropTypes.bool,
	nowrap: PropTypes.bool,
	profile: PropTypes.bool,
	showAddedToFavoritesAt: PropTypes.bool,
	showArchetypes: PropTypes.bool,
	showArchivedAt: PropTypes.bool,
	showCreatedAt: PropTypes.bool,
	showEvent: PropTypes.bool,
	showEventDate: PropTypes.bool,
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

DeckLink.defaultProps = {
	browsePublicDecks: false,
	canEdit: false,
	editing: false,
	excludedArchetypeIds: null,
	hideCommunity: false,
	isResult: false,
	nowrap: false,
	profile: false,
	showAddedToFavoritesAt: false,
	showArchetypes: false,
	showArchivedAt: false,
	showCreatedAt: false,
	showEvent: false,
	showEventDate: false
}

export const StyledDeckLink = styled.div`
	display: block;
    white-space: nowrap;
	flex: 1;
	overflow: hidden;

	${desktop`
		${props => !props.nowrap && `
			width: 50%;
		`}
	`}
`

const DeckNameWrapper = styled.div`
	display: flex;
`

const CardThemes = styled.div`
	display: flex;
	gap: 0.35rem;
	margin-left: auto;
	padding-left: 0.5rem;
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(DeckLink)
