import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Icon from '../../../components/general/Icon'
import Link from '../../../components/router/Link'
import Modal from '../../../components/router/Modal'
import { events, trackEvent } from '../../../helper/track'
import { domains } from '../../../../config'

const QrCode = ({ deck, disabled, onBeforeNavigate, t, userLanguage }) => {
	const [visible, setVisible] = useState(false)

	return (
		<>
			<Link
				onClick={() => {
					onBeforeNavigate()
					setVisible(true)
					trackEvent(events.SHOW_QR_CODE)
				}}
				disabled={disabled}
			>
				<Icon name="qrcode" />
				{t('general.qrCode')}
			</Link>

			{ReactDOM.createPortal((
				<Modal center visible={visible} onClickAway={() => setVisible(false)}>
					<ModalInner>
						<img src={`${process.env.NODE_ENV !== 'development' ? `https://${window.location.hostname || domains[userLanguage]}` : ''}/api/decks/${deck.slug}/qr`} alt="" />
					</ModalInner>
				</Modal>
			), document.getElementById('root-modal'))}
		</>
	)
}

QrCode.propTypes = {
	deck: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	onBeforeNavigate: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	userLanguage: PropTypes.string.isRequired
}

const ModalInner = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	img {
		height: 250px;
		width: 250px;
	}
`

const enhance = connect(state => ({
	userLanguage: state.persist.language
}))

export default enhance(QrCode)
