import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import languages from '../../data/languages'

const Mark = ({ children, language }) => (
	<>
		{languages[language].leftQuote}<StyledMark>{children}</StyledMark>{languages[language].rightQuote}
	</>
)

Mark.propTypes = {
	children: PropTypes.any.isRequired,
	language: PropTypes.string.isRequired
}

export const StyledMark = styled.span`
	color: ${props => props.theme.primary};
	font-weight: 600;
`

const enhance = connect(state => ({
	language: state.persist.language
}))

export default enhance(Mark)
