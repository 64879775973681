import styled from 'styled-components'

const KeyBinding = styled.span`
	align-items: center;
	display: inline-flex;

	color: ${props => props.theme.textLight};
	cursor: text;
	font-size: 0.7rem;
	font-weight: 600;
	pointer-events: none;

	span, strong {
		background: ${props => props.theme.backgroundLight};
		border-radius: 2px;
		font-size: 0.6rem;
		padding: 0.25rem 0.5rem;
		text-transform: uppercase;
	}

	span {
		margin-right: 0.25rem;
	}

	strong {
		margin-left: 0.25rem;
	}
`

export default KeyBinding
