const conditions = {
	GM: {
		name: 'Gem Mint',
		background: 'black',
		order: 1
	},
	M: {
		name: 'Mint',
		background: 'green',
		order: 2
	},
	NM: {
		name: 'Near Mint',
		background: 'green',
		order: 3,
		lighten: 0.1
	},
	EX: {
		name: 'Excellent',
		background: 'gold2',
		order: 4
	},
	GD: {
		name: 'Good',
		background: 'orange',
		order: 5
	},
	LP: {
		name: 'Lightly Played',
		background: 'red',
		order: 6,
		lighten: 0.2
	},
	PL: {
		name: 'Played',
		background: 'red',
		order: 7,
		lighten: 0.1
	},
	P: {
		name: 'Poor',
		background: 'red',
		order: 8
	}
}

export default conditions
