import styled from 'styled-components'
import { StyledIcon } from '../general/Icon'
import { desktop } from '../../styles/media'

const IconButton = styled.button`
    background: ${props => props.theme.backgroundVeryLight};
	border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 35px;
    width: 35px;
    min-width: 35px;

	align-items: center;
	display: flex;
	justify-content: center;

	color: ${props => props.theme.textLight};
	padding: 0;
	transition: 0.1s;

	${StyledIcon} {
		height: 20px;
		width: 20px;
	}

	${props => props.disabled && `
		color: ${props.theme.textVeryLight};
		cursor: default;
	`}

	${props => props.hasValue && `
		color: ${props.theme.primary};
	`}

	${props => props.small && `
		height: 28px;
		width: 28px;
		min-width: 28px;

		${StyledIcon} {
			height: 16px;
			width: 16px;
		}
	`}

	${desktop`
		${props => !props.disabled && `
			&:hover {
				background: ${props.theme.backgroundLight};
				color: ${props.theme.textLightened};
			}
		`}
	`}
`

export default IconButton
