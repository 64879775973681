const gradingServices = {
	PSA: {
		name: 'Professional Sports Authenticator',
		website: 'https://www.psacard.com/',
		scale: [
			{
				value: '10',
				name: 'GEM-MT 10 (Gem Mint)',
				nameShort: 'GEM-MT 10'
			},
			{
				value: '9',
				name: 'Mint 9 (Mint)',
				nameShort: 'Mint 9'
			},
			{
				value: '8',
				name: 'NM-MT 8 (Near Mint-Mint)',
				nameShort: 'NM-MT 8'
			},
			{
				value: '7',
				name: 'NM 7 (Near Mint)',
				nameShort: 'NM 7'
			},
			{
				value: '6',
				name: 'EX-MT 6 (Excellent-Mint)',
				nameShort: 'EX-MT 6'
			},
			{
				value: '5',
				name: 'EX 5 (Excellent)',
				nameShort: 'EX 5'
			},
			{
				value: '4',
				name: 'VG-EX 4 (Very Good-Excellent)',
				nameShort: 'VG-EX'
			},
			{
				value: '3',
				name: 'VG 3 (Very Good)',
				nameShort: 'VG 3'
			},
			{
				value: '2',
				name: 'Good 2 (Good)',
				nameShort: 'Good 2'
			},
			{
				value: '1.5',
				name: 'FR 1.5 (Fair)',
				nameShort: 'FR 1.5'
			},
			{
				value: '1',
				name: 'PR 1 (Poor)',
				nameShort: 'PR 1'
			}
		]
	},
	BGS: {
		name: 'Beckett Grading Service',
		website: 'https://www.beckett.com/',
		scale: [
			{
				value: '10',
				name: '10 - Pristine',
				nameShort: '10 - Pristine'
			},
			{
				value: '9.5',
				name: '9.5 - Gem Mint',
				nameShort: '9.5 - Gem Mint'
			},
			{
				value: '9',
				name: '9 - Mint',
				nameShort: '9 - Mint'
			},
			{
				value: '8.5',
				name: '8.5 - Near Mint-Mint +',
				nameShort: '8.5 - Near Mint-Mint +'
			},
			{
				value: '8',
				name: '8 - Near Mint-Mint',
				nameShort: '8 - Near Mint-Mint'
			},
			{
				value: '7.5',
				name: '7.5 - Near Mint +',
				nameShort: '7.5 - Near Mint +'
			},
			{
				value: '7',
				name: '7 - Near Mint',
				nameShort: '7 - Near Mint'
			},
			{
				value: '6.5',
				name: '6.5 - EX-NM+',
				nameShort: '6.5 - EX-NM+'
			},
			{
				value: '6',
				name: '6 - EX-NM (Excellent-Near Mint)',
				nameShort: '6 - EX-NM'
			},
			{
				value: '5.5',
				name: '5.5 - EX+',
				nameShort: '5.5 - EX+'
			},
			{
				value: '5',
				name: '5 - EX (Excellent)',
				nameShort: '5 - EX'
			},
			{
				value: '4.5',
				name: '4.5 - VG-EX+',
				nameShort: '4.5 - VG-EX+'
			},
			{
				value: '4',
				name: '4 - VG-EX (Very Good-Excellent)',
				nameShort: '4 - VG-EX'
			},
			{
				value: '3.5',
				name: '3.5 - VG+',
				nameShort: '3.5 - VG+'
			},
			{
				value: '3',
				name: '3 - VG (Very Good)',
				nameShort: '3 - VG'
			},
			{
				value: '2.5',
				name: '2.5 - G+',
				nameShort: '2.5 - G+'
			},
			{
				value: '2',
				name: '2 - G (Good)',
				nameShort: '2 - G'
			},
			{
				value: '1.5',
				name: '1.5 (Fair)',
				nameShort: '1.5'
			},
			{
				value: '1',
				name: '1 (Poor)',
				nameShort: '1'
			}
		]
	},
	SGC: {
		name: 'Serious Grading Culture',
		website: 'https://gosgc.com/',
		scale: [
			{
				value: '10',
				name: '10 PRI (Pristine)',
				nameShort: '10 PRI'
			},
			{
				value: '9.9',
				name: '10 GM (GEM)',
				nameShort: '10 GM'
			},
			{
				value: '9.5',
				name: '9.5 (MINT+)',
				nameShort: '9.5'
			},
			{
				value: '9',
				name: '9 (Mint)',
				nameShort: '9'
			},
			{
				value: '8.5',
				name: '8.5 (NM/MT+)',
				nameShort: '8.5'
			},
			{
				value: '8',
				name: '8 (NM/MT)',
				nameShort: '8'
			},
			{
				value: '7.5',
				name: '7.5 (NM+)',
				nameShort: '7.5'
			},
			{
				value: '7',
				name: '7 (NRMT)',
				nameShort: '7'
			},
			{
				value: '6.5',
				name: '6.5 (EX/NM+)',
				nameShort: '6.5'
			},
			{
				value: '6',
				name: '6 (EX/NM)',
				nameShort: '6'
			},
			{
				value: '5.5',
				name: '5.5 (EX+)',
				nameShort: '5.5'
			},
			{
				value: '5',
				name: '5 (EX)',
				nameShort: '5'
			},
			{
				value: '4.5',
				name: '4.5 (VG/EX+)',
				nameShort: '4.5'
			},
			{
				value: '4',
				name: '4 (VG/EX)',
				nameShort: '4'
			},
			{
				value: '3.5',
				name: '3.5 (VG+)',
				nameShort: '3.5'
			},
			{
				value: '3',
				name: '3 (VG)',
				nameShort: '3'
			},
			{
				value: '2.5',
				name: '2.5 (GOOD+)',
				nameShort: '2.5'
			},
			{
				value: '2',
				name: '2 (Good)',
				nameShort: '2'
			},
			{
				value: '1.5',
				name: '1.5 (FAIR)',
				nameShort: '1.5'
			},
			{
				value: '1',
				name: '1 (POOR)',
				nameShort: '1'
			}
		]
	},
	GSG: {
		name: 'Gold Standard Grading',
		website: 'https://gs-grading.com/',
		scale: [
			{
				value: '10',
				name: 'GOLD STANDARD CERTIFICATE OF 10',
				nameShort: '10'
			},
			{
				value: '9.5',
				name: '9,5 MINT PLUS',
				nameShort: '9.5'
			},
			{
				value: '9',
				name: '9,0 MINT',
				nameShort: '9,0'
			},
			{
				value: '8',
				name: '8,5 - 8,0 NEAR MINT - MINT',
				nameShort: '8,5 - 8,0'
			},
			{
				value: '7',
				name: '7,5 - 7,0 NEAR MINT',
				nameShort: '7,5 - 7,0'
			},
			{
				value: '6',
				name: '6,5 - 6,0 EXCELLENT - MINT',
				nameShort: '6,5 - 6,0'
			},
			{
				value: '5',
				name: '5,5 - 5,0 EXCELLENT',
				nameShort: '5,5 - 5,0'
			},
			{
				value: '4',
				name: '4,5 - 4,0 VERY GOOD - EXCELLENT',
				nameShort: '4,5 - 4,0'
			},
			{
				value: '3',
				name: '3,5 - 3,0 VERY GOOD',
				nameShort: '3,5 - 3,0'
			},
			{
				value: '2',
				name: '2,5 - 2,0 GOOD',
				nameShort: '2,5 - 2,0'
			},
			{
				value: '1',
				name: '1,5 - 1,0 POOR',
				nameShort: '1,5 - 1,0'
			}
		]
	},
	CMG: {
		name: 'Cardmarket Grading',
		website: 'https://www.cardmarket.com',
		scale: [
			{
				value: '10',
				name: '10 (Gem Mint)',
				nameShort: '10 (Gem Mint)'
			},
			{
				value: '9.5',
				name: '9.5 (Gem Mint)',
				nameShort: '9.5 (Gem Mint)'
			},
			{
				value: '9',
				name: '9 (Mint)',
				nameShort: '9 (Mint)'
			},
			{
				value: '8.5',
				name: '8.5 (Near Mint)',
				nameShort: '8.5 (Near Mint)'
			},
			{
				value: '8',
				name: '8 (Near Mint)',
				nameShort: '8 (Near Mint)'
			},
			{
				value: '7.5',
				name: '7.5 (Excellent)',
				nameShort: '7.5 (Excellent)'
			},
			{
				value: '7',
				name: '7 (Excellent)',
				nameShort: '7 (Excellent)'
			},
			{
				value: '6.5',
				name: '6.5 (Excellent)',
				nameShort: '6.5 (Excellent)'
			},
			{
				value: '6',
				name: '6 (Good)',
				nameShort: '6 (Good)'
			},
			{
				value: '5.5',
				name: '5.5 (Good)',
				nameShort: '5.5 (Good)'
			},
			{
				value: '5',
				name: '5 (Good)',
				nameShort: '5 (Good)'
			},
			{
				value: '4.5',
				name: '4.5 (Light Played)',
				nameShort: '4.5 (Light Played)'
			},
			{
				value: '4',
				name: '4 (Light Played)',
				nameShort: '4 (Light Played)'
			},
			{
				value: '3.5',
				name: '3.5 (Played)',
				nameShort: '3.5 (Played)'
			},
			{
				value: '3',
				name: '3 (Played)',
				nameShort: '3 (Played)'
			},
			{
				value: '2.5',
				name: '2.5 (Played)',
				nameShort: '2.5 (Played)'
			},
			{
				value: '2',
				name: '2 (Poor)',
				nameShort: '2 (Poor)'
			},
			{
				value: '1.5',
				name: '1.5 (Poor)',
				nameShort: '1.5 (Poor)'
			},
			{
				value: '1',
				name: '1 (Poor)',
				nameShort: '1 (Poor)'
			}
		]
	},
}

export default gradingServices
