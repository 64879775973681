import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PrintSetNumber from './PrintSetNumber'
import { mobile } from '../../styles/media'

const AlternateSetNumbers = ({ highlight, language, print }) => {
	const hasAltSetNumbers = !!(!language && print.set.languages && print.set.languages.find(item => item.alternateSetNumber || item.alternateLanguageCode))

	return (
		<StyledAlternateSetNumbers>
			{hasAltSetNumbers ? print.set.languages.map(setLanguage => (
				<AlternateSetNumber key={setLanguage.id}>
					<PrintSetNumber
						print={print}
						language={setLanguage.language}
						highlight={highlight}
					/>
				</AlternateSetNumber>
			)) : (
				<AlternateSetNumber>
					<PrintSetNumber
						print={print}
						language={language}
						highlight={highlight}
					/>
				</AlternateSetNumber>
			)}
		</StyledAlternateSetNumbers>
	)
}

AlternateSetNumbers.propTypes = {
	highlight: PropTypes.string,
	language: PropTypes.string,
	print: PropTypes.object.isRequired
}

AlternateSetNumbers.defaultProps = {
	highlight: null,
	language: null
}

export const StyledAlternateSetNumbers = styled.div`
	display: flex;
	gap: 0.25rem;
	flex-direction: column;

	font-family: ${props => props.theme.fontMonospace};
	font-size: 0.9rem;

	${mobile`
		font-size: 0.8rem;
	`}
`

const AlternateSetNumber = styled.div`
	white-space: nowrap;
`

export default AlternateSetNumbers
