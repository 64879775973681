import React from 'react'
import PropTypes from 'prop-types'
import { monsterAttributeIcons } from '../../images'

const MonsterAttributeIcon = ({ id }) => <img src={monsterAttributeIcons[id]} alt="" />

MonsterAttributeIcon.propTypes = {
	id: PropTypes.number.isRequired
}

export default MonsterAttributeIcon
