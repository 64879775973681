import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mobile } from '../../styles/media'
import rarities from '../../data/rarities'
import Rarity from './Rarity'
import Artwork from './Artwork'
import CardImage from './name/ZoomableImage'
import AlternateSetNumbers from './AlternateSetNumbers'
import LazyLoadIfNotPrerender from '../image/LazyLoadedImage'

const Print = ({ highlight, image, imageIsPlaceholder, language, print, rarityTiny, showArtwork, showImage, showRarity }) => (
	<StyledPrint>
		{showImage && (
			<LazyLoadIfNotPrerender height={30} offset={100}>
				<CardImage hover image={image} imageIsPlaceholder={imageIsPlaceholder} />
			</LazyLoadIfNotPrerender>
		)}
		{showRarity && (
			<Rarity tiny={rarityTiny} id={print.rarity}>{(rarities[print.rarity] && rarities[print.rarity].abbr) || '?'}</Rarity>
		)}
		<AlternateSetNumbers
			print={print}
			language={language}
			highlight={highlight}
		/>
		{showArtwork && print.artworkNumber && print.artworkNumber > 1 && <Artwork number={print.artworkNumber} />}
	</StyledPrint>
)

Print.propTypes = {
	highlight: PropTypes.string,
	image: PropTypes.string,
	imageIsPlaceholder: PropTypes.bool,
	language: PropTypes.string,
	print: PropTypes.object.isRequired,
	rarityTiny: PropTypes.bool,
	showArtwork: PropTypes.bool,
	showImage: PropTypes.bool,
	showRarity: PropTypes.bool
}

Print.defaultProps = {
	highlight: '',
	image: null,
	imageIsPlaceholder: false,
	language: null,
	rarityTiny: false,
	showArtwork: false,
	showImage: true,
	showRarity: false
}

export const StyledPrint = styled.div`
	color: ${props => props.theme.textLight};

	align-items: center;
	display: flex;
	width: 116px;
	min-width: 116px;

	${mobile`
		width: 107px;
		min-width: 107px;
	`}
`

export default Print
