import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../general/Icon'

const BooleanValue = ({ children, falseLabel, falseRed, small, trueLabel }) => {
	let icon = children ? 'check' : 'x'

	if (children === 'warning') {
		icon = 'exclamation'
	} else if (children === 'info') {
		icon = 'information-circle'
	}

	return (
		<Wrapper falseRed={!children && falseRed}>
			<StyledBooleanValue value={!!children} small={small} info={children === 'info'} warning={children === 'warning'}>
				<Icon name={icon} size={small ? 10 : 14} />
			</StyledBooleanValue>
			{((children && trueLabel) || (!children && falseLabel)) && (
				<Text value={!!children}>
					{children ? trueLabel : falseLabel}
				</Text>
			)}
		</Wrapper>
	)
}

BooleanValue.propTypes = {
	children: PropTypes.any,
	falseLabel: PropTypes.any,
	falseRed: PropTypes.bool,
	small: PropTypes.bool,
	trueLabel: PropTypes.any
}

BooleanValue.defaultProps = {
	children: null,
	falseLabel: null,
	falseRed: false,
	small: false,
	trueLabel: null
}

const Text = styled.span`
	color: ${props => props.value ? props.theme.green : props.theme.textLight};
	font-size: 0.9rem;
	margin-left: 0.5rem;
`

export const StyledBooleanValue = styled.span`
	background: ${props => props.value ? props.theme.green : props.theme.secondary};
	border-radius: 50%;
	color: #FFF;

	align-items: center;
	display: inline-flex;
	justify-content: center;

	${props => props.warning && `
		background: transparent;
		color: ${props.theme.orange};
	`}

	${props => props.info && `
		background: transparent;
		color: ${props.theme.textLight};
	`}
`

const Wrapper = styled.span`
	align-items: center;
	display: inline-flex;

	${props => props.falseRed && `
		${Text} {
			color: ${props.theme.red};
		}

		${StyledBooleanValue} {
			background: ${props.theme.red};
		}
	`}
`

export default BooleanValue
