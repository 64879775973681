import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translationsMapping } from '../../../config'
import cardTypes from '../../data/cardTypes'

const CardTypeName = ({ id, language, settings }) => {
	const appliedLanguage = settings['app.cardNameLanguage'] || translationsMapping[language]

	return cardTypes[id][`name_${appliedLanguage}`]
}

CardTypeName.propTypes = {
	id: PropTypes.number.isRequired,
	language: PropTypes.string.isRequired,
	settings: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
	language: state.persist.language,
	settings: state.persist.user.settings
})

const enhance = connect(mapStateToProps)

export default enhance(CardTypeName)
