import history from './history'

export const getScrollContainer = () => document.getElementById('app-main')

export const getLazyLoadScrollContainer = () => '#app-main'

export const getScrollPosition = () => getScrollContainer().scrollTop

export const scrollToPosition = (y, el = getScrollContainer()) => {
	if (typeof el.scrollTo === 'function') {
		el.scrollTo(0, y)
	} else {
		// Edge does not support scrollTo method
		el.scrollTop = y
	}
}

export const scrollTop = () => scrollToPosition(0)

export const scrollTo = y => scrollToPosition(y)

export const restoreScrollPosition = () => scrollTo(history.location.state.scrollPosition)
