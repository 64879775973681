import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import translate from '../../helper/translate'
import Message, { StyledMessage } from '../../components/form/Message'
import { mobile } from '../../styles/media'
import Link from '../../components/router/Link'
import Content from '../../components/layout/Content'

const SubscriptionUnpaidWarning = ({ location, t }) => {
	const [hidden, setHidden] = useState(location.pathname === '/settings/subscription' || location.pathname.startsWith('/settings/receipts'))

	return !hidden ? (
		<Wrapper>
			<Message error banner icon="exclamation" onClose={() => setHidden(true)}>
				<p>{t('general.subscriptionUnpaidWarning')}</p>
				<Link to="/settings/subscription" onBeforeNavigate={() => setHidden(true)}>{t('general.subscriptionUnpaidWarningLink')}</Link>
			</Message>
		</Wrapper>
	) : null
}

const Wrapper = styled(Content)`
	padding-bottom: 1rem !important;

	${StyledMessage} {
		margin-bottom: 0;
	}

	${mobile`
		padding: 0 !important;

		${StyledMessage} {
			border-radius: 0;
			font-size: 0.9rem;
		}
	`}
`

SubscriptionUnpaidWarning.propTypes = {
	location: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired
}

const enhance = compose(
	translate('general'),
	withRouter
)

export default enhance(SubscriptionUnpaidWarning)
