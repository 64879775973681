import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import darken from 'polished/lib/color/darken'
import Icon from '../general/Icon'
import Link from '../router/Link'
import { desktop } from '../../styles/media'
import Spinner, { StyledSpinner } from '../general/Spinner'

const ActionButton = React.forwardRef(({ as, children, color, disabled, external, href, htmlFor, loading, modal, icon, onClick, textColor, title, to }, ref) => (
	<StyledActionButton
		to={to}
		onClick={onClick}
		color={color}
		textColor={textColor}
		as={as}
		href={href}
		htmlFor={htmlFor}
		external={external}
		ref={ref}
		modal={modal}
		title={title}
		disabled={disabled}
		loading={loading ? 1 : undefined}
	>
		{icon && (
			<IconWrapper color={color} noText={!children}>
				<Icon name={icon} />
			</IconWrapper>
		)}
		{children && <Text>{children}</Text>}
		{loading && <Spinner />}
	</StyledActionButton>
))

ActionButton.propTypes = {
	as: PropTypes.string,
	children: PropTypes.any,
	color: PropTypes.string.isRequired,
	external: PropTypes.bool,
	disabled: PropTypes.bool,
	href: PropTypes.string,
	htmlFor: PropTypes.string,
	icon: PropTypes.string,
	loading: PropTypes.bool,
	modal: PropTypes.bool,
	onClick: PropTypes.func,
	textColor: PropTypes.string,
	title: PropTypes.string,
	to: PropTypes.string
}

ActionButton.defaultProps = {
	as: null,
	children: null,
	external: false,
	disabled: false,
	href: null,
	htmlFor: null,
	icon: null,
	loading: false,
	modal: false,
	onClick: () => {},
	textColor: '#FFF',
	title: null,
	to: null
}

const IconWrapper = styled.div`
	background: ${props => darken(0.1, props.color)};
	border-radius: ${props => props.noText ? '8px' : '8px 0 0 8px'};
	height: 34px;
	padding: 0.5rem;
	transition: background-color 0.2s;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}

	${props => props.noText && `
		width: 34px;
	`}
`

export const StyledActionButton = styled(Link)`
	background: ${props => props.color};
	border: 0;
	border-radius: 8px;
	color: ${props => props.textColor};
	padding: 0;
	text-decoration: none;
	transition: background-color 0.2s;
	white-space: nowrap;

	align-items: center;
	display: flex;

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;

		&, svg {
			color: rgba(255, 255, 255, 0.5) !important;
		}
	`}

	${props => props.loading && `
		pointer-events: none;
		position: relative;

		${IconWrapper}, ${Text} {
			opacity: 0;
		}
	`}

	${StyledSpinner} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		height: 100%;

		> div {
			height: 10px;
			width: 10px;
		}
	}

	${desktop`
		&:hover {
			background: ${props => darken(0.1, props.color)};
			color: ${props => props.textColor};

			${IconWrapper} {
				background: ${props => darken(0.1, darken(0.1, props.color))};
			}
		}
	`}
`

const Text = styled.div`
	align-items: center;
	display: flex;
	padding: 0.5rem;
`

export default ActionButton
