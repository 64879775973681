import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from '../../components/button/Button'
import { desktop, mobile } from '../../styles/media'
import Text from '../../components/general/Text'
import { cardNameLanguageMapping, domains, translationsMapping } from '../../../config'
import { changeLanguage } from '../../state/actionCreators/app'
import { getBrowserLanguage } from '../../helper/translate'
import { events, trackEvent } from '../../helper/track'

class LanguageMismatchBanner extends Component {
	constructor(props) {
		super(props)

		this.suggestedLanguage = getBrowserLanguage()
	}

	handleSubmit = () => {
		trackEvent(events.GO_TO_SUGGESTED_DOMAIN)
		window.location.href = `https://${domains[cardNameLanguageMapping[this.suggestedLanguage]] + window.location.pathname}`
	}

	handleClose = () => {
		trackEvent(events.STAY_ON_CURRENT_DOMAIN)
		try {
			localStorage.setItem('bannerSeenNewLanguageAvailableHint', true)
		} catch (e) {
		}
		this.props.changeLanguage(this.props.language)
	}

	render() {
		return (
			<Banner>
				<Hint>
					{this.suggestedLanguage === 'de-DE' && (
						<>
							<strong>→</strong> Gehe zu <strong>cardcluster.de</strong> um unsere Seite auf Deutsch anzuzeigen
						</>
					)}

					{(this.suggestedLanguage === 'en-US' || this.suggestedLanguage === 'en-UK') && (
						<>
							<strong>→</strong> Go to <strong>cardcluster.com</strong> to view our site in English
						</>
					)}

					{this.suggestedLanguage === 'en-FR' && (
						<>
							<strong>→</strong> Go to <strong>cardcluster.fr</strong> to view our site with french card texts
						</>
					)}

					{this.suggestedLanguage === 'en-IT' && (
						<>
							<strong>→</strong> Go to <strong>cardcluster.it</strong> to view our site with italian card texts
						</>
					)}

					{this.suggestedLanguage === 'en-ES' && (
						<>
							<strong>→</strong> Go to <strong>cardcluster.es</strong> to view our site with spanish card texts
						</>
					)}

					{this.suggestedLanguage === 'en-PT' && (
						<>
							<strong>→</strong> Go to <strong>cardcluster.pt</strong> to view our site with portuguese card texts
						</>
					)}
				</Hint>

				<Form>
					<Button onClick={this.handleSubmit}>
						{translationsMapping[this.suggestedLanguage] === 'de' ? 'Weiter' : 'Continue'}
					</Button>
					<Button secondary onClick={this.handleClose}>
						{translationsMapping[this.suggestedLanguage] === 'de' ? 'Nein, danke' : 'No, thanks'}
					</Button>
				</Form>
			</Banner>
		)
	}
}

LanguageMismatchBanner.propTypes = {
	changeLanguage: PropTypes.func.isRequired,
	language: PropTypes.string.isRequired
}

const Hint = styled(Text)`
	${mobile`
		margin-right: calc(1rem + 52px);
		min-height: 30px;
	`}
`

const Banner = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	background: ${props => props.theme.backgroundLight};
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding: 1.5rem;

	position: sticky;
	top: 0;
	z-index: ${props => props.theme.zLayer5};

	${desktop`
		margin-top: -1.4rem;
		margin-bottom: 1.5rem;
	`}

	${mobile`
		align-items: flex-start;
		flex-direction: column;
		padding: 1rem;
	`}
`

const Form = styled.div`
	display: flex;

	flex: 1;
	margin: 0 1rem;

	${mobile`
		margin: 1rem 0 0 0;
		width: 100%;
	`}
`

const mapStateToProps = state => ({
	language: state.persist.language
})

const mapDispatchToProps = dispatch => bindActionCreators({
	changeLanguage
}, dispatch)

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(LanguageMismatchBanner)
