import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Link from '../router/Link'
import { desktop } from '../../styles/media'
import themeLight from '../../styles/theme-light'

const SkipButton = React.forwardRef(({ children, color, ...other }, ref) => (
	<StyledSkipButton
		color={color}
		ref={ref}
		{...other}
	>
		{children}
	</StyledSkipButton>
))

SkipButton.propTypes = {
	children: PropTypes.any.isRequired,
	color: PropTypes.string
}

SkipButton.defaultProps = {
	color: themeLight.primary
}

export const StyledSkipButton = styled(Link)`
	color: ${props => props.theme.textLight};
	font-weight: 400;
	padding: 0.75rem 0.5rem;
	text-decoration: none;

	align-items: center;
	display: flex;
	justify-content: center;

	span + svg, svg + span {
		margin-left: 0.5rem;
	}

	${props => props.caps && `
		font-size: 0.9rem;
		font-weight: 500;
		text-transform: uppercase;
	`}

	${desktop`
		&:not([disabled]):hover {
			color: ${props => props.theme.text};
		}
	`}
`

export default SkipButton
