import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mobile } from '../../styles/media'
import Icon from './Icon'

class OnPageSpinner extends Component {
	static propTypes = {
		delay: PropTypes.bool
	}

	static defaultProps = {
		delay: true
	}

	state = {
		show: !this.props.delay
	}

	componentDidMount() {
		const { delay } = this.props

		if (delay) {
			this.loadingTimeout = window.setTimeout(() => {
				this.setState({
					show: true
				})
			}, 1000)
		}
	}

	componentWillUnmount() {
		clearTimeout(this.loadingTimeout)
	}

	render() {
		const { show } = this.state

		return (
			<StyledOnPageSpinner show={show}>
				<Icon spinner name="loading" size={30} />
			</StyledOnPageSpinner>
		)
	}
}

export const StyledOnPageSpinner = styled.div`
	opacity: ${props => props.show ? 1 : 0};
	padding-top: 2rem;
	transition: opacity 0.2s;

	${mobile`
		padding-top: 4rem;
		text-align: center;
	`}
`

export default OnPageSpinner
