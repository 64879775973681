import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from '../router/Modal'
import Button, { StyledButton } from '../button/Button'
import { setConfirm } from '../../state/actions/app'
import { mobile } from '../../styles/media'

class Confirm extends Component {
	state = {
		visible: this.props.confirm !== null,
		loading: false
	}

	componentDidUpdate(prevProps) {
		const { confirm } = this.props

		if (prevProps.confirm !== confirm) {
			this.setState({
				visible: confirm !== null
			}, () => {
				if (this.state.visible) {
					window.addEventListener('keydown', this.handleKeyDown)
				} else {
					window.removeEventListener('keydown', this.handleKeyDown)
				}
			})
		}
	}

	handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			this.close(true)
		}
	}

	handleClickSuccess = () => {
		const { confirm } = this.props

		if (confirm.loading) {
			this.setState({
				loading: true
			}, () => {
				confirm.loading(this.close)
			})
		} else {
			this.close()
			this.props.confirm.success()
		}
	}

	handleClickSecondary = () => {
		this.close()
		this.props.confirm.secondary()
	}

	handleClickAway = () => {
		const { loading } = this.state

		if (!loading) {
			this.close()
		}
	}

	close = (isCancel = false) => {
		const { confirm } = this.props

		this.setState({
			visible: false,
			loading: false
		}, () => {
			if (isCancel && confirm.cancel) {
				confirm.cancel()
			}
			window.setTimeout(() => setConfirm(null), 500)
		})
	}

	render() {
		const { confirm } = this.props
		const { visible, loading } = this.state

		return (
			<Modal
				center
				visible={visible}
				onClickAway={this.handleClickAway}
				width={confirm && confirm.width ? confirm.width : undefined}
			>
				<ConfirmText>{confirm && confirm.text}</ConfirmText>

				<ConfirmButtons>
					<Button
						red={confirm && (typeof confirm.submitRed !== 'undefined' ? confirm.submitRed : true)}
						onClick={this.handleClickSuccess}
						loading={loading}
						disabled={loading}
					>
						{confirm && confirm.submitText}
					</Button>
					{confirm && confirm.secondaryText && (
						<Button
							red={typeof confirm.secondaryRed !== 'undefined' ? confirm.secondaryRed : false}
							onClick={this.handleClickSecondary}
							disabled={loading}
						>
							{confirm && confirm.secondaryText}
						</Button>
					)}
					<Button
						secondary
						disabled={loading}
						onClick={this.close}
					>
						{confirm && confirm.cancelText}
					</Button>
				</ConfirmButtons>
			</Modal>
		)
	}
}

Confirm.propTypes = {
	confirm: PropTypes.object,
}

Confirm.defaultProps = {
	confirm: null
}

const ConfirmText = styled.p`
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 2rem;
	text-align: center;
`

const ConfirmButtons = styled.div`
	display: flex;
	justify-content: center;

	${mobile`
		${StyledButton} + ${StyledButton} {
			margin: 0 0 0 0.5rem;
		}

		${StyledButton} {
			border-radius: 8px;
			font-size: 0.9rem;
			flex: 1;
			padding: 0.5rem;
		}
	`}
`

const enhance = connect(state => ({
	confirm: state.app.confirm
}), dispatch => bindActionCreators({
	setConfirm
}, dispatch))

export default enhance(Confirm)
