import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { siteHasDeckStatus } from '../../../config'
import PlaceTrophy, { Place, StyledPlace } from '../event/PlaceTrophy'
import { formatPlace } from '../../helper/string'
import Icon from '../general/Icon'
import FormatName from '../ygo/FormatName'
import formats, { OCG } from '../../data/formats'
import { VIEWPORT_DESKTOP } from '../../styles/media'
import translate from '../../helper/translate'

const DeckName = ({ browsePublicDecks, canEdit, deck, editing, isResult, language, large, profile, showEvent, t, viewport }) => {
	const approvalPending = canEdit && !profile && deck.archived && deck.hasResult && deck.eventPlayers[0].needsApproval && !deck.eventPlayers[0].approvedAt
	const approvalDenied = canEdit && !profile && deck.archived && deck.hasResult && deck.eventPlayers[0].needsApproval && deck.eventPlayers[0].approvedAt

	return (
		<StyledDeckName large={large} notPlayable={deck.legalSince === null}>
			{siteHasDeckStatus && deck.status === 'draft' && !editing && !deck.hasResult && !isResult && <Draft>{t('general.deckStatusDraft')}</Draft>}

			{deck.hasResult && !isResult && (viewport === VIEWPORT_DESKTOP || !large) ? (
				<PlaceTrophy medium={large} place={deck.eventPlayers[0].place}>
					<Place tops={deck.eventPlayers[0].placeRaw <= 128}>{formatPlace(deck.eventPlayers[0].place, language)}</Place>
					<span>{deck.name}</span>
				</PlaceTrophy>
			) : <span>{deck.name}</span>}

			{!large && deck.youtubeVideoId && !editing && <HasYouTubeVideo hasResult={deck.hasResult}><Icon name="film" size={16} /></HasYouTubeVideo>}
			{!large && deck.description && !deck.youtubeVideoId && !editing && <HasYouTubeVideo><Icon name="document-text" size={16} /></HasYouTubeVideo>}
			{deck.legalSinceSet && !deck.legalStatus && !editing && <LegalSinceSet large={large}><strong>POST</strong> {deck.legalSinceSet.setNumber || deck.legalSinceSet.name}</LegalSinceSet>}
			{deck.legalStatus === 'ocg' && !editing && <LegalSinceSet large={large}><FormatName format={OCG} /></LegalSinceSet>}
			{!large && deck.legalSince && deck.main !== formats[deck.format].deckMin && !editing && <LegalSinceSet>{deck.main} {t('general.card', { count: deck.main })}</LegalSinceSet>}

			{viewport === VIEWPORT_DESKTOP && showEvent && deck.hasResult && (
				<PlaceEvent hasTrophyIcon={deck.eventPlayers[0].place === '1' || deck.eventPlayers[0].place === '2'}>
					&nbsp;@&nbsp;
					{deck.eventPlayers[0].event.name}
				</PlaceEvent>
			)}

			{canEdit && !profile && !browsePublicDecks && deck.archived && !approvalPending && !approvalDenied && (
				<Archived>{t('general.archived')}</Archived>
			)}

			{approvalPending && (
				<ApprovalPending>{t('general.eventPlayerApprovalPending')}</ApprovalPending>
			)}

			{approvalDenied && (
				<ApprovalDenied>{t('general.eventPlayerDenied')}</ApprovalDenied>
			)}
		</StyledDeckName>
	)
}

DeckName.propTypes = {
	browsePublicDecks: PropTypes.bool,
	canEdit: PropTypes.bool,
	deck: PropTypes.object.isRequired,
	editing: PropTypes.bool,
	isResult: PropTypes.bool,
	language: PropTypes.string.isRequired,
	large: PropTypes.bool,
	profile: PropTypes.bool,
	showEvent: PropTypes.bool,
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

DeckName.defaultProps = {
	browsePublicDecks: false,
	canEdit: false,
	editing: false,
	isResult: false,
	large: false,
	profile: false,
	showEvent: false
}

export const StyledDeckName = styled.div`
	font-weight: 500;

	max-width: 100%;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;

	${props => props.notPlayable && `
		color: ${props.theme.textLight};
		font-weight: 400;
	`}

	${props => !props.large ? `
		height: 20px;

		${StyledPlace} {
			svg {
				height: 14px;
				width: 14px;
				margin-right: 0.3rem;
			}
		}
	` : `
		${StyledPlace} {
			svg {
				height: 20px;
				width: 20px;
			}
		}

		${Place} {
			font-size: 0.9rem;
			margin-right: 0.5rem;
		}
	`}
`

const Draft = styled.span`
	background: ${props => props.theme.backgroundLight};
	border-radius: 4px;
	color: ${props => props.theme.textSlightlyLight};
	font-size: 0.6rem;
	font-weight: bold;
	padding: 0.1rem 0.2rem;
	text-transform: uppercase;
	margin-right: 0.25rem;
`

const HasYouTubeVideo = styled.span`
	vertical-align: middle;

  	${props => props.hasResult && `
  		position: relative;
  		top: 3px;
  	`}

	svg {
		margin-left: 0.25rem;
	}
`

const LegalSinceSet = styled.span`
	color: ${props => props.theme.textLight};
	font-size: 0.8rem;
	margin-left: 0.25rem;

	strong {
		font-weight: 700;
	}

	${props => props.large && `
		font-size: 1rem;
		margin-left: 0.35rem;
	`}
`

const PlaceEvent = styled.div`
	display: inline;
	color: ${props => props.theme.textLight};
	font-size: 0.7rem;
	vertical-align: text-bottom;

	${props => props.hasTrophyIcon && `
		position: relative;
		top: -3px;
	`}
`

export const Archived = styled.div`
	background: ${props => props.theme.backgroundLight};
	border-radius: 4px;
	color: ${props => props.theme.textLight};
	display: inline;
	font-size: 0.6rem;
	font-weight: bold;
	padding: 0.15rem 0.25rem;
	margin-left: 0.35rem;
	text-transform: uppercase;
	vertical-align: middle;
`

const ApprovalPending = styled(Archived)`
	background: ${props => props.theme.orangeLight};
	color: ${props => props.theme.shadowThick};
`

const ApprovalDenied = styled(Archived)`
	background: ${props => props.theme.redLight};
	color: ${props => props.theme.shadowThick};
`

const enhance = compose(
	connect(state => ({
		language: state.persist.language,
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(DeckName)
