import { VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../styles/media'
import store from '../state/store'
import { setOffline, setViewport } from '../state/actions/app'

/**
 * Fix variable 100vh on mobile devices
 */
export const updateViewportHeight = () => {
	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	const vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export const updateViewport = () => {
	if (window.matchMedia('(min-width: 1025px)').matches) {
		store.dispatch(setViewport(VIEWPORT_DESKTOP))
	} else {
		store.dispatch(setViewport(VIEWPORT_MOBILE))

		if (window.matchMedia('(orientation: portrait)').matches) {
			document.getElementsByTagName('html')[0].style.height = '100vh'

			setTimeout(() => {
				document.getElementsByTagName('html')[0].style.height = '100%'
				updateViewportHeight()
			}, 200)
		}
	}

	updateViewportHeight()
}

export const setOfflineStatus = status => store.dispatch(setOffline(status))

export const preventBounce = (event) => {
	const { viewport } = store.getState().app
	const { authToken } = store.getState().persist

	if (authToken && viewport === VIEWPORT_MOBILE) {
		if (!document.getElementById('app-main').contains(event.target) && !document.getElementById('root-modal').contains(event.target)) {
			event.preventDefault()
		}
	}
}
