const effectActions = {
	0: {
		keywords: 'own',
		name_de: 'Besitzen',
		name_en: 'Own'
	},
	1: {
		keywords: 'possess',
		name_de: 'Besitzen (ursprünglich)',
		name_en: 'Possess'
	},
	2: {
		keywords: 'control',
		name_de: 'Kontrollieren',
		name_en: 'Control'
	},
	3: {
		keywords: 'look',
		name_de: 'Ansehen',
		name_en: 'Look'
	},
	4: {
		keywords: 'reveal',
		name_de: 'Zeigen',
		name_en: 'Reveal'
	},
	5: {
		keywords: 'excavate',
		name_de: 'Ausgraben',
		name_en: 'Excavate'
	},
	6: {
		keywords: 'add',
		name_de: 'Hinzufügen',
		name_en: 'Add'
	},
	7: {
		keywords: 'draw',
		name_de: 'Ziehen',
		name_en: 'Draw'
	},
	8: {
		keywords: 'shuffle',
		name_de: 'Mischen',
		name_en: 'Shuffle'
	},
	9: {
		keywords: 'random',
		name_de: 'Zufällig',
		name_en: 'Random'
	},
	10: {
		keywords: 'summon',
		name_de: 'Beschwören',
		name_en: 'Summon'
	},
	11: {
		keywords: 'set',
		name_de: 'Setzen',
		name_en: 'Set'
	},
	12: {
		keywords: 'flip',
		name_de: 'Umdrehen',
		name_en: 'Flip'
	},
	13: {
		keywords: 'change',
		name_de: 'Kampfposition ändern',
		name_en: 'Change battle position'
	},
	14: {
		keywords: 'send',
		name_de: 'Legen',
		name_en: 'Send'
	},
	15: {
		keywords: 'discard',
		name_de: 'Abwerfen',
		name_en: 'Discard'
	},
	16: {
		keywords: 'tribute',
		name_de: 'Als Tribut anbieten',
		name_en: 'Tribute'
	},
	17: {
		keywords: 'destroy',
		name_de: 'Zerstören',
		name_en: 'Destroy'
	},
	18: {
		keywords: 'banish',
		name_de: 'Verbannen',
		name_en: 'Banish'
	},
	19: {
		keywords: 'attach',
		name_de: 'Anhängen',
		name_en: 'Attach'
	},
	20: {
		keywords: 'detach',
		name_de: 'Abhängen',
		name_en: 'Detach'
	},
	21: {
		keywords: 'equip',
		name_de: 'Ausrüsten',
		name_en: 'Equip'
	},
	22: {
		keywords: 'unequip',
		name_de: 'Ausklinken',
		name_en: 'Unequip'
	},
	23: {
		keywords: 'leave',
		name_de: 'Verlassen',
		name_en: 'Leave'
	},
	24: {
		keywords: '"return to the hand"',
		name_de: 'Auf die Hand zurückgeben',
		name_en: 'Return to the hand (bounce)'
	},
	25: {
		keywords: 'place "top of the deck"',
		name_de: 'Auf das Deck legen',
		name_en: 'Place on the top of the deck (spin)'
	},
	26: {
		keywords: 'return',
		name_de: 'Zurückkehren',
		name_en: 'Return'
	},
	27: {
		keywords: 'attack',
		name_de: 'Angreifen',
		name_en: 'Attack'
	},
	28: {
		keywords: 'battle',
		name_de: 'Kämpfen',
		name_en: 'Battle'
	},
	29: {
		keywords: 'place',
		name_de: 'Platzieren',
		name_en: 'Place'
	},
	30: {
		keywords: 'pay',
		name_de: 'Zahlen',
		name_en: 'Pay'
	},
	31: {
		keywords: 'declare',
		name_de: 'Deklarieren',
		name_en: 'Declare'
	},
	32: {
		keywords: 'target',
		name_de: 'Wählen',
		name_en: 'Target'
	},
	33: {
		keywords: 'respond',
		name_de: 'Reagieren',
		name_en: 'Respond'
	},
	34: {
		keywords: 'activate',
		name_de: 'Aktivieren',
		name_en: 'Activate'
	},
	35: {
		keywords: 'chain',
		name_de: 'Kette',
		name_en: 'Chain'
	},
	36: {
		keywords: 'resolve',
		name_de: 'Auflösen',
		name_en: 'Resolve'
	},
	37: {
		keywords: 'unaffected',
		name_de: 'Unberührt',
		name_en: 'Unaffected'
	},
	38: {
		keywords: 'negate',
		name_de: 'Negieren',
		name_en: 'Negate'
	},
	39: {
		keywords: 'become',
		name_de: 'Werden',
		name_en: 'Become'
	},
	40: {
		keywords: 'offer handshake',
		name_de: 'Handschlag anbieten',
		name_en: 'Offer handshake'
	},
	41: {
		keywords: 'accept handshake',
		name_de: 'Handschlag akzeptieren',
		name_en: 'Accept handshake'
	},
	42: {
		keywords: 'remove',
		name_de: 'Entfernen',
		name_en: 'Remove'
	},
	43: {
		keywords: 'win',
		name_de: 'Gewinnen',
		name_en: 'Win'
	},
	44: {
		keywords: 'turn',
		name_de: 'Zug',
		name_en: 'Turn'
	},
	45: {
		keywords: 'increase',
		name_de: 'Erhöhen',
		name_en: 'Increase'
	},
	46: {
		keywords: 'decrease',
		name_de: 'Senken',
		name_en: 'Decrease'
	},
	47: {
		keywords: 'reduce',
		name_de: 'Reduzieren',
		name_en: 'Reduce'
	},
	48: {
		keywords: 'gain',
		name_de: 'Erhalten',
		name_en: 'Gain'
	},
	49: {
		keywords: '"take damage"',
		name_de: 'Schaden nehmen',
		name_en: 'Take damage'
	},
	50: {
		keywords: 'inflict',
		name_de: 'Schaden zufügen',
		name_en: 'Inflict damage (burn)'
	},
	51: {
		keywords: 'skip',
		name_de: 'Überspringen',
		name_en: 'Skip'
	},
	52: {
		keywords: 'perform',
		name_de: 'Durchführen',
		name_en: 'Perform'
	},
	53: {
		keywords: 'conduct',
		name_de: 'Ausführen',
		name_en: 'Conduct'
	},
	54: {
		keywords: 'make',
		name_de: 'Durchführen (make)',
		name_en: 'make'
	},
	55: {
		keywords: 'rearrange',
		name_de: 'Neu anordnen',
		name_en: 'Rearrange'
	},
	56: {
		keywords: 'remain',
		name_de: 'Verbleiben',
		name_en: 'Remain'
	}
}

export default effectActions
