import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mobile } from '../../styles/media'

const Artwork = ({ number }) => (
	<StyledArtwork>
		#{number}
	</StyledArtwork>
)

Artwork.propTypes = {
	number: PropTypes.number.isRequired
}

export const StyledArtwork = styled.span`
	background: ${props => props.theme.sage};
	border-radius: 2px;
	color: #FFF;
	font-size: 0.7rem;
	font-weight: 600;
	position: relative;

	align-items: center;
	display: inline-flex;
	justify-content: center;

	vertical-align: middle;

	margin-left: 0.5rem;

	height: 18px;
	width: 22px;
	min-width: 22px;

	${mobile`
		font-size: 0.6rem;
		margin-left: 0.25rem;

		height: 15px;
		width: 18px;
		min-width: 18px;
	`}
`

export default Artwork
