import React, { useEffect, useState } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DropDown from '../../components/button/DropDown'
import translate from '../../helper/translate'
import { get, post } from '../../helper/api'
import DeckCommentLink from '../../pages/deck/DeckCommentLink'
import Icon from '../../components/general/Icon'
import Message from '../../components/form/Message'
import OnPageSpinner, { StyledOnPageSpinner } from '../../components/general/OnPageSpinner'
import ActionButton from '../../components/button/ActionButton'
import themeLight from '../../styles/theme-light'
import { setUnreadComments } from '../../state/actions/persist'
import Link from '../../components/router/Link'
import { desktop, mobile } from '../../styles/media'
import CoverImage from '../../pages/deck/components/CoverImage'
import ThickHeading from '../../components/text/ThickHeading'
import { RegionLink, Regions } from '../../components/card/Regions'
import { CommentMeta } from '../../pages/deck/DeckComment'

const CommentsDropDown = ({ authToken, button, rejectedComments, setUnreadComments, t, unreadComments }) => {
	const [visible, setVisible] = useState(false)
	const [tab, setTab] = useState('unread')
	const [unread, setUnread] = useState(null)
	const [bell, setBell] = useState(null)
	const [rejected, setRejected] = useState(null)

	const loadUnread = (page = 1) => {
		get(`comments/unread?page=${page}`, ({ data: comments }) => {
			setUnread(comments)
		})
	}

	const loadBell = (page = 1) => {
		get(`comments/subscribed?page=${page}`, ({ data: decks }) => {
			setBell(decks)
		})
	}

	const loadRejected = (page = 1) => {
		get(`comments/rejected?page=${page}`, ({ data: comments }) => {
			setRejected(comments)
		})
	}

	const markAllAsRead = () => {
		post('comments/markAllAsRead', null, (count) => {
			setUnread([])

			if (unreadComments >= count) {
				setUnreadComments(unreadComments - count)
			}
		})
	}

	useEffect(() => {
		if (visible) {
			if (authToken) {
				loadUnread()
				loadBell()
			} else {
				setUnread(null)
				setBell(null)
			}
		}
	}, [authToken, unreadComments, visible])

	useEffect(() => {
		if (visible) {
			if (authToken && (rejected !== null || rejectedComments > 0)) {
				loadRejected()
			} else {
				setRejected(null)
			}
		}
	}, [authToken, rejectedComments, visible])

	return (
		<DropDown
			mobileFullHeight
			button={button}
			top={50}
			right={9}
			width={500}
			onVisibleChange={setVisible}
		>
			{onBeforeNavigate => (
				<StyledCommentsDropDown>
					<ThickHeading>{t('general.comments')}</ThickHeading>

					<Regions>
						<RegionLink
							dontCollapse
							onClick={() => setTab('unread')}
							active={tab === 'unread'}
						>
							<Icon name="sparkles" />
							<span>{t('general.unread')} {unread && unread.length > 0 && ` (${unread.length})`}</span>
						</RegionLink>
						<RegionLink
							dontCollapse
							onClick={() => setTab('bell')}
							active={tab === 'bell'}
						>
							<Icon name="bell" />
							<span>{t('general.subscribedDecks')}</span>
						</RegionLink>
						{rejected !== null && (
							<RegionLink onClick={() => setTab('rejected')} active={tab === 'rejected'}>
								<Icon name="ban" />
								<span>{t('general.rejectedComments')}</span>
							</RegionLink>
						)}
					</Regions>

					{tab === 'unread' && unread && unread.length > 0 && (
						<CommentsDropDownActions>
							<ActionButton
								color={themeLight.secondary}
								icon="check-circle"
								onClick={markAllAsRead}
								disabled={!unread || unread.length === 0}
							>
								{t('general.markAllAsRead')}
							</ActionButton>
						</CommentsDropDownActions>
					)}

					<CommentsDropDownContent>
						{tab === 'unread' && unread !== null && (
							<DeckCommentLinkList>
								{unread.map(comment => (
									<DeckCommentLink
										comment={comment}
										showUnread={false}
										onBeforeNavigate={onBeforeNavigate}
										key={comment.id}
									/>
								))}
							</DeckCommentLinkList>
						)}

						{tab === 'bell' && bell !== null && (
							<DeckCommentLinkList>
								{bell.map(deck => (
									<Link
										to={`/deck/${deck.slug}`}
										onBeforeNavigate={onBeforeNavigate}
										key={deck.slug}
									>
										<CoverImage deck={deck} />
										<DeckName>{deck.name}</DeckName>
										<DeckComments>
											<Icon name="annotation" />
											{deck.comments}
										</DeckComments>
									</Link>
								))}
							</DeckCommentLinkList>
						)}

						{tab === 'rejected' && rejected !== null && (
							<DeckCommentLinkList>
								{rejected.map(comment => (
									<DeckCommentLink
										profile
										comment={comment}
										showUnread={false}
										onBeforeNavigate={onBeforeNavigate}
										key={comment.id}
									/>
								))}
							</DeckCommentLinkList>
						)}

						{authToken && ((tab === 'unread' && unread === null) || (tab === 'bell' && bell === null) || (tab === 'rejected' && rejected === null)) && (
							<OnPageSpinner />
						)}

						{tab === 'unread' && unread && unread.length === 0 && (
							<EmptyInfo>
								<Icon name="check" />
								{t('general.unreadCommentsEmpty')}
							</EmptyInfo>
						)}

						{tab === 'bell' && bell && bell.length === 0 && (
							<EmptyInfo>
								<Icon name="information-circle" />
								{t('general.subscribedDecksEmpty')}
							</EmptyInfo>
						)}

						{tab === 'unread' && !authToken && (
							<Message primary light icon="information-circle">{t('general.unreadCommentsLoginHint', { loginTo: <Link to="/signup" onBeforeNavigate={onBeforeNavigate}>{t('general.loginTo')}</Link> })}</Message>
						)}

						{tab === 'subscribed' && !authToken && (
							<Message primary light icon="information-circle">{t('general.subscribedDecksLoginHint', { loginTo: <Link to="/signup" onBeforeNavigate={onBeforeNavigate}>{t('general.loginTo')}</Link> })}</Message>
						)}
					</CommentsDropDownContent>
				</StyledCommentsDropDown>
			)}
		</DropDown>
	)
}

CommentsDropDown.propTypes = {
	authToken: PropTypes.string,
	button: PropTypes.any.isRequired,
	rejectedComments: PropTypes.number,
	t: PropTypes.func.isRequired,
	setUnreadComments: PropTypes.func.isRequired,
	unreadComments: PropTypes.number
}

CommentsDropDown.defaultProps = {
	authToken: null,
	rejectedComments: 0,
	unreadComments: 0
}

const StyledCommentsDropDown = styled.div`
	padding: 0.5rem 1rem;

	${ThickHeading} {
		margin-bottom: 1rem;
	}

	${desktop`
		max-height: calc(100vh - 80px - 0.5rem);
		overflow: auto;
		width: 500px;
	`}
`

const CommentsDropDownTabs = styled.div`
	display: flex;
`

const CommentsDropDownActions = styled.div`
	padding: 1rem 1rem 0.5rem;

	display: flex;
	justify-content: flex-end;
`

const CommentsDropDownTab = styled.button`
	background: ${props => props.theme.id === 'dark' ? 'rgba(0, 0, 0, 0.5)' : props.theme.backgroundLight};
	border: 0;
	color: ${props => props.theme.textSecondary};
	cursor: pointer;
    opacity: 0.7;
	padding: 1rem;
	transition: 0.1s;
	white-space: nowrap;

	flex: 1;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		color: ${props => props.theme.textLight};
		margin-right: 0.35rem;
	}

	&:first-child {
		border-radius: 4px 0 0 0;
	}

	&:last-child {
		border-radius: 0 4px 0 0;
	}

	${props => props.active && `
		background: ${props.theme.id === 'dark' ? props.theme.primaryLight : props.theme.primaryVeryLight};
		color: ${props.theme.text};
		font-weight: 500;
		opacity: 1;
	`}

	${desktop`
		&:hover {
			opacity: 1;
		}

		${props => !props.active && `
			&:hover {
				background: ${props.theme.id === 'dark' ? 'rgba(0, 0, 0, 0.2)' : props.theme.backgroundLight};
			}
		`}
	`}

	${mobile`
		font-size: 0.9rem;
	`}
`

const CommentsDropDownContent = styled.div`
	${StyledOnPageSpinner} {
		margin: 0 auto 1.5rem;
		width: fit-content;
		color: ${props => props.theme.textVeryLight};
	}

	${desktop`
		padding: 0.5rem 1rem;
	`}
`

const DeckCommentLinkList = styled.div`
	display: flex;
	flex-direction: column;

	> a {
		border-radius: 4px;
		align-items: center;
		display: flex;
		margin-left: -0.5rem;
		padding: 0.5rem 0.5rem;
		text-decoration: none;
		width: calc(100% + 1rem);
	}

    > a + a {
        border-top: 1px solid ${props => props.theme.backgroundVeryLight};
    }

	${desktop`
		> a {
			padding: 0.5rem;

			${CommentMeta} {
				white-space: normal;
			}

			&:hover {
				background: ${props => props.theme.backgroundVeryLight};
				color: ${props => props.theme.text};
			}
		}
	`}
`

const DeckName = styled.div`
	font-weight: 500;
	padding: 0 1rem;
	flex: 1;
`

const DeckComments = styled.div`
	font-size: 0.9rem;
	font-weight: 600;

	align-items: center;
	display: flex;

	svg {
		color: ${props => props.theme.textLight};
		margin-right: 0.35rem;

		height: 16px;
		width: 16px;
	}
`

const EmptyInfo = styled.div`
	align-items: center;
    display: flex;
    flex-direction: column;

	color: ${props => props.theme.textLight};
	padding: 2rem 0;
	text-align: center;

	svg {
		background: ${props => props.theme.id === 'dark' ? props.theme.backgroundVeryLight : props.theme.secondary};
		border-radius: 50%;
		color: ${props => props.theme.white};
		height: 30px;
		width: 30px;
		padding: 0.5rem;
		margin-bottom: 1rem;
	}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		rejectedComments: state.persist.rejectedComments,
		unreadComments: state.persist.unreadComments
	}), dispatch => bindActionCreators({
		setUnreadComments
	}, dispatch)),
	translate('general'),
	withRouter
)

export default enhance(CommentsDropDown)
