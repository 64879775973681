import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Unread = ({ children, red, gray, small, warning }) => (
	<StyledUnread
		small={small}
		doubleDigits={children > 9}
		tripleDigits={children > 99}
		red={red}
		gray={gray}
		warning={warning}
	>
		{children > 99 ? '99+' : children}
	</StyledUnread>
)

Unread.propTypes = {
	children: PropTypes.number.isRequired,
	red: PropTypes.bool,
	gray: PropTypes.bool,
	small: PropTypes.bool,
	warning: PropTypes.bool
}

Unread.defaultProps = {
	red: false,
	gray: false,
	small: false,
	warning: false
}

export const StyledUnread = styled.span`
	font-size: 0.7rem;
	font-weight: bold;
	text-align: right;
	margin-left: auto;

	background: ${props => props.theme.primary};
	border-radius: 11px;
	color: ${props => props.theme.white};
	height: 22px;
	min-width: 22px;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.small && `
		border-radius: 9px;
		height: 18px;
		min-width: 18px;
	`}

	${props => props.tripleDigits && `
		padding: 0 0.35rem;
	`}

	${props => props.warning && `
		background: ${props.theme.orange};
	`}

	${props => props.red && `
		background: ${props.theme.red};
	`}

	${props => props.gray && `
		background: ${props.theme.backgroundDark};
	`}
`

export default Unread
