import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { cardTypeIcons } from '../../images'
import Tag from '../general/Tag'
import CardTypeName from './CardTypeName'
import cardTypesData from '../../data/cardTypes'
import { translationsMapping } from '../../../config'

const CardTypes = ({ cardTypes, language, isMonster, settings }) => {
	const appliedLanguage = settings['app.cardNameLanguage'] || translationsMapping[language]

	let categories = ''

	if (cardTypes.length > 0) {
		if (isMonster) {
			categories = cardTypes.reduce((accumulator, currentValue, index) => {
				if (cardTypesData[currentValue]) {
					accumulator += cardTypesData[currentValue][`name_${appliedLanguage}`]

					if (index + 1 !== cardTypes.length) return `${accumulator}, `
				}

				return accumulator
			}, '')
		} else {
			const image = cardTypeIcons[cardTypes[0]]

			categories = (
				<>
					{image && <img src={image} alt="" />}
					<CardTypeName id={cardTypes[0]} />
				</>
			)
		}
	}

	return <StyledCardTypes>{categories}</StyledCardTypes>
}

CardTypes.propTypes = {
	cardTypes: PropTypes.array.isRequired,
	language: PropTypes.string.isRequired,
	isMonster: PropTypes.bool.isRequired,
	settings: PropTypes.object.isRequired
}

export const StyledCardTypes = styled.span`
	align-items: center;
	display: inline-flex;
	margin-bottom: 0.5rem;

	${Tag} + ${Tag} {
		margin-left: 0.5rem;
	}
`

const enhance = connect(state => ({
	language: state.persist.language,
	settings: state.persist.user.settings
}))

export default enhance(CardTypes)
