import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../general/Icon'
import translate from '../../helper/translate'

const DeckLinkCommunity = ({ deck, t }) => (
	<Community>
		{deck.votes > 0 && (
			<>
				<Votes>
					+{deck.votes}
				</Votes>
				&nbsp;&middot;&nbsp;
			</>
		)}

		{deck.comments > 0 && (
			<>
				<Comments unread={deck.unread}>
					<Icon name="annotation" /><span>{deck.comments}</span>
				</Comments>
				&nbsp;&middot;&nbsp;
			</>
		)}

		<Views>
			{t('general.view', { count: deck.views })}
		</Views>
	</Community>
)

DeckLinkCommunity.propTypes = {
	deck: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired
}

const Community = styled.div`
	align-items: flex-end;
	display: flex;
	margin-left: auto;
	padding-left: 0.5rem;
	width: fit-content;

	color: ${props => props.theme.textVeryLight};
	font-size: 0.6rem;
	font-weight: 700;
	white-space: nowrap;
`

const Votes = styled.span`
	color: ${props => props.theme.green};

	align-items: flex-end;
	display: flex;
	height: 14px;
`

const Views = styled.span`
	font-weight: 500;
`

const Comments = styled.span`
	color: ${props => props.theme.textLight};

	align-items: flex-end;
	display: flex;
	height: 14px;

	svg {
		height: 12px;
		width: 12px;
		margin-right: 0.2rem;
	}

	span {
		vertical-align: top;
	}

	${props => props.unread && `
		color: ${props.theme.primary};
	`}
`

const enhance = translate('general')

export default enhance(DeckLinkCommunity)
