import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon, { StyledIcon } from '../general/Icon'
import Tooltip from '../general/Tooltip'
import { desktop } from '../../styles/media'

const IconSwitch = ({ children, disabled, onChange, onClick, transparent, value }) => (
	<StyledIconSwitch onClick={onClick} transparent={transparent} disabled={disabled}>
		{React.Children.map(children, child => {
			const active = child.props.value === value
			const exclamation = child.props.exclamation && !active

			return !child.props.hidden ? (
				<Tooltip title={child.props.title}>
					<IconButton
						type="button"
						active={active}
						onClick={() => onChange(child.props.value)}
						transparent={transparent}
						exclamation={exclamation}
					>
						{child}

						{exclamation && (
							<Exclamation>
								<Icon name="exclamation" />
							</Exclamation>
						)}
					</IconButton>
				</Tooltip>
			) : null
		})}
	</StyledIconSwitch>
)

IconSwitch.propTypes = {
	children: PropTypes.any.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	transparent: PropTypes.bool,
	value: PropTypes.any.isRequired
}

IconSwitch.defaultProps = {
	disabled: null,
	onClick: undefined,
	transparent: false
}

export const StyledIconSwitch = styled.div`
	border: 1px solid ${props => props.transparent ? props.theme.shadowThick : props.theme.backgroundLight};
	border-radius: 3px;

	align-items: center;
	display: flex;

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}
`

const IconButton = styled.button`
	background: ${props => props.active ? '#444' : 'transparent'};
	border: 0;
	border-radius: 3px;
	color: ${props => props.active ? '#EEE' : props.theme.textVeryLight};

	align-items: center;
	display: flex;
	justify-content: center;

	height: 36px;
	width: 36px;
	min-width: 36px;

	position: relative;

	${desktop`
		&:hover {
			color: ${props => props.active ? '#EEE' : props.theme.textLight};
		}
	`}

	> img, > svg {
		min-width: 20px;
	}

	${props => props.transparent && `
		background: ${props.active ? '#444' : 'rgba(255, 255, 255, 0.1)'};
		color: ${props.active ? 'rgba(255, 255, 255)' : 'rgba(255, 255, 255, 0.7)'};
	`}

	${props => props.exclamation && `
		> img, > svg {
			opacity: 0.7;

			filter: grayscale(100%);
			-webkit-transform: translateZ(0);
			-webkit-perspective: 1000;
			-webkit-backface-visibility: hidden;
		}
	`}
`

const Exclamation = styled.div`
	position: absolute;
	top: 45%;
	left: 50%;
	z-index: 5;

	background: ${props => props.theme.background};
	border-radius: 50%;

	height: 12px;
	width: 12px;

	align-items: center;
	display: flex;
	justify-content: center;

	pointer-events: none;

	svg {
		color: ${props => props.theme.red};
		height: 12px;
		width: 12px;
	}
`

export default IconSwitch
