export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

	return [
		result ? parseInt(result[1], 16) : null,
		result ? parseInt(result[2], 16) : null,
		result ? parseInt(result[3], 16) : null
	]
}

export const getContrastColor = (hex, alpha = 1) => {
	const rgb = hexToRgb(hex)

	const brightness = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)

	return brightness > 130 ? `rgba(0, 0, 0, ${alpha})` : `rgba(255, 255, 255, ${alpha})`
}

export const rgbToHex = (r, g, b) => '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

export const formatRgb = (rgbData, opacity = 1) => rgbData ? `rgba(${rgbData[0]}, ${rgbData[1]}, ${rgbData[2]}, ${opacity})` : 'transparent'
