import styled from 'styled-components'
import formats, { TCG } from '../../data/formats'

const SetNumber = styled.div`
	background: #333;
	border: 1px solid #333;
	border-radius: 2px;
	color: ${props => props.theme.white};
	font-family: ${props => props.theme.fontMonospace};
	font-size: 1rem;
	font-weight: bold;
	padding: 0.5rem;
	text-align: center;
	width: 57px;
	min-width: 57px;
	white-space: nowrap;

	${props => props.small && `
		font-size: 0.8rem;
		padding: 0.25rem;
		width: 41px;
		min-width: 41px;
	`}

	${props => props.theme.id === 'dark' && `
		background: #444;
		border: 1px solid #444;
		color: ${props.theme.white};
	`}

	${props => props.format && props.format !== TCG && `
		background: ${formats[props.format].color};
		border: 1px solid ${formats[props.format].color};
	`}
`

export default SetNumber
