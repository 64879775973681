import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import SelectLabel from './SelectLabel'

const SelectSingleValue = props => (
	<components.SingleValue {...props}>
		<SelectLabel data={props} />
	</components.SingleValue>
)

SelectSingleValue.propTypes = {
	children: PropTypes.any,
	data: PropTypes.any.isRequired,
	selectProps: PropTypes.any.isRequired
}

SelectSingleValue.defaultProps = {
	children: null
}

export default SelectSingleValue
