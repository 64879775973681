import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon, { StyledIcon } from '../general/Icon'
import { desktop, mobile } from '../../styles/media'

const Message = ({ banner, center, children, dark, error, icon, info, light, onClose, primary, small, success, transparent, warning }) => (
	<StyledMessage
		banner={banner}
		center={center}
		light={light}
		error={error}
		info={info}
		success={success}
		warning={warning}
		primary={primary}
		dark={dark}
		transparent={transparent}
		small={small}
	>
		{icon && <Icon name={icon} />}
		<span>{children}</span>
		{banner && onClose && (
			<CloseButton error={error} success={success} warning={warning} onClick={onClose}>
				<Icon name="x" size={16} />
			</CloseButton>
		)}
	</StyledMessage>
)

Message.propTypes = {
	banner: PropTypes.bool,
	center: PropTypes.bool,
	children: PropTypes.any.isRequired,
	dark: PropTypes.bool,
	error: PropTypes.bool,
	icon: PropTypes.string,
	info: PropTypes.bool,
	light: PropTypes.bool,
	onClose: PropTypes.func,
	primary: PropTypes.bool,
	small: PropTypes.bool,
	success: PropTypes.bool,
	transparent: PropTypes.bool,
	warning: PropTypes.bool
}

Message.defaultProps = {
	banner: false,
	center: false,
	dark: false,
	error: true,
	icon: '',
	info: false,
	light: false,
	onClose: undefined,
	primary: false,
	small: false,
	success: false,
	transparent: false,
	warning: false
}

const CloseButton = styled.button`
	background: transparent;
	border-radius: 50%;
	border: 0;
	padding: 0.75rem;
	transition: all 0.1s ease;

	color: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.7)' : props.theme.backgroundLight};

	position: absolute;
	top: 0;
	right: 0;

	align-items: center;
	display: flex;
	justify-content: center;

	${desktop`
		&:hover {
			background: rgba(0, 0, 0, 0.2);
			color: #FFF;
		}
	`}
`

const getBasicColorSelector = props => (props.success && 'green') || (props.warning && 'orange') || (props.info && 'backgroundLight') || (props.primary && 'primary') || (props.dark && 'header') || (props.error && 'red')

const getBackgroundColor = (props) => {
	if (props.transparent) {
		return 'rgba(255, 255, 255, 0.2)'
	}

	let colorSelector = getBasicColorSelector(props)

	if (props.light) {
		colorSelector = `${colorSelector}Light`
	}

	return props.theme[colorSelector]
}

const getTextColor = (props) => {
	if (props.transparent) {
		return '#FFF'
	}

	if (props.light) {
		if (props.theme.id === 'dark') {
			return '#FFF'
		}

		return '#000'
	}

	if (!props.info) {
		return '#FFF'
	}

	return props.theme.id === 'dark' ? props.theme.textLight : props.theme.secondary
}

export const StyledMessage = styled.div`
	background: ${props => getBackgroundColor(props)};
	border-radius: 2px;
	color: ${props => getTextColor(props)};
	line-height: 1.45;
	padding: 1rem;
	position: relative;
	margin-bottom: 1rem;
	width: fit-content;

	align-items: center;
	display: flex;

	> ${StyledIcon} {
		margin-right: 0.75rem;
	}

	> span {
		flex: 1;

		ul {
			padding-left: 1.1rem;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}

	${props => props.small && `
		border-radius: 2px;
		font-size: 12px;
		padding: 4px 6px;
	`}

	${desktop`
		${props => props.banner && `
			padding: 1rem 3rem 1rem 1rem;
		`}
	`}

	${mobile`
		width: 100%;

		${props => props.banner && `
			padding: 1rem 1.5rem 1rem 1rem;
		`}
	`}

	${props => props.banner && `
		width: 100%;
	`}

	.react-selectize.open + & {
		display: none;
	}

	a:not(${CloseButton}) {
		color: ${props => getTextColor(props)};
		font-weight: bold;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	${props => props.center && `
		border-radius: 0;
		font-size: 1rem;
		font-weight: 600;
		padding: 2rem 1rem;

		justify-content: center;

		> span {
			flex: 0 1 auto;
		}

		svg {
			height: 24px;
			width: 24px;
			margin-right: 0.5rem !important;
		}
	`}
`

export default Message
