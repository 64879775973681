import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Route from './Route'

const PrivateRoute = ({ authToken, component: Component, redirect, ...rest }) => (
	<Route
		{...rest}
		render={props => (
			authToken ? (
				<Component {...props} />
			) : (
				<Redirect to={{
					pathname: redirect,
					state: { from: props.location.pathname }
				}}
				/>
			)
		)}
	/>
)

PrivateRoute.propTypes = {
	authToken: PropTypes.string,
	component: PropTypes.any.isRequired,
	location: PropTypes.any,
	redirect: PropTypes.string
}

PrivateRoute.defaultProps = {
	authToken: null,
	location: {},
	redirect: '/login'
}

const enhance = connect(state => ({
	authToken: state.persist.authToken
}))

export default enhance(PrivateRoute)
