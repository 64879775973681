import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { default as ReactSelect } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from '../../helper/api'
import Message from './Message'
import theme from '../../styles/theme-light'
import { desktop, VIEWPORT_MOBILE } from '../../styles/media'
import { StyledInputContainer } from './Input'
import translate from '../../helper/translate'
import SelectOption from './SelectOption'
import SelectSingleValue from './SelectSingleValue'
import { month } from '../../helper/date'

class Select extends Component {
	static propTypes = {
		autoFocus: PropTypes.bool,
		clearable: PropTypes.bool,
		creatable: PropTypes.bool,
		data: PropTypes.array,
		disabled: PropTypes.bool,
		errors: PropTypes.array,
		filterableFields: PropTypes.array,
		filterRemote: PropTypes.bool,
		id: PropTypes.string,
		iconOnly: PropTypes.bool,
		innerRef: PropTypes.any,
		isOptionDisabled: PropTypes.func,
		isValidNewOption: PropTypes.func,
		max: PropTypes.number,
		multiple: PropTypes.bool,
		onChange: PropTypes.func,
		onSourceLoaded: PropTypes.func,
		placeholder: PropTypes.string,
		searchable: PropTypes.bool,
		small: PropTypes.bool,
		source: PropTypes.string,
		t: PropTypes.func.isRequired,
		tabIndex: PropTypes.number,
		value: PropTypes.any,
		valueUseObject: PropTypes.bool,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		autoFocus: false,
		clearable: false,
		creatable: false,
		data: [],
		disabled: false,
		errors: [],
		filterableFields: null,
		filterRemote: false,
		iconOnly: false,
		id: null,
		innerRef: null,
		isOptionDisabled: undefined,
		isValidNewOption: undefined,
		max: null,
		multiple: false,
		placeholder: '',
		onChange: () => {},
		onSourceLoaded: () => {},
		searchable: true,
		small: false,
		source: null,
		tabIndex: null,
		value: '',
		valueUseObject: false
	}

	state = {
		loading: false
	}

	constructor(props) {
		super(props)

		this.state = {
			data: []
		}
	}

	componentDidMount() {
		if (this.props.source && !this.props.filterRemote) {
			this.load(this.props.source)
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.source !== this.props.source && this.props.source !== '') {
			this.load(this.props.source)
		}
	}

	componentWillUnmount() {
		if (this.req) {
			this.req.abort()
		}

		if (this.req2) {
			this.req2.abort()
		}
	}

	load = (source) => {
		const { onSourceLoaded } = this.props

		this.setState({
			loading: true
		})

		this.req = get(source, (data) => {
			data = data.data ? data.data : data

			this.setState({
				data,
				loading: false
			}, () => {
				onSourceLoaded(data)
			})
		})
	}

	handleChange = (obj) => {
		const { multiple, onChange, valueUseObject } = this.props

		if (obj) {
			if (valueUseObject) {
				// use whole object
				onChange(obj)
			} else {
				// use id
				const value = !multiple ? obj.value : obj.map(item => item.value)

				onChange(value, obj)
			}
		} else {
			onChange(null)
		}
	}

	loadOptions = (inputValue, callback) => {
		if (!inputValue || inputValue === '') {
			callback(this.parseOptions([]))
			return
		}

		this.req2 = get(`${this.props.source}${this.props.source.includes('?') ? '&' : '?'}q=${inputValue}`, results => callback(this.parseOptions(results)))
	}

	filterOption = (option, search) => {
		const { filterableFields } = this.props

		const q = search + ''.toLowerCase().trim()

		if (q !== '') {
			if (filterableFields && filterableFields.length > 0) {
				let filterableFieldsMatch = false

				filterableFields.forEach((field) => {
					if (option.data[field] && option.data[field].toString().toLowerCase() === q) {
						filterableFieldsMatch = true
					}
				})

				if (filterableFieldsMatch) {
					return true
				}
			}

			return option.label.toString().toLowerCase().trim().startsWith(q) || option.label.toString().toLowerCase().trim().includes(q)
		}

		return true
	}

	parseValue = (options) => {
		const { multiple, value, valueUseObject } = this.props

		let parsedValue = value

		if (value === null && multiple) {
			parsedValue = []
		}

		if (!valueUseObject) {
			if (multiple && parsedValue && parsedValue.length > 0) {
				parsedValue = parsedValue.map(item => this.findValueInOptions(options, item))
			} else if (parsedValue && parsedValue.constructor === Object && !multiple) {
				parsedValue = options.find(obj => obj.value === (parsedValue.id || parsedValue.value))
			} else if (parsedValue) {
				parsedValue = this.findValueInOptions(options, parsedValue)
			}
		} else if (parsedValue) {
			parsedValue.label = this.parseLabel(parsedValue)
		}

		return parsedValue
	}

	findValueInOptions = (options, value) => {
		let parsedValue = value

		options.forEach((obj) => {
			if (obj.options) {
				const found = obj.options.find(obj2 => obj2.value.toString() === parsedValue.toString())

				if (found) {
					parsedValue = found
				}
			} else if (obj.value && obj.value.toString() === parsedValue.toString()) {
				parsedValue = obj
			}
		})

		return parsedValue
	}

	parseOptions = data => data.map((item) => {
		const label = this.parseLabel(item)
		let { options } = item

		if (options) {
			options = this.parseOptions(item.options)
		}

		return {
			...item,
			options,
			label,
			value: item.id || item.value || item.slug
		}
	})

	parseLabel = (item) => {
		const label = item.label || item.name

		if (!label && item.startsOn) {
			return month(item.startsOn)
		}

		return label && label.toString().startsWith('general') ? this.props.t(label) : label
	}

	render() {
		const { autoFocus, clearable, creatable, disabled, filterRemote, errors, iconOnly, id, innerRef, isOptionDisabled, isValidNewOption, max, multiple, placeholder, searchable, small, source, t, tabIndex, viewport } = this.props

		const message = errors.reduce((accumulator, error) => `${accumulator}${error} `, '')

		if (filterRemote) {
			if (creatable) {
				return (
					<StyledInputContainer small={small} id={id}>
						<StyledCreatableAsyncSelect
							allowCreateWhileLoading
							autoFocus={autoFocus}
							// blurInputOnSelect={viewport === VIEWPORT_MOBILE && !multiple}
							classNamePrefix="react-select"
							components={{ Option: SelectOption, SingleValue: SelectSingleValue }}
							defaultOptions
							formatCreateLabel={value => t('general.selectAddValue', { value })}
							iconOnly={iconOnly}
							loadingMessage={() => t('general.loading')}
							loadOptions={this.loadOptions}
							isClearable={clearable}
							isDisabled={disabled}
							isLoading={this.state.loading}
							isMulti={multiple}
							isOptionDisabled={isOptionDisabled}
							isValidNewOption={isValidNewOption}
							noOptionsMessage={({ inputValue }) => inputValue.length > 0 ? t('general.selectNoOptions') : t('general.selectTypeToSearch')}
							onChange={this.handleChange}
							openMenuOnFocus
							placeholder={placeholder}
							ref={innerRef}
							styles={styles}
							tabIndex={tabIndex}
							value={this.props.value}
						/>
						{message !== '' && <Message>{message}</Message>}
					</StyledInputContainer>
				)
			}

			return (
				<StyledInputContainer small={small} id={id}>
					<StyledAsyncSelect
						autoFocus={autoFocus}
						// blurInputOnSelect={viewport === VIEWPORT_MOBILE && !multiple}
						classNamePrefix="react-select"
						components={{ Option: SelectOption, SingleValue: SelectSingleValue }}
						defaultOptions
						loadingMessage={() => t('general.loading')}
						loadOptions={this.loadOptions}
						iconOnly={iconOnly}
						isClearable={clearable}
						isDisabled={disabled}
						isLoading={this.state.loading}
						isMulti={multiple}
						isOptionDisabled={isOptionDisabled}
						noOptionsMessage={({ inputValue }) => inputValue.length > 0 ? t('general.selectNoOptions') : t('general.selectTypeToSearch')}
						onChange={this.handleChange}
						openMenuOnFocus
						placeholder={placeholder}
						ref={innerRef}
						styles={styles}
						tabIndex={tabIndex}
						value={this.props.value}
					/>
					{message !== '' && <Message>{message}</Message>}
				</StyledInputContainer>
			)
		}

		const data = source ? this.state.data : this.props.data
		const loading = !data || this.state.loading
		const options = data ? this.parseOptions(data) : []
		const value = this.parseValue(options)

		if (creatable) {
			return (
				<StyledInputContainer small={small} id={id}>
					<StyledCreatableSelect
						allowCreateWhileLoading
						autoFocus={autoFocus}
						// blurInputOnSelect={viewport === VIEWPORT_MOBILE && !multiple}
						classNamePrefix="react-select"
						components={{ Option: SelectOption, SingleValue: SelectSingleValue }}
						formatCreateLabel={value => t('general.selectAddValue', { value })}
						iconOnly={iconOnly}
						isClearable={clearable}
						isDisabled={disabled}
						isLoading={loading}
						isMulti={multiple}
						isOptionDisabled={isOptionDisabled}
						isSearchable={searchable}
						isValidNewOption={max ? inputValue => !(multiple && max && inputValue.length === max) : isValidNewOption}
						noOptionsMessage={() => t('general.selectNoOptions')}
						openMenuOnFocus
						options={options}
						onChange={this.handleChange}
						placeholder={placeholder}
						ref={innerRef}
						styles={styles}
						tabIndex={tabIndex}
						value={value}
					/>
					{message !== '' && <Message>{message}</Message>}
				</StyledInputContainer>
			)
		}

		return (
			<StyledInputContainer small={small} id={id}>
				<StyledSelect
					autoFocus={autoFocus}
					// blurInputOnSelect={viewport === VIEWPORT_MOBILE && !multiple}
					classNamePrefix="react-select"
					components={{ Option: SelectOption, SingleValue: SelectSingleValue }}
					filterOption={this.filterOption}
					iconOnly={iconOnly}
					isClearable={clearable}
					isDisabled={disabled}
					isLoading={loading}
					isMulti={multiple}
					isOptionDisabled={isOptionDisabled}
					isSearchable={searchable}
					isValidNewOption={max ? inputValue => !(multiple && max && inputValue.length === max) : undefined}
					noOptionsMessage={() => t('general.selectNoOptions')}
					openMenuOnFocus
					options={options}
					onChange={this.handleChange}
					placeholder={placeholder}
					ref={innerRef}
					styles={styles}
					tabIndex={tabIndex}
					value={value}
				/>
				{message !== '' && <Message>{message}</Message>}
			</StyledInputContainer>
		)
	}
}

const styles = {
	menu: base => ({
		...base,
		zIndex: theme.zLayer2
	}),
	singleValue: (base, { data }) => ({
		...base,
		color: data.color || undefined,
		fontWeight: data.color ? 'bold' : undefined
	}),
	option: (base, { data, isSelected }) => ({
		...base,
		color: !isSelected ? data.color || undefined : '#FFF',
		fontWeight: !isSelected && data.color ? 'bold' : undefined
	})
}

const SelectStyles = `
	${desktop`
		width: 300px;
	`}
`

const StyledSelect = styled(ReactSelect)(SelectStyles)
const StyledCreatableSelect = styled(CreatableSelect)(SelectStyles)
const StyledAsyncSelect = styled(AsyncSelect)(SelectStyles)
const StyledCreatableAsyncSelect = styled(AsyncCreatableSelect)(SelectStyles)

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(Select)
