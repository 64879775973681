import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { desktop } from '../../styles/media'
import Message from './Message'
import { StyledTooltip } from '../general/Tooltip'

class Checkbox extends Component {
	static propTypes = {
		disabled: PropTypes.bool,
		disabledBoxOnly: PropTypes.bool,
		errors: PropTypes.array,
		hasFieldLabel: PropTypes.bool,
		id: PropTypes.string,
		label: PropTypes.any,
		longText: PropTypes.bool,
		name: PropTypes.string,
		small: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		value: PropTypes.bool.isRequired,
	}

	static defaultProps = {
		disabled: false,
		disabledBoxOnly: false,
		hasFieldLabel: false,
		id: null,
		errors: [],
		label: null,
		longText: false,
		name: '',
		small: false
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.handleChange()
		}
	}

	handleChange = () => {
		this.props.onChange(!this.props.value)
	}

	handleClick = (event) => {
		event.stopPropagation()
	}

	render() {
		const { disabled, disabledBoxOnly, errors, id, value, label, longText, name, hasFieldLabel, small } = this.props

		const message = errors.reduce((accumulator, error) => `${accumulator}${error} `, '')

		return (
			<>
				<StyledCheckboxLabel
					disabled={disabled}
					disabledBoxOnly={disabledBoxOnly}
					hasFieldLabel={hasFieldLabel}
					longText={longText}
					onClick={this.handleClick}
					onKeyPress={this.handleKeyPress}
					tabIndex="0"
					id={id}
				>
					<input
						name={name}
						onChange={this.handleChange}
						type="checkbox"
						checked={value}
					/>
					<StyledBox longText={longText} small={small} label={label}>
						<Tick small={small} />
					</StyledBox>
					{label && <Text small={small}>{label}</Text>}
				</StyledCheckboxLabel>
				{message !== '' && (
					<Message>
						{message}
					</Message>
				)}
			</>
		)
	}
}

const Text = styled.div`
	align-items: center;
	display: flex;

	${StyledTooltip} {
		align-items: center;
		display: flex;
		margin-left: 0.35rem;
	}

	${props => props.small && `
		font-size: 0.9rem;
	`}
`

const Tick = styled.div`
	border-color: #FFF;
	border-style: solid;
	border-width: 0 2px 2px 0;

	display: block;
	height: 12px;
	width: 6px;

	position: absolute;
	top: 1px;
	left: 5px;
	transform: rotate(45deg);
	opacity: 0;

	${props => props.small && `
		height: 10px;
		width: 6px;

		top: -1px;
		left: 2px;
	`}
`

export const StyledBox = styled.div`
	background-color: ${props => props.theme.background};
	border: 2px solid ${props => props.theme.backgroundSolid};
	border-radius: 4px;
	display: block;
	font-size: 20px;
	margin-right: ${props => props.longText ? '1rem' : '10px'};
	position: relative;
	height: 20px;
	width: 20px;
	min-width: 20px;
	transition: all .2s ease-in-out;

	label:focus & {
		border-color: ${props => props.theme.primary};
	}

	${props => props.small && `
		border-radius: 3px;
		height: 14px;
		width: 14px;
		min-width: 14px;
		margin-right: ${props.longText ? '1rem' : '5px'};
	`}

	input:checked + & {
		background: ${props => props.theme.primary};
		border-color: ${props => props.theme.primary};

		${Tick} {
			opacity: 1;
		}
	}

	${props => !props.label && `
		margin-right: 0;
	`}
`

export const StyledCheckboxLabel = styled.label`
	cursor: pointer;
	display: flex !important;
	align-items: center;
	margin-top: ${props => props.hasFieldLabel ? '0' : '0.5rem'};
	position: relative;
	user-select: none;
	width: fit-content;

	input {
		display: none;
	}

	${props => props.longText && `
		color: ${props.theme.textLight};
		font-size: 0.8rem;
		line-height: 1.3;
	`}

	${desktop`
		&:hover ${StyledBox} {
			transform: scale(1.05);
		}
	`}

	${props => props.disabled && `
		pointer-events: none;
	`}

	${props => props.disabled && !props.disabledBoxOnly && `
		color: ${props.theme.textVeryLight};
		cursor: not-allowed !important;
		opacity: 0.5;
	`}

	${props => props.disabled && props.disabledBoxOnly && `
		${StyledBox} {
			cursor: not-allowed !important;
			opacity: 0.5;
		}
	`}
`

export default Checkbox
