import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import translate from '../../helper/translate'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import Spinner from '../../components/general/Spinner'
import { fixedCollapsibleIsVisible, locationMatchesCardSearch } from '../../helper/router'
import { getAppMenuHeight } from '../../helper/device'
import cardcluster from '../../images/logo/cardcluster.svg'
import cardclusterWhite from '../../images/logo/cardcluster-white.svg'
import OnPageSpinner from '../../components/general/OnPageSpinner'

class SiteLoading extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		cardSearchCollapsed: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool.isRequired,
		menuCollapsed: PropTypes.bool.isRequired,
		location: PropTypes.object.isRequired,
		settings: PropTypes.object.isRequired,
		subscribed: PropTypes.string,
		t: PropTypes.func.isRequired,
		theme: PropTypes.object.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		authToken: null,
		subscribed: null
	}

	state = {
		spinner: false,
		text: false
	}

	componentDidMount() {
		this.setLoadingTimeouts()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isLoading !== this.props.isLoading) {
			this.setLoadingTimeouts()
		}
	}

	componentWillUnmount() {
		clearTimeout(this.spinnerTimeout)
		clearTimeout(this.textTimeout)
	}

	setLoadingTimeouts = () => {
		if (!this.props.isLoading) {
			clearTimeout(this.spinnerTimeout)
			clearTimeout(this.textTimeout)

			this.setState({
				spinner: false,
				text: false
			})
		} else {
			this.spinnerTimeout = window.setTimeout(() => {
				this.setState({
					spinner: true
				})
			}, 2000)

			this.textTimeout = window.setTimeout(() => {
				this.setState({
					text: true
				})
			}, 8000)
		}
	}

	render() {
		const { authToken, cardSearchCollapsed, isLoading, location, settings, subscribed, t, theme, viewport } = this.props
		const { spinner, text } = this.state

		const fixedCollapsibleVisible = fixedCollapsibleIsVisible(location)
		const collectionUseFullWidth = (location.pathname.includes('/collection/') || location.pathname.includes('/add/')) && settings['collection.useFullWidth']
		const isEditingDeck = location.pathname.startsWith('/deck-builder/')
		const isCardSearch = locationMatchesCardSearch(location)
		const dark = !isCardSearch || theme.id === 'dark'

		const menuCollapsed = this.props.menuCollapsed || collectionUseFullWidth || isEditingDeck

		const transparent = !!(isEditingDeck && subscribed && settings['app.deckBuilderWallpaper'] ? settings['app.deckBuilderWallpaper'] : false)

		let navHeight

		if (viewport === VIEWPORT_DESKTOP) {
			navHeight = fixedCollapsibleVisible || isCardSearch ? 0 : 80
		} else if (!isEditingDeck) {
			navHeight = 88
		} else {
			navHeight = 44
		}

		return isLoading ? (
			<>
				{isEditingDeck && !cardSearchCollapsed && viewport === VIEWPORT_DESKTOP && <CardSearchPlaceholder transparent={transparent} />}
				{isEditingDeck && viewport === VIEWPORT_MOBILE && <HeaderPlaceholder transparent={transparent} />}
				{isEditingDeck && (
					<DeckBuilderLoading
						authToken={authToken}
						cardSearchCollapsed={cardSearchCollapsed}
						fixedCollapsibleVisible={fixedCollapsibleVisible}
						menuCollapsed={menuCollapsed}
						navHeight={0}
						transparent={transparent}
						viewport={viewport}
					>
						<DeckBuilderLoadingInner transparent={transparent}>
							<img src={transparent ? cardclusterWhite : cardcluster} alt="cardcluster" />
							<div>Deck Builder&nbsp;{subscribed ? <strong>Pro</strong> : <em>Classic</em>}</div>
							<OnPageSpinner delay={false} />
						</DeckBuilderLoadingInner>
					</DeckBuilderLoading>
				)}
				{!isEditingDeck && (
					<Container
						authToken={authToken}
						fixedCollapsibleVisible={fixedCollapsibleVisible}
						isCardSearch={isCardSearch}
						menuCollapsed={menuCollapsed}
						navHeight={navHeight}
						viewport={viewport}
					>
						{spinner && <Spinner dark={dark} />}
						<Text dark={dark} visible={text}>{t('general.pleaseWaitLong')}</Text>
					</Container>
				)}
			</>
		) : null
	}
}

const Placeholder = styled.div`
	position: fixed;
	z-index: ${props => props.theme.zLayer6};
`

export const CardSearchPlaceholder = styled(Placeholder)`
	background: ${props => props.transparent ? 'rgba(0, 0, 0, 0.5)' : '#1F1F1F'};
	height: 100vh;
	width: 340px;

	left: 80px;
	top: 0;
`

const HeaderPlaceholder = styled(Placeholder)`
	background: ${props => props.transparent ? 'rgba(0, 0, 0, 0.5)' : props.theme.header};
	height: 214px;
	width: 100%;

	top: 44px;
	left: 0;
`

const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	position: fixed;
	bottom: ${props => props.viewport === VIEWPORT_MOBILE ? getAppMenuHeight() : '0'};
	left: 300px;
	z-index: ${props => props.fixedCollapsibleVisible ? props.theme.zLayer10 : props.theme.zLayer5};

	background: ${props => !props.isCardSearch ? props.theme.background : 'transparent'};
	height: calc(100% - ${props => props.navHeight}px);
	width: ${props => props.fixedCollapsibleVisible ? '450px' : 'calc(100% - 300px)'};

	${props => props.menuCollapsed && `
		left: 80px;
		width: ${props.fixedCollapsibleVisible ? '450px' : 'calc(100% - 80px)'};
	`}

	${desktop`
		${props => props.fixedCollapsibleVisible && `
			left: auto;
			right: 0;
			z-index: ${props.theme.zLayer12};
		`}
	`}

	${mobile`
		left: 0;
		width: 100%;
	`}

	svg {
		height: 40px;
		width: 40px;
	}

	p {
		color: ${props => props.theme.textLight};
		position: absolute;
		top: calc(50% + 3rem);
	}
`

const DeckBuilderLoading = styled(Container)`
	background: ${props => props.transparent ? 'transparent' : props.theme.background};
	left: ${props => props.cardSearchCollapsed ? '80px' : '420px'};
	width: ${props => props.fixedCollapsibleVisible ? '450px' : `calc(100% - ${props.cardSearchCollapsed ? '80px' : '420px'})`};

	img {
		height: 30px;
		margin-bottom: 0.5rem;
	}

	div {
		color: ${props => props.theme.textVeryLight};
		font-family: TechnaSans, ${props => props.theme.fontDefault};
		font-size: 1.4rem;
		padding-left: 0.5rem;

		em {
			font-family: ${props => props.theme.fontDefault};
			font-size: 1.2rem;
		}
	}

	${mobile`
		height: calc(100% - 214px - 44px - 50px);
		left: 0;
		width: 100%;
	`}
`

const DeckBuilderLoadingInner = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;

	${props => props.transparent && `
		background: rgba(0, 0, 0, 0.7);
		border-radius: 12px;
		padding: 2rem 4rem !important;
	`}
`

const Text = styled.p`
	opacity: ${props => props.visible ? '1' : '0'};
	transition: opacity 0.3s;

	${props => !props.dark && `
		color: rgba(255, 255, 255, 0.3) !important;
	`}
`

const mapStateToProps = state => ({
	authToken: state.persist.authToken,
	cardSearchCollapsed: state.persist.cardSearchCollapsed,
	isLoading: state.app.isLoading,
	menuCollapsed: state.persist.menuCollapsed,
	settings: state.persist.user.settings,
	subscribed: state.persist.user.subscribed,
	viewport: state.app.viewport
})

const enhance = compose(
	connect(mapStateToProps, null),
	translate('general'),
	withRouter,
	withTheme
)

export default enhance(SiteLoading)
