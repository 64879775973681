import {
	ADD_CARD,
	REMOVE_CARD,
	SET_DECK,
	SET_DECK_NAME,
	SET_DECK_NAME_COMMIT,
	SET_DECK_NAME_ROLLBACK,
	SET_ARCHIVED,
	SET_DECK_CARDS,
	VOTE_DECK,
	VOTE_DECK_COMMIT,
	VOTE_DECK_ROLLBACK,
	SAVE_DECK,
	SAVE_DECK_COMMIT,
	SAVE_DECK_ROLLBACK,
	SET_DRAGGING,
	SET_MODE,
	RESET,
	ADD_EVENT_PLAYER,
	REMOVE_EVENT_PLAYERS,
	SET_VALIDATION_INFO,
	SET_DECK_CARD_CHECKED,
	SET_INVALID_IMPORT_LINES,
	UNSHELVE_DECK,
	SET_DECK_HAVES,
	SET_DECK_ADDITIONAL,
	DISCARD_CHANGES,
	CLEAR_DECK
} from '../actions/deckbuilder'
import { COMMIT, OPTIMISTIC_UPDATE, ROLLBACK, updateDeck } from '../helper/deckbuilder'
import unique from '../../helper/unique'

const initialState = {
	// active deck being edited / viewed
	deck: null,

	// keep deck builder state to browse / compare to other decks
	shelvedDeck: null,

	// edit mode
	mode: 'info',

	dragging: false,
	invalidImportLines: null
}

const deckbuilder = (state = initialState, action) => {
	switch (action.type) {
		case SET_DECK: {
			const { change, deck, isEditingDeck } = action

			let update = null
			let shelvedDeck = state.shelvedDeck

			if (state.deck && deck && deck.slug && state.deck.slug !== deck.slug && state.deck.isEditing && !isEditingDeck) {
				shelvedDeck = state.deck
			}

			if (deck) {
				if (change === 'refresh') {
					update = { ...deck }
				} else {
					update = {
						...deck,
						saved: !change,
						...(isEditingDeck && { isEditing: isEditingDeck })
					}

					if (Array.isArray(change)) {
						update.changes = {
							...(deck.changes || {}),
							...change.reduce((accumulator, current) => {
								accumulator[current] = true

								return accumulator
							}, {})
						}
					} else if (change) {
						update.changes = {
							...(deck.changes || {}),
							[change]: true
						}
					} else if (Object.keys(deck.changes || {}).length === 0) {
						update.changes = {}
					}
				}
			}

			return {
				...state,

				deck: update,
				shelvedDeck
			}
		}

		case UNSHELVE_DECK: {
			return {
				...state,
				deck: state.shelvedDeck,
				shelvedDeck: null
			}
		}

		case DISCARD_CHANGES: {
			return {
				...state,
				deck: null,
				shelvedDeck: null
			}
		}

		case CLEAR_DECK: {
			const { deck, shelvedDeck } = state

			return {
				...state,
				deck: shelvedDeck ? deck : null,
				shelvedDeck: null
			}
		}

		case SET_VALIDATION_INFO: {
			const { invalidLegalSince, invalidLimitedList, legalSince, legalSinceSet, legalStatus, tooSmallMain } = action
			const { deck } = state

			if (deck) {
				const update = {
					...deck,
					invalidLegalSince,
					invalidLimitedList,
					legalSince,
					legalSinceSet,
					legalStatus,
					tooSmallMain
				}

				return {
					...state,
					deck: update
				}
			}

			return state
		}

		case SET_DECK_HAVES: {
			const { haves } = action
			const { deck } = state

			if (deck) {
				const update = {
					...deck,
					haves
				}

				return {
					...state,
					deck: update
				}
			}

			return state
		}

		case SET_DECK_ADDITIONAL: {
			const { data } = action
			const { deck } = state

			if (deck) {
				const update = {
					...deck,
					...data
				}

				return {
					...state,
					deck: update
				}
			}

			return state
		}

		case SET_MODE: {
			const { mode } = action

			return {
				...state,
				mode
			}
		}

		/**
		 * ADD_CARD
		 */
		case ADD_CARD: {
			const { card, place, artworkId, haves } = action
			const { deck } = state

			let deckCards = deck.cards

			// a new skill overrides the old one
			if (place === 'skill') {
				const existingSkill = deck.cards.find(item => item.place === 'skill')

				if (existingSkill) {
					deckCards = deckCards.filter(item => item.id !== existingSkill.id)
				}
			}

			const update = {
				...deck,
				updateIndicator: new Date().getTime(),
				cards: [
					...deckCards,
					{
						card,
						place,
						checked: null,
						artworkId,
						id: unique('deckCardIdTmp')
					}
				],
				haves: haves ? {
					...deck.haves,
					[artworkId ? `artwork-${artworkId}` : `card-${card.id}`]: haves
				} : deck.haves,
				saved: false,
				changes: {
					...deck.changes,
					cards: true
				}
			}

			return {
				...state,
				deck: update
			}
		}

		/**
		 * SET_DECK_CARDS
		 */
		case SET_DECK_CARDS: {
			const { cards, userId } = action
			const { deck } = state

			const ownsDeck = deck.user.id === userId

			const update = {
				...deck,
				cards,
				updateIndicator: new Date().getTime(),
				saved: !ownsDeck,
				changes: ownsDeck ? {
					...deck.changes,
					cards: true
				} : deck.changes
			}

			return {
				...state,
				deck: update
			}
		}

		/**
		 * SET_DECK_CARD_CHECKED
		 */
		case SET_DECK_CARD_CHECKED: {
			const { checked, deckCardId, userId } = action
			const { deck } = state

			const ownsDeck = deck.user.id === userId

			const cards = deck.cards.reduce((accumulator, current) => {
				if (current.id === deckCardId) {
					current.checked = checked
				}

				accumulator.push(current)

				return accumulator
			}, [])

			const update = {
				...deck,
				cards,
				updateIndicator: new Date().getTime(),
				saved: !ownsDeck,
				changes: ownsDeck ? {
					...deck.changes,
					cards: true
				} : deck.changes
			}

			return {
				...state,
				deck: update
			}
		}

		/**
		 * REMOVE_CARD
		 */
		case REMOVE_CARD: {
			const { deckCard } = action
			const { deck } = state

			const update = {
				...deck,
				cards: deck.cards.filter(item => item.id !== deckCard.id),

				updateIndicator: new Date().getTime(),
				saved: false,
				changes: {
					...deck.changes,
					cards: true
				}
			}

			return {
				...state,
				deck: update
			}
		}

		/**
		 * SAVE_DECK
		 */
		case SAVE_DECK: {
			const key = state.shelvedDeck ? 'shelvedDeck' : 'deck'
			const deck = state.shelvedDeck || state.deck

			const updated = {
				...deck,
				saving: true
			}

			return {
				...state,

				[key]: updated
			}
		}
		case SAVE_DECK_COMMIT: {
			const { deckSlug, archetypes, cards, coverCard, coverArtwork, createdAt, format, legalSince, legalSinceSet, invalidLimitedList, invalidLegalSince, notPublicReasons, isPublic, tooSmallMain, main, side, extra } = action

			const key = state.shelvedDeck ? 'shelvedDeck' : 'deck'
			const savedDeck = state.shelvedDeck || state.deck

			// prevent app crash if active deck is null
			if (!savedDeck) {
				console.error(`[SAVE_DECK_COMMIT] Deck ${deckSlug} is not loaded.`)
				return state
			}

			const update = {
				...savedDeck,

				archetypes: archetypes || savedDeck.archetypes,
				cards: cards || savedDeck.cards,
				coverCard: coverCard || savedDeck.coverCard,
				coverArtwork: coverArtwork || savedDeck.coverArtwork,
				createdAt: createdAt || savedDeck.createdAt,
				format: format || savedDeck.format,
				legalSince,
				legalSinceSet,
				invalidLimitedList,
				invalidLegalSince,
				notPublicReasons,
				isPublic,
				tooSmallMain,
				main,
				side,
				extra,

				updatedAt: new Date().toISOString(),
				saving: false,
				saved: true,
				changes: {}
			}

			return {
				...state,
				[key]: state[key].slug === deckSlug ? update : state[key]
			}
		}
		case SAVE_DECK_ROLLBACK: {
			const { deckSlug } = action
			const savedDeck = state.deck

			// prevent app crash if active deck is null
			if (!savedDeck) {
				console.error(`[SAVE_DECK_ROLLBACK] Deck ${deckSlug} is not loaded.`)
				return state
			}

			const update = {
				...savedDeck,
				saving: false,
				saved: false
			}

			return {
				...state,
				deck: state.deck.slug === deckSlug ? update : state.deck
			}
		}

		/**
		 * SET_DECK_NAME
		 */
		case SET_DECK_NAME: {
			const { deckSlug, loadingId, name } = action
			const { deck } = state

			const updated = {
				...state.deck,
				name,
				nameBeforeUpdate: deck.name,
				nameLoadingId: loadingId,
				updateIndicator: new Date().getTime()
			}

			return {
				...state,
				deck: state.deck && state.deck.slug === deckSlug ? updated : state.deck
			}
		}
		case SET_DECK_NAME_COMMIT: {
			const { deckSlug } = action
			const { deck } = state

			const remainingChanges = deck.changes ? Object.keys(deck.changes).reduce((accumulator, current) => {
				if (current !== 'name') {
					accumulator[current] = true
				}

				return accumulator
			}, {}) : {}

			const updated = {
				...deck,
				nameBeforeUpdate: undefined,
				nameLoadingId: undefined,
				changes: remainingChanges,
				saved: Object.keys(remainingChanges).length === 0,
				updateIndicator: new Date().getTime()
			}

			return {
				...state,
				deck: state.deck && state.deck.slug === deckSlug ? updated : state.deck,
			}
		}
		case SET_DECK_NAME_ROLLBACK: {
			const { deckSlug, loadingId, errorMessage } = action

			const deck = state.deck && state.deck.slug === deckSlug ? state.deck : state.decks.find(item => item.slug === deckSlug)

			const updated = {
				...deck,
				name: deck.nameBeforeUpdate,
				nameLoadingId: undefined,
				updateIndicator: new Date().getTime()
			}

			return {
				...state,
				deck: state.deck.slug === deckSlug ? updated : state.deck,
				error: [
					...state.error,
					{
						loadingId,
						message: errorMessage
					}
				]
			}
		}

		/**
		 * SET_ARCHIVED
		 */
		case SET_ARCHIVED: {
			const { deckSlug, archived } = action
			const { deck } = state

			const updated = {
				...deck,
				archived
			}

			return {
				...state,
				deck: deck && deck.slug === deckSlug ? updated : state.deck
			}
		}

		/**
		 * VOTE
		 */
		case VOTE_DECK: {
			const { deck, loadingId, vote, voteDiff, upvotesDiff, downvotesDiff } = action

			const update = {
				...deck,
				votes: Number(deck.votes) + voteDiff,
				userVote: vote,
				upvotes: Number(deck.upvotes) + upvotesDiff,
				downvotes: Number(deck.downvotes) + downvotesDiff
			}

			return updateDeck(state, OPTIMISTIC_UPDATE, deck.slug, loadingId, {

			})
		}
		case VOTE_DECK_COMMIT: {
			const { deck, loadingId } = action

			return updateDeck(state, COMMIT, deck.slug, loadingId, {
				votes: undefined
			})
		}
		case VOTE_DECK_ROLLBACK: {
			const { deck, loadingId } = action

			return updateDeck(state, ROLLBACK, deck.slug, loadingId, {
				votes: undefined
			})
		}

		case ADD_EVENT_PLAYER: {
			const { deck } = action
			const stateDeck = state.deck && state.deck.slug === deck.slug ? state.deck : null

			if (!stateDeck) {
				return state
			}

			const update = {
				...stateDeck,
				...deck
			}

			return {
				...state,
				deck: state.deck && state.deck.slug === deck.slug ? update : state.deck
			}
		}

		case REMOVE_EVENT_PLAYERS: {
			const { deck } = state

			const update = {
				...deck,
				eventPlayers: [],
				hasResult: false,
				hasEvent: false
			}

			return {
				...state,
				deck: update
			}
		}

		/**
		 * GENERAL
		 */
		case SET_DRAGGING: {
			const { dragging } = action

			return {
				...state,
				dragging
			}
		}
		case RESET: {
			return initialState
		}
		case SET_INVALID_IMPORT_LINES: {
			const { invalidImportLines } = action

			return {
				...state,
				invalidImportLines
			}
		}
		default:
			return state
	}
}

export default deckbuilder
