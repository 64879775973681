import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { desktop, mobile, VIEWPORT_DESKTOP } from '../../../styles/media'
import translate from '../../../helper/translate'
import Icon from '../../../components/general/Icon'
import { toggleCardSearch } from '../../../state/actions/persist'
import { CardSearch } from '../../imports'
import { locationMatchesCardSearch } from '../../../helper/router'

const TabSearch = ({ cardSearchCollapsed, deck, location, settings, subscribed, t, toggleCardSearch, userId, viewport }) => {
	const matchCards = matchPath(location.pathname, { path: '/cards/:collectionSlug?', exact: true })
	const isEditingDeck = !!(location.pathname.startsWith('/deck-builder/') && deck && deck.user.id === userId)

	const match = matchPath(location.pathname, {
		path: '/deck-builder/:slug?/:modal?',
		exact: true
	})

	const [firstLoaded, setFirstLoaded] = useState(false)
	const visible = !!((matchCards && locationMatchesCardSearch(location)) || (match && isEditingDeck && deck && (deck.slug === match.params.slug || (!deck.slug && location.pathname === '/deck-builder/')) && viewport === VIEWPORT_DESKTOP))

	const transparent = !!(subscribed && settings['app.deckBuilderWallpaper'])

	useEffect(() => {
		if (visible && !firstLoaded) {
			setFirstLoaded(true)
		}
	}, [visible])

	return (
		<Wrapper collapsed={cardSearchCollapsed} fullscreen={!!matchCards} transparent={transparent} visible={visible}>
			{firstLoaded && (
				<CardSearch
					fullscreen={!!(matchCards && viewport === VIEWPORT_DESKTOP)}
					location={location}
					match={matchCards}
					visible={visible}
				/>
			)}

			{viewport === VIEWPORT_DESKTOP && cardSearchCollapsed && (
				<CardSearchToggle onClick={toggleCardSearch}>
					<Icon name="search" />
					{t('general.cardSearch')}
				</CardSearchToggle>
			)}
		</Wrapper>
	)
}

TabSearch.propTypes = {
	cardSearchCollapsed: PropTypes.bool,
	deck: PropTypes.object,
	location: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	toggleCardSearch: PropTypes.func.isRequired,
	userId: PropTypes.number,
	viewport: PropTypes.string.isRequired
}

TabSearch.defaultProps = {
	cardSearchCollapsed: false,
	deck: null,
	subscribed: null,
	userId: null
}

const Wrapper = styled.div`
	background: ${props => props.theme.id === 'dark' ? '#151515' : '#1F1F1F'};
	display: ${props => props.visible ? 'block' : 'none'};
	min-height: 100vh;

	${desktop`
		transition: 0.2s;

		${props => !props.fullscreen && props.collapsed && `
			transform: translate(-100%);
		`}
	`}

	${mobile`
		position: fixed;

		top: 0;
		left: 0;
		bottom: 50px;

		width: 100%;
	`}

	${props => !props.fullscreen && `
		position: fixed;
		top: 0;
		left: 80px;
		z-index: ${props.theme.zLayer5};

		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		width: 340px;
	`}

	${props => props.transparent && `
		background: rgba(0, 0, 0, 0.7);
	`}
`

const CardSearchToggle = styled.button`
	background: rgba(0, 0, 0, 0.7);
	border: 0;
	border-radius: 0 0 2px 2px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.8rem;
	font-weight: 500;
	padding: 0.5rem;
	transform: rotate(-90deg);
	transition: 0.1s;

	position: absolute;
	right: -72px;
	top: 134px;

	align-items: center;
	display: flex;

	svg {
		height: 16px;
		width: 16px;
		margin-right: 0.25rem;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.8);
		cursor: pointer;
		color: ${props => props.theme.white};
	}
`

const enhance = compose(
	connect(state => ({
		cardSearchCollapsed: state.persist.cardSearchCollapsed,
		deck: state.deckbuilder.deck,
		search: state.persist.search,
		settings: state.persist.user.settings,
		subscribed: state.persist.user.subscribed,
		userId: state.persist.user.id,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		toggleCardSearch
	}, dispatch)),
	translate('general')
)

export default enhance(TabSearch)
