import styled from 'styled-components'
import Link from '../router/Link'
import { desktop } from '../../styles/media'

const FollowsInputButton = styled(Link)`
	background: ${props => props.theme.secondary};
	border-radius: 3px;
	cursor: pointer;
	transition: 0.1s;
	text-decoration: none;

	height: 34px;
	width: 34px;
	min-width: 34px;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		color: ${props => props.theme.white};
		transition: 0.1s;
	}

	${desktop`
		&:hover {
			background: ${props => props.theme.primary};
		}
	`}
`

export default FollowsInputButton
