import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledInputContainer } from './Input'
import Icon, { StyledIcon } from '../general/Icon'

const NativeSelect = ({ children, data, onChange, value }) => (
	<Wrapper>
		<StyledInputContainer>
			<select
				onChange={e => onChange(e.target.value)}
				value={value || ''}
			>
				{children && !data && children}
				{data && !children && data.map(({ value, label }) => (
					<option
						key={value}
						value={value}
					>
						{label}
					</option>
				))}
			</select>
			<Icon name="chevron-down" />
		</StyledInputContainer>
	</Wrapper>
)

NativeSelect.propTypes = {
	children: PropTypes.any,
	data: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any
}

NativeSelect.defaultProps = {
	children: null,
	data: null,
	value: null
}

const Wrapper = styled.div`
	${StyledInputContainer} > ${StyledIcon} {
		color: ${props => props.theme.backgroundSolid};
		cursor: text;
		pointer-events: none;

		position: absolute;
		top: calc(50% + 8px);
		left: auto;
		right: 0.5rem;
		transform: translate(0, -50%);
	}
`

export default NativeSelect
