import React from 'react'
import styled from 'styled-components'
import Logo from '../../images/logo/cardcluster-white.svg'
import Link from '../router/Link'
import { desktop } from '../../styles/media'
import Icon, { StyledIcon } from '../general/Icon'

const ProductLink = () => (
	<StyledProductLink to="/product/collection-manager">
		<Icon name="information-circle" />
		<img src={Logo} alt="" />
		<span>Collection Manager</span>
	</StyledProductLink>
)
export const StyledProductLink = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	border-radius: 6px;
	margin: -0.25rem -0.25rem -0.25rem 0;
	padding: 0.25rem 0.5rem;
	position: relative;
	text-decoration: none;
	transition: 0.2s;

	img {
		height: 18px;
	}

	span {
		color: #DDD;
		font-family: TechnaSans, ${props => props.theme.fontDefault};
		font-size: 1rem;
		margin-top: 0.25rem;
		white-space: nowrap;
	}

	${StyledIcon} {
        color: ${props => props.theme.white};
		opacity: 0;
		position: absolute;
		left: -1.75rem;
		top: 50%;
		transform: translate(0, -50%);
        transition: 0.2s;
	}

	${desktop`
		&:hover {
			background: rgba(255, 255, 255, 0.1);

			${StyledIcon} {
				opacity: 1;
			}

			span {
				color: ${props => props.theme.white};
			}
		}
	`}
`

export default ProductLink
