const plans = {
	starter: {
		nameRaw: 'cardcluster Starter',
		watchListSpace: 15
	},
	pro: {
		nameRaw: 'cardcluster Pro',
		watchListSpace: 100
	},
	images: {
		nameRaw: 'Kartenbilder Nutzungslizenz'
	}
}

export default plans
