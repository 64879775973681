import { getArtworkNumber } from './card'

export const sortDeckCards = (deckCards, archetypes, mainDeckAlgorithm, extraDeckAlgorithm, cardNameLanguage) => {
	const main = sortByCustomAlgorithm(deckCards.filter(deckCard => deckCard.place === 'main'), mainDeckAlgorithm, archetypes, cardNameLanguage)
	const extra = sortByCustomAlgorithm(deckCards.filter(deckCard => deckCard.place === 'extra'), extraDeckAlgorithm, archetypes, cardNameLanguage)
	const side = sortByCustomAlgorithm(deckCards.filter(deckCard => deckCard.place === 'side'), mainDeckAlgorithm, archetypes, cardNameLanguage)
	const note = sortByCustomAlgorithm(deckCards.filter(deckCard => deckCard.place === 'note'), mainDeckAlgorithm, archetypes, cardNameLanguage)

	return [
		...deckCards.filter(deckCard => deckCard.place === 'skill'),
		...main.map((item, index) => ({ ...item, order: index + 1 })),
		...extra.map((item, index) => ({ ...item, order: index + 1 })),
		...side.map((item, index) => ({ ...item, order: index + 1 })),
		...note.map((item, index) => ({ ...item, order: index + 1 }))
	]
}

export const sortByCustomAlgorithm = (cards, algorithm, deckArchetypes = null, cardNameLanguage) => {
	const archetypesCount = deckArchetypes && algorithm && Array.isArray(algorithm) && algorithm.find(item => item === 'theme') ? deckArchetypes.reduce((accumulator, item, index) => {
		accumulator[item.id] = index

		return accumulator
	}, {}) : null

	const cardsCount = algorithm.find(item => item === 'copies') ? cards.reduce((accumulator, item) => {
		const card = item.card ? item.card : item

		if (!accumulator[card.slug]) {
			accumulator[card.slug] = 0
		}

		accumulator[card.slug] += 1

		return accumulator
	}, {}) : null

	return cards.sort((aSource, bSource) => {
		const a = aSource.card ? aSource.card : aSource
		const b = bSource.card ? bSource.card : bSource

		for (const item of algorithm) {
			if (item === 'primary') {
				const aPrimaryCardTypeOrder = a.cardTypeOrder.substring(0, 1) + '0'
				const bPrimaryCardTypeOrder = b.cardTypeOrder.substring(0, 1) + '0'
				const diff = aPrimaryCardTypeOrder - bPrimaryCardTypeOrder

				if (diff !== 0) {
					return diff
				}
			} else if (item === 'secondary') {
				const diff = a.cardTypeOrder - b.cardTypeOrder

				if (diff !== 0) {
					return diff
				}
			} else if (item === 'theme' && archetypesCount) {
				// prioritize theme where the card is a member
				// only use another theme if there is no theme assigned where the card is a member
				let aFirstMember = a.archetypesData && a.archetypesData.find(item2 => (
					deckArchetypes.find(item3 => (
						item3.id === item2.id && (
							item2.cardRelationType === 'member'
							|| !a.archetypesData.find(item4 => (
								item4.cardRelationType === 'member'
								&& item4.id !== item2.id
								&& deckArchetypes.find(item5 => item5.id === item4.id)
							))
						)
					))
				))

				const aSecondMember = aFirstMember && a.archetypesData ? a.archetypesData.find(item2 => (
					deckArchetypes.find(item3 => (
						item3.id === item2.id && (
							item2.cardRelationType === 'member'
							&& aFirstMember.id !== item2.id
						)
					))
				)) : null

				if (aSecondMember) {
					const firstOrder = deckArchetypes.findIndex(item => item.id === aFirstMember.id)
					const secondOrder = deckArchetypes.findIndex(item => item.id === aSecondMember.id)

					if (secondOrder < firstOrder) {
						aFirstMember = aSecondMember
					}
				}

				let bFirstMember = b.archetypesData && b.archetypesData.find(item2 => (
					deckArchetypes.find(item3 => (
						item3.id === item2.id && (
							item2.cardRelationType === 'member'
							|| !(b.archetypesData && b.archetypesData.find(item4 => (
								item4.cardRelationType === 'member'
								&& item4.id !== item2.id
								&& deckArchetypes.find(item5 => item5.id === item4.id)
							)))
						)
					))
				))

				const bSecondMember = bFirstMember && b.archetypesData ? b.archetypesData.find(item2 => (
					deckArchetypes.find(item3 => (
						item3.id === item2.id && (
							item2.cardRelationType === 'member'
							&& bFirstMember.id !== item2.id
						)
					))
				)) : null

				if (bSecondMember) {
					const firstOrder = deckArchetypes.findIndex(item => item.id === bFirstMember.id)
					const secondOrder = deckArchetypes.findIndex(item => item.id === bSecondMember.id)

					if (secondOrder < firstOrder) {
						bFirstMember = bSecondMember
					}
				}

				if (aFirstMember && !bFirstMember) {
					return -1
				}

				if (!aFirstMember && bFirstMember) {
					return 1
				}

				if (aFirstMember && bFirstMember) {
					if (archetypesCount[aFirstMember.id] < archetypesCount[bFirstMember.id]) return -1
					if (archetypesCount[aFirstMember.id] > archetypesCount[bFirstMember.id]) return 1
				}
			} else if (item === 'copies' && cardsCount) {
				if (cardsCount[a.slug] < cardsCount[b.slug]) return 1
				if (cardsCount[a.slug] > cardsCount[b.slug]) return -1
			} else if (item === 'a-z') {
				const aField = a[`name_${cardNameLanguage}`] || a.name_en
				const bField = b[`name_${cardNameLanguage}`] || b.name_en

				const compare = aField.localeCompare(bField, 'en', {
					numeric: true,
					sensitivity: 'base'
				})

				if (compare !== 0) {
					return compare
				}
			} else if (item === 'levelDesc') {
				if (a.level < b.level) return 1
				if (a.level > b.level) return -1

				if (a.linkRating < b.linkRating) return 1
				if (a.linkRating > b.linkRating) return -1
			} else if (item === 'levelAsc') {
				if (a.level < b.level) return -1
				if (a.level > b.level) return 1

				if (a.linkRating < b.linkRating) return -1
				if (a.linkRating > b.linkRating) return 1
			} else if (item === 'atkDesc') {
				if (a.attack < b.attack) return 1
				if (a.attack > b.attack) return -1
			} else if (item === 'atkAsc') {
				if (a.attack < b.attack) return -1
				if (a.attack > b.attack) return 1
			} else if (item === 'defDesc') {
				if (a.defense < b.defense) return 1
				if (a.defense > b.defense) return -1
			} else if (item === 'defAsc') {
				if (a.defense < b.defense) return -1
				if (a.defense > b.defense) return 1
			}
		}

		// artworkNumber
		if (aSource.artworkId && bSource.artworkId) {
			const aNumber = getArtworkNumber(aSource)
			const bNumber = getArtworkNumber(bSource)

			if (aNumber < bNumber) return -1
			if (aNumber > bNumber) return 1
		}

		return 0
	})
}

export const sortByCardType = (cards, sortByDirection = 'ASC') => cards.sort((aSource, bSource) => {
	const a = aSource.card ? aSource.card : aSource
	const b = bSource.card ? bSource.card : bSource

	const diff = a.cardTypeOrder - b.cardTypeOrder

	if (diff !== 0) {
		return sortByDirection === 'DESC' ? diff * -1 : diff
	}

	// name
	const aField = a.name || a.nameEn
	const bField = b.name || b.nameEn

	if (aField !== bField) {
		return aField.localeCompare(bField, 'en', {
			numeric: true,
			sensitivity: 'base'
		})
	}

	// artworkNumber
	if (aSource.artwork && bSource.artwork) {
		if (aSource.artwork.number < bSource.artwork.number) return -1
		if (aSource.artwork.number > bSource.artwork.number) return 1
	}

	return aField.localeCompare(bField, 'en', {
		numeric: true,
		sensitivity: 'base'
	})
})

export const sortCardsByName = cards => cards.sort((a, b) => {
	const aName = a.card.name || a.card.nameEn
	const bName = b.card.name || b.card.nameEn

	if (aName < bName) return -1
	if (aName > bName) return 1

	return 0
})

export const sortByName = (items, field, fieldFallback = 'nameEn') => items.sort((a, b) => {
	const aField = a[field] || a[fieldFallback]
	const bField = b[field] || b[fieldFallback]

	return aField.localeCompare(bField, 'en', {
		numeric: true,
		sensitivity: 'base'
	})
})

export const sortComments = (comments, sortBy) => {
	if (sortBy === 'popularity') {
		return comments.sort((a, b) => {
			if (!a.permalink && b.permalink) return 1
			if (a.permalink && !b.permalink) return -1

			if (b.votes > a.votes) return 1
			if (b.votes < a.votes) return -1

			return new Date(b.createdAt) - new Date(a.createdAt)
		})
	}

	return comments.sort((a, b) => {
		if (!a.permalink && b.permalink) return 1
		if (a.permalink && !b.permalink) return -1

		return new Date(b.createdAt) - new Date(a.createdAt)
	})
}
