const places = {
	1: {
		nameShort_de: '1.',
		nameList_de: '1.',
		nameShort_en: '1st',
		nameList_en: '1st',
		order: 1
	},
	2: {
		nameShort_de: '2.',
		nameList_de: '2.',
		nameShort_en: '2nd',
		nameList_en: '2nd',
		order: 2
	},
	top4: {
		nameShort_de: 'Top 4',
		nameList_de: '3. – 4.',
		nameShort_en: 'Top 4',
		nameList_en: '3 – 4th',
		order: 3
	},
	top8: {
		nameShort_de: 'Top 8',
		nameList_de: '5. – 8.',
		nameShort_en: 'Top 8',
		nameList_en: '5 – 8th',
		order: 5
	},
	top16: {
		nameShort_de: 'Top 16',
		nameList_de: '9. – 16.',
		nameShort_en: 'Top 16',
		nameList_en: '9 – 16th',
		order: 9
	},
	top32: {
		nameShort_de: 'Top 32',
		nameList_de: '17. – 32.',
		nameShort_en: 'Top 32',
		nameList_en: '17 – 32nd',
		order: 17
	},
	top64: {
		nameShort_de: 'Top 64',
		nameList_de: '33. – 64.',
		nameShort_en: 'Top 64',
		nameList_en: '33 – 64th',
		order: 33
	},
	top128: {
		nameShort_de: 'Top 128',
		nameList_de: '65. – 128.',
		nameShort_en: 'Top 128',
		nameList_en: '65 – 128th',
		order: 65
	}
}

export default places
