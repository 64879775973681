import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from '../router/Modal'
import Button, { StyledButton } from '../button/Button'
import { setModalForm } from '../../state/actions/app'
import { mobile, VIEWPORT_MOBILE } from '../../styles/media'
import Input, { StyledInputContainer } from '../form/Input'

class ModalForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			name: '',
			visible: this.props.modalForm !== null
		}

		this.inputRef = React.createRef()
	}

	componentDidUpdate(prevProps) {
		const { modalForm } = this.props

		if (prevProps.modalForm !== modalForm) {
			this.setState({
				name: modalForm && modalForm.name ? modalForm.name : '',
				visible: modalForm !== null
			}, () => {
				if (this.state.visible) {
					window.addEventListener('keydown', this.handleKeyDown)
					this.inputRef.current.select()
				} else {
					window.removeEventListener('keydown', this.handleKeyDown)
				}
			})
		}
	}

	handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			this.close(true)
		}
	}

	handleInputKeyDown = (event) => {
		const { name } = this.state

		if (event.key === 'Enter' && name !== '') {
			this.success()
		}
	}

	success = () => {
		const { name } = this.state

		this.close()
		this.props.modalForm.success(name)
	}

	close = (isCancel = false) => {
		const { modalForm } = this.props

		this.setState({
			name: '',
			visible: false
		}, () => {
			if (isCancel && modalForm.cancel) {
				modalForm.cancel()
			}
			window.setTimeout(() => setModalForm(null), 500)
		})
	}

	render() {
		const { modalForm, viewport } = this.props
		const { name, visible } = this.state

		return (
			<Modal center centerTop={viewport === VIEWPORT_MOBILE} visible={visible} onClickAway={this.close} width={modalForm && modalForm.width ? modalForm.width : undefined}>
				<ModalFormText>
					{modalForm && modalForm.text}
				</ModalFormText>

				<ModalFormField>
					<Input
						value={name}
						onChange={name => this.setState({ name })}
						onKeyDown={this.handleInputKeyDown}
						inputRef={this.inputRef}
					/>
				</ModalFormField>

				<ModalFormButtons>
					<Button
						onClick={this.success}
						disabled={name.trim() === ''}
					>
						{modalForm && modalForm.submitText}
					</Button>
					<Button
						secondary
						onClick={this.close}
					>
						{modalForm && modalForm.cancelText}
					</Button>
				</ModalFormButtons>
			</Modal>
		)
	}
}

ModalForm.propTypes = {
	modalForm: PropTypes.object,
	viewport: PropTypes.string.isRequired
}

ModalForm.defaultProps = {
	modalForm: null
}

const ModalFormText = styled.p`
	color: ${props => props.theme.textLight};
	font-size: 1.2rem;
	font-weight: 500;
	margin-bottom: 1rem;
	text-align: center;
`

const ModalFormField = styled.div`
	margin-bottom: 1rem;

	${StyledInputContainer} {
		width: 100%;
	}

	input {
		font-size: 1.1rem;
		font-weight: 500;
		padding: 0.75rem;
		text-align: center;
		width: 100%;
	}
`

const ModalFormButtons = styled.div`
	display: flex;
	justify-content: center;

	${mobile`
		${StyledButton} + ${StyledButton} {
			margin: 0 0 0 0.5rem;
		}

		${StyledButton} {
			font-size: 0.8rem;
			padding: 0.5rem;
			border-radius: 8px;
		}
	`}
`

const enhance = connect(state => ({
	modalForm: state.app.modalForm,
	viewport: state.app.viewport
}), dispatch => bindActionCreators({
	setModalForm
}, dispatch))

export default enhance(ModalForm)
