import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { matchPath, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Spinner from '../general/Spinner'
import { desktop, mobilePortrait, VIEWPORT_MOBILE } from '../../styles/media'
import Icon from '../general/Icon'
import { scrollToPosition } from '../../helper/scroll'
import ThickHeading from '../text/ThickHeading'
import translate from '../../helper/translate'
import BetterButton, { StyledBetterButton } from '../button/BetterButton'

class Modal extends Component {
	static propTypes = {
		center: PropTypes.bool,
		centerTop: PropTypes.bool,
		children: PropTypes.any.isRequired,
		mobileLargeClose: PropTypes.bool,
		loading: PropTypes.bool,
		onClose: PropTypes.func,
		onClickAway: PropTypes.func,
		t: PropTypes.func.isRequired,
		theme: PropTypes.object.isRequired,
		transparentCloseButton: PropTypes.bool,
		visible: PropTypes.bool,
		viewport: PropTypes.string.isRequired,
		width: PropTypes.string
	}

	static defaultProps = {
		mobileLargeClose: false,
		loading: false,
		onClickAway: null,
		onClose: null,
		center: false,
		centerTop: false,
		transparentCloseButton: false,
		visible: null,
		width: '500px'
	}

	constructor(props) {
		super(props)

		this.wrapperRef = React.createRef()
	}

	componentDidUpdate(prevProps) {
		const prevVisible = this.matches(prevProps) || prevProps.visible
		const visible = this.matches(this.props) || this.props.visible

		if (prevVisible !== visible && visible) {
			scrollToPosition(0, document.scrollingElement)
		}
	}

	handleOnClick = () => {
		const { onClickAway } = this.props

		if (onClickAway) {
			onClickAway()
		}
	}

	matches = props => !!matchPath(props.location.pathname, {
		path: props.path
	})

	render() {
		const { center, centerTop, children, mobileLargeClose, loading, onClickAway, onClose, t, theme, transparentCloseButton, viewport, width } = this.props

		const visible = this.matches(this.props) || this.props.visible

		return ReactDOM.createPortal((
			<>
				<Helmet>
					{visible && <html className="offscreen" />}
				</Helmet>

				<Overlay onClick={this.handleOnClick} visible={visible} />

				<Wrapper ref={this.wrapperRef} loading={loading || undefined} visible={visible} center={center} centerTop={centerTop} width={width}>
					{loading && <Spinner />}
					{onClose && <CloseButton onClick={onClose || onClickAway} transparentCloseButton={transparentCloseButton}><Icon name="x" /></CloseButton>}
					{visible && children}
				</Wrapper>

				{onClose && viewport === VIEWPORT_MOBILE && mobileLargeClose && (
					<MobileCloseButton
						onClick={onClose || onClickAway}
						transparentCloseButton={transparentCloseButton}
						visible={visible}
					>
						<BetterButton color={theme.secondary}>
							<Icon name="x" />
							{t('general.close')}
						</BetterButton>
					</MobileCloseButton>
				)}
			</>
		), document.getElementById('root-modal'))
	}
}

const Overlay = styled.div`
	background: ${props => props.theme.backgroundOverlay};
	transition: opacity 0.2s;
	opacity: ${props => props.visible ? 1 : 0};
	z-index: ${props => props.visible ? props.theme.zLayer13 : -1};

	align-items: center;
	display: flex;
	justify-content: center;

	height: calc(100vh + 30px);
	height: calc(var(--vh, 1vh) * 100 + 30px);
	width: 100%;

	position: fixed;
	top: -30px;

	overflow-x: auto;
`

const Wrapper = styled.div`
	background: ${props => props.theme.background};
	border-radius: 5px;
	margin: 2rem auto;
	padding: 2rem;
	width: ${props => props.width};
	max-width: 100%;

	position: fixed;
	left: -100%;
	z-index: ${props => props.theme.zLayer14};

	transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
	opacity: 0;
	transform: scale(0.85);

	${props => props.theme.id === 'dark' && `
		box-shadow: ${props.theme.shadowSearch};
	`}

	${props => props.visible && `
		position: relative;
		left: auto;
		transition: opacity 0.1s, transform 0.2s;
		opacity: 1;
		transform: scale(1);
	`}

	${mobilePortrait`
		margin: 0 auto;
		padding: 1.5rem 1rem 2rem;
		width: calc(100% - 2rem);

		${props => !props.center && `
			border-radius: 24px 24px 0 0;
			margin: 0;
			width: 100%;

			min-height: 100vh;
			min-height: calc(var(--vh, 1vh) * 100);
		`}
	`}

	* {
		visibility: ${props => props.loading ? 'hidden' : 'visible'};
	}

	> :first-child {
		margin-top: 0;
		padding-top: 0;
	}

	> :last-child {
		margin-bottom: 0;

		> :last-child {
			margin-bottom: 0;

			> :last-child {
				margin-bottom: 0;
			}
		}
	}

	${StyledBetterButton} + ${StyledBetterButton} {
		margin-top: 1rem;
	}

	${props => props.center && props.visible ? `
		position: fixed;
		top: ${props.centerTop ? '1rem' : '50%'};
		left: 50%;
		margin: 0;
	` : ''}

	${props => props.center ? `
		transform: ${props.visible ? `translate(-50%, ${props.centerTop ? '0' : '-50%'}) scale(1)` : `translate(-50%, ${props.centerTop ? '0' : '-50%'}) scale(0.85)`};
	` : ''}

	> ${ThickHeading} {
		margin-bottom: 1rem;
	}
`

const CloseButton = styled.button`
	background: ${props => props.theme.backgroundVeryLight};
	border: 1px solid ${props => props.theme.backgroundLight};
	border-radius: 50%;
	color: ${props => props.theme.textLight};
	height: 30px;
	padding: 0;
	transition: all 0.1s;
	width: 30px;

	align-items: center;
	display: flex;
	justify-content: center;

	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1;

	svg {
		height: 16px;
		width: 16px;
	}

	&:after {
		content: "";
		display: block;

		position: absolute;
		height: 50px;
		width: 50px;
		left: -10px;
		top: -10px;
	}

	${desktop`
		&:hover {
			border-color: ${props => props.transparentCloseButton ? 'rgba(255, 255, 255, 0.3)' : props.theme.textVeryLight};
		}
	`}

	${props => props.transparentCloseButton && `
		background: rgba(255, 255, 255, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.1);
		color: rgba(255, 255, 255, 0.8);
	`}
`

const MobileCloseButton = styled.div`
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 100;

	background: ${props => props.theme.background};
	box-shadow: 0 3px 2px 3px ${props => props.theme.backgroundSolid};
	padding: 1rem;

	${props => !props.visible && `
		display: none;
	`}
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general'),
	withRouter,
	withTheme
)

export default enhance(Modal)
