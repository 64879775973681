import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Content from '../../components/layout/Content'
import H1 from '../../components/header/H1'
import BetterButton, { StyledBetterButton } from '../../components/button/BetterButton'
import Icon from '../../components/general/Icon'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import translate from '../../helper/translate'

const SignUpJumbotron = ({ t, viewport }) => (
	<StyledSignUpJumbotron>
		<Content>
			<H1 as="div">
				<Icon name="user-group" />
				{t('general.signUpJumbotronHeading', {
					linebreak1: viewport === VIEWPORT_MOBILE ? <br /> : <>&nbsp;</>,
					linebreak2: viewport === VIEWPORT_DESKTOP ? <br /> : <>&nbsp;</>
				})}
			</H1>

			<ul>
				<li><Icon name="check" />{t('general.signUpJumbotronPerk1')}</li>
				<li><Icon name="check" />{t('general.signUpJumbotronPerk2')}</li>
				<li><Icon name="check" />{t('general.signUpJumbotronPerk3')}</li>
				<li><Icon name="check" />{t('general.signUpJumbotronPerk4')}</li>
			</ul>

			<BetterButton to="/signup">
				{t('general.signUpNow')}
			</BetterButton>
		</Content>
	</StyledSignUpJumbotron>
)

SignUpJumbotron.propTypes = {
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

const StyledSignUpJumbotron = styled.div`
	color: ${props => props.theme.white};
	margin-bottom: 2rem;

	${Content} {
		display: grid !important;
		grid-gap: 1.5rem;
		grid-template-columns: calc(550px - 1.5rem) 1fr;
	}

	${StyledBetterButton} {
		width: fit-content;
	}

	ul {
		list-style: none;
		margin: 0.5rem 0 0 0;
		padding: 0;

		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0.75rem;
		height: fit-content;
	}

	li {
		align-items: center;
		display: flex;
		gap: 0.5rem;

		font-size: 1.1rem;
		font-weight: 500;

		svg {
			color: ${props => props.theme.greenValid};
		}
	}

	${desktop`
		${Content} {
			padding-top: 1rem !important;
			padding-bottom: 2rem !important;
		}

		${H1} {
			align-items: center;
			display: flex;
			gap: 1rem;
		}

		${H1} svg {
			color: rgba(255, 255, 255, 0.5);
			height: 50px;
			width: 50px;
			min-width: 50px;
		}

		ul {
			grid-row: 1 / 3;
			grid-column: 2;
		}

		li svg {
			height: 24px;
			width: 24px;
		}
	`}

	${mobile`
		background: ${props => props.theme.header};
		margin-bottom: 0.5rem;
		padding-bottom: 1rem;

		${Content} {
			grid-gap: 1rem;
			grid-template-columns: 1fr;
			justify-items: center;
		}

		${H1} {
			text-align: center;

			svg {
				display: none;
			}
		}

		ul {
			grid-template-columns: 1fr;
			gap: 0.25rem;
			margin: 0 0 0.5rem 0;
		}

		li {
			font-size: 0.9rem;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(SignUpJumbotron)
