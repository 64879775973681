import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { StyledIcon } from '../general/Icon'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import Link from '../router/Link'
import { StyledOnPageSpinner } from '../general/OnPageSpinner'
import Tag from '../general/Tag'
import { StyledMark } from '../general/Mark'
import Separator from './Separator'
import { setDropDownVisible } from '../../state/actions/app'
import { bindActionCreators } from 'redux'

class DropDown extends Component {
	static propTypes = {
		button: PropTypes.any.isRequired,
		children: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
		handleBackground: PropTypes.string,
		isSelect: PropTypes.bool,
		mobileFullHeight: PropTypes.bool,
		noStyles: PropTypes.bool,
		onVisibleChange: PropTypes.func,
		left: PropTypes.number,
		right: PropTypes.number,
		title: PropTypes.string,
		top: PropTypes.number,
		bottom: PropTypes.number,
		setDropDownVisible: PropTypes.func.isRequired,
		viewport: PropTypes.string.isRequired,
		width: PropTypes.number
	}

	static defaultProps = {
		disabled: false,
		handleBackground: null,
		isSelect: false,
		mobileFullHeight: false,
		noStyles: false,
		onVisibleChange: null,
		left: null,
		right: null,
		title: null,
		top: 44,
		bottom: null,
		width: 200
	}

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}

		this.buttonRef = React.createRef()
		this.overlayRef = React.createRef()
		this.itemsRef = React.createRef()
	}

	componentDidMount() {
		window.addEventListener('mousedown', this.handleBlur)
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentDidUpdate(prevProps, prevState) {
		const { viewport } = this.props
		const { visible } = this.state

		if (viewport === VIEWPORT_MOBILE && prevState.visible !== visible) {
			if (visible) {
				this.itemsRef.current.style.transform = 'translate(0, 0)'
			} else {
				this.itemsRef.current.style.transform = 'translate(0, 100%)'
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleBlur)
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleButtonClick = (event) => {
		const { disabled, onVisibleChange, setDropDownVisible } = this.props

		event.preventDefault()
		event.stopPropagation()

		if (disabled) return

		this.setState(prevState => ({
			visible: !prevState.visible
		}), () => {
			setDropDownVisible(this.state.visible)

			if (onVisibleChange) {
				onVisibleChange(this.state.visible)
			}
		})
	}

	handleBlur = (event) => {
		const { onVisibleChange, setDropDownVisible } = this.props

		if (this.itemsRef && !this.itemsRef.current.contains(event.target) && !this.buttonRef.current.contains(event.target)) {
			this.setState({
				visible: false
			}, () => {
				setDropDownVisible(this.state.visible)

				if (onVisibleChange) {
					onVisibleChange(this.state.visible)
				}
			})
		}
	}

	handleKeyDown = (event) => {
		const { onVisibleChange, setDropDownVisible } = this.props

		if (event.key === 'Escape') {
			this.setState({
				visible: false
			}, () => {
				setDropDownVisible(this.state.visible)

				if (onVisibleChange) {
					onVisibleChange(this.state.visible)
				}
			})
		}
	}

	handleTouchStart = (event, ref) => {
		if (this.itemsRef.current.scrollTop !== 0) {
			return
		}

		this.touchRef = ref
		this.touchStartY = event.touches[0].clientY

		this.touchRef.current.addEventListener('touchmove', this.handleTouchMove)
		this.touchRef.current.addEventListener('touchend', this.handleTouchEnd)

		this.itemsRef.current.classList.add('notransition')
	}

	handleTouchMove = (event) => {
		const touchMoveY = event.touches[0].clientY

		this.touchDiff = touchMoveY - this.touchStartY

		if (this.touchDiff > -32) {
			this.itemsRef.current.style.transform = `translate(0, ${this.touchDiff}px)`
		}
	}

	handleTouchEnd = () => {
		if (this && this.touchRef && this.touchRef.current) {
			this.touchRef.current.removeEventListener('touchmove', this.handleTouchMove)
			this.touchRef.current.removeEventListener('touchend', this.handleTouchMove)

			this.itemsRef.current.classList.remove('notransition')
		}

		if (this.touchDiff > 50) {
			this.setState({
				visible: false
			})
		} else {
			this.itemsRef.current.style.transform = 'translate(0, 0)'
		}

		this.touchStartY = undefined
		this.touchDiff = undefined
		this.touchRef = undefined
	}

	handleBeforeNavigate = () => this.setState({ visible: false })

	renderItems = () => {
		const { children, handleBackground, isSelect, mobileFullHeight, noStyles, left, right, title, top, bottom, viewport, width } = this.props
		const { visible } = this.state

		const buttonTop = this.buttonRef.current ? this.buttonRef.current.getBoundingClientRect().top + top : 0

		return (
			<DropDownItems
				className={viewport === VIEWPORT_DESKTOP ? 'notransition' : undefined}
				onTouchStart={viewport === VIEWPORT_MOBILE ? event => this.handleTouchStart(event, this.itemsRef) : undefined}
				ref={this.itemsRef}
				noStyles={noStyles}
				buttonTop={buttonTop}
				left={left}
				right={right}
				top={top}
				bottom={bottom}
				visible={visible}
				width={width}
				mobileFullHeight={mobileFullHeight}
				isSelect={isSelect}
			>
				{viewport === VIEWPORT_MOBILE && (
					<>
						<HandleWrapper background={handleBackground}>
							<Handle />
						</HandleWrapper>

						{title && <Title>{title}</Title>}
					</>
				)}

				{children(this.handleBeforeNavigate, visible)}
			</DropDownItems>
		)
	}

	render() {
		const { button, disabled, viewport } = this.props
		const { visible } = this.state

		return (
			<StyledDropDown>
				{React.cloneElement(button, {
					focused: visible ? 1 : undefined,
					active: visible ? 1 : undefined,
					ref: this.buttonRef,
					onClick: this.handleButtonClick,
					...(button.props.onBeforeNavigate ? { onBeforeNavigate: button.props.onBeforeNavigate } : {}),
					disabled
				})}
				{viewport === VIEWPORT_DESKTOP && this.renderItems()}
				{viewport === VIEWPORT_MOBILE && ReactDOM.createPortal((
					<>
						<Overlay
							visible={visible}
							onTouchStart={event => this.handleTouchStart(event, this.overlayRef)}
							ref={this.overlayRef}
						/>
						{this.renderItems()}
					</>
				), document.getElementById('root-modal'))}
			</StyledDropDown>
		)
	}
}

export const StyledDropDown = styled.div`
	position: relative;
`

const Title = styled.div`
	margin-bottom: 1rem;
	text-align: center;
`

const DropDownItems = styled.div`
	background: ${props => props.theme.inputBackground};
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadowDropDown};
	padding: 0.5rem 0;

	transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1), z-index 0.1s 0.2s;

	position: absolute;
	pointer-events: none;
	z-index: -1;

	${props => props.top && `
		top: ${props.top}px;
	`}

	${props => props.bottom && `
		bottom: ${props.bottom}px;
	`}

	${props => props.right !== null && `
		right: 0;
	`}

	${props => props.visible ? `
		pointer-events: auto;
		transition: opacity 0.1s, transform 0.2s;
		z-index: ${props.theme.zLayer12};
	` : ''}

	${desktop`
		opacity: 0;
		min-width: ${props => props.width}px;

		${props => props.right !== null ? `
			transform: scale(0.85) translate(-${(props.right + (props.right) * 0.15)}px);
		` : ''}

		${props => props.left !== null ? `
			transform: scale(0.85) translate(${props.left}px);
		` : ''}

		${props => props.right === null && props.left === null ? `
			transform: scale(0.85);
		` : ''}

		${props => props.visible ? `
			opacity: 1;

			${props.right !== null ? `
				transform: scale(1) translate(-${props.right}px);
			` : ''}

			${props.left !== null ? `
				transform: scale(1) translate(${props.left}px);
			` : ''}

			${props.right === null && props.left === null ? `
				transform: scale(1);
			` : ''}
		` : ''}
	`}

	${props => !props.noStyles && `
		> a, > button {
			color: ${props.theme.text};
			align-items: center;
			display: flex;
			font-size: 1rem;
			padding: 0.5rem 0.75rem;
			text-decoration: none;
			white-space: nowrap;
			user-select: none;

			> ${StyledIcon}, img {
				color: ${props.theme.id === 'dark' ? props.theme.textVeryLight : props.theme.textLight};
				height: 22px;
				width: 22px;
				min-width: 22px;
				margin-right: 1rem;
			}

			${StyledMark} {
				max-width: 50vw;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&:hover {
				background: ${props.theme.selectOptionHover};
				color: ${props.theme.text};
			}
		}

		${props.theme.id === 'dark' ? `
			${Separator} {
				background: #393939;
			}
		` : ''}
	`}

	${mobile`
		border-radius: 24px 24px 0 0;
		margin-bottom: -2rem;
		padding: 0 0 4rem 0;

		position: fixed;
		transform: translate(0, 100%);
		top: ${props => props.mobileFullHeight ? '50px !important' : 'auto'};
		bottom: 0;
		width: 100%;

		overflow: auto;
		max-height: 100vh;

		${props => props.visible ? `
			transform: translate(0, 0);
		` : ''}

		${props => !props.noStyles && `
			> a, > button {
				font-size: 1.1rem;
				padding: 0.75rem 1.5rem;

				&:first-child {
					border-radius: 24px 24px 0 0;
				}

				& + & {
					border-top: 1px solid ${props.theme.backgroundLight};
				}

				> ${StyledIcon} {
					height: 20px;
					width: 20px;
					margin-right: 1.25rem;
				}
			}
		`}
	`}

	&.notransition {
		transition: none !important;
	}
`

const Overlay = styled.div`
	background: ${props => props.theme.backgroundOverlay};
	transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
	opacity: 0;
	z-index: -1;

	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	${props => props.visible && `
		opacity: 1;
		transition: opacity 0.1s, transform 0.2s;
		z-index: ${props.theme.zLayer11};
	`}
`

const HandleWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	padding: 0.5rem;

	position: sticky;
	top: 0;

	${props => props.background && `
		background: ${props.background};
	`}
`

const Handle = styled.div`
	background: ${props => props.theme.backgroundSolid};
	border-radius: 3px;
	height: 6px;
	width: 40px;
`

export const DropDownLinkRight = styled.div`
	padding-left: 0.5rem;
	padding-right: 0.5rem;

	${StyledOnPageSpinner} {
        height: 30px;
        margin: auto -0.25rem auto 0;
		padding-top: 0;
	}

    ${desktop`
		margin-right: -0.5rem;
	`}
`

export const DropDownLink = styled(Link)`
    align-items: center;
    display: flex;
    margin-bottom: 1px;
    transition: 0.1s;
    text-decoration: none;

    font-size: 1rem;
    font-weight: 400;

    img {
        border-radius: 2px;
        height: 20px;
    }

	> ${DropDownLinkRight}:first-of-type {
		margin-left: auto;
	}

	${Tag} {
		margin-left: 0.35rem;
	}

    &:hover {
        color: ${props => props.theme.text};
    }

    ${props => props.highlight && `
		background: ${props.theme.yellowLight};
	`}

    ${props => !props.active && `
		&:hover {
			background: ${props.theme.backgroundLight};
		}
	`}

    ${props => props.active && `
		background: ${props.theme.id === 'dark' ? props.theme.backgroundVeryLight : props.theme.backgroundLight} !important;
		color: ${props.theme.text};
		font-weight: 500;
	`}

    ${props => props.red && `
		color: ${props.theme.id === 'dark' ? '#e36a6d' : props.theme.red} !important;
	`}

    ${props => props.clearOption && `
    	color: ${props.theme.textLight} !important;
		font-style: italic;
	`}

	${props => props.avatar && `
		svg, img {
			border-radius: 50%;
			height: 34px !important;
			width: 34px !important;

			margin-left: -6px !important;
			margin-right: calc(1.25rem - 6px) !important;
		}
	`}

    ${props => props.disabled && `
    	svg {
    		color: rgba(255, 255, 255, 0.5) !important;
    	}
	`}

    ${desktop`
		height: 36px;
		min-height: 36px;
		padding: 0 0.75rem;

		> svg {
			color: ${props => props.theme.textLight};
			height: 24px;
			width: 24px;
		}
	`}
`

export const DropDownLinkCheck = styled.div`
	background: ${props => props.theme.primary};
	border-radius: 50%;
	height: 18px;
	width: 18px;

	align-items: center;
	display: flex;
	justify-content: center;
	transition: 0.1s;

	svg {
		color: ${props => props.theme.white};
		height: 12px;
		width: 12px;
	}

	${props => !props.active && `
		background: ${props.theme.secondary};
		opacity: 0;
	`}

	${mobile`
		height: 16px;
		width: 16px;

		svg {
			height: 12px;
			width: 12px;
		}
	`}
`

export const DropDownLinkNeedsPro = styled.div`
	font-size: 0.7rem;
	margin-left: auto;
	padding-left: 0.5rem;

	${Tag} {
		margin-right: 0.35rem;
	}
`

export const HeaderLinkDropDownHeading = styled.div`
    color: ${props => props.theme.textSlightlyLight};
    font-size: 12px;
    font-weight: 500;
    padding: 0.5rem 0.75rem 3px 0.75rem;
    text-transform: uppercase;

    position: sticky;
    top: 1rem;

    ${mobile`
		padding-left: 1.5rem;
	`}

	${DropDownLink} + & {
		margin-top: 1rem;
	}
`

const enhance = connect(state => ({
	viewport: state.app.viewport
}), dispatch => bindActionCreators({
	setDropDownVisible
}, dispatch))

export default enhance(DropDown)
