import React from 'react'
import PropTypes from 'prop-types'
import Rarity from '../Rarity'
import rarities from '../../../data/rarities'

const RarityAbbr = ({ id, tiny }) => {
	const rarity = id && rarities[id]

	return (
		<Rarity tiny={tiny} id={id}>{rarity ? rarity.abbr : '?'}</Rarity>
	)
}

RarityAbbr.propTypes = {
	id: PropTypes.any,
	tiny: PropTypes.bool
}

RarityAbbr.defaultProps = {
	id: null,
	tiny: false
}

export default RarityAbbr
