import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Link from '../../components/router/Link'
import Icon from '../../components/general/Icon'
import translate from '../../helper/translate'
import { events, trackEvent } from '../../helper/track'
import { copyToClipboard } from '../../helper/string'
import { showToast } from '../../state/actions/app'
import Mark from '../../components/general/Mark'

class ShareLinks extends Component {
	static propTypes = {
		disabled: PropTypes.bool,
		onBeforeNavigate: PropTypes.func,
		showToast: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		texts: PropTypes.array
	}

	static defaultProps = {
		disabled: false,
		onBeforeNavigate: () => {},
		texts: null
	}

	copy = (what, link = false) => {
		const { showToast, t } = this.props

		copyToClipboard(what)
		showToast('success', link ? t('general.linkCopied') : t('general.textCopied'))
	}

	render() {
		const { disabled, onBeforeNavigate, t, texts } = this.props

		const href = window.location.href.replace('deck-builder', 'deck')

		return (
			<>
				<Link onClick={() => this.copy(href, true)} disabled={disabled} onBeforeNavigate={onBeforeNavigate}>
					<Icon name="link" />
					{t('general.copyLink')}
				</Link>
				{texts && texts.length > 0 && texts.map(text => (
					<Link onClick={() => this.copy(text)} disabled={disabled} onBeforeNavigate={onBeforeNavigate} key={text}>
						<Icon name="document-text" />
						{t('general.copyText', { text: <Mark>{text}</Mark> })}
					</Link>
				))}
				{window.navigator.share && (
					<Link
						onClick={() => {
							try {
								window.navigator.share({
									title: document.title,
									text: '',
									url: href
								})
								trackEvent(events.SHARE, href)
							} catch (e) {

							}
						}}
						disabled={disabled}
						onBeforeNavigate={onBeforeNavigate}
					>
						<Icon name="share" />
						{t('general.share')}
					</Link>
				)}
			</>
		)
	}
}

const enhance = compose(
	connect(
		null,
		dispatch => bindActionCreators({
			showToast
		}, dispatch)
	),
	translate('general')
)

export default enhance(ShareLinks)
