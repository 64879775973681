import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Message, { StyledMessage } from '../form/Message'
import Link from '../router/Link'
import translate from '../../helper/translate'
import { desktop, mobile } from '../../styles/media'
import { updateUserSetting } from '../../state/actionCreators/app'
import { getAppMenuHeight } from '../../helper/device'
import ThickHeading from '../text/ThickHeading'
import Icon from './Icon'
import SkipButton, { StyledSkipButton } from '../button/SkipButton'

const SurveyBanner = ({ authToken, settings, userLanguage }) => {
	const [visible, setVisible] = useState(!localStorage.getItem('survey202408Closed'))

	const cardNameLanguage = settings['app.cardNameLanguage']

	const close = () => {
		setVisible(false)
		localStorage.setItem('survey202408Closed', new Date())
	}

	return visible && (userLanguage === 'de' || cardNameLanguage === 'de') ? (
		<StyledSurveyBanner authToken={authToken}>
			<Message>
				<MessageContent>
					<ThickHeading><Icon name="information-circle" />Umfrage zum Collection Manager</ThickHeading>
					<p>Nimm an dieser kurzen Umfrage teil und hilf uns cardcluster zu verbessern.</p>
				</MessageContent>
				<MessageButtons>
					<OKButton
						external
						externalNoIcon
						to="https://docs.google.com/forms/d/e/1FAIpQLSeAj7hYAEyMZ1B01GmCI3Ilm4n0eClE6zK2FPIpu9En9tFo8Q/viewform?usp=sf_link"
						onBeforeNavigate={close}
					>
						Zur Umfrage
					</OKButton>
					<SkipButton onClick={close}>
						Nein, danke
					</SkipButton>
				</MessageButtons>
			</Message>
		</StyledSurveyBanner>
	) : null
}

SurveyBanner.propTypes = {
	authToken: PropTypes.string,
	surveysAccepted: PropTypes.any,
	updateUserSetting: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

SurveyBanner.defaultProps = {
	authToken: null,
	surveysAccepted: null
}

const StyledSurveyBanner = styled.div`
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	z-index: ${props => props.theme.zLayer3};

	${StyledMessage} {
		background: rgba(40, 40, 40);
		color: #FFF;
		font-weight: 600;
		padding: 1rem 1.5rem;
		margin-bottom: 0;
		max-width: 600px;

		${ThickHeading} {
			color: #FFF;
			font-size: 1.1rem;
			margin-bottom: 0.5rem;

			svg {
				color: rgba(255, 255, 255, 0.8);
			}
		}

		p {
			color: rgba(255, 255, 255, 0.7);
			margin-bottom: 0;
		}

		> span {
			display: flex;
			flex-direction: column;
		}

		${mobile`
			border-radius 0;
			max-width: none;
		`}
	}

	${mobile`
		left: 0;
		right: 0;
		bottom: ${getAppMenuHeight()};
	`}
`

const MessageContent = styled.div`

`

const MessageButtons = styled.div`
	align-items: center;
	display: flex;
	gap: 0.5rem;
	margin-top: 1rem;

	${StyledSkipButton} {
		color: rgba(255, 255, 255, 0.7) !important;
		font-size: 0.9rem;
		font-weight: 400 !important;
		white-space: nowrap;
	}

	${mobile`
		margin-bottom: 1rem !important;
	`}
`

const OKButton = styled(Link)`
	background: #555;
	border: 0;
	border-radius: 4px;
	color: #FFF;
	cursor: pointer;
	font-size: 1rem;
	font-weight: bold;
	padding: 0.5rem 0.75rem;
	transition: background-color 0.2s;
	text-decoration: none !important;
	white-space: nowrap;

	${desktop`
		&:hover {
			background: #666;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		settings: state.persist.user.settings,
	}), dispatch => bindActionCreators({
		updateUserSetting
	}, dispatch)),
	translate('general')
)

export default enhance(SurveyBanner)
