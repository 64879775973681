import styled from 'styled-components'
import darken from 'polished/lib/color/darken'
import { desktop, mobile } from '../../../styles/media'

const SaveButton = styled.button`
	background: ${props => props.theme.primary};
	border: 0;
	border-radius: 8px;
	color: #FFF;
	cursor: pointer;
	font-weight: bold;
	padding: 0.5rem 1.5rem;
	position: relative;
	text-decoration: none;
	transition: background-color 0.2s;
	white-space: nowrap;

	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;

	small {
		display: block;
		font-weight: 500;
		margin-top: 0.15rem;
	}

	${props => props.disabled && `
		cursor: wait;
		pointer-events: none;
		opacity: 0.5;
	`}

	${props => props.saved && `
		line-height: 1;
		padding: 0.35rem 1.5rem;
	`}

	${desktop`
		&:not([disabled]):hover {
			background: ${props => props.theme.primaryHover};
			color: #FFF;
		}
	`}

	${mobile`
		font-size: 0.8rem;
		padding: ${props => props.saving ? '0.35rem 0.5rem' : '0.35rem 0'};
    	margin-left: 0.5rem;
		width: ${props => props.saving ? 'auto' : 'calc(88px - 0.5rem)'};

		&:after {
			content: "";
			display: block;

			position: absolute;
			top: -9px;
			left: -9px;
			height: calc(100% + 17px);
			width: calc(100% + 17px);
		}
	`}
`

export default SaveButton
