import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import lighten from 'polished/lib/color/lighten'
import Icon from '../general/Icon'

const AllowedCopies = ({ allowed, className, modest, small }) => (
	<StyledAllowedCopies className={className} allowed={allowed} small={small} modest={modest}>
		{allowed === 0 && <Icon name="ban" size={16} />}
		{allowed === null && <Icon name="x" size={14} />}
		{allowed !== null && allowed !== 0 && allowed}
	</StyledAllowedCopies>
)

AllowedCopies.propTypes = {
	allowed: PropTypes.number,
	className: PropTypes.string,
	modest: PropTypes.bool,
	small: PropTypes.bool
}

AllowedCopies.defaultProps = {
	allowed: null,
	className: null,
	modest: false,
	small: false
}

export const getAllowedColor = (props, light = false) => {
	if (props.allowed === 0) {
		return props.theme[`red${light ? 'Light' : ''}`]
	}

	if (props.allowed === 1) {
		return props.theme[`red${light ? 'Light' : ''}`]
	}

	if (props.allowed === 2) {
		return props.theme[`orange${light ? 'Light' : ''}`]
	}

	if (props.allowed === 3) {
		return props.theme[`green${light ? 'Light' : ''}`]
	}

	return light ? props.theme.backgroundLight : props.theme.textSecondary
}

export const StyledAllowedCopies = styled.div`
	background: ${props => !props.modest ? getAllowedColor(props) : (props.theme.id === 'dark' ? 'rgba(0, 0, 0, 0.4)' : lighten(0.45, getAllowedColor(props)))};
	border-radius: 50%;
	box-shadow: ${props => !props.modest ? `0 0 3px ${props.theme.shadowThick}` : 'none'};
	color: ${props => !props.modest ? props.theme.white : (props.theme.id === 'dark' ? props.theme.white : getAllowedColor(props))};
	font-size: 0.9rem;
	font-weight: bold;

	height: 22px;
	width: 22px;
	min-width: 22px;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.small && `
		box-shadow: none;
		font-size: 0.7rem;

		height: 16px;
		width: 16px;
		min-width: 16px;

		svg {
			height: 14px;
			width: 14px;
		}
	`}
`

export default AllowedCopies
