import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Name from './Name'
import Flag, { StyledFlag } from '../../general/Flag'

const PlayerName = ({ country, name, needsApproval, username }) => (
	<StyledPlayerName>
		{name && country ? (
			<>
				<Flag country={country} />
				{name}
			</>
		) : username || name}
		{needsApproval && <NeedsApproval>&nbsp;(nicht freigeschaltet)</NeedsApproval>}
	</StyledPlayerName>
)

PlayerName.propTypes = {
	country: PropTypes.string,
	name: PropTypes.string,
	needsApproval: PropTypes.bool,
	username: PropTypes.string
}

PlayerName.defaultProps = {
	country: null,
	name: null,
	needsApproval: false,
	username: null
}

const StyledPlayerName = styled(Name)`
	${StyledFlag} {
		margin-right: 0.35rem;
	}
`

const NeedsApproval = styled.span`
	color: ${props => props.theme.textLight};
`

export default PlayerName
