import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { desktop } from '../../styles/media'
import translate from '../../helper/translate'
import Link from '../../components/router/Link'
import Icon from '../../components/general/Icon'
import references from '../../data/references'
import { setReference } from '../../state/actions/app'

const CollectionBox = ({ lastPrintAdded, location, settings, setReference, subscribed, t, transparent }) => {
	const defaultHavesCollection = settings['collection.defaultHavesCollection']

	const [haves, setHaves] = useState(9421)
	const [nextHaves, setNextHaves] = useState(9421)
	const moveUp = haves !== nextHaves

	const [image, setImage] = useState(null)
	const [nextImage, setNextImage] = useState(null)

	const wrapperRef = useRef()
	const imageRef = useRef()
	const nextImageRef = useRef()

	const enableAnimate = () => {
		wrapperRef.current.classList.remove('no-animate')
	}

	const diableAnimate = () => {
		wrapperRef.current.classList.add('no-animate')
	}

	let havesResetTimeout = useRef()
	let imageResetTimeout = useRef()
	let setNextImageTimeout = useRef()

	useEffect(() => {
		if (moveUp) {
			havesResetTimeout.current = window.setTimeout(() => {
				diableAnimate()
				setHaves(nextHaves)
				window.setTimeout(enableAnimate, 150)
			}, 200)
		}
	}, [nextHaves])

	const reset = () => {
		diableAnimate()
		imageRef.current.classList.remove('animate')
		setImage(nextImage)
		window.setTimeout(enableAnimate, 150)
	}

	const animate = () => {
		clearTimeout(imageResetTimeout.current)
		clearTimeout(setNextImageTimeout.current)
		imageRef.current.classList.add('animate')
	}

	useEffect(() => {
		if (lastPrintAdded) {
			if (nextImage && nextImage !== image) {
				reset()

				setNextImageTimeout.current = window.setTimeout(() => {
					setNextImage(lastPrintAdded.image)
				}, 200)
			} else {
				setNextImage(lastPrintAdded.image)
			}
		}
	}, [lastPrintAdded])

	useEffect(() => {
		if (image !== nextImage) {
			animate()

			imageResetTimeout.current = window.setTimeout(() => {
				reset()
			}, 1600)
		}
	}, [nextImage])

	return (
		<Wrapper ref={wrapperRef}>
			<AddCardsLink primary to={subscribed ? '/add' : '/product/collection-manager'} onBeforeNavigate={() => setReference(references.ADD_CARDS_BUTTON)}>
				<Icon name="document-add" />
				{t('general.add')}
			</AddCardsLink>

			{/*<CollectionBoxButton onClick={() => setNextHaves(haves + 1)}>
				<StyledCollectionBox
					transparent={transparent}
				/>

				<LastPrintAdded ref={imageRef}>
					<img src={nextImage ? `${nextImage}-140.jpg` : null} alt="" />
				</LastPrintAdded>

				<CollectionHaves>
					<Hidden>{nextHaves.toLocaleString()}</Hidden>
					<Number moveUp={moveUp}>{haves.toLocaleString()}</Number>
					<Number moveUp={moveUp}>{nextHaves.toLocaleString()}</Number>
					&nbsp;{t('general.card', { count: nextHaves })}
				</CollectionHaves>
			</CollectionBoxButton>*/}
		</Wrapper>
	)
}

CollectionBox.propTypes = {
	lastPrintAdded: PropTypes.object,
	location: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	setReference: PropTypes.func.isRequired,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	transparent: PropTypes.bool
}

CollectionBox.defaultProps = {
	lastPrintAdded: null,
	subscribed: null,
	transparent: false
}

const CollectionBoxButton = styled.button`
	background: transparent;
	border: 0;
	border-radius: 6px;
	margin-left: -0.5rem;
	margin-right: 0.5rem;
	padding: 0.5rem;
	position: relative;
	transition: 0.2s;

	align-items: center;
	display: flex;

	${desktop`
		&:hover {
			background: ${props => props.theme.background};
			cursor: pointer;
		}
	`}
`

const tiltShaking = keyframes`
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(5deg);
	}
	50% {
		transform: rotate(0deg);
	}
	75% {
		transform: rotate(-5deg);
	}
	100% {
		transform: rotate(0deg);
	}
`

const StyledCollectionBox = styled.div`
	border-radius: 0 0 4px 4px;
	border-color: ${props => props.transparent ? 'rgba(0, 0, 0, 0.2)' : props.theme.backgroundConcrete};
	border-style: solid;
	border-width: 0 3px 3px 3px;
	transition: 0.2s;

	height: 34px;
	width: 55px;

	&.shake {
		animation: ${tiltShaking} 0.25s 0.4s forwards;
		border-color: ${props => props.theme.primaryLight};
	}
`

const Number = styled.strong`
	color: ${props => props.theme.text};
	font-weight: 500;

	position: absolute;
	top: 0;
	left: 0;

	&:first-of-type {
		transition: 0.2s;

		${props => props.moveUp && `
			opacity: 0;
			transform: translate(0, -100%);
		`}
	}

	&:last-of-type {
		opacity: 0;
		transition: 0.2s;
		transform: translate(0, 100%);

		${props => props.moveUp && `
			opacity: 1;
			transform: translate(0, 0);
		`}
	}
`

const Hidden = styled.span`
	visibility: hidden;
`

const LastPrintAdded = styled.div`
	position: absolute;
	left: 21px;
	top: -8px;
	z-index: 5;

	img {
		border-radius: 1px;
		width: 30px;
	}

	opacity: 0;
	transition: 0.2s;
	transform: translate(0, -100%);

	&.animate {
		opacity: 1;
		transform: translate(0, 0);
	}
`

const CollectionHaves = styled.div`
	color: ${props => props.theme.textLight};
	margin-left: 1rem;
	position: relative;
	white-space: nowrap;
`

const AddCardsLink = styled(Link)`
	border: 1px solid transparent;
	border-radius: 12px;
	color: #FFF;
	cursor: pointer;
	padding: 0.5rem 1rem;
	text-decoration: none;
	transition: 0.1s;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		color: rgba(255, 255, 255, 0.7);

		margin-right: 0.35rem;
	}

	background: ${props => props.theme.primary};
	box-shadow: 0 2px rgb(0 0 0 / 20%);
	font-size: 0.9rem;
	font-weight: 600;
	text-shadow: 0 1px rgb(0 0 0 / 20%);

	svg {
		color: #FFF;
		height: 20px;
		width: 20px;
		min-width: 20px;
	}

	&:hover {
		background: ${props => props.theme.primaryHover};
		color: #FFF;
	}

	&:active {
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
		transform: translate(0, 2px);
	}
`

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	margin-right: 1.5rem;

	&.no-animate {
		${Number}, ${LastPrintAdded} {
			transition: none;
		}
	}
`

const enhance = compose(
	connect(state => ({
		lastPrintAdded: state.collection.lastPrintAdded,
		settings: state.persist.user.settings,
		subscribed: state.persist.user.subscribed
	}), dispatch => bindActionCreators({
		setReference
	}, dispatch)),
	translate('general'),
	withRouter
)

export default enhance(CollectionBox)
