import YgoLogo from '../images/games/ygo.svg'
import PkmnLogo from '../images/games/pkmn.svg'

export const YGO = 1
export const PKMN = 2

const games = {
	[YGO]: {
		name: 'Yu-Gi-Oh!',
		slug: 'ygo',
		image: YgoLogo
	},
	[PKMN]: {
		name: 'Pokémon',
		slug: 'pkmn',
		image: PkmnLogo,
		deckMin: 60,
		deckMax: 60,
		maxCopies: 4
	}
}

export default games
