import { MASTER_DUEL, TCG } from './formats'

const rarityGroups = [
	{
		name_en: 'Standard Rarities',
		name_de: 'Standard-Raritäten',
		members: [7, 5, 4, 3, 1],
		format: TCG
	},
	{
		name_en: 'Special Rarities',
		name_de: 'Besondere Raritäten',
		members: [6, 73, 30, 72, 70],
		format: TCG
	},
	{
		name_en: 'Extremely Rare Rarities',
		name_de: 'Extrem seltene Raritäten',
		members: [8, 63, 28, 61, 71],
		format: TCG
	},
	{
		name_en: 'Foiling Variants',
		name_de: 'Foiling-Varianten',
		members: [11, 60],
		format: TCG
	},
	{
		name_en: 'Gold Rares',
		name_de: 'Gold Rares',
		members: [9, 18, 10, 29],
		format: TCG
	},
	{
		name_en: 'Platinum Rares',
		name_de: 'Platinum Rares',
		members: [12, 14],
		format: TCG
	},
	{
		name_en: 'Lettering Variants',
		name_de: 'Schriftzug-Varianten',
		members: [62, 75, 76, 31, 32, 33, 69, 74, 59, 55, 52, 54, 56, 58, 57, 35],
		format: TCG
	},
	{
		name_en: 'Duel Terminal',
		name_de: 'Duel Terminal',
		members: [27, 26, 25, 24, 23],
		format: TCG
	},
	{
		name_en: 'Parallel Foil Rares',
		name_de: 'Parallel Foil Rares',
		members: [22, 21, 20],
		format: TCG
	},
	{
		name_en: 'Other Foil Rares',
		name_de: 'Sonstige Raritäten',
		members: [16, 17, 15],
		format: TCG
	},
	{
		name_en: 'Extremely Special Rarities',
		name_de: 'Außergewöhnliche Raritäten',
		members: [19, 34],
		format: TCG
	},
	{
		name_en: 'Standard Rarities',
		name_de: 'Standard-Raritäten',
		members: [65, 66, 67, 68],
		format: MASTER_DUEL
	}
]

export default rarityGroups
