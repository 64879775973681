import styled from 'styled-components'
import Link from '../router/Link'
import { desktop, mobile } from '../../styles/media'
import Name from './name/Name'

export const Regions = styled.div`
	align-items: center;
	display: flex;
	margin: 0.5rem 0;
	overflow: auto;

	${props => props.small && `
		${RegionLink} {
			border-radius: 14px;
			font-size: 0.8rem;
			padding: 0.35rem 0.5rem;
		}
	`}
`

export const RegionLink = styled(Link)`
	border-radius: 16px;
	color: ${props => props.theme.textLight};
	font-size: 0.9rem;
	padding: 0.5rem 0.75rem;
	text-decoration: none;
	white-space: nowrap;

	align-items: center;
	display: flex;

	span {
		margin-left: 0.35rem;
		vertical-align: middle;

		${mobile`
			${props => !props.dontCollapse && !props.active && `
				display: none;
			`}
		`}
	}

	svg {
		height: 16px;
		width: 16px;

		${mobile`
			${props => !props.dontCollapse && !props.active && `
				margin-right: 0;
			`}
		`}
	}

	& + a {
		margin-left: 0.5rem;
	}

	${Name} {
		white-space: nowrap;
	}

	${Name} + ${Name} {
		margin-left: 0.5rem;
	}

	${props => props.active && `
		background: ${props.theme.backgroundLight};
		color: ${props.theme.text};
		font-weight: 500;
	`}

	${desktop`
		${props => props.active ? `
			&:hover {
				background: ${props.theme.backgroundLight};
				color: ${props.theme.text};
			}
		` : `
			&:hover {
				background: ${props.theme.backgroundLight};
				color: ${props.theme.text};
			}
		`}
	`}
`
