import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Input, { InputInlineButton, StyledInputContainer } from '../form/Input'
import Select from '../form/Select'
import translate from '../../helper/translate'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import InlineField from '../form/InlineField'
import Icon from '../general/Icon'
import IconSwitch from '../form/IconSwitch'
import FollowsInputButton from '../button/FollowsInputButton'
import { StyledField } from '../form/Field'
import IconButton from '../button/IconButton'
import DropDownSelect from '../button/DropDownSelect'
import Checkbox from '../form/Checkbox'
import { updateUserSetting } from '../../state/actionCreators/app'
import { languagesGeneric } from '../../data/languages'
import { translationsMapping } from '../../../config'
import cardLanguages from '../../data/cardLanguages'
import Flag from '../general/Flag'
import Tooltip from '../general/Tooltip'
import NativeSelect from '../form/NativeSelect'

const SortAndFilter = ({ children, filter, filterNameStartsWith, filterNameStartsWithOnChange, filterNameLanguage, filterNameLanguageOnChange, filterCollapsable, filterOnKeyDown, filterOnChange, filterOnSubmit, filterPlaceholder, filterRef, filterSingleLine, groupBy, groupByOnChange, groupByOptions, openFilterCollapsesChildren, searchFieldLeft, settings, showFullscreenCheckbox, sortBy, sortByOnChange, sortByOptions, sortByWidth, sortByDirection, sortByDirectionOnChange, t, updateUserSetting, userLanguage, viewport }) => {
	const [filterFocused, setFilterFocused] = useState(filter !== '' && viewport === VIEWPORT_MOBILE)
	const filterActive = filterFocused || (filter !== '' && filter !== null)

	const collectionUseFullWidth = settings['collection.useFullWidth']
	const cardNameLanguage = settings['app.cardNameLanguage'] || translationsMapping[userLanguage]

	const handleKeyDown = (event) => {
		if (filterOnKeyDown !== null) {
			filterOnKeyDown(event)
		}

		if (event.key === 'Enter') {
			event.preventDefault()

			if (filterOnSubmit !== null) {
				filterOnSubmit()
			} else {
				filterRef.current.blur()
			}
		}
	}

	useEffect(() => {
		if ((filter === '' || filter === null) && viewport === VIEWPORT_MOBILE) {
			setFilterFocused(false)
		}
	}, [filter])

	const defaultFallbackEnglishLabel = `${cardLanguages[cardNameLanguage][`name_${translationsMapping[userLanguage]}`]}${cardNameLanguage !== 'en' ? `, ${cardLanguages.en[`name_${translationsMapping[userLanguage]}`]}` : ''}`

	const filterEl = (
		<Input
			light={filterCollapsable}
			clearable
			collapsable={filterCollapsable}
			triggerCommandF
			icon={filterCollapsable ? 'search' : null}
			value={filter || ''}
			onClear={() => filterOnChange(null)}
			onChange={filterOnChange}
			onFocus={() => setFilterFocused(true)}
			onBlur={() => {
				if (filter === '' || viewport === VIEWPORT_DESKTOP) {
					setFilterFocused(false)
				}
			}}
			onKeyDown={handleKeyDown}
			inputRef={filterRef}
			placeholder={filterPlaceholder}
			inlineButtons={filterNameLanguageOnChange !== null && (filterSingleLine || ((searchFieldLeft && !filterCollapsable) || filterActive)) ? (
				<>
					<Tooltip title={t('general.searchStartsWith')}>
						<InputInlineButton
							active={filterNameStartsWith}
							onClick={() => filterNameStartsWithOnChange(!filterNameStartsWith)}
						>
							Aa
						</InputInlineButton>
					</Tooltip>

					<DropDownSelect
						button={(
							<InputInlineButton>
								<Icon name="translate" />
							</InputInlineButton>
						)}
						label={t('general.searchCardName')}
						data={[
							{
								value: 'defaultFallbackEnglish',
								label: defaultFallbackEnglishLabel
							},
							...languagesGeneric.filter(item => cardNameLanguage !== 'en' || item !== 'en').map(item => ({
								value: item,
								label: cardLanguages[item][`name_${translationsMapping[userLanguage]}`]
							}))
						]}
						value={filterNameLanguage}
						onChange={filterNameLanguageOnChange}
					/>
				</>
			) : null}
		/>
	)

	const searchLeftField = (
		<InlineField>
			<Icon name="search" />
			{filterEl}
			{filterOnSubmit !== null && (
				<FollowsInputButton onClick={filterOnSubmit}>
					<Icon name="arrow-right" />
				</FollowsInputButton>
			)}
		</InlineField>
	)

	const filterRight = (
		<MobileSingleLine
			hasChildren={!!(children && (!Array.isArray(children) || children.find(item => item !== false && item !== null)))}
			filterActive={filterActive}
			filterCollapsable={filterCollapsable}
			filterFocused={filterFocused}
			filterSingleLine={filterSingleLine}
			openFilterCollapsesChildren={openFilterCollapsesChildren}
		>
			{filterOnChange !== null && filterCollapsable && filterEl}
			{viewport === VIEWPORT_DESKTOP && showFullscreenCheckbox && (
				<Checkbox
					small
					onChange={() => updateUserSetting('collection.useFullWidth', !collectionUseFullWidth, true)}
					value={collectionUseFullWidth}
					label={t('general.fullscreen')}
				/>
			)}
			{groupByOnChange !== null && groupByOptions.length !== 0 && (
				<DropDownSelect
					data={groupByOptions}
					value={groupBy}
					onChange={groupByOnChange}
					button={<IconButton hasValue={groupBy !== null}><Icon name="template" /></IconButton>}
					label={t('general.groupBy')}
					clearable
					clearableLabel={t('general.dontGroup')}
				/>
			)}
			{(sortByOnChange !== null || sortByDirectionOnChange !== null) && (
				<>
					{sortByOnChange !== null && (
						<>
							{viewport === VIEWPORT_DESKTOP ? (
								<InlineField>
									<Icon name="sort-descending" />
									<NativeSelect
										data={sortByOptions}
										value={sortBy}
										onChange={sortByOnChange}
									/>
								</InlineField>
							) : (
								<DropDownSelect
									data={sortByOptions}
									value={sortBy}
									onChange={sortByOnChange}
									button={<IconButton><Icon name="sort-descending" /></IconButton>}
									label={t('general.sortBy')}
									dataSecondary={[
										{
											value: 'ASC',
											label: t('general.sortByDirectionAsc')
										},
										{
											value: 'DESC',
											label: t('general.sortByDirectionDesc')
										}
									]}
									secondaryOnChange={() => sortByDirectionOnChange(sortByDirection === 'DESC' ? 'ASC' : 'DESC')}
									labelSecondary={t('general.sortByDirection')}
									valueSecondary={sortByDirection}
								/>
							)}
						</>
					)}
					{viewport === VIEWPORT_DESKTOP && sortByDirectionOnChange !== null && (
						<IconSwitch
							value={sortByDirection}
							onChange={sortByDirectionOnChange}
						>
							<Icon name="sort-ascending" value="ASC" title={t('general.sortByDirectionAsc')} />
							<Icon name="sort-descending" value="DESC" title={t('general.sortByDirectionDesc')} />
						</IconSwitch>
					)}
				</>
			)}
		</MobileSingleLine>
	)

	return (
		<>
			{filterSingleLine && (
				<FilterSingleLine>
					{searchLeftField}
				</FilterSingleLine>
			)}
			<StyledSortAndFilter hasFilter={filterOnChange !== null} filterFocused={filterFocused}>
				{viewport === VIEWPORT_DESKTOP ? (
					<>
						<SortAndFilterLeft
							filterFocused={filterFocused}
							searchFieldLeft={searchFieldLeft}
						>
							{children}
							{searchFieldLeft && !filterCollapsable && searchLeftField}
						</SortAndFilterLeft>
						<SortAndFilterRight
							filterActive={filterActive}
							sortByWidth={sortByWidth}
							searchFieldLeft={searchFieldLeft}
							filterSingleLine={filterSingleLine}
						>
							{filterRight}
						</SortAndFilterRight>
					</>
				) : (
					<>
						{searchFieldLeft && !filterSingleLine && (
							<InlineField>
								<Icon name="search" />
								{filterEl}
								{filterOnSubmit !== null && (
									<FollowsInputButton onClick={filterOnSubmit}>
										<Icon name="arrow-right" />
									</FollowsInputButton>
								)}
							</InlineField>
						)}

						{viewport === VIEWPORT_MOBILE && (!filterFocused || !openFilterCollapsesChildren) && children}
						{filterRight}
					</>
				)}
			</StyledSortAndFilter>
		</>
	)
}

SortAndFilter.propTypes = {
	children: PropTypes.any,
	filter: PropTypes.string,
	filterNameStartsWith: PropTypes.bool,
	filterNameStartsWithOnChange: PropTypes.func,
	filterNameLanguage: PropTypes.string,
	filterNameLanguageOnChange: PropTypes.func,
	filterCollapsable: PropTypes.bool,
	filterRef: PropTypes.any,
	filterOnKeyDown: PropTypes.func,
	filterOnChange: PropTypes.func,
	filterOnSubmit: PropTypes.func,
	filterPlaceholder: PropTypes.string,
	filterSingleLine: PropTypes.bool,
	groupBy: PropTypes.string,
	groupByOnChange: PropTypes.func,
	groupByOptions: PropTypes.array,
	openFilterCollapsesChildren: PropTypes.bool,
	searchFieldLeft: PropTypes.bool,
	settings: PropTypes.object.isRequired,
	showFullscreenCheckbox: PropTypes.bool,
	sortBy: PropTypes.string,
	sortByWidth: PropTypes.number,
	sortByOnChange: PropTypes.func,
	sortByOptions: PropTypes.array,
	sortByDirection: PropTypes.string,
	sortByDirectionOnChange: PropTypes.func,
	t: PropTypes.func.isRequired,
	updateUserSetting: PropTypes.func.isRequired,
	userLanguage: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

SortAndFilter.defaultProps = {
	children: null,
	filter: null,
	filterNameStartsWith: null,
	filterNameStartsWithOnChange: null,
	filterNameLanguage: null,
	filterNameLanguageOnChange: null,
	filterCollapsable: true,
	filterRef: null,
	filterOnKeyDown: null,
	filterOnChange: null,
	filterOnSubmit: null,
	filterPlaceholder: null,
	filterSingleLine: false,
	openFilterCollapsesChildren: false,
	groupBy: null,
	groupByOnChange: null,
	groupByOptions: null,
	searchFieldLeft: false,
	showFullscreenCheckbox: false,
	sortBy: null,
	sortByOnChange: null,
	sortByOptions: null,
	sortByWidth: 150,
	sortByDirection: null,
	sortByDirectionOnChange: null
}

export const StyledSortAndFilter = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 1rem;
	width: 100%;

	${desktop`
		${props => props.hasFilter && !props.filterFocused && `
			> ${StyledInputContainer} {
				width: 36px;
			}
		`}
	`}

	${mobile`
		gap: 1rem;
		min-height: 38px;
	`}
`

export const SortAndFilterLeft = styled.div`
	align-items: center;
	display: flex;
	gap: 1rem;

	${StyledField} {
		margin-bottom: 0;
	}

	${props => props.searchFieldLeft && `
		${InlineField} {
			max-width: 650px;
			position: relative;
		}

		${InlineField}, ${StyledInputContainer}, input:not([type="range"]) {
			width: 100%;
		}
	`}

	${desktop`
		${props => props.searchFieldLeft && `
			flex: 1;
		`}
	`}

	${mobile`
		transition: 0.1s;
	`}
`

export const SortAndFilterRight = styled.div`
	align-items: center;
	display: flex;

	padding-left: 1rem;

    ${props => (!props.filterActive || props.filterSingleLine) && `
		margin-left: auto;
	`}

	${props => props.filterActive && `
		input {
			min-width: 244px;
		}
	`}

	> * + * {
		margin-left: 1rem;
	}

	.react-select__control {
		width: ${props => props.sortByWidth}px;

		.react-select__single-value {
			font-size: 0.9rem;
		}
	}

	.react-select__control:not(.react-select__control--is-focused) {
		border-color: ${props => props.theme.id === 'dark' ? props.theme.inputBackground : props.theme.backgroundLight} !important;

		.react-select__single-value {
			color: ${props => props.theme.textLight};
		}
	}

	${desktop`
		${props => props.filterActive && !props.searchFieldLeft && !props.filterSingleLine && `
			width: 100%;
		`}

		.react-select__control:not(.react-select__control--is-focused):hover {
			border-color: ${props => props.theme.inputBorderHoverTop} ${props => props.theme.inputBorderHoverSides} ${props => props.theme.inputBorderHoverBottom} !important;

			.react-select__single-value {
				color: ${props => props.theme.text};
			}

			.react-select__dropdown-indicator {
				color: ${props => props.theme.textLight};
			}
		}
	`}

	${mobile`
		${props => props.filterActive && `
			flex: 1;
		`}
	`}
`

const MobileSingleLine = styled.div`
	align-items: center;
	display: flex;
	gap: 1rem;

	select {
		width: 200px;
	}

	${props => props.filterActive && `
		> ${StyledInputContainer}:first-of-type, > ${StyledInputContainer}:first-of-type input {
			width: 100%;
		}
	`}

	${props => props.filterActive && !props.filterSingleLine && props.openFilterCollapsesChildren && `
		> *:not(:first-child) {
			display: none;
		}
	`}

	${desktop`
		width: 100%;
	`}

	${mobile`
		${props => props.hasChildren && !(props.filterFocused && props.openFilterCollapsesChildren) && `
			padding-left: 1rem;
		`}

		${props => props.filterCollapsable && props.filterActive ? `
			flex: 1;
		` : `
			margin-left: auto;
		`}

		> ${IconButton} svg {
			height: 16px;
			width: 16px;
		}
	`}
`

export const FilterSingleLine = styled.div`
	margin-bottom: 1rem;

	${StyledInputContainer}, input:not([type="range"]) {
		width: 100%;
		max-width: none;
	}

	input {
		padding-right: 110px !important;
	}
`

const SearchFilterFlags = styled.div`
	display: flex;
	gap: 0.35rem;

	img {
		border-radius: 2px;
		height: 12px;
		width: 12px;
	}
`

const enhance = compose(
	connect(state => ({
		settings: state.persist.user.settings,
		userLanguage: state.persist.language,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		updateUserSetting
	}, dispatch)),
	translate('general')
)

export default enhance(SortAndFilter)
