/**
 * Collapse/expand main menu
 */
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const toggleMenu = () => ({
	type: TOGGLE_MENU
})

/**
 * Collapse/expand card search
 */
export const TOGGLE_CARD_SEARCH = 'TOGGLE_CARD_SEARCH'
export const toggleCardSearch = () => ({
	type: TOGGLE_CARD_SEARCH
})

/**
 * Sets the site language
 */

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const setLanguage = ([language, languageMismatchHint]) => ({
	type: SET_LANGUAGE,
	language,
	languageMismatchHint
})

export const SET_CARD_NAME_LANGUAGE = 'SET_CARD_NAME_LANGUAGE'
export const setCardNameLanguage = cardNameLanguage => ({
	type: SET_CARD_NAME_LANGUAGE,
	cardNameLanguage
})

/**
 * Sets the user object and auth token
 */
export const SET_USER = 'SET_USER'
export const setUser = (authToken, user) => ({
	type: SET_USER,
	authToken,
	user
})

/**
 * Resets the user object and clears the auth token
 */
export const RESET_USER = 'RESET_USER'
export const resetUser = () => ({
	type: RESET_USER
})

/**
 * Updates a user setting
 */
export const SET_USER_SETTING = 'SET_USER_SETTING'
export const setUserSetting = (name, value) => ({
	type: SET_USER_SETTING,
	name,
	value
})

/**
 * Updates multiple user settings
 */
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const setUserSettings = settings => ({
	type: SET_USER_SETTINGS,
	settings
})

export const SET_CARD_SEARCH = 'SET_CARD_SEARCH'
export const setCardSearch = (q, filter, load = true, collection, sortBy, sortOrder, view) => ({
	type: SET_CARD_SEARCH,
	q,
	filter,
	load,
	collection,
	sortBy,
	sortOrder,
	view
})

export const DELETE_SAVED_SEARCH_QUERY = 'DELETE_SAVED_SEARCH_QUERY'
export const deleteSavedSearchQuery = id => ({
	type: DELETE_SAVED_SEARCH_QUERY,
	id
})

export const SET_COUPON = 'SET_COUPON'
export const setCoupon = coupon => ({
	type: SET_COUPON,
	coupon
})

export const SET_UNREAD = 'SET_UNREAD'
export const setUnread = data => ({
	type: SET_UNREAD,
	data
})

export const SET_UNREAD_COMMENTS = 'SET_UNREAD_COMMENTS'
export const setUnreadComments = unreadComments => ({
	type: SET_UNREAD_COMMENTS,
	unreadComments
})

export const SET_REJECTED_COMMENTS = 'SET_REJECTED_COMMENTS'
export const setRejectedComments = rejectedComments => ({
	type: SET_REJECTED_COMMENTS,
	rejectedComments
})

export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const setCollections = collections => ({
	type: SET_COLLECTIONS,
	collections
})

export const APPLY_COLLECTION_UPDATES = 'APPLY_COLLECTION_UPDATES'
export const applyCollectionUpdates = updatedCollections => ({
	type: APPLY_COLLECTION_UPDATES,
	updatedCollections
})

export const SET_COLLECTIONS_LOADING = 'SET_COLLECTIONS_LOADING'
export const setCollectionsLoading = collectionsLoading => ({
	type: SET_COLLECTIONS_LOADING,
	collectionsLoading
})

export const SET_ANNOUNCEMENTS_READ = 'SET_ANNOUNCEMENTS_READ'
export const setAnnouncementsRead = (announcementsRead) => ({
	type: SET_ANNOUNCEMENTS_READ,
	announcementsRead
})
