import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import isBefore from 'date-fns/isBefore'
import Highlighter from 'react-highlight-words'
import cardLanguages from '../../data/cardLanguages'
import { translationsMapping } from '../../../config'

const PrintSetNumber = ({ fallbackToUserLanguage, highlight, language, print, userLanguage }) => {
	let setNumber = print.language && print.language.alternateSetNumber ? print.language.alternateSetNumber : print.set.setNumber || <QuestionMarks>????</QuestionMarks>
	const isReleased = print.set.releaseEu ? isBefore(new Date(print.set.releaseEu), new Date()) : false

	let languageStr
	let setLanguage = print.language

	const setLanguagesMatch = print.set && print.set.languages && print.set.languages.find(item => item.language === language)

	if (!setLanguage && setLanguagesMatch) {
		setLanguage = setLanguagesMatch
	}

	if (setLanguage) {
		languageStr = setLanguage.alternateLanguageCode !== null ? setLanguage.alternateLanguageCode : cardLanguages[setLanguage.language].abbr

		if (setLanguage.alternateSetNumber) {
			setNumber = setLanguage.alternateSetNumber
		}
	} else {
		languageStr = language ? cardLanguages[language].abbr : (fallbackToUserLanguage ? translationsMapping[userLanguage].toUpperCase() : null)
	}

	return (
		<>
			{highlight && (
				<Highlighter
					highlightClassName="highlightedText"
					searchWords={highlight.split('-')}
					autoEscape
					textToHighlight={setNumber}
				/>
			)}
			{!highlight && setNumber}
			{print.cardNumber !== '' && print.cardNumber !== null && (
				<>
					-{languageStr}
					{highlight && (
						<Highlighter
							highlightClassName="highlightedText"
							searchWords={highlight.split('-')}
							autoEscape
							textToHighlight={print.cardNumber}
						/>
					)}
					{!highlight && print.cardNumber}
				</>
			)}
			{!isReleased && (print.cardNumber === '' || print.cardNumber === null) && <>-{languageStr}<QuestionMarks>???</QuestionMarks></>}
		</>
	)
}

PrintSetNumber.propTypes = {
	fallbackToUserLanguage: PropTypes.bool,
	highlight: PropTypes.string,
	language: PropTypes.string,
	print: PropTypes.object.isRequired,
	userLanguage: PropTypes.string.isRequired
}

PrintSetNumber.defaultProps = {
	fallbackToUserLanguage: true,
	highlight: null,
	language: null
}

const QuestionMarks = styled.span`
	color: ${props => props.theme.textVeryLight};
`

const enhance = connect(state => ({
	userLanguage: state.persist.language
}))

export default enhance(PrintSetNumber)
