import styled from 'styled-components'

const ThickHeading = styled.div`
	color: ${props => props.theme.text};
	font-weight: 700;
	text-align: left;

	align-items: center;
	display: flex;

	svg {
		color: ${props => props.theme.textLight};
		margin-right: 0.5rem;
	}
`

export default ThickHeading
