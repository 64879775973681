import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const Spinner = ({ dark, size }) => (
	<StyledSpinner
		dark={dark}
		size={size}
	>
		<div />
		<div />
		<div />
	</StyledSpinner>
)

Spinner.propTypes = {
	dark: PropTypes.bool,
	size: PropTypes.number
}

Spinner.defaultProps = {
	dark: false,
	size: 20
}

const animation = keyframes`
	0%, 80%, 100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
`

export const StyledSpinner = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	& > div {
		background-color: ${props => props.dark ? props.theme.textSecondary : props.theme.backgroundLight};
		height: ${props => props.size}px;
		width: ${props => props.size}px;

		animation: ${animation} 1.4s infinite ease-in-out both;
		border-radius: 50%;
		margin: 0 0.25rem;
	}

	div:first-child {
		animation-delay: -0.32s;
	}

	div:nth-child(2) {
		animation-delay: -0.16s;
	}

	.selectContainer .spinner {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -10px;
		margin-left: -10px;
	}
`

export default Spinner
