import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Icon from '../general/Icon'
import { mobile, VIEWPORT_MOBILE } from '../../styles/media'
import { getContrastColor } from '../../helper/color'

const Visibility = ({ color, large, noShadow, value, viewport }) => {
	if (value === 'public') return null

	return (
		<StyledVisibility
			large={large}
			noShadow={noShadow}
			color={color}
		>
			{value === 'private' && <Icon name="lock-closed" size={large ? 12 : 9} top={viewport === VIEWPORT_MOBILE && large ? -1 : 0} />}
			{value === 'notListed' && <Icon name="eye-off" size={large ? 12 : 9} />}
		</StyledVisibility>
	)
}

Visibility.propTypes = {
	color: PropTypes.string,
	large: PropTypes.bool,
	noShadow: PropTypes.bool,
	value: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

Visibility.defaultProps = {
	color: null,
	large: null,
	noShadow: false
}

const StyledVisibility = styled.div`
	position: absolute;
	bottom: -4px;
	right: -4px;
	z-index: ${props => props.theme.zLayer1};

	background: ${props => props.color || '#555555'};
	border-radius: 50%;
	box-shadow: ${props => props.noShadow ? `0 0 0 2px ${props.theme.background}` : `0 0 2px ${props.theme.shadowNormal}`};
	color: ${props => getContrastColor(props.color)};
	height: ${props => props.large ? '20px' : '15px'};
	width: ${props => props.large ? '20px' : '15px'};

	align-items: center;
	display: flex;
	justify-content: center;

	${mobile`
		${props => props.large && `
			height: 16px;
			width: 16px;
		`}

		svg {
			height: ${props => props.large ? '10px' : '8px'};
			width: ${props => props.large ? '10px' : '8px'};
		}
	`}
`

const enhance = connect(state => ({
	viewport: state.app.viewport
}))

export default enhance(Visibility)
