import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { default as AutosizeTextarea } from 'react-textarea-autosize'
import { StyledInputContainer } from './Input'
import Message from './Message'

class Textarea extends Component {
	static propTypes = {
		disabled: PropTypes.bool,
		errors: PropTypes.array,
		id: PropTypes.string,
		inputRef: PropTypes.any,
		maxRows: PropTypes.number,
		minRows: PropTypes.number,
		name: PropTypes.string,
		placeholder: PropTypes.string,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		value: PropTypes.string
	}

	static defaultProps = {
		disabled: false,
		errors: [],
		id: undefined,
		inputRef: undefined,
		maxRows: null,
		minRows: 6,
		name: '',
		onBlur: () => {},
		onFocus: () => {},
		placeholder: null,
		value: ''
	}

	handleChange = (e) => {
		this.props.onChange(e.target.value)
	}

	render() {
		const { disabled, errors, id, inputRef, maxRows, minRows, name, onBlur, onFocus, placeholder, value } = this.props

		const message = errors.reduce((accumulator, error) => `${accumulator}${error} `, '')

		return (
			<StyledInputContainer>
				<AutosizeTextarea
					disabled={disabled}
					id={id}
					inputRef={inputRef}
					minRows={minRows}
					maxRows={maxRows}
					name={name}
					onBlur={onBlur}
					onChange={this.handleChange}
					onFocus={onFocus}
					placeholder={placeholder}
					tabIndex="0"
					value={value}
				/>
				{message !== '' ? (
					<Message>
						{message}
					</Message>
				) : ''}
			</StyledInputContainer>
		)
	}
}

export default Textarea
