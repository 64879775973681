import isBefore from 'date-fns/isBefore'
import React from 'react'
import cardLanguages from '../data/cardLanguages'
import { translationsMapping } from '../../config'
import QuestionMarks from '../components/general/QuestionMarks'

export const getPrintNumber = (print, language, userLanguage, returnString = false) => {
	const setNumber = print.language && print.language.alternateSetNumber ? print.language.alternateSetNumber : print.set.setNumber || (returnString ? '????' : <QuestionMarks>????</QuestionMarks>)
	const isReleased = print.set.releaseEu ? isBefore(new Date(print.set.releaseEu), new Date()) : false

	let languageStr

	if (print.language) {
		languageStr = print.language.alternateLanguageCode || cardLanguages[print.language.language].abbr
	} else {
		languageStr = language ? cardLanguages[language].abbr : translationsMapping[userLanguage].toUpperCase()
	}

	return returnString
		? `${setNumber}${print.cardNumber !== '' && print.cardNumber !== null ? `-${languageStr}${print.cardNumber}` : ''}${!isReleased && (print.cardNumber === '' || print.cardNumber === null) ? `-${languageStr}???` : ''}`
		: (
			<>
				{setNumber}
				{print.cardNumber !== '' && print.cardNumber !== null && <>-{languageStr}{print.cardNumber}</>}
				{!isReleased && (print.cardNumber === '' || print.cardNumber === null) && <>-{languageStr}<QuestionMarks>???</QuestionMarks></>}
			</>
		)
}
