import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Link from '../../components/router/Link'
import Icon from '../../components/general/Icon'
import translate from '../../helper/translate'
import Separator from '../../components/button/Separator'
import QrCode from '../../pages/deck/components/QrCode'
import {
	closeDeck,
	copyDeckList,
	deleteDeck,
	downloadPdf,
	downloadYdk, unarchiveDeck
} from '../../helper/deck'
import ShareLinks from './ShareLinks'
import { forkDeckSafely, removeDeck, saveDeck, setArchived } from '../../state/actionCreators/deckbuilder'
import { hideToast, setConfirm, showToast } from '../../state/actions/app'
import { clearDeck, discardChanges, setDeck } from '../../state/actions/deckbuilder'
import { translationsMapping } from '../../../config'
import { VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import RedLink from '../../components/router/RedLink'

class DeckLinks extends Component {
	static propTypes = {
		cardNameLanguage: PropTypes.string,
		clearDeck: PropTypes.func.isRequired,
		deck: PropTypes.object.isRequired,
		decklistLanguage: PropTypes.string,
		discardChanges: PropTypes.func.isRequired,
		forkDeckSafely: PropTypes.func.isRequired,
		hideToast: PropTypes.func.isRequired,
		language: PropTypes.string.isRequired,
		location: PropTypes.object.isRequired,
		onBeforeNavigate: PropTypes.func.isRequired,
		removeDeck: PropTypes.func.isRequired,
		saveDeck: PropTypes.func.isRequired,
		setArchived: PropTypes.func.isRequired,
		setConfirm: PropTypes.func.isRequired,
		setDeck: PropTypes.func.isRequired,
		shelvedDeck: PropTypes.object,
		showToast: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		userId: PropTypes.number.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		cardNameLanguage: null,
		decklistLanguage: 'en',
		shelvedDeck: null
	}

	render() {
		const { cardNameLanguage, clearDeck, deck, shelvedDeck, decklistLanguage, discardChanges, forkDeckSafely, hideToast, language, location, onBeforeNavigate, removeDeck, saveDeck, setArchived, setConfirm, setDeck, showToast, t, userId, viewport } = this.props

		const canEdit = deck !== null && location.pathname.startsWith('/deck-builder/') && deck.user.id === userId

		return (
			<>
				{canEdit && (
					<>
						<Link to={`/deck-builder${deck.slug ? `/${deck.slug}` : ''}/import`} onBeforeNavigate={onBeforeNavigate}>
							<Icon name="upload" />
							{t('general.import')}
						</Link>
						{viewport === VIEWPORT_MOBILE && (
							<Link to={deck.slug ? `/deck/${deck.slug}` : null} disabled={!deck.slug} onBeforeNavigate={onBeforeNavigate}>
								<Icon name="desktop-computer" />
								{t('general.preview')}
							</Link>
						)}
						<Separator />
						<Link
							onClick={() => forkDeckSafely(deck.slug, location, t)}
							disabled={!deck.slug}
							onBeforeNavigate={onBeforeNavigate}
						>
							<Icon name="duplicate" />
							{t('general.duplicate')}
						</Link>
						<Link
							onClick={deck.archived ? event => unarchiveDeck(event, deck, setConfirm, setArchived, t) : () => setArchived(deck.slug, true, t)}
							disabled={!deck.slug}
							onBeforeNavigate={onBeforeNavigate}
						>
							{deck.archived ? (
								<>
									<Icon name="reply" />
									{t('general.unarchive')}
								</>
							) : (
								<>
									<Icon name="archive" />
									{t('general.archiveDeck')}
								</>
							)}
						</Link>
						{!deck.hasResult && (
							<RedLink
								onClick={e => deleteDeck(e, deck, setConfirm, removeDeck, location, t)}
								onBeforeNavigate={onBeforeNavigate}
							>
								<Icon name="trash" />
								{t('general.remove')}
							</RedLink>
						)}
						<Separator />
					</>
				)}

				{!canEdit && viewport === VIEWPORT_MOBILE && (
					<>
						<Link
							onClick={() => forkDeckSafely(deck.slug, location, t)}
							disabled={!deck.slug}
							onBeforeNavigate={onBeforeNavigate}
						>
							<Icon name="duplicate" />
							{t('general.import')}
						</Link>
						<Separator />
					</>
				)}
				<Link onClick={() => downloadYdk(deck.slug, language, showToast, hideToast, t)} disabled={!deck.slug} onBeforeNavigate={onBeforeNavigate}>
					<Icon name="download-document" />
					{t('general.downloadYdk')}
				</Link>
				<Link onClick={() => downloadPdf(deck.slug, decklistLanguage || translationsMapping[language], language, showToast, hideToast, t)} disabled={!deck.slug} onBeforeNavigate={onBeforeNavigate}>
					<Icon name="printer" />
					{t('general.downloadPdf')}
				</Link>
				<Separator />
				<Link
					onClick={() => copyDeckList(deck, showToast, cardNameLanguage, t)}
					onBeforeNavigate={onBeforeNavigate}
				>
					<Icon name="clipboard-copy" />
					{t('general.copyDeckList')}
				</Link>
				<ShareLinks disabled={!deck.slug} onBeforeNavigate={onBeforeNavigate} />
				<QrCode deck={deck} disabled={!deck.slug} onBeforeNavigate={onBeforeNavigate} t={t} />
				{canEdit && viewport === VIEWPORT_DESKTOP && (
					<>
						<Separator />
						<Link
							onClick={() => closeDeck(deck, shelvedDeck, setConfirm, saveDeck, discardChanges, setDeck, clearDeck, location, viewport, t)}
							onBeforeNavigate={onBeforeNavigate}
						>
							<Icon name="x-circle" />
							{t('general.closeDeck')}
						</Link>
					</>
				)}
			</>
		)
	}
}

const enhance = compose(
	connect(state => ({
		cardNameLanguage: state.persist.user.settings['app.cardNameLanguage'],
		deck: state.deckbuilder.deck,
		decklistLanguage: state.persist.user.settings['app.decklistLanguage'],
		language: state.persist.language,
		shelvedDeck: state.deckbuilder.shelvedDeck,
		userId: state.persist.user.id,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		clearDeck,
		discardChanges,
		forkDeckSafely,
		hideToast,
		removeDeck,
		saveDeck,
		setArchived,
		setConfirm,
		setDeck,
		showToast
	}, dispatch)),
	translate(['general', 'yugi']),
	withRouter
)

export default enhance(DeckLinks)
