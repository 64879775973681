import styled from 'styled-components'
import { desktop, mobile } from '../../styles/media'

const Content = styled.div`
	position: relative;
	padding-left: 2rem;
	padding-right: 2rem;

	${desktop`
		margin: 0 auto;
		max-width: calc(1280px + 4rem);
		width: 100%;

		${props => props.text && `
			max-width: 800px !important;
		`}
	`}

	${mobile`
		padding-left: 1rem;
		padding-right: 1rem;
	`}
`

export default Content
