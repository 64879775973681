import {
	endAutoAdd,
	increaseAutoAddIndex, queueCollectionStatsUpdate,
	resetSession,
	setEndSessionLoading,
	startAutoAdd
} from '../actions/collection'
import { post, remove } from '../../helper/api'
import { setConfirm, shouldReload, showToast } from '../actions/app'
import { applyCollectionUpdates, setCollections } from '../actions/persist'
import history from '../../helper/history'
import { updateUserSetting } from './app'

export const deleteCollection = (collection, t) => (dispatch, getState) => {
	const { collections, user: { settings } } = getState().persist
	const { slug, haves, wants } = collection

	if (haves !== 0 || wants !== 0) {
		dispatch(showToast('error', t('general.cantDeleteNotEmptyCollection'), null))
	} else {
		dispatch(setConfirm({
			text: t('general.collectionConfirmDelete'),
			submitText: t('general.remove'),
			cancelText: t('general.cancel'),
			success: () => {
				remove(`collection/${slug}`, () => {
					if (settings['collection.defaultHavesCollection'] === slug) {
						dispatch(updateUserSetting('collection.defaultHavesCollection', null))
					}

					dispatch(setCollections(collections.filter(item => item.slug !== slug)))

					dispatch(showToast('success', t('general.collectionDeleted')))

					history.push('/mycollections')
				})
			}
		}))
	}
}

export const updateStats = (collectionSlugStr = null) => (dispatch, getState) => {
	const collections = collectionSlugStr || getState().collection.updatedCollectionSlugs?.toString() || ''

	if (collections && collections !== '') {
		dispatch(setEndSessionLoading())

		post(`collections/updateStats?collections=${collections}`, null, ({ data: updatedCollections }) => {
			dispatch(resetSession())
			dispatch(applyCollectionUpdates(updatedCollections))
			dispatch(shouldReload('cardSearchHaves'))
		})
	}
}

export const addAll = (group, collectionSlug, whatToCapture, callback, t, canBatchAdd = true) => (dispatch, getState) => {
	if (!group && getState().collection.dirtyRows === 0 && canBatchAdd) {
		dispatch(addAllBulkAdd(collectionSlug, whatToCapture, callback, t))
	} else {
		callback()
		dispatch(startAutoAdd(group))
	}
}

export const addAllBulkAdd = (collectionSlug, whatToCapture, callback, t) => (dispatch, getState) => {
	const { settings } = getState().persist.user
	const printIds = getState().collection.addAllBatchPrintIds

	const quantity = settings['collection.defaultQuantity']
	const language = settings['collection.defaultLanguage']
	const edition = settings['collection.defaultEdition']
	const condition = settings['collection.defaultCondition']
	const notes = settings['collection.defaultNotes'] || ''

	const storage = settings['collection.defaultStorage']
	const deck = settings['collection.defaultDeck']

	const origin = settings['collection.defaultOrigin']
	const date = settings['collection.defaultDate']

	const altered = settings['collection.defaultAltered']
	const signed = settings['collection.defaultSigned']
	const gradingService = settings['collection.defaultGradinService']
	const grade = settings['collection.defaultGrade']

	post(`collection/${collectionSlug}/bulkAdd`, {
		ids: printIds,
		[whatToCapture]: quantity,
		language,
		edition,
		condition,
		notes,

		storage,
		deck,

		origin,
		date,

		altered,
		signed,
		gradingService,
		grade
	}, () => {
		dispatch(endAutoAdd(true))
		dispatch(queueCollectionStatsUpdate(collectionSlug))

		callback()

		dispatch(showToast('success', t('general.collectionCardAddAllSuccess')))
	}, () => {
		dispatch(endAutoAdd())
		dispatch(queueCollectionStatsUpdate(collectionSlug))

		callback()

		dispatch(showToast('error', t('general.collectionCardAddAllError'), null))
	})
}

export const cancelAddAll = () => (dispatch) => {
	dispatch(endAutoAdd())
}

export const onAutoAdd = t => (dispatch, getState) => {
	const { addAllLoading, autoAddIndex, autoAddTotal } = getState().collection

	if (addAllLoading && autoAddIndex !== null) {
		if (autoAddIndex + 1 !== autoAddTotal) {
			dispatch(increaseAutoAddIndex())
		} else {
			dispatch(endAutoAdd())
			dispatch(showToast('success', t('general.collectionCardAddAllSuccess')))
		}
	}
}
