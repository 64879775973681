import styled from 'styled-components'

const H3 = styled.div`
	padding: 1rem 0;

	color: ${props => props.theme.text};
	font-weight: 800;
	text-align: left;
`

export default H3
