const editions = {
	'1E': {
		name_en: '1st Edition',
		name_de: '1. Auflage',
		order: 1
	},
	UE: {
		name_en: 'Unlimited',
		name_de: 'Unlimitiert',
		order: 2
	},
	LE: {
		name_en: 'Limited Edition',
		name_de: 'Limitierte Auflage',
		order: 3
	}
}

export default editions
