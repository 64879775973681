import styled from 'styled-components'
import Link from './Link'

const RedLink = styled(Link)`
	&, svg {
		color: ${props => props.theme.red} !important;
	}
`

export default RedLink
