import de from 'date-fns/locale/de'
import enUS from 'date-fns/locale/en-US'
import enGB from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import es from 'date-fns/locale/es'
import pt from 'date-fns/locale/pt'
import DE from 'flag-icon-css/flags/1x1/de.svg'
import FR from 'flag-icon-css/flags/1x1/fr.svg'
import IT from 'flag-icon-css/flags/1x1/it.svg'
import ES from 'flag-icon-css/flags/1x1/es.svg'
import PT from 'flag-icon-css/flags/1x1/pt.svg'
import UK from 'flag-icon-css/flags/1x1/gb.svg'
import US from 'flag-icon-css/flags/1x1/us.svg'

const languages = {
	'en-FR': {
		name: 'English',
		nameLong: 'English (France)',
		flag: FR,
		locale: fr,
		day: 'dd/MM/yyyy',
		dayShort: 'dd/MM',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd/MM/yyyy, HH:mm',
		first: '1er',
		second: '2e',
		firstEdition: 'ère',
		leftQuote: '«',
		rightQuote: '»',
		decimalSeparator: ','
	},
	'en-IT': {
		name: 'English',
		nameLong: 'English (Italy)',
		flag: IT,
		locale: it,
		day: 'dd/MM/yyyy',
		dayShort: 'dd/MM',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd/MM/yyyy, HH:mm',
		first: '1°',
		second: '2°',
		firstEdition: 'a',
		leftQuote: '"',
		rightQuote: '"',
		decimalSeparator: ','
	},
	'en-PT': {
		name: 'English',
		nameLong: 'English (Portugal)',
		flag: PT,
		locale: pt,
		day: 'dd/MM/yyyy',
		dayShort: 'dd/MM',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd/MM/yyyy, HH:mm',
		first: '1º.',
		second: '2º',
		firstEdition: 'a',
		leftQuote: '"',
		rightQuote: '"',
		decimalSeparator: ','
	},
	'en-ES': {
		name: 'English',
		nameLong: 'English (Spain)',
		flag: ES,
		locale: es,
		day: 'dd/MM/yyyy',
		dayShort: 'dd/MM',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd/MM/yyyy, HH:mm',
		first: '1º',
		second: '2º',
		firstEdition: 'a',
		leftQuote: '"',
		rightQuote: '"',
		decimalSeparator: ','
	},
	'en-GB': {
		name: 'English',
		nameLong: 'English (UK)',
		flag: UK,
		locale: enGB,
		day: 'dd/MM/yyyy',
		dayShort: 'dd/MM',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd/MM/yyyy, HH:mm',
		first: '1st',
		second: '2nd',
		firstEdition: 'st',
		leftQuote: '"',
		rightQuote: '"',
		decimalSeparator: '.'
	},
	'en-US': {
		name: 'English',
		nameLong: 'English (US)',
		flag: US,
		locale: enUS,
		day: 'MM/dd/yyyy',
		dayShort: 'MM/dd',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'MM/dd/yyyy, HH:mm',
		first: '1st',
		second: '2nd',
		firstEdition: 'st',
		leftQuote: '"',
		rightQuote: '"',
		decimalSeparator: '.'
	},
	'de-DE': {
		name: 'Deutsch',
		nameLong: 'Deutsch',
		flag: DE,
		locale: de,
		day: 'dd.MM.yyyy',
		dayShort: 'd.M',
		month: 'MMMM yyyy',
		year: 'yyyy',
		time: 'dd.MM.yyyy, HH:mm',
		first: '1.',
		second: '2.',
		firstEdition: '.',
		leftQuote: '„',
		rightQuote: '“',
		decimalSeparator: ',',
		firstDayOfWeek: 1,
		months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		weekdaysLong: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
	}
}

export const languagesGeneric = [
	'en',
	'de',
	'fr',
	'it',
	'es',
	'pt'
]

export default languages
