import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../general/Icon'

const PlaceTrophy = ({ bold, children, lightBold, place }) => (
	<StyledPlace bold={bold} lightBold={lightBold} place={place} largeFont={place === '1' || place === '2' || place === '3'}>
		{(place === '1' || place === '2' || place === '3') && <Icon name="trophy" />}
		{children}
	</StyledPlace>
)

PlaceTrophy.propTypes = {
	bold: PropTypes.bool,
	children: PropTypes.any,
	lightBold: PropTypes.bool,
	place: PropTypes.string.isRequired
}

PlaceTrophy.defaultProps = {
	bold: false,
	children: null,
	lightBold: false
}

export const StyledPlace = styled.div`
	align-items: center;
	display: inline-flex;
	white-space: nowrap;

	svg {
		color: #CD7F32;
		margin-right: ${props => props.bold ? '0.75rem' : '0.5rem'};
	}

	${props => props.place === '1' && `
		svg {
			color: #FFC64C;

			${props.bold ? `
				height: 30px;
				width: 30px;
			` : ''}
		}
	`}

	${props => props.place === '2' && `
		svg {
			color: silver;

			${props.bold ? `
				height: 30px;
				width: 30px;
			` : ''}
		}
	`}

	${props => props.place === '3' && `
		svg {
			color: ${props.theme.bronze};

			${props.bold ? `
				height: 30px;
				width: 30px;
			` : ''}
		}
	`}

	${props => props.bold && `
		font-family: TechnaSans, ${props.theme.fontDefault};
		font-size: ${props.largeFont ? '2rem' : '1.2rem'};
		font-weight: bold;
	`}

	${props => props.lightBold && `
		font-family: TechnaSans, ${props.theme.fontDefault};
		font-size: ${props.largeFont ? '1.4rem' : '1rem'};
		font-weight: bold;
	`}
`

export const Place = styled.span`
	background: #444;
	border-radius: 2px;
	color: #FFF;
	font-size: 0.7rem;
	font-weight: bold;
	line-height: 1.1;
	margin-right: 0.35rem;
	padding: 0.1rem 0.2rem;
	text-transform: uppercase;

	${props => !props.tops && `
		background: ${props.theme.backgroundLight};
		border: 1px solid ${props.theme.backgroundConcrete};
		color: ${props.theme.text};
		font-weight: 600;
		padding-top: calc(0.1rem - 1px);
		padding-bottom: calc(0.1rem - 1px);
	`}
`

export default PlaceTrophy
