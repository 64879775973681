import spell from './attribute/spell.svg'
import trap from './attribute/trap.svg'
import continuous from './cardType/continuous.svg'
import counter from './cardType/counter.svg'
import equip from './cardType/equip.svg'
import field from './cardType/field.svg'
import normal from './cardType/normal.svg'
import quickplay from './cardType/quick-play.svg'
import ritual from './cardType/ritual.svg'
import dark from './attribute/dark.svg'
import divine from './attribute/divine.svg'
import earth from './attribute/earth.svg'
import fire from './attribute/fire.svg'
import light from './attribute/light.svg'
import water from './attribute/water.svg'
import wind from './attribute/wind.svg'
import aqua from './monsterType/aqua.svg'
import beast from './monsterType/beast.svg'
import beastwarrior from './monsterType/beast-warrior.svg'
import dinosaur from './monsterType/dinosaur.svg'
import divinebeast from './monsterType/divine-beast.svg'
import dragon from './monsterType/dragon.svg'
import fairy from './monsterType/fairy.svg'
import fiend from './monsterType/fiend.svg'
import fish from './monsterType/fish.svg'
import insect from './monsterType/insect.svg'
import machine from './monsterType/machine.svg'
import plant from './monsterType/plant.svg'
import psychic from './monsterType/psychic.svg'
import pyro from './monsterType/pyro.svg'
import reptile from './monsterType/reptile.svg'
import rock from './monsterType/rock.svg'
import seaserpent from './monsterType/seaserpent.svg'
import spellcaster from './monsterType/spellcaster.svg'
import thunder from './monsterType/thunder.svg'
import warrior from './monsterType/warrior.svg'
import wingedbeast from './monsterType/winged-beast.svg'
import zombie from './monsterType/zombie.svg'
import level from './level/level.svg'
import rank from './level/rank.svg'

export const toIconSelector = str => str.replace('-', '').replace(' ', '').toLowerCase()

export const general = {
	spell,
	trap,
	level,
	rank
}

export const cardTypeIcons = {
	16: continuous,
	22: continuous,
	23: counter,
	18: equip,
	17: field,
	15: normal,
	21: normal,
	19: quickplay,
	20: ritual
}

export const monsterAttributeIcons = {
	1: dark,
	2: divine,
	3: earth,
	4: fire,
	5: light,
	6: water,
	7: wind
}

export const monsterTypeIcons = {
	1: aqua,
	2: beast,
	3: beastwarrior,
	5: dinosaur,
	6: divinebeast,
	7: dragon,
	8: fairy,
	9: fiend,
	10: fish,
	11: insect,
	12: machine,
	13: plant,
	14: psychic,
	25: pyro,
	15: reptile,
	16: rock,
	17: seaserpent,
	18: spellcaster,
	19: thunder,
	20: warrior,
	21: wingedbeast,
	22: zombie
}
