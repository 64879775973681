import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { forceCheck } from 'react-lazyload'
import isBefore from 'date-fns/isBefore'
import Effect, { getTokenText } from '../../components/card/effect/Effect'
import CardTypes, { StyledCardTypes } from '../../components/card/CardTypes'
import MonsterAttributeIcon from '../../components/card/MonsterAttributeIcon'
import MonsterTypeName from '../../components/card/MonsterTypeName'
import {
	getAllowed,
	isExtraDeckCard,
	isLink,
	isMonster,
	isNormal,
	isPendulum,
	isToken,
	isTokenWithStats,
	isXyz
} from '../../helper/card'
import CardTypeName from '../../components/card/CardTypeName'
import Image from '../../pages/deck/components/Image'
import monsterAttributes from '../../data/monsterAttributes'
import types from '../../data/types'
import translate from '../../helper/translate'
import { desktop, mobile, mobileLandscape, mobilePortrait, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import Link from '../../components/router/Link'
import DeckCardActions from '../../components/deck/DeckCardActions'
import { countCardQuantities } from '../../helper/deck'
import Icon from '../../components/general/Icon'
import history from '../../helper/history'
import { TCG_TRADITIONAL } from '../../data/formats'
import AllowedCopies, { getAllowedColor, StyledAllowedCopies } from '../../components/card/AllowedCopies'
import ArchetypeName from '../../components/card/name/ArchetypeName'
import { sortByName } from '../../helper/sort'
import { translationsMapping } from '../../../config'
import Unreleased from '../../components/card/Unreleased'

const CardInfo = ({ actionsRef, card, cardNameLanguage, deck, elementRef, hideImage, highlight, moveImageToRight, t, userId, userLanguage, viewport }) => {
	let lightColor = '#333'
	let darkColor = '#333'

	if (card) {
		lightColor = isMonster(card) && card.monsterAttribute ? monsterAttributes[card.monsterAttribute].lightColor : types.find(type => type.value === card.type).lightColor
		darkColor = isMonster(card) && card.monsterAttribute ? monsterAttributes[card.monsterAttribute].darkColor : types.find(type => type.value === card.type).darkColor
	}

	const canEdit = deck && deck.user.id === userId && !deck.archived

	const imageOnClick = (event) => {
		event.stopPropagation()

		history.push(`/card/${card.slug}`)
	}

	const image = card ? card.image || card[`image_${cardNameLanguage}`] : null
	const allowed = card && deck ? getAllowed(card, deck.season ? deck.season.id : null, deck.format === TCG_TRADITIONAL) : null
	const unreleased = card ? !card.firstPrint || !isBefore(new Date(card.firstPrint * 1000), new Date()) : null

	useEffect(() => {
		forceCheck()
	}, [card])

	const cardName = card ? card[`name_${cardNameLanguage}`] || card.name_en : null

	return (
		<Wrapper
			hideImage={hideImage}
			ref={elementRef}
		>
			{card ? (
				<>
					{viewport === VIEWPORT_DESKTOP && !hideImage && <Image fixedSize image={image} />}
					<Info moveImageToRight={moveImageToRight} hideImage={hideImage}>
						<Header lightColor={lightColor}>
							{viewport === VIEWPORT_MOBILE && !hideImage && <Image fixedSize image={image} onClick={imageOnClick} />}
							<HeaderContent>
								<CloseButton><Icon name="x" /></CloseButton>
								<CardName lightColor={lightColor} darkColor={darkColor}>
									<strong>
										{cardName}
										{isMonster(card) && card.monsterAttribute && <MonsterAttributeIcon id={card.monsterAttribute} />}
									</strong>
									{card.cardTypes.length > 0 && (
										<Meta>
											{isMonster(card) && card.monsterType && (
												<>
													<MonsterTypeName small id={card.monsterType} />
													,&nbsp;
													<CardTypes cardTypes={card.cardTypes} isMonster={isMonster(card)} />
												</>
											)}
											{isMonster(card) && !card.monsterType && (
												<CardTypes cardTypes={card.cardTypes} isMonster />
											)}
											{!isMonster(card) && <CardTypeName id={card.cardTypes[0]} />}
										</Meta>
									)}
								</CardName>
								{(isMonster(card) && (!isToken(card) || isTokenWithStats(card, cardName))) && (
									<MonsterDetails lightColor={lightColor} darkColor={darkColor}>
										{!isLink(card) && (
											<span>
												<MonsterDetailsLabel>{isXyz(card) ? t('yugi.rank') : t('yugi.level')}</MonsterDetailsLabel>
												{card.level}
											</span>
										)}
										{isPendulum(card) && (
											<span>
												<MonsterDetailsLabel>{t('yugi.scale')}</MonsterDetailsLabel>
												{card.pendulumScale || '0'}
											</span>
										)}
										<span>
											<MonsterDetailsLabel>ATK</MonsterDetailsLabel>
											{card.attack !== null ? card.attack : '?'}
											{isLink(card) ? (
												<>
													<MonsterDetailsLabel>Link</MonsterDetailsLabel>
													{card.linkRating}
												</>
											) : (
												<>
													<MonsterDetailsLabel>DEF</MonsterDetailsLabel>
													{card.defense !== null ? card.defense : '?'}
												</>
											)}
										</span>
									</MonsterDetails>
								)}
							</HeaderContent>
						</Header>
						{card.type !== 'other' && (
							<Content darkColor={darkColor}>
								<Effect
									type={card.type}
									highlightFirstLine={isExtraDeckCard(card)}
									highlightQuery={highlight}
									isNormal={isNormal(card) || isToken(card)}
									text={!isToken(card) ? card[`cardEffect_${cardNameLanguage}`] || card[`cardEffect_${translationsMapping[userLanguage]}`] || card.cardEffect_en : getTokenText(cardName, cardNameLanguage, isTokenWithStats(card, cardName))}
									textPendulum={card[`pendulumEffect_${cardNameLanguage}`] || card[`pendulumEffect_${translationsMapping[userLanguage]}`] || card.pendulumEffect_en || ''}
									textLore={isToken(card) ? card[`cardEffect_${cardNameLanguage}`] || card[`cardEffect_${translationsMapping[userLanguage]}`] || card.cardEffect_en : ''}
								/>

								<CardTags>
									{card.archetypesData && card.archetypesData.length > 0 && sortByName(card.archetypesData, `name_${cardNameLanguage}`, 'name_en').filter(item => item.cardRelationType !== 'related').map(archetype => (
										<ArchetypeName
											small
											name={archetype[`name_${cardNameLanguage}`] || archetype.name_en}
											image={archetype[`image_${cardNameLanguage}`] || archetype.image_en}
											color={archetype.color}
											key={archetype.id}
										/>
									))}

									{allowed !== null && allowed !== 3 ? (
										<CardAllowedItem allowed={allowed}>
											<AllowedCopies small allowed={allowed} /> {t(`yugi.banlistAllowed${allowed}`)}
										</CardAllowedItem>
									) : null}

									{unreleased && (
										<Unreleased firstPrint={card.firstPrint ? new Date(card.firstPrint * 1000) : null} />
									)}
								</CardTags>

								{viewport === VIEWPORT_MOBILE && (
									<Actions ref={actionsRef}>
										<ShowDatabaseLink to={`/card/${card.slug}`}>
											<Icon name="information-circle" />
											{t('general.showInDatabase')}
										</ShowDatabaseLink>

										{deck && (
											<DeckCardActions
												allowed={allowed}
												artworkId={card.artworkId}
												canEdit={canEdit}
												card={card}
												format={deck.format}
												quantities={canEdit ? countCardQuantities(deck.cards, card.slug) : {
													total: 0,
													main: 0,
													side: 0,
													note: 0,
													skill: 0
												}}
												row
											/>
										)}
									</Actions>
								)}
							</Content>
						)}
					</Info>
				</>
			) : null}
		</Wrapper>
	)
}

CardInfo.propTypes = {
	actionsRef: PropTypes.object,
	card: PropTypes.object,
	cardNameLanguage: PropTypes.string,
	deck: PropTypes.object,
	hideImage: PropTypes.bool,
	highlight: PropTypes.string,
	moveImageToRight: PropTypes.bool,
	elementRef: PropTypes.object,
	t: PropTypes.func.isRequired,
	userId: PropTypes.number.isRequired,
	userLanguage: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

CardInfo.defaultProps = {
	actionsRef: undefined,
	card: null,
	cardNameLanguage: null,
	deck: null,
	elementRef: undefined,
	hideImage: false,
	highlight: null,
	moveImageToRight: false
}

const Wrapper = styled.div`
	left: 0;
	top: 0;
	position: fixed;
	opacity: ${props => props.hovering ? 1 : 0};
	transform-origin: 0 0 0;
	z-index: -1;
	width: ${props => props.hideImage ? '446px' : '610px'};

	${mobile`
		max-height: 100vh;
		max-height: calc(var(--vh, 1vh) * 100);
		overflow-x: auto;
		width: 350px;
	`}

	${mobilePortrait`
		width: 100%;
	`}

	align-items: flex-start;
	display: flex;

	picture img {
		//box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.7);
		border-radius: 2px;
		height: auto !important;
		width: 160px !important;

		${mobile`
			height: 203px !important;
			width: 140px !important;
		`}
	}
`

const Info = styled.div`
	border-radius: 2px;
	box-shadow: 0 0 2px 0 ${props => props.theme.id === 'dark' ? props.theme.shadowSearch : props.theme.shadowNormal};
	margin: ${props => props.moveImageToRight ? '0 0.25rem 0 0' : '0 0 0 0.25rem'};
	order: ${props => props.moveImageToRight ? -1 : 0};

	${desktop`
		width: 446px;
	`}

	${props => props.hideImage && `
		margin: 0;
	`}

	${mobilePortrait`
		margin: 0 0 2px 0;
		width: 100%;
	`}

	${mobileLandscape`
		margin: 0;
	`}
`

const Header = styled.div`
	display: flex;

	${mobile`
		> div:first-child {
			background: ${props => props.lightColor};
		}
	`}

	${mobileLandscape`
		picture {
			display: none;
		}
	`}
`

const HeaderContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	position: relative;
`

const CloseButton = styled.div`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;

	height: 24px;
	width: 24px;

	align-items: center;
	display: flex;
	justify-content: center;

	background: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	color: rgba(255, 255, 255, 0.6);

	svg {
		height: 16px;
		width: 16px;
	}

	${desktop`
		display: none;
	`}
`

const CardName = styled.div`
	background-image: linear-gradient(to right bottom, ${props => props.lightColor}, ${props => props.darkColor});
	border-radius: 2px 2px 0 0;
	color: #FFF;
	padding: 1rem;

	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;

	${mobileLandscape`
		padding: 0.75rem 0.5rem;
	`}

	strong {
		font-size: 1.2rem;
		font-variant: small-caps;
		margin-bottom: 0.5rem;
		text-shadow: 1px 1px ${props => props.theme.shadowThick};

		align-items: center;
		display: flex;
		justify-content: center;
		text-align: center;
		word-break: break-word;

		${mobileLandscape`
			font-size: 1.1rem;
		`}

		img {
			margin-left: 0.5rem;
			height: 25px;
			width: 25px;
			min-width: 25px;

			${mobilePortrait`
				display: none;
			`}
		}
	}
`

const Meta = styled.div`
	color: ${props => props.theme.white};
	text-align: center;

	${mobile`
		font-size: 0.9rem;
	`}

	span {
		font-size: 1rem;

		${mobile`
			font-size: 0.9rem;
		`}
	}

	${StyledCardTypes} {
		margin-bottom: 0;
	}
`

const MonsterDetails = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	background: ${props => props.darkColor};
	border-top: 1px solid ${props => props.lightColor};
	color: ${props => props.theme.white};
	padding: 0.5rem;

	span {
		font-size: 0.8rem;

		align-items: center;
		display: flex;

		margin-left: 0.75rem;

		&:first-child {
			margin-left: 0;
		}
	}

	* + * {
		margin-left: 0.3rem;
	}

	${mobilePortrait`
		display: none;
	`}
`

const MonsterDetailsLabel = styled.div`
	background: rgba(255, 255, 255, 0.3);
	border-radius: 4px;
	color: rgba(255, 255, 255, 0.8);
	font-family: ${props => props.theme.fontDefault};
	font-size: 0.6rem;
	font-weight: bold;
	margin-right: 0.3rem;
	padding: 0.2rem;
	text-transform: uppercase;
`

const Content = styled.div`
	background: ${props => props.theme.id === 'dark' ? props.theme.backgroundVeryLight : props.theme.background};
	padding: 1rem;

	${mobileLandscape`
		border-color: rgba(0, 0, 0, 0.05);
		border-style: solid;
		border-width: 0 1px 1px;
		font-size: 0.9rem;
		padding: 0.75rem;
	`}
`

const CardAllowedItem = styled.div`
	align-items: center;
	display: flex;

	background: ${props => getAllowedColor(props, false)};
	border-radius: 10px;
	color: ${props => props.theme.white};
	font-size: 0.8rem;
	font-weight: 500;
	padding: 0.35rem 0.5rem;
	width: fit-content;

	${StyledAllowedCopies} {
		background: rgba(255, 255, 255, 0.3);
		margin-right: 0.5rem;
	}
`

const CardTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;

	&:not(:empty) {
		margin-top: 1rem;
	}
`

const Actions = styled.div`
	height: 40px;
	margin-top: 0.5rem;

	align-items: center;
	display: flex;
	justify-content: space-between;
`

const ShowDatabaseLink = styled(Link)`
	align-items: center;
	display: flex;

	&, svg {
		color: ${props => props.theme.textLight};
	}

	svg {
		margin-right: 0.35rem;
	}
`

const enhance = compose(
	connect(state => ({
		cardNameLanguage: state.persist.user.settings['app.cardNameLanguage'],
		deck: state.deckbuilder.deck,
		userId: state.persist.user.id,
		userLanguage: state.persist.language,
		viewport: state.app.viewport
	})),
	translate(['general', 'yugi'])
)

export default enhance(CardInfo)
