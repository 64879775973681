import React, { useEffect } from 'react'
import styled from 'styled-components'
import { mobile } from '../../styles/media'
import { scrollTop } from '../../helper/scroll'

const ChunkLoading = () => {
	useEffect(() => {
		scrollTop()
	}, [])

	return (
		<>
			<Spacer />
		</>
	)
}

const Spacer = styled.div`
	min-height: 100vh !important;
	min-height: calc(var(--vh, 1vh) * 100) !important;

	${mobile`
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
	`}
`

export default ChunkLoading
