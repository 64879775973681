const cardLanguages = {
	world: {
		name_en: 'Worldwide',
		name_de: 'Weltweit'
	},
	en: {
		name_en: 'English',
		name_de: 'Englisch',
		flag: 'GB',
		abbr: 'EN',
		color: 'en',
		adminOrder: 4
	},
	'en-US': {
		name_en: 'English (NA)',
		name_de: 'Englisch (NA)',
		flag: 'US',
		abbr: 'EN',
		color: 'en',
		adminOrder: 1,
		specificationOf: 'en'
	},
	'en-EU': {
		name_en: 'English (EU)',
		name_de: 'Englisch (EU)',
		flag: 'EU',
		abbr: 'EN',
		color: 'en',
		adminOrder: 2,
		specificationOf: 'en'
	},
	'en-AU': {
		name_en: 'English (AU)',
		name_de: 'Englisch (AU)',
		flag: 'AU',
		abbr: 'A',
		color: 'en',
		adminOrder: 3,
		other: true
	},
	de: {
		name_en: 'German',
		name_de: 'Deutsch',
		flag: 'DE',
		abbr: 'DE',
		color: 'de',
		adminOrder: 7
	},
	fr: {
		name_en: 'French',
		name_de: 'Französisch',
		flag: 'FR',
		abbr: 'FR',
		color: 'fr',
		adminOrder: 5
	},
	'fr-CA': {
		name_en: 'French (CA)',
		name_de: 'Französisch (CA)',
		flag: 'CA',
		abbr: 'CA',
		color: 'fr',
		adminOrder: 6,
		other: true
	},
	it: {
		name_en: 'Italian',
		name_de: 'Italienisch',
		flag: 'IT',
		abbr: 'IT',
		color: 'it',
		adminOrder: 8
	},
	es: {
		name_en: 'Spanish',
		name_de: 'Spanisch',
		flag: 'ES',
		abbr: 'SP',
		color: 'es',
		adminOrder: 12
	},
	pt: {
		name_en: 'Portuguese',
		name_de: 'Portugiesisch',
		flag: 'PT',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 9
	},
	'pt-BR': {
		name_en: 'Portuguese (BR)',
		name_de: 'Portugiesisch (BR)',
		flag: 'BR',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 10,
		specificationOf: 'pt'
	},
	'pt-PT': {
		name_en: 'Portuguese (PT)',
		name_de: 'Portugiesisch (PT)',
		flag: 'PT',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 11,
		specificationOf: 'pt'
	},
	jp: {
		name_en: 'Japanese',
		name_de: 'Japanisch',
		flag: 'JP',
		abbr: 'JP',
		color: 'jp',
		adminOrder: 13,
		other: true
	},
	kr: {
		name_en: 'Korean',
		name_de: 'Koreanisch',
		flag: 'KR',
		abbr: 'KR',
		color: 'kr',
		adminOrder: 14,
		other: true
	}
}

export default cardLanguages
