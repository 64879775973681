const skillCardGroups = [
	{
		name_en: 'Yu-Gi-Oh!',
		name_de: 'Yu-Gi-Oh!',
		members: [48, 31, 45, 29, 27, 26, 33, 47, 30, 32, 46, 63, 28, 34, 49, 35, 44, 43, 25, 83, 84, 85]
	},
	{
		name_en: 'Yu-Gi-Oh! GX',
		name_de: 'Yu-Gi-Oh! GX',
		members: [75, 73, 55, 71, 52, 58, 59, 68, 53, 64, 74, 54, 61, 51, 65, 56, 72, 67, 66, 60, 69, 70, 76, 62, 57, 79, 80, 81, 82]
	},
	{
		name_en: 'Yu-Gi-Oh! Duel Links',
		name_de: 'Yu-Gi-Oh! Duel Links',
		members: [41, 39, 42, 40]
	},
	{
		name_en: 'Spell / Trap',
		name_de: 'Zauber / Falle',
		members: [36, 37, 38]
	}
]

export default skillCardGroups
