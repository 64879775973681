import { css } from 'styled-components'

export const VIEWPORT_DESKTOP = 'desktop'
export const VIEWPORT_MOBILE = 'mobile'

export const browserPrefersDarkScheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches

export const isStandalone = () => window.matchMedia('(display-mode: standalone) or (display-mode: fullscreen)').matches
export const isDesktop = () => window.matchMedia('(min-width: 1025px)').matches
export const isMobilePortrait = () => window.matchMedia('(max-width: 1024px) and (orientation: portrait)').matches
export const isMobileLandscape = () => window.matchMedia('(max-width: 1024px) and (orientation: landscape)').matches
export const isIos = () => /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream
export const isMac = () => /Mac/i.test(window.navigator.platform)
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
export const isPrerender = () => window.navigator.userAgent.includes('Prerender')

export const desktop = (...args) => css`
	@media (min-width: 1025px) {
		${css(...args)}
	}
`

export const desktopOrLandscape = (...args) => css`
	@media (min-width: 481px), (orientation: landscape)  {
		${css(...args)}
	}
`

export const mobile = (...args) => css`
	@media (max-width: 1024px) {
		${css(...args)}
	}
`

export const mobileSmall = (...args) => css`
	@media (max-width: 480px) {
		${css(...args)}
	}
`

export const mobilePortrait = (...args) => css`
	@media (max-width: 1024px) and (orientation: portrait) {
		${css(...args)}
	}
`

export const mobileLandscape = (...args) => css`
	@media (max-width: 1024px) and (orientation: landscape) {
		${css(...args)}
	}
`

export const mobileTablet = (...args) => css`
	@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
		${css(...args)}
	}
`

export const standalone = (...args) => css`
	@media all and (display-mode: standalone) {
		${css(...args)}
	}
`

export const print = (...args) => css`
	@media print {
		${css(...args)}
	}
`

export const height100Percent = (offset = '+ 0px') => css`
	height: calc(100vh ${offset});
	height: calc(var(--vh, 1vh) * 100 ${offset});

	${standalone`
		height: 100vh;
	`}
`
