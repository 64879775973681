import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { cardNameLanguageMapping, priceSourceDefaults, translationsMapping } from '../../../config'
import Icon from '../../components/general/Icon'
import DropDown, {
	DropDownLink,
	DropDownLinkCheck,
	DropDownLinkRight,
	HeaderLinkDropDownHeading
} from '../../components/button/DropDown'
import priceSources, { CARD_MARKET } from '../../data/priceSources'
import { priceSourceOptions } from '../../data'
import languages from '../../data/languages'
import Avatar from '../../components/user/Avatar'
import AccountNotifications from './AccountNotifications'
import ProTag from '../../components/general/ProTag'
import { desktop, mobile, VIEWPORT_DESKTOP } from '../../styles/media'
import BetterButton, { StyledBetterButton } from '../../components/button/BetterButton'
import { Login, Register } from '../../pages/imports'
import themeLight from '../../styles/theme-light'
import { changeCardNameLanguage, changeLanguage, updateUserSetting } from '../../state/actionCreators/app'
import translate from '../../helper/translate'
import Link from '../../components/router/Link'
import Tag from '../../components/general/Tag'
import UserDropDown from './UserDropDown'
import CommentsDropDown from './CommentsDropDown'
import { StyledUnread } from '../../components/general/Unread'
import { capitalize } from '../../helper/string'

const SiteSettings = ({ avatar, authToken, changeLanguage, changeCardNameLanguage, homeJumbotron, onBeforeNavigate, pwaInstallEvent, settings, subscribed, t, theme, title, transparent, unreadComments, updateUserSetting, userLanguage, username, viewport }) => {
	const priceSourceDefault = priceSourceDefaults[translationsMapping[userLanguage]]
	const priceSource = settings['app.priceSource'] || CARD_MARKET
	const appearance = settings['app.appearance'] || 'light'
	const dark = theme.id === 'dark'

	const setAppearance = (appearance) => {
		updateUserSetting('app.appearance', appearance, true)
	}

	return (
		<HeaderLinks transparent={transparent}>
			{/*<HeaderLink username transparent={transparent} onBeforeNavigate={onBeforeNavigate} authToken={authToken}>
				<HeaderLinkInner>
					<Icon name="archive" />
					<span>8.521<small>Karten</small></span>
				</HeaderLinkInner>
				<HeaderLinkBackground />
			</HeaderLink>*/}

			{/*{!isStandalone() && (
				<HeaderLink
					install
					to={!pwaInstallEvent ? '/install' : null}
					onClick={pwaInstallEvent ? () => install(pwaInstallEvent) : undefined}
					readyToInstall={!!pwaInstallEvent}
					transparent={transparent}
					title={t('general.installApp')}
				>
					<HeaderLinkInner>
						<Icon name="device-mobile" />
						<ReadyToInstall visible={!!pwaInstallEvent}>
							<Icon name="install" />
						</ReadyToInstall>
					</HeaderLinkInner>
					<HeaderLinkBackground />
				</HeaderLink>
			)}*/}

			<HeaderLinkGroup transparent={transparent}>
				<DropDown
					button={(
						<HeaderLink transparent={transparent} title={t('general.changeLanguage')}>
							<HeaderLinkInner>
								<Icon name="globe-alt" />
								<HeaderLinkLanguageIcon><img src={languages[userLanguage].flag} alt={languages[userLanguage].nameLong} /></HeaderLinkLanguageIcon>
							</HeaderLinkInner>
							<HeaderLinkBackground />
						</HeaderLink>
					)}
					top={50}
					right={9}
					width={230}
				>
					{onBeforeNavigate2 => (
						<>
							<HeaderLinkDropDownHeading>
								{t('general.language')}
							</HeaderLinkDropDownHeading>
							{['en-US', 'en-GB', 'de-DE']
								.map((key) => {
									const value = languages[key]

									return (
										<DropDownLink
											onClick={() => {
												changeLanguage(key)
												changeCardNameLanguage(cardNameLanguageMapping[key])
											}}
											onBeforeNavigate={() => {
												onBeforeNavigate2()
												onBeforeNavigate()
											}}
											active={userLanguage === key}
											key={key}
										>
											<img src={value.flag} alt="" />
											<span>{value.nameLong}</span>
											<DropDownLinkRight>
												<DropDownLinkCheck active={userLanguage === key}>
													<Icon name="check" />
												</DropDownLinkCheck>
											</DropDownLinkRight>
										</DropDownLink>
									)
								})}
							<HeaderLinkDropDownHeading>
								{t('general.cardTextsTranslated')}
							</HeaderLinkDropDownHeading>
							{['en-FR', 'en-IT', 'en-ES', 'en-PT']
								.map((key) => {
									const value = languages[key]

									return (
										<DropDownLink
											onClick={() => {
												changeLanguage(key)
												changeCardNameLanguage(cardNameLanguageMapping[key])
											}}
											onBeforeNavigate={() => {
												onBeforeNavigate2()
												onBeforeNavigate()
											}}
											active={userLanguage === key}
											key={key}
										>
											<img src={value.flag} alt="" />
											<span>{value.nameLong}</span>
											<DropDownLinkRight>
												<DropDownLinkCheck active={userLanguage === key}>
													<Icon name="check" />
												</DropDownLinkCheck>
											</DropDownLinkRight>
										</DropDownLink>
									)
								})}
						</>
					)}
				</DropDown>

				<DropDown
					button={(
						<HeaderLink transparent={transparent} title={t('general.appearance')}>
							<HeaderLinkInner>
								<Icon name={dark ? 'moon' : 'sun'} />
								{appearance === 'system' && <ThemeAutoLabel>Auto</ThemeAutoLabel>}
							</HeaderLinkInner>
							<HeaderLinkBackground />
						</HeaderLink>
					)}
					top={50}
					right={9}
				>
					{onBeforeNavigate2 => (
						<>
							<HeaderLinkDropDownHeading>
								{t('general.appearance')}
							</HeaderLinkDropDownHeading>
							{['system', 'light', 'dark'].map((item) => {
								const active = appearance === item

								return (
									<DropDownLink
										onClick={() => setAppearance(item)}
										onBeforeNavigate={() => {
											onBeforeNavigate2()
											onBeforeNavigate()
										}}
										active={active}
										key={item}
									>
										{item === 'system' && <Icon name="desktop-computer" />}
										{item === 'light' && <Icon name="sun" />}
										{item === 'dark' && <Icon name="moon" />}
										<span>{t(`general.appearance${capitalize(item)}`)}</span>
										<DropDownLinkRight>
											<DropDownLinkCheck active={active}>
												<Icon name="check" />
											</DropDownLinkCheck>
										</DropDownLinkRight>
									</DropDownLink>
								)
							})}
						</>
					)}
				</DropDown>

				<DropDown
					button={(
						<HeaderLink transparent={transparent} title={t('general.changePriceSource')}>
							<HeaderLinkInner>
								<Icon name={priceSources[priceSource].icon} />
							</HeaderLinkInner>
							<HeaderLinkBackground />
						</HeaderLink>
					)}
					top={50}
					right={9}
				>
					{onBeforeNavigate2 => (
						<>
							<HeaderLinkDropDownHeading>
								{t('general.priceSource')}
							</HeaderLinkDropDownHeading>
							{priceSourceOptions(priceSourceDefault).map((item) => {
								const active = Number(priceSource) === Number(item.value)

								return (
									<DropDownLink
										onClick={() => {
											updateUserSetting('app.priceSource', item.value, true)
										}}
										onBeforeNavigate={() => {
											onBeforeNavigate2()
											onBeforeNavigate()
										}}
										active={active}
										key={item.value}
									>
										<span>{item.name}</span>
										<HeaderLinkDropDownLinkCurrency>
											{item.currency}
										</HeaderLinkDropDownLinkCurrency>
										<DropDownLinkRight>
											<DropDownLinkCheck active={active}>
												<Icon name="check" />
											</DropDownLinkCheck>
										</DropDownLinkRight>
									</DropDownLink>
								)
							})}
						</>
					)}
				</DropDown>

				{authToken && viewport === VIEWPORT_DESKTOP && (
					<CommentsDropDown
						button={(
							<HeaderLink comments unread={unreadComments} transparent={transparent} onBeforeNavigate={onBeforeNavigate} authToken={authToken} title={t('general.comments')}>
								<HeaderLinkInner>
									<Icon name="chat-alt-2" />
									<AccountNotifications small type="comments" />
								</HeaderLinkInner>
								<HeaderLinkBackground />
							</HeaderLink>
						)}
					/>
				)}
			</HeaderLinkGroup>

			{authToken && viewport === VIEWPORT_DESKTOP && (
				<UserDropDown
					button={(
						<HeaderLink username avatar transparent={transparent} onBeforeNavigate={onBeforeNavigate} authToken={authToken}>
							<HeaderLinkInner>
								<Avatar width={40} avatar={avatar || 'avatar-male'} />
								<span>{username || t('general.guest')}</span>
								{subscribed === 'pro' && <ProTag title={title} />}

								<AccountNotifications type="account" />
							</HeaderLinkInner>
							<HeaderLinkBackground />
						</HeaderLink>
					)}
				/>
			)}

			{!authToken && viewport === VIEWPORT_DESKTOP && (
				<>
					<BetterButton
						to="/login"
						onMouseOver={() => Login.preload()}
						transparent={transparent}
						color={themeLight.secondary}
					>
						{t('general.login')}
					</BetterButton>
					<BetterButton
						to="/signup"
						onMouseOver={() => Register.preload()}
						transparent={transparent}
					>
						{t('general.register')}
					</BetterButton>
				</>
			)}
		</HeaderLinks>
	)
}

SiteSettings.propTypes = {
	authToken: PropTypes.string,
	avatar: PropTypes.string,
	changeLanguage: PropTypes.func.isRequired,
	changeCardNameLanguage: PropTypes.func.isRequired,
	homeJumbotron: PropTypes.bool,
	pwaInstallEvent: PropTypes.object,
	onBeforeNavigate: PropTypes.func,
	settings: PropTypes.object.isRequired,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
	title: PropTypes.string,
	transparent: PropTypes.string,
	unreadComments: PropTypes.number,
	updateUserSetting: PropTypes.func.isRequired,
	userLanguage: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

SiteSettings.defaultProps = {
	authToken: null,
	avatar: null,
	homeJumbotron: false,
	pwaInstallEvent: null,
	onBeforeNavigate: () => {},
	subscribed: null,
	title: null,
	transparent: null,
	unreadComments: 0
}

const HeaderLinks = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	gap: 1rem;

	${StyledBetterButton} {
		margin-top: auto;
		margin-bottom: auto;
		white-space: nowrap;
	}

	${StyledBetterButton}:nth-last-child(2) {
		margin-left: 0.5rem;
	}

	${props => props.transparent === 'dark' && `
		${StyledBetterButton}:nth-last-child(2) {
			background: rgba(255, 255, 255, 0.2);
		}
	`}

	${desktop`
		> * + * {
			margin-left: 2px;
		}

		${props => props.transparent === 'dark' && `
			${StyledBetterButton}:nth-last-child(2):hover {
				background: rgba(255, 255, 255, 0.3);
			}
		`}
	`}

	${mobile`
		flex-direction: row-reverse;

		> * + * {
			margin-right: 0.75rem;
		}
	`}
`

const HeaderLinkInner = styled.div`
	position: relative;
	z-index: 5;
	white-space: nowrap;

	align-items: center;
	display: flex;
	justify-content: center;
`

const HeaderLinkBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	height: 100%;
	width: 100%;

	background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.04)' : 'rgba(0, 0, 0, 0.05)'};
	transform: skew(-15deg);
`

const HeaderLink = styled(Link)`
	color: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.8)'};
	transition: 0.1s;

	position: relative;
	z-index: 5;

	align-items: center;
	display: flex;
	justify-content: center;
	text-decoration: none;
	user-select: none;

	svg, img {
		height: 24px;
		width: 24px;
	}

	${props => props.username && `
		border-radius: 20px;
		font-weight: 500;
		padding: 0 1rem 0 1rem;
		width: auto;

		${HeaderLinkInner} {
			> * + *:not(${HeaderLinkBackground}) {
				margin-left: 0.5rem;
			}
		}

		${Tag} {
			margin-left: 0.35rem;
		}

		${!props.authToken && `
			svg {
				filter: grayscale(100%);
				-webkit-transform: translateZ(0);
				-webkit-perspective: 1000;
				-webkit-backface-visibility: hidden;
			}
		`}
	`}

	${props => props.avatar && `
		svg, img {
			border-radius: 50%;
			height: 34px;
			width: 34px;
		}
	`}

	${props => props.comments && `
		${StyledUnread} {
			position: absolute;
			right: -0.35rem;
			top: -0.35rem;
			font-size: 0.8rem !important;
		}
	`}

	${props => props.comments && props.unread > 0 && `
		background: ${props.theme.primaryLight} !important;
		color: ${props.theme.text} !important;
	`}

	> span:first-of-type {
		font-size: 1.1rem;
		font-weight: 500;
		margin-left: 0.5rem;
	}

	${HeaderLinkBackground} {
		${props => props.transparent === 'light' && `
			background: rgba(0, 0, 0, 0.05);
		`}

		${props => props.transparent === 'dark' && `
			background: rgba(255, 255, 255, 0.05);
		`}
	}

	&:hover {
		${HeaderLinkBackground} {
			background: ${props => props.theme.id === 'dark' ? props.theme.backgroundLight : 'rgba(0, 0, 0, 0.1)'};

			${props => props.transparent === 'dark' && `
				background: rgba(255, 255, 255, 0.1);
			`}
		}

		color: ${props => props.transparent === 'dark' ? props.theme.white : props.theme.text};

		${props => !props.focused && `
			border-color: ${props.theme.textTranslucentLight};
		`}
	}

	${props => props.focused && `
		${HeaderLinkBackground} {
			background: ${props.theme.id === 'dark' ? props.theme.backgroundLight : 'rgba(0, 0, 0, 0.1)'};
			color: ${props.theme.text};

			${props.transparent === 'dark' && `
				background: rgba(255, 255, 255, 0.1);
			`}
		}
	`}

	${desktop`
		${props => props.transparent === 'dark' && `
			color: rgba(255, 255, 255, 0.7);
		`}
	`}

	${props => props.username && `
		> span:first-of-type {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 52px;
		}
	`}

	small {
		color: ${props => props.theme.textLight};
		display: block;
		font-size: 0.7rem;
		font-weight: 600;
		margin-top: 0.1rem;
		text-transform: uppercase;
	}

	background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.05)' : props.theme.background};
	height: 46px;

	${props => !props.transparent && `
		box-shadow: ${props.theme.shadowSearch};
	`}

	${props => props.transparent === 'light' && `
		background: rgba(0, 0, 0, 0.05);
	`}

	${props => props.transparent === 'dark' && `
		background: rgba(255, 255, 255, 0.05);
	`}

	${props => !props.username && `
		border-radius: 50%;
		width: 46px;
	`}

	${HeaderLinkBackground} {
		display: none;
	}

	${mobile`
		background: ${props => props.theme.backgroundLight};
		box-shadow: none;

		border-radius: 50%;
	`}
`

const HeaderLinkGroup = styled.div`
	align-items: center;
	display: flex;
	gap: 2px;

	${props => !props.transparent && `
		${HeaderLink} {
			color: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.6)'};
		}
	`}

	${desktop`
		> :first-child ${HeaderLink}, > :last-child ${HeaderLink} {
			border-radius: 20px;
			width: calc(46px + 0.25rem);
		}

		> :first-child ${HeaderLink} {
			padding-left: 0.25rem;
		}

		> :last-child ${HeaderLink} {
			padding-right: 0.25rem;
		}

		> :not(:first-child) ${HeaderLink} {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		> :not(:last-child) ${HeaderLink} {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		${props => !props.transparent && `
			${HeaderLink}:hover {
				color: ${props.theme.text};
			}
		`}
	`}

	${mobile`
		gap: 1rem;
	`}
`

const HeaderLinkLanguageIcon = styled.div`
	position: absolute;
	right: 0;
	top: calc(50% + 6px);
	transform: translate(0, -50%);

	img {
		border-radius: 1px;
		height: 12px;
		width: 12px;
	}
`

const HeaderLinkDropDown = styled.div`
	position: absolute;
	right: 0;
	top: calc(100% + 0.5rem);
	width: ${props => props.width}px;
	min-width: 100%;
	z-index: ${props => props.theme.zLayer6};

	max-height: calc(100vh - 71px);
	overflow: auto;

	display: none;
	flex-direction: column;

	background: ${props => props.theme.background};
	border: 2px solid ${props => props.theme.backgroundLight};
	border-radius: 4px;
	padding: 0.25rem 0;
`

export const HeaderLinkDropDownLinkCurrency = styled.div`
	align-self: flex-end;
	color: ${props => props.theme.textLight};
	font-size: 0.9rem;
	padding-left: 0.35rem;
`

const RelativeWrapper = styled.div`
	position: relative;

	&.visible {
		${HeaderLink} {
			background: ${props => props.theme.backgroundLight};
			color: ${props => props.theme.text};
		}

		${HeaderLinkDropDown} {
			display: flex;
		}

		&:after {
			content: "";
			display: block;

			position: absolute;
			right: 0;
			top: calc(100% - 16px);
			z-index: 1;

			background: transparent;
			height: 30px;
			width: 180px;
		}
	}
`

const UserLinks = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;

	border-radius: 4px;
	margin-left: 0.5rem;

	> a {
		white-space: nowrap;

		&:first-of-type {
			margin-left: 1rem;
		}
	}

	> * + * {
		margin-left: 0.5rem;
	}
`

const ReadyToInstall = styled.div`
	position: absolute;
	bottom: -0.15rem;
	right: -0.15rem;

	background: ${props => props.theme.green};
	border-radius: 50%;
	color: ${props => props.theme.white};

	height: 16px;
	width: 16px;

	align-items: center;
	display: flex;
	justify-content: center;

	transition: 0.1s ease-in;
	opacity: 0;
	transform: scale(0.9);

	svg {
		height: 12px;
		width: 12px;
	}

	${props => props.visible && `
		opacity: 1;
		transform: scale(1);
	`}
`

const ThemeAutoLabel = styled.div`
	position: absolute;
	bottom: -0.25rem;
	right: -0.35rem;

	background: ${props => props.theme.background};
	color: ${props => props.theme.text};

	border-radius: 2px;
	font-size: 0.5rem;
	font-weight: 600;
	padding: 0.1rem 0.15rem;
	text-transform: uppercase;
`

const mapStateToProps = state => ({
	authToken: state.persist.authToken,
	avatar: state.persist.user.avatar,
	language: state.persist.language,
	pwaInstallEvent: state.app.pwaInstallEvent,
	settings: state.persist.user.settings,
	subscribed: state.persist.user.subscribed,
	title: state.persist.user.title,
	unreadComments: state.persist.unreadComments,
	username: state.persist.user.username,
	userLanguage: state.persist.language,
	viewport: state.app.viewport
})


const mapDispatchToProps = dispatch => bindActionCreators({
	changeCardNameLanguage,
	changeLanguage,
	updateUserSetting
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate('general'),
	withTheme
)

export default enhance(SiteSettings)
