import styled from 'styled-components'
import { StyledFlag } from '../../general/Flag'
import { mobile } from '../../../styles/media'

const Name = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	position: relative;

	text-decoration: none;

	> * + * {
		margin-left: 0.5rem;
	}

	.lazyload-wrapper {
		width: 21px;
	}

	> ${StyledFlag} {
		border-radius: 2px;
		height: 16px;
		width: 16px;
	}

	${mobile`
		${props => props.flexColumn && `
			flex-direction: column;

			> * + * {
				margin-left: 0;
			}
		`}
	`}
`

export const Parenthesis = styled.span`
	color: ${props => props.theme.textLight};
`

export default Name
