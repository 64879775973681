import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../general/Icon'
import { getContrastColor } from '../../helper/color'

const CollectionIcon = ({ color, icon, size }) => (
	<StyledCollectionIcon
		color={color}
		size={size}
	>
		<Icon name={icon} />
	</StyledCollectionIcon>
)

CollectionIcon.propTypes = {
	color: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired
}

export const StyledCollectionIcon = styled.div`
	background: ${props => props.color};
	color: ${props => props.color ? getContrastColor(props.color) : props.theme.textLight};
	position: relative;
	text-decoration: none;
	transition: 0.2s;

	align-items: center;
	display: flex;
	justify-content: center;

	border-radius: ${props => props.size / 10}px;
	height: ${props => props.size}px;
	min-width: ${props => props.size}px;

	svg {
		height: ${props => props.size * 0.5}px;
		width: ${props => props.size * 0.5}px;
		min-height: ${props => props.size * 0.5}px;
		min-width: ${props => props.size * 0.5}px;
		opacity: 0.5;

		${props => props.size === 20 && `
			height: 12px;
			width: 12px;
			min-height: 12px;
			min-width: 12px;
		`}
	}
`

export default CollectionIcon
