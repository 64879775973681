const types = [
	{
		value: 'monster',
		name_de: 'Monster',
		name_en: 'Monster',
		name_fr: 'Monstre',
		name_it: 'Mostro',
		name_es: 'Monstruo',
		name_pt: 'Monstro',
		namePlural_de: 'Monster',
		namePlural_en: 'Monsters',
		namePlural_fr: 'Monstres',
		namePlural_it: '',
		namePlural_es: '',
		namePlural_pt: '',
		modestColor: 'rgb(139,98,32, 0.3)',
		lightColor: '#c4c4c4',
		darkColor: '#787878'
	},
	{
		value: 'spell',
		name_de: 'Zauber',
		name_en: 'Spell',
		name_fr: 'Magie',
		name_it: 'Magia',
		name_es: 'Mágica',
		name_pt: 'Magia',
		namePlural_de: 'Zauber',
		namePlural_en: 'Spells',
		namePlural_fr: '',
		namePlural_it: '',
		namePlural_es: '',
		namePlural_pt: '',
		modestColor: 'rgb(140, 185, 192, 0.3)',
		lightColor: '#8cb9c0',
		darkColor: '#134D74',
		iconColor: '#0e9180'
	},
	{
		value: 'trap',
		name_de: 'Falle',
		name_en: 'Trap',
		name_fr: 'Piège',
		name_it: 'Trappola',
		name_es: 'Trampa',
		name_pt: 'Armadilha',
		namePlural_de: 'Fallen',
		namePlural_en: 'Traps',
		namePlural_fr: '',
		namePlural_it: '',
		namePlural_es: '',
		namePlural_pt: '',
		modestColor: 'rgb(182, 135, 150, 0.3)',
		lightColor: '#b68796',
		darkColor: '#731433',
		iconColor: '#af267c'
	},
	{
		value: 'skill',
		name_de: 'Fähigkeit',
		name_en: 'Skill',
		name_fr: 'Compétence',
		name_it: 'Abilità',
		name_es: 'Habilidad',
		name_pt: 'Habilidade',
		namePlural_de: 'Fähigkeiten',
		namePlural_en: 'Skills',
		namePlural_fr: '',
		namePlural_it: '',
		namePlural_es: '',
		namePlural_pt: '',
		modestColor: 'rgb(28, 155, 224, 0.3)',
		lightColor: '#1C9BE0',
		darkColor: '#3E59A8'
	},
	{
		value: 'other',
		name_de: 'Sonstige',
		name_en: 'Other',
		name_fr: 'Autres',
		name_it: 'Altro',
		name_es: 'Otros',
		name_pt: 'Outros',
		namePlural_de: 'Sonstige',
		namePlural_en: 'Others',
		namePlural_fr: '',
		namePlural_it: '',
		namePlural_es: '',
		namePlural_pt: '',
		lightColor: '#c4c4c4',
		darkColor: '#787878'
	}
]

export default types
