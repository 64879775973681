const userTypes = [
	'player',
	'collector',
	'casual',
	'competitive',
	'masterduel',
	'duellinks',
	'goat',
	'edison',
	'remote'
]

export default userTypes
