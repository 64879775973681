import styled from 'styled-components'
import { StyledInputContainer } from './Input'
import { desktop } from '../../styles/media'
import FollowsInputButton from '../button/FollowsInputButton'

const InlineField = styled.label`
	align-items: center;
	display: flex;

	background: ${props => props.theme.id === 'dark' ? props.theme.backgroundVeryLight : props.theme.backgroundLight};
	border-radius: 4px;
	margin-bottom: 0;
	padding: 1px;

	> svg {
		color: ${props => props.theme.textVeryLight};
		margin: 0 0.5rem;
		min-width: 20px;
	}

	${StyledInputContainer} > input, ${StyledInputContainer} > select, .react-select__control {
		border-color: ${props => props.theme.id === 'dark' ? props.theme.backgroundVeryLight : props.theme.backgroundLight};
		border-width: 1px;
		height: 36px;
		min-height: 36px;

		${desktop`
			&:hover:not(:focus) {
				border-color: ${props => props.theme.id === 'dark' ? props.theme.backgroundDark : props.theme.backgroundSolid} !important;
				box-shadow: 0 0 0 1px ${props => props.theme.id === 'dark' ? props.theme.backgroundDark : props.theme.backgroundSolid};
			}
		`}
	}

	.react-select__control {
		min-height: 36px;
	}

	${FollowsInputButton} {
		margin: 0 2px 0 0.25rem;
	}
`

export default InlineField
