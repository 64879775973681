import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../general/Icon'

const Avatar = ({ avatar, onClick, width }) => avatar !== 'avatar-male' && avatar !== 'avatar-female' ? (
	<img
		src={`${avatar}-${width}.jpg`}
		srcSet={`${avatar}-${width}.jpg 1x, ${avatar}${width * 2 !== 200 ? `-${width * 2}` : ''}.jpg 2x`}
		onClick={onClick}
		alt=""
	/>
) : <Icon name={avatar} onClick={onClick} />

Avatar.propTypes = {
	avatar: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	width: PropTypes.number.isRequired
}

Avatar.defaultProps = {
	onClick: null
}

export default Avatar
