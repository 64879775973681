const reportUserReasons = [
	{
		label: 'general.reportUserReasonUsername',
		value: 'username'
	},
	{
		label: 'general.reportUserReasonAvatar',
		value: 'avatar'
	},
	{
		label: 'general.reportUserReasonOther',
		value: 'other'
	}
]

export default reportUserReasons
