import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getMoveElementIntoViewCoordinates } from '../../helper/position'
import CardInfo from './CardInfo'
import { isMobileLandscape, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import { scrollToPosition } from '../../helper/scroll'
import { setHoverCard } from '../../state/actions/app'

class CardInfoTooltip extends Component {
	static propTypes = {
		animate: PropTypes.bool,
		card: PropTypes.object,
		hideImage: PropTypes.bool,
		q: PropTypes.string,
		position: PropTypes.object,
		setHoverCard: PropTypes.func.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		animate: false,
		card: null,
		hideImage: false,
		q: null,
		position: null
	}

	state = {
		card: null,
		visible: false,
		moveImageToRight: false
	}

	constructor(props) {
		super(props)

		this.element = React.createRef()
		this.actions = React.createRef()
	}

	componentDidUpdate(prevProps) {
		const { animate, card, position, viewport } = this.props
		const { visible } = this.state

		if (JSON.stringify(prevProps.position) !== JSON.stringify(position)) {
			clearTimeout(this.timer)

			const hoverCardElement = this.element.current

			if (card && position) {
				if (!visible) {
					if (viewport === VIEWPORT_DESKTOP) {
						this.timer = window.setTimeout(() => this.updateCard(hoverCardElement, card, position, prevProps.position), 200)
					} else {
						this.updateCard(hoverCardElement, card, position)
					}
				} else if (visible && animate) {
					this.updateCard(hoverCardElement, card, position)
				} else {
					this.hide(hoverCardElement)
				}
			} else {
				this.hide(hoverCardElement)
			}
		}
	}

	enableClickAwayHandler = () => {
		window.addEventListener('touchstart', this.onTouchStart)
		this.element.current.addEventListener('click', this.onHoverCardClick)
	}

	disableClickAwayHandler = () => {
		window.removeEventListener('touchstart', this.onTouchStart)
		this.element.current.removeEventListener('click', this.onHoverCardClick)
	}

	onTouchStart = (event) => {
		const { setHoverCard } = this.props

		if (this.element.current && !this.element.current.contains(event.target)) {
			this.hide(this.element.current)
			setHoverCard(null, null, false)
		}
	}

	onHoverCardClick = (event) => {
		const { setHoverCard } = this.props

		if (this.actions.current && !this.actions.current.contains(event.target)) {
			this.hide(this.element.current)
			setHoverCard(null, null, false)
		}
	}

	updateCard = (hoverCardElement, card, position) => {
		const { hideImage, viewport } = this.props

		const hoverCardWidth = viewport === VIEWPORT_MOBILE ? 350 : (hideImage ? 446 : 610)

		// reset width
		hoverCardElement.style.width = `${hoverCardWidth}px`

		const overlapX = document.body.getBoundingClientRect().width - position.left - position.width - hoverCardWidth

		this.setState({ card, visible: true, moveImageToRight: overlapX < 0 }, () => {
			const margin = card.margin || (card.noMargin ? 0 : (viewport === VIEWPORT_MOBILE ? 6 : 8))

			let { x, y } = getMoveElementIntoViewCoordinates(position, hoverCardElement, margin)

			hoverCardElement.style.zIndex = 100
			hoverCardElement.style.visibility = 'visible'
			hoverCardElement.style.opacity = 1

			hoverCardElement.style.transition = 'opacity 0.2s'

			if (isMobileLandscape() || viewport === VIEWPORT_DESKTOP) {
				if (Number(x.replace('px', '') < 0)) {
					const widthLeft = position.left - margin - 2
					const widthRight = document.body.getBoundingClientRect().width - position.left - position.width - 2 * margin

					if (widthLeft > widthRight) {
						x = 0
						hoverCardElement.style.width = `${widthLeft > hoverCardWidth ? hoverCardWidth : widthLeft}px`
					} else {
						x = `${position.left + position.width + 2 * margin}px`
						hoverCardElement.style.width = `${widthRight > hoverCardWidth ? hoverCardWidth : widthRight}px`
					}
				}

				hoverCardElement.style.transform = `translate(${x}, ${y})`
			} else {
				hoverCardElement.style.transform = 'none'
				hoverCardElement.style.width = '100%'
			}


			if (viewport === VIEWPORT_MOBILE) {
				scrollToPosition(0, this.element.current)
				this.enableClickAwayHandler()
			}
		})
	}

	hide = (wrapper) => {
		const { viewport } = this.props

		this.setState({
			visible: false
		}, () => {
			wrapper.style.visibility = 'hidden'
			wrapper.style.opacity = 0
			wrapper.style.zIndex = -1

			if (viewport === VIEWPORT_MOBILE) {
				this.disableClickAwayHandler()
			}
		})
	}

	render() {
		const { hideImage } = this.props
		const { card, moveImageToRight } = this.state

		return ReactDOM.createPortal((
			<CardInfo
				actionsRef={this.actions}
				card={card}
				elementRef={this.element}
				hideImage={hideImage}
				highlight={card ? card.q : null}
				moveImageToRight={moveImageToRight}
			/>
		),
		document.getElementById('root-modal'))
	}
}

const enhance = connect(state => ({
	animate: state.app.hoverAnimate,
	card: state.app.hoverCard,
	position: state.app.hoverPosition,
	hideImage: state.app.hoverHideImage,
	viewport: state.app.viewport
}), dispatch => bindActionCreators({
	setHoverCard
}, dispatch))

export default enhance(CardInfoTooltip)
