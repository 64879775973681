/* eslint-disable */
import { createGlobalStyle } from 'styled-components'
import { desktop, mobile, standalone, VIEWPORT_MOBILE } from './media'
import themeLight from './theme-light'
import TechnaSansRegularOpenType from './fonts/TechnaSans-Regular.otf'
import TechnaSansRegularEot from './fonts/TechnaSans-Regular.eot'
import TechnaSansRegularSvg from './fonts/TechnaSans-Regular.svg'
import TechnaSansRegularWoff from './fonts/TechnaSans-Regular.woff'
import TechnaSansRegularWoff2 from './fonts/TechnaSans-Regular.woff2'
import TechnaSansRegularTtf from './fonts/TechnaSans-Regular.ttf'

const GlobalStyle = createGlobalStyle`
	/*!
	 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
	 * Copyright 2011-2018 The Bootstrap Authors
	 * Copyright 2011-2018 Twitter, Inc.
	 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
	 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
	 */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
		outline: 0;
	}

	:root {
		color-scheme: light dark;
	}

	html {
		font-family: sans-serif;
		line-height: 1.15;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-ms-overflow-style: scrollbar;
		-webkit-tap-highlight-color: transparent;
	}

	html, body {
		height: auto;
		min-height: 100%;
	}

	html, body {
		position: relative;
		overflow: hidden;
	}

	#root {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}

	${props => props.viewport === VIEWPORT_MOBILE ? `
		body {
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	` : ''}

	html.offscreen, html.offscreen body {
		padding: 0;
		position: relative;
		overflow: visible;
	}

	html.offscreen #root {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
	}

	html.offscreen #root-modal {
		height: auto;
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
		position: static;
	}

	body > svg {
		display: none;
	}

	svg {
		fill: currentColor;
	}

	@-ms-viewport {
		width: device-width;
	}

	article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
		display: block;
	}

	body {
		background-color: ${props => props.theme.background};
		color: ${props => props.theme.text};
		margin: 0;
		font-family: ${props => props.theme.fontDefault};
		font-size: 1rem;
		font-weight: 400;
		text-align: left;

		${standalone`
			background-color: #2D2D2D;
		`}
	}

	[tabindex="-1"]:focus {
		outline: 0 !important;
	}

	hr {
		box-sizing: content-box;
		height: 0;
		overflow: visible;
	}

	h1, h2, h3, h4 {
		margin: 1.414em 0 0.5em;
		font-weight: inherit;
		line-height: 1.2;
	}

	h1 {
		margin-top: 0;
		font-size: 3.998em;
	}

	h2 {
		font-size: 2.827em;
	}

	h3 {
		font-size: 1.999em;
	}

	h4 {
		font-size: 1.414em;
	}

	small {
		font-size: 0.707em;
	}

	p {
		line-height: 1.45;
		margin-top: 0;
		margin-bottom: 1.3rem;
	}

	abbr[title],
	abbr[data-original-title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		cursor: help;
		border-bottom: 0;
	}

	address {
		margin-bottom: 1rem;
		font-style: normal;
		line-height: inherit;
	}

	ol,
	ul,
	dl {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	ol ol,
	ul ul,
	ol ul,
	ul ol {
		margin-bottom: 0;
	}

	li {
		line-height: 1.45;
	}

	dt {
		font-weight: 700;
	}

	dd {
		margin-bottom: .5rem;
		margin-left: 0;
	}

	blockquote {
		margin: 0 0 1rem;
	}

	dfn {
		font-style: italic;
	}

	b,
	strong {
		font-weight: bolder;
	}

	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}

	sub {
		bottom: -.25em;
	}

	sup {
		top: -.5em;
	}

	a {
		color: ${props => props.theme.text};
		text-decoration: underline solid ${themeLight.primary};
		background-color: transparent;
		-webkit-text-decoration-skip: objects;
	}

	${desktop`
		a:hover {
			color: ${themeLight.primary};
			text-decoration: none;
		}
	`}

	a:not([href]):not([tabindex]):focus {
		outline: 0;
	}

	pre,
	code,
	kbd,
	samp {
		font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		font-size: 1em;
	}

	pre {
		margin-top: 0;
		margin-bottom: 1rem;
		overflow: auto;
		-ms-overflow-style: scrollbar;
	}

	figure {
		margin: 0 0 1rem;
	}

	img {
		vertical-align: middle;
		border-style: none;
	}

	svg:not(:root) {
		overflow: hidden;
	}

	table {
		border-collapse: collapse;
	}

	caption {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		color: #6c757d;
		text-align: left;
		caption-side: bottom;
	}

	th {
		text-align: inherit;
	}

	label {
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	button {
		border-radius: 0;
	}

	input,
	button,
	select,
	optgroup,
	textarea {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}

	button,
	input {
		overflow: visible;
	}

	button,
	select {
		text-transform: none;
	}

	button,
	html [type="button"],
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button;
	}

	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}

	input[type="radio"],
	input[type="checkarchpve"] {
		box-sizing: border-box;
		padding: 0;
	}

	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		-webkit-appearance: listbox;
	}

	textarea {
		overflow: auto;
		resize: vertical;
	}

	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;
	}

	legend {
		display: block;
		width: 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: .5rem;
		font-size: 1.5rem;
		line-height: inherit;
		color: inherit;
		white-space: normal;
	}

	progress {
		vertical-align: baseline;
	}

	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}

	[type="search"] {
		outline-offset: -2px;
		-webkit-appearance: none;
	}

	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
	}

	output {
		display: inline-block;
	}

	summary {
		display: list-item;
		cursor: pointer;
	}

	template {
		display: none;
	}

	[hidden] {
		display: none !important;
	}

	body.disable-hover {
		pointer-events: none;
	}

	@font-face {
		font-family: TechnaSans;
		src: url('${TechnaSansRegularWoff2}') format('woff2'), /* Super Modern Browsers */
		url('${TechnaSansRegularOpenType}') format('opentype'),
		url('${TechnaSansRegularEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('${TechnaSansRegularWoff}') format('woff'), /* Pretty Modern Browsers */
		url('${TechnaSansRegularTtf}') format('truetype'), /* Safari, Android, iOS */
		url('${TechnaSansRegularSvg}#TechnaSans') format('svg'); /* Legacy iOS */
	}

	:target::before {
		content: '';
		display: block;
		height: 80px;
		margin-top: -80px;

		${mobile`
			height: 44px;
			margin-top: -44px;
		`}
	}

	${props => props.theme.id === 'dark' && `
		img {
			filter: brightness(0.9) contrast(1.1);
		}
	`}

	[data-tooltip]:not(a):not(button) {
		position: relative;
	}

	/* Positioning and visibility settings of the tooltip */
	[data-tooltip]:before,
	[data-tooltip]:after {
		position: absolute;
		z-index: 30;
		visibility: hidden;
		opacity: 0;
		left: 50%;
		bottom: calc(100% + 5px); /* 5px is the size of the arrow */
		pointer-events: none;
		transition: 0.15s ease-in-out;
		will-change: transform;
	}

	/* The actual tooltip with a dynamic width */
	[data-tooltip]:before {
		content: attr(data-tooltip);
		padding: 10px 18px;
		min-width: 50px;
		max-width: 300px;
		width: max-content;
		width: -moz-max-content;
		border-radius: 6px;
		font-size: 0.9rem;
		font-weight: 500;
		background-color: ${props => props.theme.tooltipBackground};
		box-shadow: ${props => props.theme.shadowSearch};
		color: #fff;
		text-align: center;
		white-space: pre-wrap;
		transform: translate3d(-50%, 5px, 0);
	}

	/* Tooltip arrow */
	[data-tooltip]:after {
		content: '';
		border-style: solid;
		border-width: 6px 6px 0 6px; /* CSS triangle */
		border-color: ${props => props.theme.tooltipBackground} transparent transparent transparent;
		/* If the mouse leaves the element,
									 the transition effects for the
									 tooltip arrow are "turned off" */
		transform-origin: bottom;
		/* Orientation setting for the
								   slide-down effect */
		transform: translate3d(-5px, 10px, 0);
	}

	/* LEFT */
	[data-tooltip-location="left"]:before,
	[data-tooltip-location="left"]:after {
		left: auto;
		right: calc(100% + 5px);
		bottom: 50%;
	}

	[data-tooltip-location="left"]:after {
		border-width: 6px 0 6px 6px;
		border-color: transparent transparent transparent ${props => props.theme.tooltipBackground};
		transform-origin: left;
		transform: translate3d(5px, 50%, 0);
	}

	[data-tooltip-location="left"]:before {
		transform: translate3d(0, 50%, 0);
	}

	/* RIGHT */
	[data-tooltip-location="right"]:before,
	[data-tooltip-location="right"]:after {
		left: calc(100% + 5px);
		bottom: 50%;
	}

	[data-tooltip-location="right"]:before {
		transform: translate3d(0, 50%, 0);
	}

	[data-tooltip-location="right"]:after {
		border-width: 6px 6px 6px 0px;
		border-color: transparent ${props => props.theme.tooltipBackground} transparent transparent;
		transform: translate3d(-5px, 50%, 0);
	}


	/* BOTTOM */
	[data-tooltip-location="bottom"]:before,
	[data-tooltip-location="bottom"]:after {
		top: calc(100% + 5px);
		bottom: auto;
	}

	[data-tooltip-location="bottom"]:before {
		transform: translate3d(-50%, 0, 0);
	}

	[data-tooltip-location="bottom"]:after {
		border-width: 0 6px 6px 6px;
		border-color: transparent transparent ${props => props.theme.tooltipBackground} transparent;
		transform-origin: bottom;
		transform: translate3d(-50%, -5px, 0);
	}

	${desktop`
		/* Tooltip becomes visible at hover */
		[data-tooltip]:hover:before,
		[data-tooltip]:hover:after {
			visibility: visible;
			opacity: 1;
		}

		/* TOP */
		[data-tooltip-location="top"]:hover:before {
			transform: translate3d(-50%, 0px, 0);
		}

		[data-tooltip-location="top"]:hover:after {
			transform: translate3d(-5px, 5px, 0);
		}

		/* LEFT */
		[data-tooltip-location="left"]:hover:before {
			transform: translate3d(-5px, 50%, 0);
		}

		[data-tooltip-location="left"]:hover:after {
			transform: translate3d(0, 50%, 0);
		}

		/* RIGHT */
		[data-tooltip-location="right"]:hover:before {
			transform: translate3d(5px, 50%, 0);
		}

		[data-tooltip-location="right"]:hover:after {
			transform: translate3d(0, 50%, 0);
		}


		/* BOTTOM */
		[data-tooltip-location="bottom"]:hover:before {
			transform: translate3d(-50%, 5px, 0);
		}

		[data-tooltip-location="bottom"]:hover:after {
			transform: translate3d(-50%, 0, 0);
		}
	`}

	${mobile`
		[data-tooltip]:not([data-tooltip-visible-mobile]):before, [data-tooltip]:not([data-tooltip-visible-mobile]):after {
			display: none;
		}

		/* Tooltip becomes visible at hover */
		[data-tooltip][data-tooltip-visible-mobile="true"]:before,
		[data-tooltip][data-tooltip-visible-mobile="true"]:after {
			visibility: visible;
			opacity: 1;
		}

		/* TOP */
		[data-tooltip-location="top"][data-tooltip-visible-mobile="true"]:before {
			transform: translate3d(-50%, 0px, 0);
		}

		[data-tooltip-location="top"][data-tooltip-visible-mobile="true"]:after {
			transform: translate3d(-5px, 5px, 0);
		}

		/* LEFT */
		[data-tooltip-location="left"][data-tooltip-visible-mobile="true"]:before {
			transform: translate3d(-5px, 50%, 0);
		}

		[data-tooltip-location="left"][data-tooltip-visible-mobile="true"]:after {
			transform: translate3d(0, 50%, 0);
		}

		/* RIGHT */
		[data-tooltip-location="right"][data-tooltip-visible-mobile="true"]:before {
			transform: translate3d(5px, 50%, 0);
		}

		[data-tooltip-location="right"][data-tooltip-visible-mobile="true"]:after {
			transform: translate3d(0, 50%, 0);
		}


		/* BOTTOM */
		[data-tooltip-location="bottom"][data-tooltip-visible-mobile="true"]:before {
			transform: translate3d(-50%, 5px, 0);
		}

		[data-tooltip-location="bottom"][data-tooltip-visible-mobile="true"]:after {
			transform: translate3d(-50%, 0, 0);
		}
	`}
`

export default GlobalStyle
