const themeDefault = {
	id: 'dark',

	fontDefault: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	fontMonospace: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',

	// background colors
	header: 'linear-gradient(to right bottom, #1A1A1A, #1D1D1D)',
	headerMobile: '#2D2D2D',
	background: '#000',
	backgroundLightest: '#121212',
	backgroundVeryLight: '#181818',
	backgroundLight: '#1F1F1F',
	backgroundDark: '#444',
	backgroundSolid: '#666',
	backgroundConcrete: '#777',
	backgroundSemi: 'rgba(50, 50, 50, 0.95)',
	backgroundOverlay: 'rgba(0, 0, 0, 0.85)',
	backgroundHighlight: '#3a300f',
	backgroundDisabled: 'repeating-linear-gradient(-45deg,rgba(100,100,100,0.5),rgba(100,100,100,0.5) 5px,rgba(100,100,100,0.8)5px,rgba(100,100,100,0.8) 10px)',
	backgroundInverted: '#EEE',
	tooltipBackground: 'rgba(40, 40, 40)',
	priceSmall: 'rgba(184, 148, 81, 0.5)',
	priceHigh: 'rgb(184, 148, 81)',
	pricingBackground: '#2a4859',
	pricingBackgroundGradient: 'linear-gradient(#2a4859, #20355b)',
	pricingBackgroundGradient2: 'linear-gradient(#20355b, #2a4859)',

	// effects
	effectActivation: '#095b4c',
	effectCosts: '#805935',
	highlight: '#FFFF00',

	// primary colors
	primary: '#3A86FF',
	primaryHover: '#0767ff',
	primaryLight: '#2c4b7e',
	primaryVeryLight: '#20355b',
	primaryVeryLightHover: '#333',
	primaryGray: '#a1b2c0',
	secondary: '#444',
	secondaryBoring: '#444',

	// text colors
	text: '#EEE',
	textLightened: '#DDD',
	textSecondary: '#AAA',
	textLight: 'rgba(255, 255, 255, 0.66)',
	textSlightlyLight: '#BBB',
	textVeryLight: 'rgba(255, 255, 255, 0.4)',
	textPrimaryVeryLight: '#828A92',
	textPrimaryVeryLightHover: '#9eabb2',
	textTranslucentLight: 'rgba(255, 255, 255, 0.4)',
	white: '#EEE',

	textInverted: '#111',
	textInvertedLight: '#666',

	// shadows
	shadowLight: 'transparent',
	shadowNormal: 'transparent',
	shadowThick: 'transparent',
	shadowSearch: '0 1px 3px rgba(0, 0, 0, 0.5)',
	shadowDropDown: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',

	// input
	inputBorderTop: '#333333',
	inputBorderSides: '#333333',
	inputBorderBottom: '#333333',

	inputBorderHoverTop: '#444444',
	inputBorderHoverSides: '#444444',
	inputBorderHoverBottom: '#444444',

	inputBorderFocus: '#3A86FF',

	inputBackground: '#2A2A2A',
	selectOptionHover: '#1F1F1F',

	// status colors
	red: '#cc1e22',
	redLight: '#780f0f',
	orange: '#ffa400',
	orangeLight: '#9d672f',
	yellow: '#FFCC00',
	yellowLight: '#a39973',
	green: '#499849',
	greenHover: '#145214',
	greenLight: '#0c3a0c',
	greenValid: '#2ab92a',
	greenBright: '#06d66e',

	// accent colors
	bronze: '#CD7F32',
	gold: '#dda93b',
	gold2: '#B89130',
	middleBlueGreen: '#83c5be',
	teal: '#52D1DC',
	blue: '#0353a4',
	purple: '#623CEA',
	purpleToTeal: 'linear-gradient(to right bottom, #B52DFF, 60%, #3CF1FF)',
	black: '#111111',
	silver: '#C0C0C0',
	platinum: '#152e36',
	sage: '#A07178',
	sageLight: '#8d7982',

	cardmarket: '#22438e',
	tcgplayer: '#447ab5',

	// language colors
	languageEn: '#665463',
	languageDe: '#617971',
	languageFr: '#689298',
	languageIt: '#414E72',
	languageEs: '#6C5153',
	languagePt: '#7C6552',
	languageJp: '#895b6c',
	languageKr: '#63476C',

	// Dropdown
	zLayer1: 5,
	zLayer2: 10,

	// Menu
	zLayer3: 15,
	zLayer4: 20,
	zLayer5: 25,

	// Overlay
	zLayer6: 30,
	zLayer7: 35,
	zLayer8: 40,
	zLayer9: 45,

	zLayer10: 50,
	zLayer11: 55,
	zLayer12: 60,
	zLayer13: 65,
	zLayer14: 70
}

export default themeDefault
