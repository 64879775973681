import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import RelativeTime from '../../components/deck/RelativeTime'
import Link from '../../components/router/Link'
import translate from '../../helper/translate'
import {
	CollapseLink,
	Comment, CommentAuthor,
	CommentAvatar,
	CommentBody,
	CommentContent, CommentDeck, CommentDeckName, CommentFooter,
	CommentMeta,
	CommentTime, NeedsApproval, Unread
} from './DeckComment'
import { desktop, mobile } from '../../styles/media'
import CoverImage, { StyledCoverImage } from './components/CoverImage'
import Avatar from '../../components/user/Avatar'
import ProTag from '../../components/general/ProTag'
import Message from '../../components/form/Message'
import communityViolations from '../../data/communityViolations'

class DeckCommentLink extends Component {
	static propTypes = {
		comment: PropTypes.object.isRequired,
		onBeforeNavigate: PropTypes.func,
		profile: PropTypes.bool,
		showUnread: PropTypes.bool,
		t: PropTypes.func.isRequired,
		username: PropTypes.string.isRequired
	}

	static defaultProps = {
		onBeforeNavigate: undefined,
		profile: false,
		showUnread: true
	}

	constructor(props) {
		super(props)

		this.state = {
			collapsed: this.isLong()
		}
	}

	isLong = () => this.props.comment.body && this.props.comment.body.length > 2000

	render() {
		const { comment, onBeforeNavigate, profile, showUnread, t, username } = this.props
		const { collapsed } = this.state

		const isActiveUsersDeck = username === comment.deck.user.username
		const isAuthorsDeck = comment.user && comment.user.username === comment.deck.user.username
		const edited = comment.createdAt !== comment.updatedAt
		const isLong = this.isLong()

		return (
			<StyledComment
				to={`/deck/${comment.deck.slug}/comment/${comment.id}`}
				onBeforeNavigate={onBeforeNavigate}
				as={Link}
			>
				<CommentAvatar>
					{profile ? (
						<CoverImage deck={comment.deck} />
					) : (
						<Avatar avatar={comment.user?.avatar || 'avatar-male'} width={40} />
					)}
				</CommentAvatar>
				<CommentContent>
					<CommentMeta>
						{!profile && comment.user && (
							<CommentAuthor as="span">
								{comment.user.username}
								{comment.user.subscribed === 'pro' && <ProTag title={comment.user.title} />}
							</CommentAuthor>
						)}
						{!profile && !comment.user && (
							<CommentAuthor deleted as="span">
								{t('general.userDeleted')}
							</CommentAuthor>
						)}
						{profile && (
							<CommentDeck>
								{t('general.commentedOnBy', {
									deck: (
										<CommentDeckName>
											{comment.deck.name}
										</CommentDeckName>
									),
									author: (
										<CommentAuthor as="span">
											{comment.deck.user.username}
											{comment.deck.user.subscribed === 'pro' && <ProTag title={comment.deck.user.title} />}
										</CommentAuthor>
									)
								})}
							</CommentDeck>
						)}
						{!profile && !isAuthorsDeck && !isActiveUsersDeck && (
							<CommentDeck>
								&nbsp;{t('general.commentedOn', {
									deck: (
										<CommentDeckName>
											{comment.deck.name}
										</CommentDeckName>
									),
									author: (
										<CommentAuthor as="span">
											{comment.deck.user.username}
											{comment.deck.user.subscribed === 'pro' && <ProTag title={comment.deck.user.title} />}
										</CommentAuthor>
									)
								})}
							</CommentDeck>
						)}
						{!profile && isAuthorsDeck && (
							<CommentDeck>
								&nbsp;{t('general.commentedOnOwnDeck', {
									deck: (
										<CommentDeckName>
											{comment.deck.name}
										</CommentDeckName>
									)
								})}
							</CommentDeck>
						)}
						{!profile && isActiveUsersDeck && (
							<CommentDeck>
								&nbsp;{t('general.commentedOnYourDeck', {
									deck: (
										<CommentDeckName>
											{comment.deck.name}
										</CommentDeckName>
									)
								})}
							</CommentDeck>
						)}

						{showUnread && comment.unread && (
							<Unread><span />{t('general.new')}</Unread>
						)}

						{comment.needsApproval && !comment.approvedAt && (
							<NeedsApproval>{t('general.eventPlayerApprovalPending')}</NeedsApproval>
						)}

						{comment.needsApproval && comment.approvedAt && (
							<NeedsApproval denied>{t('general.commentApprovalDenied')}</NeedsApproval>
						)}
					</CommentMeta>
					<CommentBody>
						{comment.needsApproval && comment.approvedAt && comment.communityViolation && (
							<Message red light>
								{t('general.commentViolatesCommunityGuidelines', { guidelines: <strong>{t('general.communityGuidelines')}</strong> })}
								&nbsp;{t(communityViolations.find(item => item.value === comment.communityViolation).label)}
							</Message>
						)}

						{isLong && collapsed ? comment.body.substring(0, 2000) : comment.body}
						{isLong && collapsed && <CollapseLink onClick={() => this.setState({ collapsed: false })}>{t('general.showMore')}</CollapseLink>}
						{isLong && !collapsed && <CollapseLink onClick={() => this.setState({ collapsed: true })}>{t('general.showLess')}</CollapseLink>}
					</CommentBody>
					<CommentFooter>
						<CommentTime>
							<RelativeTime date={comment.createdAt} />
							{edited && (
								<>
									&nbsp;({t('general.edited')})
								</>
							)}
						</CommentTime>
					</CommentFooter>
				</CommentContent>
			</StyledComment>
		)
	}
}

const StyledComment = styled(Comment)`
	padding: 0.5rem 0;
	text-decoration: none;

	${CommentAvatar} svg {
		border-radius: 50%;
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-right: 1rem;
	}

	${CommentFooter}, ${CommentTime} {
		margin-left: 0;
	}

	${desktop`
		border-radius: 4px;
		margin-left: -0.5rem;
		padding: 0.5rem;
		transition: 0.1s;
		width: calc(100% + 1rem);

		&:hover {
			background: ${props => props.theme.backgroundVeryLight};
			color: ${props => props.theme.text};
		}
	`}

	${mobile`
		${CommentAvatar} {
			${StyledCoverImage}, img, svg {
				height: 24px;
				width: 24px;
				min-width: 24px;
				margin-right: 0.75rem;
			}
		}

		${CommentContent} {
			max-width: calc(100% - 24px - 0.75rem);
		}

		${CommentMeta} {
			max-width: 100%;
		}

		${CommentAuthor}, ${CommentDeck} {
			font-size: 0.8rem;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		username: state.persist.user.username
	})),
	translate('general')
)

export default enhance(DeckCommentLink)
