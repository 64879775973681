const effectPlaces = {
	0: {
		keywords: '"main deck"',
		name_de: 'Main Deck',
		name_en: 'Main Deck'
	},
	1: {
		keywords: 'hand',
		name_de: 'Hand',
		name_en: 'Hand'
	},
	3: {
		keywords: '"extra deck" face-up',
		name_de: 'Extra Deck (offen)',
		name_en: 'Extra Deck (face-up)'
	},
	4: {
		keywords: '"extra deck"',
		name_de: 'Extra Deck',
		name_en: 'Extra Deck'
	},
	5: {
		keywords: 'field',
		name_de: 'Feld',
		name_en: 'Field'
	},
	6: {
		keywords: 'gy OR graveyard',
		name_de: 'Friedhof',
		name_en: 'Graveyard'
	},
	7: {
		keywords: 'banished',
		name_de: 'Verbannte Karten',
		name_en: 'Banished cards'
	},
	8: {
		keywords: 'banished face-down',
		name_de: 'Verbannte Karten (verdeckt)',
		name_en: 'Banished cards (face-down)'
	},
	9: {
		keywords: '"spell trap zone"',
		name_de: 'Zauber- und Fallenkartenzone',
		name_en: 'Spell & Trap Zone'
	},
	10: {
		keywords: '"extra monster zone"',
		name_de: 'Extra-Monsterzone',
		name_en: 'Extra Monster Zone'
	},
	11: {
		keywords: '"main monster zone"',
		name_de: 'Main-Monsterzone',
		name_en: 'Main Monster Zone'
	},
	12: {
		keywords: '"field zone"',
		name_de: 'Feld-Zone',
		name_en: 'Field Zone'
	},
	13: {
		keywords: '"pendulum zone"',
		name_de: 'Pendel-Zone',
		name_en: 'Pendulum Zone'
	},
	14: {
		keywords: '"side of the field"',
		name_de: 'Spielfeldseite',
		name_en: 'Side of the field'
	},
}

export default effectPlaces
