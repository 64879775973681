import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import translate from '../../helper/translate'
import { clearError } from '../../state/actions/app'
import {
	ErrorBackToHome,
	ErrorFullscreen,
	ErrorHeader, ErrorHeaderCode,
} from '../../components/general/ErrorFullscreen'
import { VIEWPORT_DESKTOP } from '../../styles/media'
import { CardSearchPlaceholder } from '../Loading/SiteLoading'
import { domains, maintenanceEnd, maintenanceStart, translationsMapping } from '../../../config'
import Button from '../../components/button/Button'
import { logout } from '../../state/actionCreators/app'
import history from '../../helper/history'
import { time } from '../../helper/date'

class PageErrorFallback extends Component {
	static propTypes = {
		cardSearchCollapsed: PropTypes.bool.isRequired,
		clearError: PropTypes.func.isRequired,
		closeable: PropTypes.bool,
		error: PropTypes.string,
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		logout: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		userLanguage: PropTypes.string.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		closeable: false,
		error: null
	}

	errors = {
		offline: {
			title: this.props.t('general.siteErrorOffline'),
			message: this.props.t('general.siteErrorOfflineMessage')
		},
		timeout: {
			title: this.props.t('general.siteErrorTimeout'),
			message: this.props.t('general.siteErrorTimeoutMessage')
		},
		serverError: {
			title: this.props.t('general.siteErrorServerError'),
			message: this.props.t('general.siteErrorServerErrorMessage')
		},
		javascriptError: {
			title: this.props.t('general.siteErrorJavascriptError'),
			message: this.props.t('general.siteErrorJavascriptErrorMessage')
		},
		forbidden: {
			title: this.props.t('general.siteErrorForbidden'),
			message: this.props.t('general.siteErrorForbiddenMessage')
		},
		unauthorized: {
			title: this.props.t('general.siteErrorUnauthorized'),
			message: this.props.t('general.siteErrorUnauthorizedMessage')
		},
		maintenance: {
			title: this.props.t('general.siteErrorMaintenance'),
			message: this.props.t('general.siteErrorMaintenanceMessage')
		}
	}

	componentDidMount() {
		this.props.history.listen(() => this.props.clearError())
	}

	render() {
		const { cardSearchCollapsed, closeable, error, location, logout, t, userLanguage, viewport } = this.props

		const isCollection = location.pathname.startsWith('/collection/')
		const isEditingDeck = location.pathname.startsWith('/deck-builder/')
		const isCardSearch = location.pathname.startsWith('/cards')

		const domain = domains[translationsMapping[userLanguage]]

		return (
			<ErrorFullscreen closeable={closeable} isCollection={isCollection} isEditingDeck={isEditingDeck} isCardSearch={isCardSearch}>
				<Helmet>
					<title>{t('general.javascriptErrorTitle')}</title>
				</Helmet>

				{isEditingDeck && !cardSearchCollapsed && viewport === VIEWPORT_DESKTOP && <CardSearchPlaceholder />}

				<ErrorHeader>
					<ErrorHeaderCode>{this.errors[error || 'javascriptError'].title}</ErrorHeaderCode>
					{this.errors[error || 'javascriptError'].message}
				</ErrorHeader>

				{error === 'maintenance' && (
					<MaintenanceWindow>{t('general.siteErrorMaintenanceWindow', { start: time(maintenanceStart), end: time(maintenanceEnd) })}</MaintenanceWindow>
				)}

				{error === 'unauthorized' && (
					<ErrorButton>
						<Button
							onClick={() => {
								logout(t, true)
								history.push('/login')
							}}
						>
							{t('general.login')}
						</Button>
					</ErrorButton>
				)}

				<ErrorBackToHome external to={`mailto:support@${domain}`}>{t('general.contactSupport')}</ErrorBackToHome>
			</ErrorFullscreen>
		)
	}
}

const ErrorButton = styled.div`
	margin-top: 1rem;
`

const MaintenanceWindow = styled.div`
	color: ${props => props.theme.text};
	font-weight: 500;
	line-height: 1.4;
	margin-top: 1rem;
`

const mapStateToProps = state => ({
	cardSearchCollapsed: state.persist.cardSearchCollapsed,
	error: state.app.siteError,
	userLanguage: state.persist.language,
	viewport: state.app.viewport
})

const mapDispatchToProps = dispatch => bindActionCreators({
	clearError,
	logout
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate('general'),
	withRouter
)

export default enhance(PageErrorFallback)
