const monsterTypes = {
	1: {
		name_en: 'Aqua',
		name_de: 'Aqua',
		name_fr: 'Aqua',
		name_it: 'Acqua',
		name_es: 'Aqua',
		name_pt: 'Aqua',
		name_jp: '水族'
	},
	2: {
		name_en: 'Beast',
		name_de: 'Ungeheuer',
		name_fr: 'Bête',
		name_it: 'Bestia',
		name_es: 'Bestia',
		name_pt: 'Besta',
		name_jp: '獣族'
	},
	3: {
		name_en: 'Beast-Warrior',
		name_de: 'Ungeheuer-Krieger',
		name_fr: 'Bête-Guerrier',
		name_it: 'Guerriero-Bestia',
		name_es: 'Guerrero-Bestia',
		name_pt: 'Besta-Guerreira',
		name_jp: '獣戦士族'
	},
	4: {
		name_en: 'Cyberse',
		name_de: 'Cyberse',
		name_fr: 'Cyberse',
		name_it: 'Cyberse',
		name_es: 'Ciberso',
		name_pt: 'Ciberso',
		name_jp: 'サイバース族',
		noIcon: true
	},
	5: {
		name_en: 'Dinosaur',
		name_de: 'Dinosaurier',
		name_fr: 'Dinosaure',
		name_it: 'Dinosauro',
		name_es: 'Dinosaurio',
		name_pt: 'Dinossauro',
		name_jp: '恐竜族'
	},
	6: {
		name_en: 'Divine-Beast',
		name_de: 'Göttliches Ungeheuer',
		name_fr: 'Bête Divine',
		name_it: 'Divinità-Bestia',
		name_es: 'Bestia Divina',
		name_pt: 'Besta Divina',
		name_jp: '幻神獣族'
	},
	7: {
		name_en: 'Dragon',
		name_de: 'Drache',
		name_fr: 'Dragon',
		name_it: 'Drago',
		name_es: 'Dragón',
		name_pt: 'Dragão',
		name_jp: 'ドラゴン族'
	},
	8: {
		name_en: 'Fairy',
		name_de: 'Fee',
		name_fr: 'Elfe',
		name_it: 'Fata',
		name_es: 'Hada',
		name_pt: 'Fada',
		name_jp: '天使族'
	},
	9: {
		name_en: 'Fiend',
		name_de: 'Unterweltler',
		name_fr: 'Démon',
		name_it: 'Demone',
		name_es: 'Demonio',
		name_pt: 'Demônio',
		name_jp: '悪魔族'
	},
	10: {
		name_en: 'Fish',
		name_de: 'Fisch',
		name_fr: 'Poisson',
		name_it: 'Pesce',
		name_es: 'Pez',
		name_pt: 'Peixe',
		name_jp: '魚族'
	},
	11: {
		name_en: 'Insect',
		name_de: 'Insekt',
		name_fr: 'Insecte',
		name_it: 'Insetto',
		name_es: 'Insecto',
		name_pt: 'Inseto',
		name_jp: '昆虫族'
	},
	12: {
		name_en: 'Machine',
		name_de: 'Maschine',
		name_fr: 'Machine',
		name_it: 'Macchina',
		name_es: 'Máquina',
		name_pt: 'Máquina',
		name_jp: '機械族'
	},
	13: {
		name_en: 'Plant',
		name_de: 'Pflanze',
		name_fr: 'Plante',
		name_it: 'Pianta',
		name_es: 'Planta',
		name_pt: 'Planta',
		name_jp: '植物族'
	},
	14: {
		name_en: 'Psychic',
		name_de: 'Psi',
		name_fr: 'Psychique',
		name_it: 'Psichico',
		name_es: 'Psíquico',
		name_pt: 'Psíquico',
		name_jp: 'サイキック族'
	},
	15: {
		name_en: 'Reptile',
		name_de: 'Reptil',
		name_fr: 'Reptile',
		name_it: 'Rettile',
		name_es: 'Reptil',
		name_pt: 'Réptil',
		name_jp: '爬虫類族'
	},
	16: {
		name_en: 'Rock',
		name_de: 'Fels',
		name_fr: 'Rocher',
		name_it: 'Roccia',
		name_es: 'Roca',
		name_pt: 'Rocha',
		name_jp: '岩石族'
	},
	17: {
		name_en: 'Sea Serpent',
		name_de: 'Seeschlange',
		name_fr: 'Serpent de Mer',
		name_it: 'Serpente Marino',
		name_es: 'Serpiente Marina',
		name_pt: 'Serpente Marinha',
		name_jp: '海竜族'
	},
	18: {
		name_en: 'Spellcaster',
		name_de: 'Hexer',
		name_fr: 'Magicien',
		name_it: 'Incantatore',
		name_es: 'Lanzador de Conjuros',
		name_pt: 'Mago',
		name_jp: '魔法使い族'
	},
	19: {
		name_en: 'Thunder',
		name_de: 'Donner',
		name_fr: 'Tonnerre',
		name_it: 'Tuono',
		name_es: 'Trueno',
		name_pt: 'Trovão',
		name_jp: '雷族'
	},
	20: {
		name_en: 'Warrior',
		name_de: 'Krieger',
		name_fr: 'Guerrier',
		name_it: 'Guerriero',
		name_es: 'Guerrero',
		name_pt: 'Guerreiro',
		name_jp: '戦士族'
	},
	21: {
		name_en: 'Winged Beast',
		name_de: 'Geflügeltes Ungeheuer',
		name_fr: 'Bête Ailée',
		name_it: 'Bestia Alata',
		name_es: 'Bestia Alada',
		name_pt: 'Besta Alada',
		name_jp: '鳥獣族'
	},
	22: {
		name_en: 'Zombie',
		name_de: 'Zombie',
		name_fr: 'Zombie',
		name_it: 'Zombie',
		name_es: 'Zombi',
		name_pt: 'Zumbi',
		name_jp: 'アンデット族'
	},
	23: {
		name_en: 'Wyrm',
		name_de: 'Wyrm',
		name_fr: 'Wyrm',
		name_it: 'Wyrm',
		name_es: 'Wyrm',
		name_pt: 'Wyrm',
		name_jp: '幻竜族',
		noIcon: true
	},
	24: {
		name_en: 'Creator God',
		name_de: 'Creator God',
		name_fr: 'Creator God',
		name_it: 'Creator God',
		name_es: 'Creator God',
		name_pt: 'Creator God',
		name_jp: '創造神族',
		noIcon: true
	},
	25: {
		name_en: 'Pyro',
		name_de: 'Pyro',
		name_fr: 'Pyro',
		name_it: 'Pyro',
		name_es: 'Piro',
		name_pt: 'Piro',
		name_jp: '炎族'
	},
	26: {
		name_en: 'Illusion',
		name_de: 'Illusion',
		name_fr: 'Illusion',
		name_it: 'Illusion',
		name_es: 'Illusion',
		name_pt: 'Illusion',
		name_jp: '',
		noIcon: true
	}
}

export default monsterTypes
