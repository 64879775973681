import {
	START_SESSION,
	RESET_SESSION,
	HANDLE_COLLECTION_CARD_ADDED,
	SET_SESSION_RETURN_LINK,
	START_AUTO_ADD,
	INCREASE_AUTO_ADD_INDEX,
	END_AUTO_ADD,
	INCREASE_DIRTY_ROWS,
	SET_END_SESSION_LOADING,
	SET_ADD_ALL_BATCH_PRINT_IDS, QUEUE_COLLECTION_STATS_UPDATE
} from '../actions/collection'

const initialState = {
	addedPrice: null,
	addedQuantity: null,
	dirtyRows: null,
	updatedCollectionSlugs: null,
	sessionReturnLink: null,
	endSessionLoading: false,

	addAllLoading: false,
	addAllBatchPrintIds: null,
	addAllBatchSuccess: null,
	autoAddIndex: null,
	autoAddGroup: null,
	autoAddTotal: null
}

const collection = (state = initialState, action) => {
	switch (action.type) {
		case START_SESSION: {
			const { sessionReturnLink } = action

			return {
				...state,
				addedPrice: 0,
				addedQuantity: 0,
				dirtyRows: 0,
				updatedCollectionSlugs: [],
				sessionReturnLink
			}
		}
		case SET_SESSION_RETURN_LINK: {
			const { sessionReturnLink } = action

			return {
				...state,
				sessionReturnLink
			}
		}
		case INCREASE_DIRTY_ROWS: {
			return {
				...state,
				dirtyRows: (state.dirtyRows || 0) + 1
			}
		}
		case HANDLE_COLLECTION_CARD_ADDED: {
			const { quantity, price } = action

			return {
				...state,
				addedPrice: (state.addedPrice || 0) + quantity * price,
				addedQuantity: (state.addedQuantity || 0) + quantity
			}
		}
		case QUEUE_COLLECTION_STATS_UPDATE: {
			const { collectionSlug } = action

			return {
				...state,
				updatedCollectionSlugs: state.updatedCollectionSlugs && state.updatedCollectionSlugs.indexOf(collectionSlug) === -1 ? [
					...state.updatedCollectionSlugs,
					collectionSlug
				] : [
					collectionSlug
				]
			}
		}
		case RESET_SESSION: {
			return initialState
		}
		case SET_END_SESSION_LOADING: {
			return {
				...state,
				endSessionLoading: true
			}
		}
		case START_AUTO_ADD: {
			const { autoAddGroup } = action

			return {
				...state,
				addAllLoading: true,
				autoAddIndex: 0,
				autoAddGroup
			}
		}
		case INCREASE_AUTO_ADD_INDEX: {
			return {
				...state,
				autoAddIndex: (state.autoAddIndex || 0) + 1
			}
		}
		case SET_ADD_ALL_BATCH_PRINT_IDS: {
			const { addAllBatchPrintIds } = action

			return {
				...state,
				addAllBatchPrintIds,
				autoAddTotal: addAllBatchPrintIds.length
			}
		}
		case END_AUTO_ADD: {
			const { addAllBatchSuccess } = action

			return {
				...state,
				addAllLoading: false,
				autoAddIndex: null,
				autoAddGroup: null,
				addAllBatchPrintIds: null,
				addAllBatchSuccess,
				autoAddTotal: null
			}
		}
		default:
			return state
	}
}

export default collection
