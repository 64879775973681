import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import formats from '../../data/formats'

const FormatName = ({ abbr, format }) => {
	const data = formats[format]

	return data ? (
		<StyledFormatName color={data.color}>
			{abbr ? data.abbr : data.name}
		</StyledFormatName>
	) : null
}

FormatName.propTypes = {
	abbr: PropTypes.bool,
	format: PropTypes.number.isRequired
}

FormatName.defaultProps = {
	abbr: false
}

export const StyledFormatName = styled.span`
	color: ${props => props.color};
	font-weight: bold;
`

export default FormatName
