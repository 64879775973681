import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import logoImage from '../../images/logo/cardcluster-white.svg'

class Logo extends PureComponent {
	static propTypes = {
		loading: PropTypes.bool
	}

	static defaultProps = {
		loading: null
	}

	state = {
		showSpinner: false
	}

	componentDidUpdate(prevProps) {
		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				clearTimeout(this.loadingTimeout)

				this.setState({
					showSpinner: false
				})
			} else {
				this.loadingTimeout = window.setTimeout(() => {
					this.setState({
						showSpinner: true
					})
				}, 1000)
			}
		}
	}

	render() {
		const { showSpinner } = this.state

		return (
			<Wrapper size="30">
				{showSpinner && (
					<Loading>
						<svg height="40" width="40">
							<circle cx="20" cy="20" r="19" strokeWidth="2" fill="none" />
						</svg>
					</Loading>
				)}
				<img src={logoImage} alt="cardcluster" />
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	position: relative;
	min-width: 155px;

	img {
		height: ${props => props.size}px;
	}
`

const dash = keyframes`
	50% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: -270;
	}
`

const Loading = styled.div`
	position: absolute;
	left: -1px;
	top: -4px;

	transform: rotate(-90deg);
	stroke: white;

	circle {
		stroke-dasharray: 270;
		stroke-dashoffset: 270;
		animation: ${dash} 2s infinite;
	}
`

export default Logo
