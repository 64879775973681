import PropTypes from 'prop-types'
import { relativeTime } from '../../helper/date'
import translate from '../../helper/translate'

const RelativeTime = ({ date, t }) => {
	return relativeTime(date, t)
}

RelativeTime.propTypes = {
	date: PropTypes.any.isRequired,
	t: PropTypes.func.isRequired
}

const enhance = translate('general')

export default enhance(RelativeTime)
