import styled from 'styled-components'
import { desktop, mobile } from '../../styles/media'
import Link, { StyledLink } from '../router/Link'

export const ErrorFullscreen = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;

	background: ${props => props.theme.id === 'dark' ? props.theme.background : props.theme.backgroundLight};
	color: ${props => props.theme.textLight};
	font-size: 1.2rem;
	font-family: ${props => props.theme.fontDefault};
	min-height: calc(100vh - 44px);
	min-height: calc(var(--vh, 1vh) * 100 - 44px);
	padding: 2rem;
	text-align: center;

	${desktop`
		${props => !(props.isEditingDeck || props.isCardSearch) && `
			min-height: calc(100vh - 80px);
			min-height: calc(var(--vh, 1vh) * 100 - 80px);
			margin-top: -1.5rem;
			margin-bottom: -5rem;
		`}
	`}

	${mobile`
		font-size: 1rem;
		min-height: calc(100vh - 94px);
		min-height: calc(var(--vh, 1vh) * 100) - 94px);
		padding: 1rem;
	`}

	${props => (props.isEditingDeck || props.isCardSearch) && `
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
	`}

	${StyledLink} {
		font-size: 1rem;
	}
`

export const ErrorHeader = styled.div`
	color: ${props => props.theme.textLight};
	font-size: 1.6rem;

	align-items: center;
	display: flex;
	flex-direction: column;

	${mobile`
		font-size: 1.2rem;
	`}
`

export const ErrorHeaderCode = styled.div`
	font-weight: 600;
	margin-bottom: 0.5rem;
`

export const ErrorBackToHome = styled(Link)`
	margin-top: 2rem;
`
