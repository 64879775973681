export const install = (event) => {
	event.prompt()
}

export const uninstallAllServiceWorkers = () => {
	if ('serviceWorker' in navigator) {
		window.navigator.serviceWorker.getRegistrations().then(registrations => registrations.forEach((registration) => {
			registration.unregister()
		})).catch(() => {})
	}
}
