import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import replace from 'react-string-replace'
import Name from './Name'
import Flag from '../../general/Flag'
import translate from '../../../helper/translate'
import { day, month } from '../../../helper/date'
import Icon from '../../general/Icon'

const EventName = ({ country, date, dateApproximately, decklists, name, needsApproval, participants, suffix, t, type }) => (
	<StyledEventName>
		{type === 'in-person' && <Icon name="user-group" />}
		{type === 'remote' && <Icon name="globe" />}
		{type === 'online' && <Icon name="desktop-computer" />}
		<Inner>
			<Upper>
				{country && <Flag country={country} />}
				<span>{suffix && suffix !== name && name.endsWith(suffix) ? replace(name, suffix, () => <Suffix key={suffix}>{suffix}</Suffix>) : name}</span>
				{needsApproval && <NeedsApproval>&nbsp;(nicht freigeschaltet)</NeedsApproval>}
			</Upper>
			<Lower>
				{date ? (dateApproximately ? month(date) : day(date)) : null}
				{participants && (
					<>
						&nbsp;&middot; {participants} {t('general.participants')}
					</>
				)}
				{decklists && (
					<>
						&nbsp;&middot; {decklists} {t('general.decklist', { count: decklists })}
					</>
				)}
			</Lower>
		</Inner>
	</StyledEventName>
)

EventName.propTypes = {
	country: PropTypes.string,
	date: PropTypes.string,
	dateApproximately: PropTypes.bool,
	decklists: PropTypes.number,
	name: PropTypes.string.isRequired,
	needsApproval: PropTypes.bool,
	participants: PropTypes.number,
	suffix: PropTypes.string,
	t: PropTypes.func.isRequired,
	type: PropTypes.string
}

EventName.defaultProps = {
	country: null,
	date: null,
	dateApproximately: false,
	decklists: null,
	needsApproval: false,
	participants: null,
	suffix: null,
	type: null
}

export const StyledEventName = styled(Name)`
	display: flex;

	> svg {
		color: ${props => props.theme.textLight};
		height: 24px;
		width: 24px;
		min-width: 24px;
		margin-right: 0.35rem;
	}
`

const Inner = styled.div`
	display: flex;
	flex-direction: column;
`

const Upper = styled.div`
	align-items: center;
	display: flex;
	font-weight: 500;

	> img {
		height: 14px;
		width: 14px;
		margin-right: 0.35rem;
	}
`

const Lower = styled.div`
	color: ${props => props.theme.textLight};
	display: flex;
	font-size: 0.9rem;
	font-weight: 400;
	margin-top: 0.15rem
`

const Suffix = styled.span`
	color: ${props => props.theme.textLight};
	font-weight: 400;
`

const NeedsApproval = styled.span`
	color: ${props => props.theme.textLight};
`

const enhance = translate('general')

export default enhance(EventName)
