import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const Icon = ({ color, name, onClick, size, spinner, top }) => (
	<StyledIcon onClick={onClick} color={color} size={size} spinner={spinner} top={top}>
		<use xlinkHref={`#icon-${name}`} />
	</StyledIcon>
)

Icon.propTypes = {
	color: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	size: PropTypes.number,
	spinner: PropTypes.bool,
	top: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	])
}

Icon.defaultProps = {
	color: '',
	onClick: () => null,
	size: 20,
	spinner: false,
	top: '0'
}

const rotating = keyframes`
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
`

const rotatingAnimation = css`
	animation: ${rotating} 0.6s linear infinite;
`

export const StyledIcon = styled.svg`
	${props => props.color && `color: ${props.color};`}
	height: ${props => props.size}px;
	width: ${props => props.size}px;

	position: relative;
	top: ${props => props.top}px;

	${props => props.spinner && rotatingAnimation}
`

export default Icon
