import styled from 'styled-components'

const UnsavedChanges = styled.div`
	background: ${props => props.theme.primary};
	border-radius: 50%;
	display: inline-block;

	transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
	opacity: ${props => props.visible ? 1 : 0};
	transform: ${props => props.visible ? 'scale(1)' : 'scale(0.85)'};

	margin-left: 0.35rem;
	height: 10px;
	width: 10px;
`

export default UnsavedChanges
