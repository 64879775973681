import styled from 'styled-components'
import { StyledCoverImage } from '../../pages/deck/components/CoverImage'
import { StyledUnread } from '../general/Unread'

const MenuLink = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
	border: 0;
	color: ${props => props.theme.backgroundSolid};
	font-weight: 400;
	font-size: 1.2rem;
	padding: 0 0.7rem;
	text-decoration: none;
	white-space: nowrap;

	position: relative;

	${StyledUnread} {
		position: absolute;
		right: 0.35rem;
		top: 0.35rem;
		height: 15px;
		width: 15px;
		min-width: 15px;
		font-size: 0.6rem;
	}

	${props => props.main && `
		font-size: 1rem;
		height: 80px;
		padding: 0 0.5rem;
		transition: color 0.1s ease-out;
		white-space: nowrap;

		&:hover {
			background: rgba(0, 0, 0, 0.2);
			color: #FFF;
			cursor: pointer;
			opacity: 1;
		}
	`}

	${props => props.bold && `
		color: #FFF;
		font-weight: 600;
		opacity: 1;

		&::after {
			content: '→';
			display: inline-block;
			margin-left: 0.25rem;
		}
	`}

	${props => (props.red || props.yellow) && `
		border-radius: 4px;
		margin-right: 4px;
		padding: 0 0.6rem;
		height: 36px !important;
		width: 36px !important;
		transition: 0.2s;
	`}

	${props => props.red && `
		background: rgba(204, 30, 34, 0.5);

		&:active {
			background: rgba(204, 30, 34, 1);
		}
	`}

	${props => props.yellow && `
		background: rgba(255, 143, 0, 0.5);

		&:active {
			background: rgba(255, 143, 0, 1);
		}
	`}

	&:first-child {
		margin-left: 0;
	}

	& > span:not(${StyledUnread}) {
		margin-left: 1.3rem;
	}

	${StyledUnread} {
		margin-left: auto;
	}

	${StyledCoverImage} {
		margin-right: 0.5rem;
	}

	em {
		color: ${props => props.theme.orange};
		font-size: 1rem;
		font-weight: 600;
		margin-left: 0.35rem;
	}

	${props => props.avatar && `
		padding: 0;

		img, svg {
			border-radius: 50%;
			height: 25px;
			width: 25px;

			${!props.authToken ? `
				filter: grayscale(100%);
				-webkit-transform: translateZ(0);
				-webkit-perspective: 1000;
				-webkit-backface-visibility: hidden;
			` : `
				background: rgba(0, 0, 0, 0.5);
			`}
		}
	`}
`

export default MenuLink
