export const OPTIMISTIC_UPDATE = 'OPTIMISTIC_UPDATE'
export const COMMIT = 'COMMIT'
export const ROLLBACK = 'ROLLBACK'
export const STRAIGHT = 'STRAIGHT'

export const updateDeck = (state, action, deckSlug, loadingId, data) => {
	// prioritize opened deck
	const deck = state.deck && state.deck.slug === deckSlug ? state.deck : state.decks.find(item => item.slug === deckSlug)

	const update = {
		...deck,
		updatedAt: new Date(),
		...Object.entries(data).reduce((accumulator, [key, value]) => {
			if (action === OPTIMISTIC_UPDATE || action === STRAIGHT) {
				accumulator[key] = value
			} else if (action === ROLLBACK) {
				accumulator[key] = deck[`${key}BeforeUpdate`]
			}

			accumulator[`${key}BeforeUpdate`] = action === OPTIMISTIC_UPDATE ? deck[key] : undefined
			accumulator[`${key}LoadingId`] = action === OPTIMISTIC_UPDATE ? loadingId : undefined
			accumulator.updateIndicator = new Date().getTime()

			return accumulator
		}, {})
	}

	return {
		...state,
		deck: state.deck && state.deck.slug === deckSlug ? update : state.deck
	}
}
