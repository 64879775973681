import styled from 'styled-components'
import Link from '../router/Link'
import { desktop } from '../../styles/media'

const CommunityButton = styled(Link)`
	align-items: center;
	display: flex;
	justify-content: space-evenly;

	border-radius: 4px;
	color: ${props => props.theme.textVeryLight};
	cursor: default;
	font-size: 0.8rem;
	font-weight: 500;
	padding: 0.5rem;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;

	svg {
		color: ${props => props.theme.backgroundSolid};
		transform: scale(0.9);
		transition: transform 0.1s;
	}

	span {
		margin-left: 0.5rem;
	}

	${props => props.right && `
		margin-left: auto;
	`}

	${desktop`
		&:hover {
			color: ${props => props.theme.textVeryLight};
			cursor: pointer;
		}

		${props => props.disabled && `
			cursor: default !important;
		`}

		${props => !props.active && !props.disabled && `
			&:hover {
				background: ${props.theme.backgroundVeryLight};

				svg {
					transform: scale(1);

					${props.green ? `
						color: ${props.theme.green} !important;
					` : ''}

					${props.red ? `
						color: ${props.theme.red} !important;
					` : ''}

					${props.yellow ? `
						color: ${props.theme.yellow} !important;
					` : ''}
				}
			}
		`}
	`}

	${props => props.active && `
		font-weight: bold;

		svg {
			transform: scale(1);
		}
	`}

	${props => props.yellow && `
		justify-content: center;
	`}

	${props => props.active && props.green && `
		${!props.light && `
			background: ${props.theme.greenLight};
		`}

		&, svg {
			color: ${props.theme.green} !important;
		}
	`}

	${props => props.active && props.red && `
		${!props.light && `
			background: ${props.theme.redLight};
		`}

		&, svg {
			color: ${props.theme.red} !important;
		}
	`}

	${props => props.active && props.yellow && `
		${!props.light && `
			background: ${props.theme.yellowLight};
		`}

		&, svg {
			color: ${props.theme.yellow} !important;
		}
	`}
`

export default CommunityButton
