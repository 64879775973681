import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers/index'
import migrations from './migrations'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['persist'],
	version: 1,
	migrate: createMigrate(migrations, { debug: false }),
	stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
	persistedReducer,
	composeEnhancers(
		applyMiddleware(thunk)
	)
)

export const persistor = persistStore(store)

export default store
