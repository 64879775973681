import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import translate from '../helper/translate'
import Button from '../components/button/Button'
import Modal from '../components/router/Modal'
import history from '../helper/history'
import H3 from '../components/general/H3'

class UpdateAvailable extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired,
		onCancel: PropTypes.func.isRequired,
		pathname: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired
	}

	close = () => {
		const { onCancel } = this.props

		onCancel()
		history.goBack(-1)
	}

	success = () => {
		window.location.reload()
	}

	render() {
		const { location, pathname, t } = this.props

		return (
			<Modal center path={pathname} onClickAway={this.close}>
				<ConfirmText>
					<H3>{t('general.updateAvailableHeader')}</H3>

					<p>{t('general.updateAvailableDescription')}</p>
				</ConfirmText>

				<ConfirmButtons>
					<Button
						onClick={this.success}
					>
						{t('general.updateAvailableInstall')}
					</Button>
					<Button
						secondary
						onClick={this.close}
					>
						{t('general.cancel')}
					</Button>
				</ConfirmButtons>
			</Modal>
		)
	}
}

const ConfirmText = styled.div`
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 2rem;

	${H3} {
		text-align: center;
	}
`

const ConfirmButtons = styled.div`
	display: flex;
	justify-content: center;
`

const enhance = compose(
	translate('general'),
	withRouter
)

export default enhance(UpdateAvailable)
