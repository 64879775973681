import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../general/Icon'
import translate from '../../helper/translate'
import Link from '../router/Link'

const TranslateWithDeepL = ({ loading, onTranslate, t, translated }) => (
	<StyledTranslateWithDeepL>
		{translated && (
			<TranslatedWithInfo>
				{t('general.translatedWith')} DeepL
			</TranslatedWithInfo>
		)}
		<TranslateButton onClick={onTranslate} loading={loading}>
			<Icon name={translated ? 'reply' : 'translate'} />
			<span>{translated ? t('general.showOriginal') : t('general.translate')}</span>
		</TranslateButton>
	</StyledTranslateWithDeepL>
)

TranslateWithDeepL.propTypes = {
	loading: PropTypes.bool.isRequired,
	onTranslate: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	translated: PropTypes.bool.isRequired
}

export const StyledTranslateWithDeepL = styled.div`
	align-items: center;
	display: flex;

	margin-top: 0.5rem;
`

const TranslatedWithInfo = styled.div`
	background: ${props => props.theme.yellowLight};
	border-radius: 2px;
	color: ${props => props.theme.textLight};
	font-size: 0.7rem;
	font-weight: 500;
	height: 24px;
	margin-right: 0.35rem;
	padding: 0 0.5rem;
	white-space: nowrap;

	align-items: center;
	display: flex;
`

const TranslateButton = styled(Link)`
	align-items: center;
	display: flex;

	color: ${props => props.theme.textLight};
	font-weight: 600;
	font-size: 0.8rem;
	text-decoration: none;
	transition: 0.1s;

	height: 24px;

	svg {
		height: 16px;
		width: 16px;
		margin-right: 0.35rem;
	}

	${props => props.loading && `
		pointer-events: none;
		opacity: 0.5;
	`}
`

const enhance = translate('general')

export default enhance(TranslateWithDeepL)
