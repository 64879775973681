const references = {
	// Collection Manager
	WELCOME: 'WELCOME',

	PRODUCT_PAGE: 'PRODUCT_PAGE',
	PRICING_PAGE: 'PRICING_PAGE',
	COLLECTION_TAB: 'COLLECTION_TAB',
	ADD_CARDS_BUTTON: 'ADD_CARDS_BUTTON',
	USER_MENU: 'USER_MENU',
	FOOTER: 'FOOTER',

	CARD_SEARCH: 'CARD_SEARCH',
	PRINT_LIST_HEADER: 'PRINT_LIST_HEADER',
	PRINT_LIST_PRICE_DIFF: 'PRINT_LIST_PRICE_DIFF',

	// Deck Builder
	MY_DECKS_TILE_VIEW: 'MY_DECKS_TILE_VIEW',
	DECK_TILE_VIEW: 'DECK_TILE_VIEW',
	DECK_TEMPLATES: 'DECK_TEMPLATES',
	DECK_COVER_CARD: 'DECK_COVER_CARD',
	DECK_TESTER: 'DECK_TESTER',
	DECK_CHECK_LIST: 'DECK_CHECK_LIST',
	PRIVATE_DECKS: 'PRIVATE_DECKS',
	DECK_WALLPAPER: 'DECK_WALLPAPER',
	DECK_WATCH_LIST: 'DECK_WATCH_LIST',
	DECK_CUSTOM_TAGS: 'DECK_CUSTOM_TAGS',
	DECK_COLLECTION_STATUS: 'DECK_COLLECTION_STATUS',

	// Settings
	SETTINGS_COLLECTION: 'SETTINGS_COLLECTION',
	SETTINGS_DECK_BUILDER: 'SETTINGS_DECK_BUILDER'
}

export default references
