import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import ProTag from './ProTag'
import Link from '../router/Link'
import translate from '../../helper/translate'
import { setReference } from '../../state/actions/app'

const ProFeature = ({ reference, setReference, t }) => (
	<StyledProFeature to="/pricing" onBeforeNavigate={() => setReference(reference)}>
		<ProTag />{t('general.proFeature')}
	</StyledProFeature>
)

ProFeature.propTypes = {
	reference: PropTypes.string,
	setReference: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

ProFeature.defaultProps = {
	reference: null
}

export const StyledProFeature = styled(Link)`
	font-size: 1rem;
	font-weight: 500;
	margin-left: 0.5rem;
	text-decoration: none;
`

const enhance = compose(
	connect(null, dispatch => bindActionCreators({
		setReference
	}, dispatch)),
	translate('general')
)

export default enhance(ProFeature)
