import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { forceVisible } from 'react-lazyload'
import translate from '../../helper/translate'
import {
	saveDeck, sortMainDeckCardsByType,
	starDeck
} from '../../state/actionCreators/deckbuilder'
import {
	discardChanges,
	setDeck,
	setDeckAdditional,
	unshelveDeck
} from '../../state/actions/deckbuilder'
import { showLoading } from '../../state/actionCreators/app'
import { setConfirm, setHoverCard } from '../../state/actions/app'
import { get } from '../../helper/api'
import { NotFound } from '../imports'
import Marker from '../../components/deck/Marker'
import history from '../../helper/history'
import { VIEWPORT_MOBILE } from '../../styles/media'

class DeckLoader extends Component {
	static propTypes = {
		deck: PropTypes.object,
		discardChanges: PropTypes.func.isRequired,
		location: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		saveDeck: PropTypes.func.isRequired,
		setDeck: PropTypes.func.isRequired,
		setDeckAdditional: PropTypes.func.isRequired,
		setConfirm: PropTypes.func.isRequired,
		setHoverCard: PropTypes.func.isRequired,
		shelvedDeck: PropTypes.object,
		showLoading: PropTypes.func.isRequired,
		sortMainDeckCardsByType: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		unshelveDeck: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		deck: null,
		shelvedDeck: null
	}

	state = {
		has404: false
	}

	componentDidMount() {
		const { deck, match } = this.props

		if (!deck || match.params.slug !== deck.slug) {
			this.loadSafely()
		}
	}

	componentDidUpdate(prevProps) {
		const { deck, location, match, setHoverCard, user, viewport } = this.props

		if (prevProps.match.params.slug !== match.params.slug) {
			setHoverCard(null, null)

			this.loadSafely()
		}

		if (prevProps.deck && deck && prevProps.deck.slug === deck.slug && prevProps.deck.saved !== deck.saved && deck.user.id === user.id) {
			if (deck.saved) {
				window.removeEventListener('beforeunload', this.handleBeforeUnload)
			} else {
				window.addEventListener('beforeunload', this.handleBeforeUnload)
			}
		} else if (!deck || deck.saved) {
			window.removeEventListener('beforeunload', this.handleBeforeUnload)
		}

		if (prevProps.deck && this.props.deck === null && location.pathname.startsWith(`/deck-builder/${prevProps.deck.slug}`)) {
			if (viewport === VIEWPORT_MOBILE) {
				history.replace('/mydecks')
			} else {
				history.push('/mydecks')
			}
		}
	}

	componentWillUnmount() {
		const { setHoverCard } = this.props

		if (this.req) {
			this.req.abort()
		}

		if (this.req2) {
			this.req2.abort()
		}

		if (this.req3) {
			this.req3.abort()
		}

		setHoverCard(null, null)
	}

	loadSafely = () => {
		const { discardChanges, location, match, saveDeck, setConfirm, showLoading, t, user } = this.props
		const isEditingDeck = location.pathname.startsWith('/deck-builder/')
		const deck = this.props.shelvedDeck || this.props.deck

		showLoading()

		if (deck && isEditingDeck && deck.slug !== match.params.slug && !deck.saved && !deck.saving && deck.user.id === user.id) {
			setConfirm({
				text: t('general.deckUnsavedWarning', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
				submitText: t('general.save'),
				submitRed: false,
				secondaryText: t('general.discardChanges'),
				secondaryRed: true,
				cancelText: t('general.cancel'),
				width: '600px',
				success: () => {
					saveDeck(t, () => {
						this.loadDeck(match.params.slug)
					})
				},
				secondary: () => {
					discardChanges()
					this.loadDeck(match.params.slug)
				},
				cancel: () => {
					history.goBack()
				}
			})
		} else {
			this.loadDeck(match.params.slug)
		}
	}

	loadDeck = (slug) => {
		const { location, setDeck, setDeckAdditional, shelvedDeck, sortMainDeckCardsByType, unshelveDeck, user } = this.props
		let isEditingDeck = location.pathname.startsWith('/deck-builder/')

		if (slug !== null) {
			if (shelvedDeck && shelvedDeck.slug === slug) {
				unshelveDeck()
			} else {
				this.req = get(`${isEditingDeck ? 'deck-builder' : 'decks'}/${slug}`, ({ data: deck }) => {
					if (isEditingDeck && deck.user.id !== user.id) {
						isEditingDeck = false
						history.replace(`/deck/${deck.slug}`)
					}

					setDeck({
						...deck,
						archetypes: deck.archetypes.filter(item => item !== null)
					}, null, isEditingDeck)

					if (deck.user.id !== user.id) {
						sortMainDeckCardsByType()
					}

					forceVisible()

					this.setState({
						has404: false
					})

					// load dynamic data
					this.req2 = get(`decks/${slug}/additional`, ({ data: additionalDeck }) => {
						setDeckAdditional(additionalDeck)
					})
				}, () => {
					setDeck(null)

					this.setState({
						has404: true
					})
				})
			}
		} else {
			setDeck(null)

			this.setState({
				has404: false
			})
		}
	}

	handleBeforeUnload = (event) => {
		const { deck, t } = this.props

		if (!deck.saved) {
			event.preventDefault()
			event.returnValue = t('general.deckUnsavedWarning')

			return t('general.deckUnsavedWarning')
		}
	}

	render() {
		const { has404 } = this.state

		if (has404) {
			return <NotFound />
		}

		return null
	}
}

const mapStateToProps = state => ({
	deck: state.deckbuilder.deck,
	shelvedDeck: state.deckbuilder.shelvedDeck,
	user: state.persist.user,
	viewport: state.app.viewport
})

const mapDispatchToProps = dispatch => bindActionCreators({
	discardChanges,
	saveDeck,
	setConfirm,
	setDeck,
	setDeckAdditional,
	showLoading,
	setHoverCard,
	sortMainDeckCardsByType,
	starDeck,
	unshelveDeck
}, dispatch)

const enhance = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	translate('general')
)

export default enhance(DeckLoader)
