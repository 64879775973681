import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import darken from 'polished/lib/color/darken'
import Link from '../router/Link'
import { desktop } from '../../styles/media'
import themeLight from '../../styles/theme-light'

const BetterButton = React.forwardRef(({ children, color, ...other }, ref) => (
	<StyledBetterButton
		color={color}
		ref={ref}
		{...other}
	>
		{children}
	</StyledBetterButton>
))

BetterButton.propTypes = {
	children: PropTypes.any.isRequired,
	color: PropTypes.string
}

BetterButton.defaultProps = {
	color: themeLight.primary
}

export const StyledBetterButton = styled(Link)`
	background: ${props => props.color};
	border-radius: 15px;
	color: ${props => props.color === themeLight.modest ? '#000' : props.theme.white};
	font-weight: bold;
	padding: 0.75rem 1.5rem;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.2s;
	width: 100%;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.loading && `
		cursor: wait;
		opacity: 0.5;
		pointer-events: none;
	`}

	${props => props.disabled && `
		opacity: 0.5;
	`}

	${props => props.small && `
		border-radius: 10px;
		height: 35px;
		padding: 0 1rem;
	`}

	${props => props.modest && `
		color: ${props.theme.textLight};
	`}

	svg {
		margin-right: 0.35rem;
	}

	${props => props.icon && `
		svg {
			margin-right: 0;
		}
	`}

	${desktop`
		&:not([disabled]):hover {
			background: ${props => darken(0.1, props.color)};
			color: ${props => props.color === themeLight.modest ? '#000' : props.theme.white};
			transform: none;

			${props => props.modest && `
				color: ${props.theme.text};
			`}
		}
	`}
`

export default BetterButton
