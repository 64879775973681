import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '../../components/general/Icon'
import SiteSearch from '../../pages/search/SiteSearch'
import Link from '../../components/router/Link'
import {
	siteHasMultipleGames
} from '../../../config'
import Content from '../../components/layout/Content'
import { desktop, isStandalone, standalone } from '../../styles/media'
import history from '../../helper/history'
import { StyledProductLink } from '../../components/product/ProductLink'
import H1 from '../../components/header/H1'
import CollectionBox from './CollectionBox'
import SiteSettings from './SiteSettings'
import HomeJumbotron from '../../pages/home/HomeJumbotron'
import SignUpJumbotron from '../../pages/home/SignUpJumbotron'

const SiteHeader = ({ authToken, isLoading, location, siteError, theme }) => {
	if (location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/welcome') || (siteHasMultipleGames && location.pathname === '/')) {
		return null
	}

	const [goneBack, setGoneBack] = useState(0)

	const handleGoBack = () => {
		setGoneBack(goneBack + 1)
		history.goBack()
	}

	const handleGoForward = () => {
		setGoneBack(goneBack - 1)
		history.goForward()
	}

	useEffect(() => {
		if (history.action === 'PUSH') {
			setGoneBack(0)
		}
	}, [history.action])

	const dark = theme.id === 'dark'
	const homeJumbotron = false/*location.pathname === '/' && !authToken && !isLoading*/
	const signUpJumbotron = false/*location.pathname === '/' && !authToken && !isLoading*/
	const transparentDarkRoutes = dark ? ['/pricing', '/pricing/yearly', '/product/collection-manager'] : ['/product/collection-manager']
	const transparentLightRoutes = !dark ? ['/pricing', '/pricing/yearly'] : []
	const transparentLight = !!(transparentLightRoutes.find(item => item === location.pathname) && !isLoading && !siteError)
	const transparentDark = !!((transparentDarkRoutes.find(item => item === location.pathname) || homeJumbotron || signUpJumbotron) && !isLoading && !siteError)
	const transparent = transparentDark ? 'dark' : (transparentLight ? 'light' : null)

	return (
		<StyledHeader homeJumbotron={homeJumbotron} signUpJumbotron={signUpJumbotron} transparent={transparent}>
			<Content>
				<Search>
					{isStandalone() && (
						<PWANavigationButtons>
							<PWANavigationButton
								onClick={handleGoBack}
								disabled={history.length <= 1}
								transparent={transparent}
							>
								<Icon name="chevron-left" />
							</PWANavigationButton>
							<PWANavigationButton
								onClick={handleGoForward}
								disabled={goneBack === 0}
								transparent={transparent}
							>
								<Icon name="chevron-right" />
							</PWANavigationButton>
						</PWANavigationButtons>
					)}

					<SiteSearch transparent={transparent} />
				</Search>

				<Right>
					{authToken && (
						<CollectionBox transparent={!!transparent} />
					)}
					<SiteSettings homeJumbotron={homeJumbotron} transparent={transparent} />
				</Right>
			</Content>

			{homeJumbotron && (
				<HomeJumbotron />
			)}
		</StyledHeader>
	)
}

SiteHeader.propTypes = {
	authToken: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	siteError: PropTypes.string,
	theme: PropTypes.object.isRequired,
}

SiteHeader.defaultProps = {
	authToken: null,
	siteError: null
}

const StyledHeader = styled.div`
	margin-bottom: 1.5rem;

	${props => !props.transparent && `
		background: ${props.theme.backgroundVeryLight};
	`}

	${props => props.homeJumbotron && `
		background: linear-gradient(#2a4859, #20355b);
	`}

	${props => props.signUpJumbotron && `
		background: ${props.theme.header};
	`}

	> ${Content} {
		align-items: stretch;
		display: flex;
		justify-content: space-between;
		min-height: 80px;
		padding: 0 2rem !important;
	}
`

const Search = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	padding-right: 1rem;

	form {
		max-width: 550px;
	}

	${H1} {
		color: #FFF;
		text-align: center;

		img {
			height: 40px;
		}
	}

	> * + * {
		margin-left: 1.5rem;
	}

	${standalone`
		max-width: 650px;
	`}
`

const Right = styled.div`
	align-items: stretch;
	display: flex;
	justify-content: flex-end;
	flex: 0 0 320px;

	${StyledProductLink} {
		background: ${props => props.theme.header};
		border-radius: 6px;
		padding: 0.35rem 1rem;

		&:hover {
			transform: scale(1.05);
		}
	}

	@media (min-width: 1025px) and (max-width: 1400px) {
		flex: 0 0 auto;
	}
`

const PWANavigationButtons = styled.div`
	display: flex;
	gap: 0.5rem;
`

const PWANavigationButton = styled.button`
	background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
	border: 0;
	border-radius: 50%;
	color: ${props => props.theme.textLight};
	cursor: default;
	transition: 0.1s;

	height: 42px;
	width: 42px;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 30px;
		width: 30px;
	}

	${props => props.theme.id === 'dark' && !props.transparent && `
		box-shadow: ${props.theme.shadowSearch};
	`}

	${desktop`
		&:hover {
			background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
		}
	`}
`

export const UserLink = styled(Link)`
	background: ${props => props.theme.primaryVeryLight};
	border-radius: 4px;
	color: ${props => props.theme.textTranslucentLight};
	cursor: pointer;
	padding: 0.75rem;
	text-decoration: none;
	transition: 0.1s;

	align-items: center;
	display: flex;
	justify-content: center;

	> span {
		font-weight: 500;
		padding: 0 0.5rem;
	}

	&:hover {
		background: ${props => props.theme.primaryVeryLightHover};
		color: ${props => props.theme.textPrimaryVeryLightHover};
	}

	${props => props.disabled && `
		cursor: not-allowed;
		opacity: 0.5;
	`}

	${props => props.install && `
		height: 44px;
		padding: 0.5rem 0.75rem;
	`}

	${props => props.readyToInstall && `
		svg {
			background: ${props.theme.green};
			border-radius: 50%;
			color: #FFF;
			padding: 0.2rem;
		}

		span {
			color: ${props.theme.green};
			font-weight: 500;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		isLoading: state.app.isLoading,
		siteError: state.app.siteError
	})),
	withRouter,
	withTheme
)

export default enhance(SiteHeader)
