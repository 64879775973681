import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { VIEWPORT_MOBILE } from '../../styles/media'

const Tooltip = ({ children, delay, mobileShowOnClick, position, positionMobile, title, viewport }) => {
	const [visible, setVisible] = useState(false)

	const handleClick = (event) => {
		event.preventDefault()
		event.stopPropagation()

		setVisible(true)
	}

	const handleClickAway = () => {
		setVisible(false)
	}

	const enableClickAwayHandler = () => {
		window.addEventListener('touchstart', handleClickAway)
	}

	const disableClickAwayHandler = () => {
		window.removeEventListener('touchstart', handleClickAway)
	}

	useEffect(() => {
		if (viewport === VIEWPORT_MOBILE && mobileShowOnClick && visible) {
			enableClickAwayHandler()
		}

		return () => {
			if (viewport === VIEWPORT_MOBILE && mobileShowOnClick) {
				disableClickAwayHandler()
			}
		}
	}, [viewport, visible])

	if (viewport === VIEWPORT_MOBILE && !mobileShowOnClick) {
		return (
			<StyledTooltip>
				{children}
			</StyledTooltip>
		)
	}

	return (
		<StyledTooltip
			data-tooltip={title || null}
			data-tooltip-location={title ? (viewport === VIEWPORT_MOBILE ? positionMobile || position : position) : null}
			data-tooltip-delay={title ? delay : null}
			data-tooltip-visible-mobile={visible}
			onClick={viewport === VIEWPORT_MOBILE ? handleClick : undefined}
		>
			{children}
		</StyledTooltip>
	)
}

Tooltip.propTypes = {
	children: PropTypes.any.isRequired,
	delay: PropTypes.bool,
	mobileShowOnClick: PropTypes.bool,
	position: PropTypes.string,
	positionMobile: PropTypes.string,
	title: PropTypes.string,
	viewport: PropTypes.string.isRequired
}

Tooltip.defaultProps = {
	delay: true,
	mobileShowOnClick: false,
	position: 'bottom',
	positionMobile: null,
	title: null
}

export const StyledTooltip = styled.div`
	display: inline-block;
`

const enhance = connect(state => ({
	viewport: state.app.viewport
}))

export default enhance(Tooltip)
