import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { domains, htmlHeadMapping } from '../../../config'
import { isStandalone } from '../../styles/media'

const HTMLHead = ({ language, location }) => {
	const domain = domains[htmlHeadMapping[language]]
	const pathname = location.pathname !== '/' ? location.pathname : ''

	return (
		<Helmet htmlAttributes={{ lang: htmlHeadMapping[language] }}>
			<link rel="alternate" hrefLang="de" href={`https://cardcluster.de${pathname}`} />
			<link rel="alternate" hrefLang="en" href={`https://cardcluster.com${pathname}`} />
			<link rel="alternate" hrefLang="fr" href={`https://cardcluster.fr${pathname}`} />
			<link rel="alternate" hrefLang="it" href={`https://cardcluster.it${pathname}`} />
			<link rel="alternate" hrefLang="es" href={`https://cardcluster.es${pathname}`} />
			<link rel="alternate" hrefLang="pt" href={`https://cardcluster.pt${pathname}`} />
			<link rel="alternate" hrefLang="x-default" href={`https://cardcluster.com${pathname}`} />
			<link rel="canonical" href={`https://${domain}${pathname}`} />

			<script type="application/ld+json">
				{`
					{
						"@context": "http://schema.org/",
						"@type": "WebSite",
						"name": "cardcluster",
						"url": "https://${domain}",
						"potentialAction": {
							"@type": "SearchAction",
							"target": "https://${domain}/search/{search_term_string}",
							"query-input": "required name=search_term_string"
						}
					}
				`}
			</script>

			{/* prevent zooming in standalone mode */}
			{isStandalone() && <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0" />}
		</Helmet>
	)
}

HTMLHead.propTypes = {
	language: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired
}

const enhance = compose(
	withRouter,
	connect(state => ({
		language: state.persist.language
	}))
)

export default enhance(HTMLHead)
