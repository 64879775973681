/**
 * Sets the deck builder's active deck.
 * Use the change parameter if a single value is changed. Possible values:
 *
 * - name
 * - visibility
 * - archetypes
 * - coverCard
 * - youtubeVideoId
 * - description
 * - format
 */
export const SET_DECK = 'SET_DECK'

export const setDeck = (deck, change = null, isEditingDeck = false) => ({
	type: SET_DECK,
	deck,
	change,
	isEditingDeck
})

export const DISCARD_CHANGES = 'DISCARD_CHANGES'
export const discardChanges = () => ({
	type: DISCARD_CHANGES
})

export const UNSHELVE_DECK = 'UNSHELVE_DECK'
export const unshelveDeck = () => ({
	type: UNSHELVE_DECK
})

export const CLEAR_DECK = 'CLEAR_DECK'
export const clearDeck = () => ({
	type: CLEAR_DECK
})

/**
 * Updates the validation info.
 */
export const SET_VALIDATION_INFO = 'SET_VALIDATION_INFO'
export const setValidationInfo = (invalidLegalSince, invalidLimitedList, legalSince, legalSinceSet, legalStatus, tooSmallMain) => ({
	type: SET_VALIDATION_INFO,
	invalidLegalSince,
	invalidLimitedList,
	legalSince,
	legalSinceSet,
	legalStatus,
	tooSmallMain
})

export const SET_DECK_HAVES = 'SET_DECK_HAVES'
export const setDeckHaves = haves => ({
	type: SET_DECK_HAVES,
	haves
})

export const SET_DECK_ADDITIONAL = 'SET_DECK_ADDITIONAL'
export const setDeckAdditional = data => ({
	type: SET_DECK_ADDITIONAL,
	data
})

/**
 * Sets the editor mode of the deck builder. Possible values:
 *
 * - info (mobile only)
 * - move
 * - add
 * - remove
 */
export const SET_MODE = 'SET_MODE'
export const setMode = mode => ({
	type: SET_MODE,
	mode
})


/**
 * LOCAL ACTIONS THAT MODIFY THE ACTIVE DECK
 */

export const ADD_CARD = 'ADD_CARD'
export const REMOVE_CARD = 'REMOVE_CARD'
export const SET_DECK_CARDS = 'SET_DECK_CARDS'
export const SET_DECK_CARD_CHECKED = 'SET_DECK_CARD_CHECKED'


/**
 * Asynchronous actions that require a backend request. Each action consists of three parts:
 *
 * - The ACTION itself applies an optimistic state change AND/OR sets a loading state.
 * - The COMMIT part is dispatched after a successful request and updates with information from the backend OR turns off loading.
 * - THE ROLLBACK part is dispatched in case of an error and handles necessary state changes.
 */

// Persist all local changes to the backend.
export const SAVE_DECK = 'SAVE_DECK'
export const SAVE_DECK_COMMIT = 'SAVE_DECK_COMMIT'
export const SAVE_DECK_ROLLBACK = 'SAVE_DECK_ROLLBACK'

// changes a deck's name
export const SET_DECK_NAME = 'SET_DECK_NAME'
export const SET_DECK_NAME_COMMIT = 'SET_DECK_NAME_COMMIT'
export const SET_DECK_NAME_ROLLBACK = 'SET_DECK_NAME_ROLLBACK'

// archives or unarchives a deck
export const SET_ARCHIVED = 'SET_ARCHIVED'

// updates the user's vote for a deck
export const VOTE_DECK = 'VOTE_DECK'
export const VOTE_DECK_COMMIT = 'VOTE_DECK_COMMIT'
export const VOTE_DECK_ROLLBACK = 'VOTE_DECK_ROLLBACK'

/**
 * Other
 */

export const ADD_EVENT_PLAYER = 'ADD_EVENT_PLAYER'
export const REMOVE_EVENT_PLAYERS = 'REMOVE_EVENT_PLAYERS'
export const addEventPlayer = deck => ({
	type: ADD_EVENT_PLAYER,
	deck
})
export const removeEventPlayers = deckSlug => ({
	type: REMOVE_EVENT_PLAYERS,
	deckSlug
})

export const SET_DRAGGING = 'SET_DRAGGING'
export const setDragging = dragging => ({
	type: SET_DRAGGING,
	dragging
})

export const RESET = 'RESET'
export const resetDeckBuilder = () => ({
	type: RESET
})

export const TRANSFER_DECK = 'TRANSFER_DECK'
export const transferDeck = user => ({
	type: TRANSFER_DECK,
	user
})

export const SET_INVALID_IMPORT_LINES = 'SET_INVALID_IMPORT_LINES'
export const setInvalidImportLines = invalidImportLines => ({
	type: SET_INVALID_IMPORT_LINES,
	invalidImportLines
})
