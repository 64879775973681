import styled from 'styled-components'
import rarities from '../../data/rarities'

const Rarity = styled.div`
	background: ${props => props.id && rarities[props.id]?.background ? props.theme[rarities[props.id].background] : `linear-gradient(45deg, ${props.theme.backgroundLight} 25%, ${props.theme.backgroundVeryLight} 25%, ${props.theme.backgroundVeryLight} 50%, ${props.theme.backgroundLight} 50%, ${props.theme.backgroundLight} 75%, ${props.theme.backgroundVeryLight} 75%, ${props.theme.backgroundVeryLight} 100%)`};
	border: ${props => props.id && rarities[props.id]?.borderColor ? `2px solid ${props.theme[rarities[props.id].borderColor]}` : 'none'};
	border-radius: 2px;
	color: ${props => props.id && rarities[props.id]?.color ? props.theme[rarities[props.id].color] : props.theme.white};
	font-family: ${props => props.theme.fontMonospace};
	font-size: 0.8rem;
	font-weight: bold;
	line-height: 1;
	text-align: center;
	white-space: nowrap;

	height: 22px;
	width: 31px;
	min-width: 31px;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.id && rarities[props.id]?.borderColor && `
		font-size: 0.7rem;
	`}

	${props => props.id && rarities[props.id]?.abbr.length > 3 && `
		font-size: 0.6rem;
	`}

	${props => props.id && rarities[props.id]?.abbr.length > 4 && rarities[props.id]?.borderColor && `
		font-size: 0.5rem;
	`}

	${props => !props.id && `
		background-size: 14.14px 14.14px;
		box-shadow: 0 0 0 2px ${props.theme.backgroundLight};
		color: ${props.theme.textSecondary};
	`}

	${props => props.tiny && `
		font-size: ${props.id && rarities[props.id]?.abbr.length > 3 ? '0.4rem' : '0.5rem'};
		height: 14px;
		width: 25px;
		min-width: 25px;
	`}

	${props => props.large && `
		font-size: ${props.id && rarities[props.id].abbr.length > 3 ? '0.9rem' : '1rem'};
		height: 34px;
    	width: 48px;
    	min-width: 48px;
	`}

	${props => props.skewed && `
		border-radius: 0 1px 0 0;
		clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
		padding-left: 6px !important;

		${props.tiny && `
			font-size: 0.6rem;
		`}
	`}
`

export default Rarity
