import { default as formatDateFns } from 'date-fns/format'
import isTomorrow from 'date-fns/isTomorrow'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import parseJSON from 'date-fns/parseJSON'
import store from '../state/store'
import languages from '../data/languages'
import { capitalize } from './string'

export const year = (dateStr, ignoreTimeZone = false) => dateStr ? format(parseDate(dateStr), languages[store.getState().persist.language].year, ignoreTimeZone) : '---'
export const month = (dateStr, ignoreTimeZone = false) => dateStr ? capitalize(format(parseDate(dateStr), languages[store.getState().persist.language].month, ignoreTimeZone)) : '---'
export const day = (dateStr, ignoreTimeZone = false) => dateStr ? format(parseDate(dateStr), languages[store.getState().persist.language].day, ignoreTimeZone) : '---'
export const dayShort = (dateStr, ignoreTimeZone = false) => {
	if (!dateStr) return '---'

	const date = parseDate(dateStr)

	if (date.getFullYear() === new Date().getFullYear()) {
		return format(date, languages[store.getState().persist.language].dayShort, ignoreTimeZone)
	}

	return day(dateStr, ignoreTimeZone)
}
export const time = (dateStr, ignoreTimeZone = false) => dateStr ? format(parseDate(dateStr), languages[store.getState().persist.language].time, ignoreTimeZone) : '---'
export const hours = dateStr => dateStr ? format(parseDate(dateStr), 'HH:mm') : '---'

export const readableDay = (dateStr, future, t) => {
	if (dateStr) {
		const date = parseDate(dateStr)

		if (future) {
			if (isTomorrow(date)) {
				return t('general.tomorrow')
			}

			if (differenceInCalendarDays(date, new Date()) <= 7) {
				return format(date, 'dddd')
			}

			return day(date)
		}

		if (isToday(date)) {
			return t('general.today')
		}

		if (isYesterday(date)) {
			return t('general.yesterday')
		}

		return day(date)
	}

	return '---';
}

export const relativeTime = (dateStr, t) => {
	if (dateStr) {
		const date = parseDate(dateStr)
		const now = new Date()

		const minutes = differenceInMinutes(now, date)
		const hours = differenceInHours(now, date)
		const days = differenceInDays(now, date)

		if (minutes < 5) {
			return t('general.dateRelativeNow')
		}

		if (minutes < 60) {
			return t('general.dateRelativeMinutesAgo', { count: minutes })
		}

		if (hours < 24) {
			return t('general.dateRelativeHoursAgo', { count: hours })
		}

		if (days < 7) {
			return t('general.dateRelativeDaysAgo', { count: days })
		}

		if (days < 31) {
			const weeks = differenceInWeeks(now, date)

			return t('general.dateRelativeWeeksAgo', { count: weeks })
		}

		const years = differenceInYears(now, date)

		if (years > 0) {
			return t('general.dateRelativeYearsAgo', { count: years })
		}

		const months = differenceInMonths(now, date)

		return t('general.dateRelativeMonthsAgo', { count: months })
	}

	return '---'
}

export const relativeTimeUpdateFrequency = (dateStr) => {
	if (dateStr) {
		const date = parseDate(dateStr)
		const now = new Date()

		const minutes = differenceInMinutes(now, date)
		const hours = differenceInHours(now, date)
		const days = differenceInDays(now, date)

		if (minutes < 5) {
			return 60 * 5
		}

		if (minutes < 60) {
			return 60
		}

		if (hours < 24) {
			return 60 * 60
		}

		if (days < 31) {
			return 60 * 60 * 24 * 7
		}

		if (days < 365) {
			return 60 * 60 * 24 * 30
		}
	}

	return null
}

export const relativeTimeFuture = (dateStr, t) => {
	if (dateStr) {
		const date = parseDate(dateStr)
		const now = new Date()

		const seconds = differenceInSeconds(date, now)
		const minutes = differenceInMinutes(date, now)
		const hours = differenceInHours(date, now)
		const days = differenceInDays(date, now)

		if (seconds <= 60) {
			return `${seconds} ${t('general.second', { count: seconds })}`
		}

		if (minutes <= 60) {
			return `${minutes} ${t('general.minute', { count: minutes })}`
		}

		if (hours <= 24) {
			return `${hours} ${t('general.hour', { count: hours })}`
		}

		return `${days} ${t('general.day', { count: days })}`
	}

	return '---'
}

export const relativeDays = (dateStr, t) => {
	if (dateStr) {
		const date = parseDate(dateStr)
		const now = new Date()

		const days = differenceInDays(now, date)

		if (isToday(date)) {
			return t('general.today')
		}

		if (isYesterday(date)) {
			return t('general.yesterday')
		}

		if (days < 7) {
			return t('general.dateRelativeDaysAgo', { count: days })
		}

		if (days < 31) {
			const weeks = differenceInWeeks(now, date)

			return t('general.dateRelativeWeeksAgo', { count: weeks })
		}

		if (days < 365) {
			const months = differenceInMonths(now, date)

			return t('general.dateRelativeMonthsAgo', { count: months })
		}

		const years = differenceInYears(now, date)

		return t('general.dateRelativeYearsAgo', { count: years })
	}

	return '---'
}

export const pureDateToDateObject = (str) => {
	const result = str.split('-')

	if (result.length === 3) {
		return new Date(result[0], Number(result[1].replace(/^0+/, '')) - 1, Number(result[2].replace(/^0+/, '')))
	}

	return null
}

/**
 * Internal functions
 */

export const parseDate = dateStr => parseJSON(dateStr)

export const format = (date, formatStr, ignoreTimezone = false) => {
	if (ignoreTimezone) {
		date = date.valueOf() + date.getTimezoneOffset() * 60 * 1000
	}

	return formatDateFns(date, formatStr, {
		locale: languages[store.getState().persist.language].locale
	})
}
