const communityViolations = [
	{
		label: 'general.communityViolationNotRelevant',
		value: 'notRelevant'
	},
	{
		label: 'general.communityViolationLowQuality',
		value: 'lowQuality'
	},
	{
		label: 'general.communityViolationNotConstructive',
		value: 'notConstructive'
	},
	{
		label: 'general.communityViolationUnwantedLink',
		value: 'unwantedLink'
	},
	{
		label: 'general.userGuidelinesViolation',
		value: 'userGuidelinesViolation'
	}
]

export default communityViolations
