import themeLight from '../styles/theme-light'
import { YGO } from './games'

export const TCG = 1
export const TCG_TRADITIONAL = 2
export const SPEED_DUEL = 3
export const OCG = 4
export const RUSH_DUEL = 6
export const MASTER_DUEL = 10

const formats = {
	[TCG]: {
		name: 'TCG',
		slug: 'tcg',
		abbr: 'TCG',
		color: themeLight.primary,
		game: YGO,
		hasSeasons: true,
		deckMin: 40,
		deckMax: 60,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note']
		},
		extra: 15,
		side: 15,
		startHand: 5,
		hasTournamentDecks: true,
		collectionsSupported: true
	},
	[TCG_TRADITIONAL]: {
		name: 'TCG Traditional',
		slug: 'tcg-traditional',
		abbr: 'TCG Trad.',
		color: themeLight.textLight,
		game: YGO,
		hasSeasons: true,
		parent: TCG,
		deckMin: 40,
		deckMax: 60,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note']
		},
		extra: 15,
		side: 15,
		startHand: 5,
		hasTournamentDecks: false,
		collectionsSupported: true
	},
	[SPEED_DUEL]: {
		name: 'Speed Duel',
		slug: 'speed-duel',
		abbr: 'SD',
		color: themeLight.orange,
		game: YGO,
		hasSeasons: false,
		deckMin: 20,
		deckMax: 30,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note', 'skill'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note', 'skill']
		},
		extra: 6,
		side: 6,
		skill: 1,
		startHand: 4,
		hasTournamentDecks: false,
		collectionsSupported: true
	},
	[OCG]: {
		name: 'OCG',
		slug: 'ocg',
		abbr: 'OCG',
		color: themeLight.teal,
		game: YGO,
		hasSeasons: true,
		deckMin: 40,
		deckMax: 60,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note']
		},
		extra: 15,
		side: 15,
		startHand: 5,
		hasTournamentDecks: false,
		collectionsSupported: false
	},
	[RUSH_DUEL]: {
		name: 'Rush Duel',
		disabled: true,
		slug: 'rush-duel',
		color: themeLight.orange,
		game: YGO,
		hasSeasons: false,
		deckMin: 40,
		deckMax: 60,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note', 'legend'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note', 'legend']
		},
		legend: 1,
		startHand: 5,
		hasTournamentDecks: false
	},
	[MASTER_DUEL]: {
		name: 'Master Duel',
		slug: 'master-duel',
		abbr: 'MD',
		color: themeLight.sage,
		game: YGO,
		hasSeasons: true,
		deckMin: 40,
		deckMax: 60,
		maxCopies: 3,
		places: {
			tile: ['main', 'extra', 'side', 'note'],
			list: ['monster', 'spell', 'trap', 'extra', 'side', 'note']
		},
		extra: 15,
		side: 15,
		startHand: 5,
		hasTournamentDecks: true,
		collectionsSupported: false
	}
}

export default formats
