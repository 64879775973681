import formats from '../data/formats'
import { month } from './date'

export const isMonster = card => card.type === 'monster'
export const isSpell = card => card.type === 'spell'
export const isTrap = card => card.type === 'trap'
export const isSkill = card => card.type === 'skill'
export const isNormal = card => !!card.cardTypes.find(id => id === 1)
export const isXyz = card => !!card.cardTypes.find(id => id === 6)
export const isPendulum = card => !!card.cardTypes.find(id => id === 7)
export const isLink = card => !!card.cardTypes.find(id => id === 8)
export const isFusion = card => !!card.cardTypes.find(id => id === 4)
export const isSynchro = card => !!card.cardTypes.find(id => id === 5)
export const isToken = card => !!card.cardTypes.find(id => id === 24)
export const isTokenWithStats = (card, cardName) => card && isToken(card) && cardName && !cardName.includes('(') && !cardName.includes(')')
export const isExtraDeckCard = card => isLink(card) || isXyz(card) || isSynchro(card) || isFusion(card)

export const groupDeckCards = (cards, place) => {
	const columnCards = place ? cards.filter((deckCard) => {
		if (place === 'monster' || place === 'spell' || place === 'trap') {
			return deckCard.place === 'main' && deckCard.card.type === place
		}

		return deckCard.place === place
	}) : cards

	let countedCards = []

	columnCards.forEach((deckCard) => {
		const existing = countedCards.find(item => item.card.slug === deckCard.card.slug && item.place === deckCard.place && (!item.artworkId || !deckCard.artworkId || item.artworkId === deckCard.artworkId))

		if (existing) {
			countedCards = [
				...countedCards.filter(item => !(item.card.slug === deckCard.card.slug && (!item.artworkId || !deckCard.artworkId || item.artworkId === deckCard.artworkId))),
				{
					...existing,
					ownsCard: existing.ownsCard === true ? deckCard.ownsCard : false,
					ids: [
						...existing.ids,
						deckCard.id
					],
					checkedValues: [
						...existing.checkedValues,
						deckCard.checked
					]
				}
			]
		} else {
			countedCards.push({
				card: deckCard.card,
				ids: [deckCard.id],
				checkedValues: [deckCard.checked],
				artworkId: deckCard.artworkId,
				order: deckCard.order,
				place: deckCard.place,
				ownsCard: deckCard.ownsCard,
				price: deckCard.price
			})
		}
	})

	return countedCards
}

export const removeDuplicateCards = (prints, entity = 'card') => {
	const filtered = []

	return prints.filter((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!filtered.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& (!item.artwork || !print.artwork || item.artwork.id === print.artwork.id)
				&& (!item.artworkNumber || !print.artworkNumber || item.artworkNumber === print.artworkNumber)
				&& (!item.artworkId || !print.artworkId || item.artworkId === print.artworkId)
				&& (!item.language || !print.language || item.language.id === print.language.id)
		})) {
			filtered.push(print)

			return true
		}

		return false
	})
}

export const getOneItemPerCardNumber = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
		})) {
			result.push(print)
		}
	})

	return result
}

export const getOneItemPerCardNumberButKeepMultipleArtworks = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
				&& (!item.artwork || !print.artwork || item.artwork.id === print.artwork.id)
				&& (!item.artworkNumber || !print.artworkNumber || item.artworkNumber === print.artworkNumber)
		})) {
			result.push(print)
		}
	})

	return result
}

export const getOneItemPerCardNumberButKeepMultipleRarities = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
				&& item.rarity === print.rarity
		})) {
			result.push(print)
		}
	})

	return result
}

export const formatSlugToId = (slug) => {
	const format = Object.entries(formats).find(([key, value]) => value.slug === slug)

	if (format) {
		return Number(format[0])
	}

	return null
}

export const getSeasonName = season => season.startsOn ? month(season.startsOn) : season.name

export const seasonsRemoveNumber = seasons => seasons.map(item => ({ ...item, options: item.options.map(item2 => ({ ...item2, number: undefined })) }))

export const getAllowed = (card, seasonId, traditional = false) => {
	if (!seasonId) {
		return 3
	}

	if (card.forbidden && card.forbidden.indexOf(seasonId) !== -1) {
		return traditional ? 1 : 0
	}

	if (card.limited && card.limited.indexOf(seasonId) !== -1) {
		return 1
	}

	if (card.semiLimited && card.semiLimited.indexOf(seasonId) !== -1) {
		return 2
	}

	return 3
}

export const getCardName = (deckCard, cardNameLanguage) => deckCard.card[`name_${cardNameLanguage}`] || deckCard.card.name || deckCard.card.name_en

export const getCardImage = (deckCard, cardNameLanguage) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork[`image_${cardNameLanguage}`] || artwork.image_en || artwork.image
	}

	return deckCard.card[`image_${cardNameLanguage}`] || deckCard.card.image_en || deckCard.card.image
}

export const getColor = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork.color
	}

	return deckCard.card.color
}

export const getArtwork = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork
	}

	return null
}

export const getArtworkNumber = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork.number
	}

	return null
}
