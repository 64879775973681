const monsterAttributes = {
	1: {
		name_en: 'Dark',
		name_de: 'Finsternis',
		name_fr: 'Ténèbres',
		name_it: 'Oscurità',
		name_es: 'Oscuridad',
		name_pt: 'Trevas',
		name_jp: '闇属性',
		modestColor: 'rgb(133, 47, 108, 0.3)',
		lightColor: '#852f6C',
		darkColor: '#50000C',
		order: 6
	},
	2: {
		name_en: 'Divine',
		name_de: 'Göttlich',
		name_fr: 'Divin',
		name_it: 'Divino',
		name_es: 'Divinidad',
		name_pt: 'Divino',
		name_jp: '神属性',
		modestColor: 'rgb(124, 98, 62, 0.3)',
		lightColor: '#7C623E',
		darkColor: '#493B20',
		order: 7
	},
	3: {
		name_en: 'Earth',
		name_de: 'Erde',
		name_fr: 'Terre',
		name_it: 'Terra',
		name_es: 'Tierra',
		name_pt: 'Terra',
		name_jp: '地属性',
		modestColor: 'rgb(99, 112, 68, 0.3)',
		lightColor: '#637044',
		darkColor: '#384231',
		order: 1
	},
	4: {
		name_en: 'Fire',
		name_de: 'Feuer',
		name_fr: 'Feu',
		name_it: 'Fuoco',
		name_es: 'Fuego',
		name_pt: 'Fogo',
		name_jp: '炎属性',
		modestColor: 'rgb(202, 101, 101, 0.3)',
		lightColor: '#ca6565',
		darkColor: '#b30201',
		order: 3
	},
	5: {
		name_en: 'Light',
		name_de: 'Licht',
		name_fr: 'Lumière',
		name_it: 'Luce',
		name_es: 'Luz',
		name_pt: 'Luz',
		name_jp: '光属性',
		modestColor: 'rgb(124, 98, 62, 0.3)',
		lightColor: '#7C623E',
		darkColor: '#493B20',
		order: 5
	},
	6: {
		name_en: 'Water',
		name_de: 'Wasser',
		name_fr: 'Eau',
		name_it: 'Acqua',
		name_es: 'Agua',
		name_pt: 'Água',
		name_jp: '水属性',
		modestColor: 'rgb(35, 155, 193, 0.3)',
		lightColor: '#239bC1',
		darkColor: '#006AAD',
		order: 4
	},
	7: {
		name_en: 'Wind',
		name_de: 'Wind',
		name_fr: 'Vent',
		name_it: 'Vento',
		name_es: 'Viento',
		name_pt: 'Vento',
		name_jp: '風属性',
		modestColor: 'rgb(99, 160, 80, 0.3)',
		lightColor: '#63A050',
		darkColor: '#25491B',
		order: 2
	}
}

export default monsterAttributes
