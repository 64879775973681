import { MASTER_DUEL } from './formats'

const rarities = {
	34: {
		name: '10000 Secret Rare',
		abbr: '10K',
		background: 'red',
		order: 1
	},
	19: {
		name: 'Extra Secret Rare',
		abbr: 'EXSCR',
		background: 'red',
		order: 2
	},
	8: {
		name: 'Ghost Rare',
		abbr: 'GHR',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 3
	},
	63: {
		name: 'Starlight Rare (Astral glyphs)',
		abbr: 'SLRA',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 4
	},
	28: {
		name: 'Starlight Rare',
		abbr: 'SLR',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 5
	},
	71: {
		name: 'Pharaoh\'s Rare (Secret Rare foiling)',
		abbr: 'PHRS',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 6
	},
	61: {
		name: 'Pharaoh\'s Rare (Ultra Rare foiling)',
		abbr: 'PHRU',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 7
	},
	18: {
		name: 'Ghost Gold Rare',
		abbr: 'GHGR',
		background: 'gold2',
		color: 'textInverted',
		borderColor: 'backgroundInverted',
		order: 8
	},
	70: {
		name: 'Quarter Century Secret Rare',
		abbr: 'QCSCR',
		background: 'teal',
		order: 9
	},
	30: {
		name: 'Collector\'s Rare',
		abbr: 'CR',
		background: 'teal',
		order: 10
	},
	6: {
		name: 'Ultimate Rare',
		abbr: 'UMR',
		background: 'teal',
		order: 11
	},
	72: {
		name: 'Prismatic Collector\'s Rare',
		abbr: 'PMCR',
		background: 'teal',
		order: 12
	},
	73: {
		name: 'Prismatic Ultimate Rare',
		abbr: 'PMUR',
		background: 'teal',
		order: 13
	},
	14: {
		name: 'Platinum Secret Rare',
		abbr: 'PLSCR',
		background: 'platinum',
		borderColor: 'purple',
		color: 'textLight',
		order: 14
	},
	11: {
		name: 'Prismatic Secret Rare',
		abbr: 'PMSCR',
		background: 'purple',
		order: 15
	},
	7: {
		name: 'Secret Rare',
		abbr: 'SCR',
		background: 'purple',
		order: 16
	},
	62: {
		name: 'Blue Secret Rare',
		abbr: 'BLSCR',
		background: 'purple',
		order: 17
	},
	27: {
		name: 'Duel Terminal Secret Rare',
		abbr: 'DTSCR',
		background: 'purple',
		borderColor: 'middleBlueGreen',
		order: 18
	},
	10: {
		name: 'Gold Secret Rare',
		abbr: 'GSCR',
		background: 'gold2',
		borderColor: 'purple',
		order: 19
	},
	75: {
		name: 'Ultra Rare (Astral glyphs)',
		abbr: 'URA',
		background: 'gold',
		order: 20
	},
	76: {
		name: 'Silver Ultra Rare (Astral glyphs)',
		abbr: 'SURA',
		background: 'gold',
		order: 21
	},
	5: {
		name: 'Ultra Rare',
		abbr: 'UR',
		background: 'gold',
		order: 22
	},
	60: {
		name: 'Duelist Saga Ultra Rare',
		abbr: 'DSUR',
		background: 'gold',
		order: 23
	},
	31: {
		name: 'Blue Ultra Rare',
		abbr: 'BLUR',
		background: 'gold',
		order: 24
	},
	32: {
		name: 'Green Ultra Rare',
		abbr: 'GRUR',
		background: 'gold',
		order: 25
	},
	33: {
		name: 'Purple Ultra Rare',
		abbr: 'PUUR',
		background: 'gold',
		order: 26
	},
	69: {
		name: 'Red Ultra Rare',
		abbr: 'REUR',
		background: 'gold',
		order: 27
	},
	74: {
		name: 'Silver Ultra Rare',
		abbr: 'SUR',
		background: 'gold',
		order: 28
	},
	26: {
		name: 'Duel Terminal Ultra Rare',
		abbr: 'DTUR',
		background: 'gold',
		borderColor: 'middleBlueGreen',
		order: 29
	},
	22: {
		name: 'Parallel Ultra Rare',
		abbr: 'PUR',
		background: 'gold',
		borderColor: 'middleBlueGreen',
		order: 30
	},
	29: {
		name: 'Premium Gold Rare',
		abbr: 'PGR',
		background: 'gold2',
		order: 31
	},
	9: {
		name: 'Gold Rare',
		abbr: 'GR',
		background: 'gold2',
		order: 32
	},
	12: {
		name: 'Platinum Rare',
		abbr: 'PLR',
		background: 'platinum',
		color: 'textLight',
		order: 33
	},
	4: {
		name: 'Super Rare',
		abbr: 'SR',
		background: 'bronze',
		order: 34
	},
	25: {
		name: 'Duel Terminal Super Rare',
		abbr: 'DTSR',
		background: 'bronze',
		borderColor: 'middleBlueGreen',
		order: 35
	},
	21: {
		name: 'Parallel Super Rare',
		abbr: 'PSR',
		background: 'bronze',
		borderColor: 'middleBlueGreen',
		order: 36
	},
	24: {
		name: 'Duel Terminal Rare',
		abbr: 'DTR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 37
	},
	23: {
		name: 'Duel Terminal Common',
		abbr: 'DTC',
		background: 'backgroundLight',
		borderColor: 'middleBlueGreen',
		color: 'textLight',
		order: 38
	},
	20: {
		name: 'Parallel Common',
		abbr: 'PC',
		background: 'backgroundLight',
		borderColor: 'middleBlueGreen',
		color: 'textLight',
		order: 39
	},
	15: {
		name: 'Shatterfoil Rare',
		abbr: 'SHR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 40
	},
	17: {
		name: 'Mosaic Rare',
		abbr: 'MSR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 41
	},
	16: {
		name: 'Starfoil Rare',
		abbr: 'SFR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 42
	},
	3: {
		name: 'Rare',
		abbr: 'R',
		background: 'silver',
		order: 43
	},
	35: {
		name: 'Gold Letter Rare',
		abbr: 'GLR',
		background: 'silver',
		order: 44
	},
	59: {
		name: 'Black/White Rare',
		abbr: 'BWR',
		background: 'silver',
		order: 45
	},
	55: {
		name: 'Blue Rare',
		abbr: 'BLR',
		background: 'silver',
		order: 46
	},
	52: {
		name: 'Bronze Rare',
		abbr: 'BRR',
		background: 'silver',
		order: 47
	},
	54: {
		name: 'Light Blue Rare',
		abbr: 'LBR',
		background: 'silver',
		order: 48
	},
	56: {
		name: 'Green Rare',
		abbr: 'GRR',
		background: 'silver',
		order: 49
	},
	58: {
		name: 'Purple Rare',
		abbr: 'PRR',
		background: 'silver',
		order: 50
	},
	57: {
		name: 'Red Rare',
		abbr: 'RER',
		background: 'silver',
		order: 51
	},
	1: {
		name: 'Common',
		abbr: 'C',
		background: 'backgroundLight',
		color: 'textLight',
		order: 52
	},
	65: {
		name: 'Ultra Rare',
		abbr: 'UR',
		background: 'purpleToTeal',
		skewedColor: '#B52DFF',
		order: 1,
		format: MASTER_DUEL
	},
	66: {
		name: 'Super Rare',
		abbr: 'SR',
		background: 'orange',
		order: 2,
		format: MASTER_DUEL
	},
	67: {
		name: 'Rare',
		abbr: 'R',
		background: 'blue',
		order: 3,
		format: MASTER_DUEL
	},
	68: {
		name: 'Normal',
		abbr: 'N',
		background: 'backgroundLight',
		color: 'textLight',
		order: 4,
		format: MASTER_DUEL
	}
}

export default rarities
