import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { desktop, mobile } from '../../styles/media'
import Icon from '../../components/general/Icon'
import Content from '../../components/layout/Content'
import SkipButton, { StyledSkipButton } from '../../components/button/SkipButton'
import { formatMarkdown } from '../../helper/string'
import translate from '../../helper/translate'
import { setAnnouncementsRead } from '../../state/actions/persist'

const Announcements = ({ announcements, setAnnouncementsRead, t }) => {
	const [activeIndex, setActiveIndex] = useState(0)

	const announcement = announcements[activeIndex]

	const prev = () => {
		setActiveIndex(activeIndex !== 0 ? activeIndex - 1 : announcements.length - 1)
	}

	const next = () => {
		setActiveIndex(activeIndex !== announcements.length - 1 ? activeIndex + 1 : 0)
	}

	const close = () => {
		setAnnouncementsRead(announcement.startsAt)
	}

	return (
		<StyledAnnouncement color={announcement.color}>
			<Content>
				<Inner>
					{announcements.length > 1 && (
						<Navigation>
							<span>{t('general.announcement')} {activeIndex + 1} {t('general.of')} {announcements.length}</span>

							<NavigationButton onClick={prev}>
								<Icon name="chevron-left" />
							</NavigationButton>
							<NavigationButton onClick={next}>
								<Icon name="chevron-right" />
							</NavigationButton>
						</Navigation>
					)}
					<AnnouncementText>
						<Icon name={announcement.icon} />
						<p>
							{formatMarkdown(announcement.text)}
						</p>
					</AnnouncementText>
				</Inner>

				<SkipButton onClick={close}>
					<Icon name="x" />
					<span>{t('general.announcementClose')}</span>
				</SkipButton>
			</Content>
		</StyledAnnouncement>
	)
}

Announcements.propTypes = {
	announcements: PropTypes.array.isRequired,
	setAnnouncementsRead: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

const StyledAnnouncement = styled.div`
	${Content} {
		align-items: center;
		display: flex;
		gap: 1rem;
		min-height: 80px;
		padding: 1rem 2rem !important;
	}

	box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
	font-weight: 500;

	position: sticky;
	top: 0;
	z-index: ${props => props.theme.zLayer5};

	p {
		margin-bottom: 0;
	}

	${StyledSkipButton} {
		background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
		border-radius: 12px;
		padding: 0.75rem 1rem;
	}

	${props => props.color === 'red' && `
		background: ${props.theme.redLight};
	`}

	${props => props.color === 'orange' && `
		background: ${props.theme.orangeLight};
	`}

	${props => props.color === 'blue' && `
		background: ${props.theme.primaryLight};
	`}

	${desktop`
		${StyledSkipButton} {
			margin-left: auto;
			padding: 0.75rem 1rem;
			white-space: nowrap;
		}
	`}

	${mobile`
		${Content} {
			flex-direction: column;
			padding: 1rem 0.5rem !important;
		}

		${StyledSkipButton} {
			font-size: 0.9rem;
			padding: 0.5rem 0.75rem;
		}
	`}
`

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	${mobile`
		align-items: center;
	`}
`

const Navigation = styled.div`
	align-items: center;
	display: flex;
	gap: 0.5rem;

	color: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;

	${desktop`
		> span {
			margin-right: 0.5rem;
		}
	`}

	${mobile`
		font-size: 0.8rem;
		justify-content: center;

		> span {
			order: 1;
			margin: 0 0.5rem;
		}
	`}
`

const NavigationButton = styled.button`
	background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
	border: 0;
	border-radius: 4px;
	color: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
	cursor: pointer;
	height: 22px;
	width: 28px;

	align-items: center;
	display: flex;
	justify-content: center;

	transition: 0.1s;

	${desktop`
		&:hover {
			background: ${props => props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
		}
	`}

	${mobile`
		&:nth-child(2) {
			order: 0;
		}

		&:nth-child(3) {
			order: 2;
		}
	`}
`

const AnnouncementText = styled.div`
	align-items: center;
	display: flex;

	svg {
		margin-right: 1rem;
		min-width: 20px;
	}
`

const enhance = compose(
	connect(null, dispatch => bindActionCreators({
		setAnnouncementsRead
	}, dispatch)),
	translate('general')
)

export default enhance(Announcements)
