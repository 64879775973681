import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Icon from '../../components/general/Icon'
import translate from '../../helper/translate'
import { isIos, isStandalone, mobile, mobileTablet } from '../../styles/media'
import Link from '../../components/router/Link'
import history from '../../helper/history'
import CoverImage from '../../pages/deck/components/CoverImage'
import { getScrollContainer, getScrollPosition, scrollTop, scrollToPosition } from '../../helper/scroll'
import { getAppMenuHeight } from '../../helper/device'
import references from '../../data/references'
import { setReference } from '../../state/actions/app'
import CollectionIcon from '../../components/collection/CollectionIcon'
import SurveyBanner from '../../components/general/SurveyBanner'
import { locationMatchesCardSearch } from '../../helper/router'

class MobileAppMenu extends Component {
	constructor(props) {
		super(props)

		this.state = {
			animateMe: null
		}

		this.bounceTimeout = null

		this.menuRef = React.createRef()
		this.appMenuHeight = 50
		this.scrollTopTotal = 0
	}

	componentDidMount() {
		this.initAutoScroll()

		if (isStandalone()) {
			this.enableOrientationChangeListener()
		}
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		const { animateMe } = this.state

		if (prevProps.location.pathname !== location.pathname && location.pathname !== '/white' && animateMe) {
			this.bounce()
		}

		if (prevProps.location.pathname !== location.pathname) {
			this.initAutoScroll()
		}
	}

	componentWillUnmount() {
		if (!isStandalone()) {
			this.disableAutoHide()
		} else {
			this.disableOrientationChangeListener()
		}
	}

	enableAutoHide = () => {
		getScrollContainer().addEventListener('scroll', this.handleScroll, { passive: true })
	}

	disableAutoHide = () => {
		getScrollContainer().removeEventListener('scroll', this.handleScroll, { passive: true })
	}

	enableOrientationChangeListener = () => {
		window.addEventListener('orientationchange', this.handleOrientationChange)
	}

	disableOrientationChangeListener = () => {
		window.removeEventListener('orientationchange', this.handleOrientationChange)
	}

	handleOrientationChange = () => {
		const orientation = window.screen.orientation.type

		if (orientation && orientation.includes('landscape')) {
			this.enableAutoHide()
		} else {
			this.disableAutoHide()
		}
	}

	initAutoScroll = () => {
		const { location } = this.props

		const el = this.menuRef.current

		if (location.pathname.startsWith('/add') || location.pathname.endsWith('/add') || location.pathname.includes('/add/')) {
			if (!isStandalone()) {
				this.disableAutoHide()
			}

			el.style.transition = '0.2s';
			el.style.transform = 'translate(0, 100px)'
		} else {
			if (!isStandalone()) {
				this.enableAutoHide()
			}

			el.style.transition = '0.2s';
			el.style.transform = 'translate(0, 0)'
		}
	}

	handleScroll = () => {
		const scrollContainer = getScrollContainer()
		const scrollTop = getScrollPosition()
		const el = this.menuRef.current

		if (!el) return

		// always show if reached bottom
		if (Math.abs(scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight) < 50) {
			el.style.transition = '0.2s';
			el.style.transform = 'translate(0, 0)'

			this.scrollTopTotal = 0
			return
		}

		// Make sure they scroll more than delta
		if (Math.abs(this.lastScrollTop - scrollTop) <= 5 && scrollTop !== 0) {
			return
		}

		if (scrollTop < this.lastScrollTop) {
			if (scrollTop > this.appMenuHeight) {
				this.scrollTopTotal += Math.abs(this.lastScrollTop - scrollTop)

				if (this.scrollTopTotal > 0) {
					el.style.transition = '0.2s';
					el.style.transform = 'translate(0, 0)'

					this.scrollTopTotal = 0
				}
			} else {
				el.style.transition = '0.2s';
				el.style.transform = 'translate(0, 0)'
			}
		} else if (scrollTop > this.appMenuHeight) {
			el.style.transition = '0.5s';
			el.style.transform = 'translate(0, 100px)'
			this.scrollTopTotal = 0
		} else {
			el.style.transition = '0.2s';
			el.style.transform = 'translate(0, 0)'
			this.scrollTopTotal = 0
		}

		this.lastScrollTop = scrollTop
	}

	handleNavigateToHome = () => {
		const { location } = this.props
		if (location.pathname === '/') {
			if (getScrollPosition() === 0) {
				history.push('/white')

				window.setTimeout(() => {
					history.replace('/')
				}, 100)
			} else {
				scrollTop()
			}
		} else {
			history.push('/')
		}
	}

	handleNavigateToSearch = () => {
		const { location } = this.props
		const el = document.getElementById('sidebar-search-input')

		if (locationMatchesCardSearch(location) && el) {
			el.select()
		} else {
			history.push('/cards')
		}
	}

	handleNavigateToDeck = () => {
		const { location, openDeck, shelvedDeck } = this.props
		const deck = shelvedDeck || openDeck

		if (deck) {
			if (location.pathname === `/deck-builder/${deck.slug}`) {
				scrollToPosition(0, document.getElementById('tabDeckWrapper'))
			} else {
				history.push(`/deck-builder/${deck.slug}`)
			}
		}
	}

	bounce = () => {
		const { animateMe } = this.state

		const el = animateMe

		if (el) {
			clearTimeout(this.bounceTimeout)

			el.classList.add('bounce')

			this.bounceTimeout = window.setTimeout(() => {
				el.classList.remove('bounce')
				this.setState({ animateMe: null })
			}, 200)
		}
	}

	render() {
		const { collections, openDeck, location, settings, setReference, shelvedDeck, subscribed, t, userId } = this.props

		const defaultHavesCollection = settings['collection.defaultHavesCollection']
		const defaultCollectionData = collections && defaultHavesCollection ? collections.find(item => item.slug === defaultHavesCollection) : null
		const pinDefaultCollection = settings['collection.pinDefaultCollection'] && defaultCollectionData

		const deck = shelvedDeck || openDeck
		const deckTabActive = deck && location.pathname.startsWith(`/deck-builder/${deck.slug}`)

		return (
			<AppMenu ref={this.menuRef}>
				{/*<SurveyBanner />*/}

				<AppMenuItem
					onClick={this.handleNavigateToHome}
					onBeforeNavigate={e => this.setState({ animateMe: e.currentTarget })}
					active={location.pathname === '/'}
				>
					<Icon name="home" />
					<span>{t('general.start')}</span>
				</AppMenuItem>
				<AppMenuItem
					to={subscribed ? (pinDefaultCollection ? `/collection/${defaultHavesCollection}` : '/mycollections') : '/product/collection-manager'}
					onBeforeNavigate={(e) => {
						this.setState({ animateMe: e.currentTarget })
						setReference(references.COLLECTION_TAB)
					}}
					active={location.pathname === (subscribed ? (pinDefaultCollection ? `/collection/${defaultHavesCollection}` : '/mycollections') : '/product/collection-manager')}
				>
					{pinDefaultCollection ? (
						<CollectionIcon
							color={defaultCollectionData.color}
							icon={defaultCollectionData.icon}
							size={20}
						/>
					) : (
						<Icon name="archive" />
					)}
					<span>{pinDefaultCollection ? defaultCollectionData.name : t('general.collection')}</span>
				</AppMenuItem>
				<AppMenuItem
					primary
					to={subscribed ? '/add' : '/product/collection-manager'}
					onBeforeNavigate={(e) => {
						this.setState({ animateMe: e.currentTarget })
						setReference(references.ADD_CARDS_BUTTON)
					}}
				>
					<AddCircle>
						<Icon name="document-add" />
					</AddCircle>
				</AppMenuItem>
				<AppMenuItem
					onClick={this.handleNavigateToSearch}
					onBeforeNavigate={e => this.setState({ animateMe: e.currentTarget })}
					active={locationMatchesCardSearch(location)}
				>
					<Icon name="search" />
					<span>{t('general.cardSearch')}</span>
				</AppMenuItem>
				{deck && deck.user.id === userId && deck.isEditing ? (
					<AppMenuItem
						onClick={this.handleNavigateToDeck}
						onBeforeNavigate={e => this.setState({ animateMe: e.currentTarget })}
						active={deckTabActive}
					>
						<CoverImage dark deck={deck} backgroundColor={deckTabActive ? '#444' : '#333'} />
					</AppMenuItem>
				) : (
					<AppMenuItem
						to="/mydecks"
						onBeforeNavigate={e => this.setState({ animateMe: e.currentTarget })}
						active={location.pathname === '/mydecks'}
					>
						<Icon name="deck" />
						<span>{t('general.deckBuilder')}</span>
					</AppMenuItem>
				)}
			</AppMenu>
		)
	}
}

MobileAppMenu.propTypes = {
	collections: PropTypes.array,
	location: PropTypes.object.isRequired,
	openDeck: PropTypes.object,
	setReference: PropTypes.func.isRequired,
	shelvedDeck: PropTypes.object,
	settings: PropTypes.object.isRequired,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	userId: PropTypes.number.isRequired
}

MobileAppMenu.defaultProps = {
	collections: [],
	openDeck: null,
	shelvedDeck: null,
	subscribed: null
}

export const AppMenu = styled.nav`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${props => props.theme.zLayer6};

	display: flex;
	height: ${getAppMenuHeight()};
	width: 100%;

	background: ${props => props.theme.headerMobile};
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	transition: 0.2s;
	user-select: none;

	.hide-appmenu & {
		display: none;
	}

	${mobileTablet`
		display: flex;
	`}

	${isStandalone() && isIos() && `
		padding-bottom: 34px;
	`}
`

const bounce = keyframes`
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
`

const bounceAnimation = css`
	${bounce} 0.2s linear;
`

export const AppMenuItem = styled(Link)`
	flex: 1;

	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	color: #CCC;
	margin: 0;
	position: relative;
	text-decoration: none;
	transition: all 0.1s;

	${props => !props.primary && `
		padding: 0.35rem 0 0.35rem;

		&:active {
			background: #333;
			border-radius: 5px;
		}
	`}

	${props => props.save && `
		background: ${props.theme.primary};
		border-radius: 6px;
		color: ${props.theme.white};
		margin: 0.1rem 0.25rem;
	`}

	> svg {
		height: 20px;
		width: 20px;
	}

	> span {
		font-size: 0.6rem;
		margin-top: auto;

		white-space: nowrap;
		overflow: hidden;
		max-width: 66px;
		text-overflow: ellipsis;
	}

	&.bounce {
		animation: ${bounceAnimation};
	}

	${props => props.active && `
		color: #FFF;
		font-weight: bold;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 1px;
			z-index: -1;

			background: #444;
			border-radius: 5px;
			color: #FFF;
			height: 48px;
			width: calc(100% - 2px);
		}
	`}

	${mobile`
		${props => props.save && `
			&:active {
				background: ${props.theme.primaryHover};
			}
		`}
	`}
`

const AddCircle = styled.div`
	background: ${props => props.theme.primary};
	border-radius: 50%;
	box-shadow: 0 4px rgb(0 0 0 / 20%);
	color: #FFF;

	position: relative;
	top: -8px;
	z-index: 100;

	height: 50px;
	width: 50px;

	align-items: center;
	display: flex;
	justify-content: center;

	> svg {
		height: 24px;
		width: 24px;
	}

	&:active {
		background: ${props => props.theme.primaryHover};
	}
`

const enhance = compose(
	withRouter,
	translate('general'),
	connect(state => ({
		collections: state.persist.collections,
		openDeck: state.deckbuilder.deck,
		shelvedDeck: state.deckbuilder.shelvedDeck,
		settings: state.persist.user.settings,
		subscribed: state.persist.user.subscribed,
		userId: state.persist.user.id
	}), dispatch => bindActionCreators({
		setReference
	}, dispatch))
)

export default enhance(MobileAppMenu)
