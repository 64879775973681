import React from 'react'
import ReactDOM from 'react-dom'

import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

// polyfills
import 'core-js/stable'
import 'element.scrollintoviewifneeded-polyfill/index'

import App from './App'
import store, { persistor } from './state/store'
import { detectLanguage } from './helper/translate'
import history from './helper/history'
import { setCardNameLanguage, setLanguage } from './state/actions/persist'
import { setPWAInstallEvent } from './state/actions/app'
import { cardNameLanguageMapping, enableServiceWorker } from '../config'
import { goals, trackGoal } from './helper/track'
import { uninstallAllServiceWorkers } from './helper/pwa'
import Route from './components/router/Route'
import { preventBounce, setOfflineStatus, updateViewport } from './helper/app'
import ThemeProvider from './styles/ThemeProvider'
import { isPrerender } from './styles/media'

// detect language if not set
if (store.getState().persist.language === null) {
	const language = detectLanguage()

	store.dispatch(setLanguage(language))
	store.dispatch(setCardNameLanguage(cardNameLanguageMapping[language[0]]))
}

// viewport
updateViewport()

window.addEventListener('resize', updateViewport)
window.addEventListener('touchmove', preventBounce, { passive: false })

// offline status
window.addEventListener('online', () => setOfflineStatus(false))
window.addEventListener('offline', () => setOfflineStatus(true))

// disable native scroll restoration as our app handles this
if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual'
}

// register service worker
try {
	if ('serviceWorker' in navigator && typeof localStorage !== 'undefined') {
		if (enableServiceWorker) {
			window.addEventListener('load', () => {
				try {
					navigator.serviceWorker.register('/service-worker.js')
				} catch {
					console.log('Service worker registration failed')
				}
			})
		} else {
			uninstallAllServiceWorkers()
		}
	}
} catch {}

// listen for PWA installable event
window.addEventListener('appinstalled', () => {
	trackGoal(goals.INSTALL_APP)
})

window.addEventListener('beforeinstallprompt', (event) => {
	event.userChoice
		.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				trackGoal(goals.CHROME_PWA_BANNER_ACCEPT)
			} else {
				trackGoal(goals.CHROME_PWA_BANNER_DISMISS)
			}
		})

	store.dispatch(setPWAInstallEvent(event))
})

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName)
	}
	// For other elements, forward all props
	return true
}

ReactDOM.render((
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider>
				<StyleSheetManager
					shouldForwardProp={shouldForwardProp}
					disableCSSOMInjection={isPrerender()}
				>
					<Router history={history}>
						<Route render={({ location }) => <App location={location} />} />
					</Router>
				</StyleSheetManager>
			</ThemeProvider>
		</PersistGate>
	</Provider>
), document.getElementById('root'))
