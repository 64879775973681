import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { fixedCollapsibleIsVisible, hideOverlay } from '../../helper/router'
import { mobile } from '../../styles/media'

const FixedCollapsibleBackdrop = ({ location }) => {
	const [visible, setVisible] = useState(fixedCollapsibleIsVisible(location))

	useEffect(() => {
		setVisible(fixedCollapsibleIsVisible(location))
	}, [location.pathname])

	return (
		<Backdrop
			onClick={hideOverlay}
			visible={visible}
		/>
	)
}

FixedCollapsibleBackdrop.propTypes = {
	location: PropTypes.object.isRequired
}

const Backdrop = styled.div`
	background: ${props => props.theme.backgroundOverlay};
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	opacity: ${props => props.visible ? 1 : 0};
	transition: ${props => props.visible ? 'opacity 0.2s' : 'opacity 0.15s 0.15s, z-index 0s 0.3s'};
	width: 100%;

	position: fixed;
	top: 0;
	right: 0;
	z-index: ${props => props.visible ? props.theme.zLayer11 : -1};

	${mobile`
		display: none;
	`}
`

const enhance = withRouter

export default enhance(FixedCollapsibleBackdrop)
