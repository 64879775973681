import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import replace from 'react-string-replace'
import escapeStringRegexp from 'escape-string-regexp'
import translate from '../../../helper/translate'
import Link, { StyledLink } from '../../router/Link'
import monsterAttributes from '../../../data/monsterAttributes'
import monsterTypes from '../../../data/monsterTypes'

const highlightEffect = (string, highlightFirstLine, highlightQuery, name) => {
	let result = string

	if (highlightFirstLine && !string.startsWith('(')) {
		if (!result.includes('\n') && result.length < 130) {
			result += '\n'
		}

		result = replace(result, /([^\n]+[\n])(?!●)/, (match, i) => i === 1 ? (
			<span className="summonRequirements" key={match + i}>{replaceSearchTerm(match, highlightQuery)}</span>
		) : match)
	}

	let count = 0

	result = replace(result, /([^.● \n„][^:\n.]+:)/, (match) => {
		count += 1

		return (
			<span className="activationRequirement" key={match + count}>{replaceSearchTerm(match, highlightQuery)}</span>
		)
	})

	result = replace(result, /([^.● \n:][^;\n.:]+;)/, (match) => {
		count += 1

		return (
			<span className="costs" key={match + count}>{replaceSearchTerm(match, highlightQuery)}</span>
		)
	})

	result = replaceSearchTerm(result, highlightQuery)

	if (name) {
		result = result.map((child) => {
			if (typeof child === 'object') {
				return React.cloneElement(child, {
					children: linkCardNames(child.props.children, name)
				})
			}

			return linkCardNames(child, name)
		})
	}

	// Wiedergeburt des Dokuro-Reiters (Text kaputt)
	/*if (setCardSearch) {
		result = stringReplaceRecursive(result, string => linkCardSearches(string, language, setCardSearch))
	}*/

	return result
}

const replaceSearchTerm = (result, highlightQuery) => {
	if (!highlightQuery) {
		return result
	}

	const terms = highlightQuery.split(' ')

	terms.forEach((term) => {
		const regex = new RegExp(`\\b(${escapeStringRegexp(term)})\\b`, 'i')
		let count = 0

		result = replace(result, regex, (match) => {
			count += 1

			return (
				<span className="highlight" key={match + count}>{match}</span>
			)
		})
	})

	return result
}

const stringReplaceRecursive = (result, callback) => {
	if (!Array.isArray(result)) {
		return result
	}

	return result.map((child) => {
		if (typeof child === 'object') {
			if (Array.isArray(child) && child.length > 0) {
				return callback(child[0])
			}

			return React.cloneElement(child, {
				children: stringReplaceRecursive(child.props.children, callback)
			})
		}

		return callback(child)
	})
}

const normalizeMatch = str => str
	.replace('(s)', '')
	.replace('-Spielmarken', '-Spielmarke')
	.replace(' Tokens', ' Token')

const linkCardNames = (string, name) => {
	let result = string

	result = replace(result, /„([^„]+)“/, (match, i) => {
		const normalizedMatch = normalizeMatch(match)

		return normalizedMatch !== name ? (
			<Fragment key={match + i}>„<Link to={`/search/${encodeURIComponent(normalizedMatch)}`} noFollow>{match}</Link>“</Fragment>
		) : `„${match}“`
	})

	result = replace(result, /"([^"]+)"/, (match, i) => {
		const normalizedMatch = normalizeMatch(match)

		return normalizedMatch !== name ? (
			<Fragment key={match + i}>"<Link to={`/search/${encodeURIComponent(normalizedMatch)}`} noFollow>{match}</Link>"</Fragment>
		) : `"${match}"`
	})

	return result
}

const linkCardSearches = (string, language, setCardSearch) => {
	let result = string

	// Level 4 Warrior
	Object.entries(monsterTypes).forEach(([monsterType, item]) => {
		let regex

		if (language === 'en') {
			regex = new RegExp(`\\b(Level [0-9]+ ${item[`name_${language}`]})\\b`)
		} else if (language === 'de') {
			regex = new RegExp(`\\b(${item[`name_${language}`]}-Monster der Stufe [0-9]+)\\b`)
		} else if (language === 'fr') {
			regex = new RegExp(`\\b(monstre ${item[`name_${language}`]} de Niveau [0-9]+)\\b`)
		} else if (language === 'it') {
			regex = new RegExp(`\\b(mostro ${item[`name_${language}`]} di Livello [0-9]+)\\b`)
		} else if (language === 'es') {
			regex = new RegExp(`\\b(monstruo ${item[`name_${language}`]} de Nivel [0-9]+)\\b`)
		} else if (language === 'pt') {
			regex = new RegExp(`\\b(monstro ${item[`name_${language}`]} de Nível [0-9]+)\\b`)
		}

		result = replace(result, regex, (match, i) => (
			<Link onClick={setCardSearch('', { type: 'monster', monsterType, level: match.match(/\d+/)[0] })} key={match + i} noFollow>{match}</Link>
		))
	})

	// DARK
	Object.entries(monsterAttributes).forEach(([monsterAttribute, item]) => {
		const regex = new RegExp(`\\b(${item[`name_${language}`].toUpperCase()})\\b`)

		result = replace(result, regex, (match, i) => (
			<Link onClick={setCardSearch('', { type: 'monster', monsterAttribute })} key={match + i} noFollow>{match}</Link>
		))

		// DARK Warrior
		Object.entries(monsterTypes).forEach(([monsterType, item2]) => {
			const regex = new RegExp(`\\b(${item[`name_${language}`].toUpperCase()} ${item2[`name_${language}`]})\\b`)

			result = replace(result, regex, (match, i) => (
				<Link onClick={setCardSearch('', { type: 'monster', monsterAttribute, monsterType })} key={match + i} noFollow>{match}</Link>
			))
		})
	})

	// Warrior
	Object.entries(monsterTypes).forEach(([monsterType, item]) => {
		const regex = new RegExp(`\\b(${item[`name_${language}`]})\\b`)

		result = replace(result, regex, (match, i) => (
			<Link onClick={setCardSearch('', { type: 'monster', monsterType })} key={match + i} noFollow>{match}</Link>
		))
	})

	return result
}

export const getTokenText = (cardName, language, hasAttributes) => {
	const normalizedLanguage = language.toLowerCase()

	if (hasAttributes) {
		if (normalizedLanguage === 'de') {
			return `Diese Karte kann als „${cardName}“ verwendet werden.\n\n*Falls sie als eine andere Spielmarke verwendet wird, verwende Typ/Eigenschaft/Stufe/ATK/DEF der Spielmarke.`
		}

		if (normalizedLanguage === 'fr') {
			return `Cette carte peut être utilisée comme "${cardName}".\n\n*Si elle est utilisée comme un autre Jeton, appliquez les Type/Attribut/Niveau/ATK/DEF du Jeton.`
		}

		if (normalizedLanguage === 'it') {
			return `Questa carta può essere utilizzata come "${cardName}".\n\n*Se viene utilizzata come un altro Segna-Mostro, utilizza il Tipo/Attributo/Livello/ATK/DEF di quel Segna-Mostro.`
		}

		if (normalizedLanguage === 'es') {
			return `Esta carta puede ser usada como una "${cardName}".\n\n*Si se usa como otra Ficha, aplica el Tipo/Atributo/Nivel/ATK/DEF de esa Ficha.`
		}

		if (normalizedLanguage === 'pt') {
			return `Este card pode ser usado como uma "${cardName}".\n\n*Se ela for usada como outra Ficha, aplique o Tipo/Atributo/Nível/ATK/DEF dessa outra Ficha.`
		}

		if (normalizedLanguage === 'jp') {
			return `このカードは「${cardName}」\n\n※「${cardName}」以外のトークンとして使用する場合、そのトークンの種族・属性・レベル・攻撃力・守備力を適用する。`
		}

		return `This card can be used as a "${cardName}".\n\n*If used for another Token, apply that Token's Type/Attribute/Level/ATK/DEF.`
	}

	if (normalizedLanguage === 'de') {
		return 'Diese Karte kann als eine beliebige Spielmarke verwendet werden.'
	}

	if (normalizedLanguage === 'fr') {
		return 'Cette carte peut être utilsée comme un Jeton.'
	}

	if (normalizedLanguage === 'it') {
		return 'Questa carta puo essere usata come Segna-Mostro generico.'
	}

	if (normalizedLanguage === 'es') {
		return 'Esta carta puede ser utilizada como cualquier Ficha.'
	}

	if (normalizedLanguage === 'pt') {
		return 'Este card pode ser usado como uma Ficha.'
	}

	if (normalizedLanguage === 'jp') {
		return 'このカードはトークンとして使用する事ができる。'
	}

	return 'This card can be used as any Token.'
}

const Effect = ({ highlightFirstLine, highlightQuery, isNormal, name, t, text, textPendulum, textLore, type }) => {
	let effect = null

	if (textPendulum !== '') {
		effect = (
			<>
				<EffectHeading>{t('yugi.pendulumEffect')}</EffectHeading>
				<StyledEffect>
					{highlightEffect(textPendulum, highlightFirstLine, highlightQuery, name)}
				</StyledEffect>

				<EffectHeading>{t('yugi.cardText')}</EffectHeading>
				<StyledEffect isNormal={isNormal}>
					{!isNormal ? highlightEffect(text, highlightFirstLine, highlightQuery, name) : replaceSearchTerm(text, highlightQuery)}
				</StyledEffect>
			</>
		)
	} else if (text !== '') {
		effect = (
			<>
				<StyledEffect isNormal={isNormal}>
					{!isNormal ? highlightEffect(text, highlightFirstLine, highlightQuery, name) : text}
				</StyledEffect>
				{textLore && textLore !== '' && (
					<StyledEffect isNormal>
						<br /><br />{replaceSearchTerm(textLore, highlightQuery)}
					</StyledEffect>
				)}
			</>
		)
	} else if (type !== 'other') {
		effect = <UnknownEffect>{t('yugi.unknownEffect')}</UnknownEffect>
	}

	return effect
}

Effect.propTypes = {
	highlightFirstLine: PropTypes.bool.isRequired,
	isNormal: PropTypes.bool.isRequired,
	name: PropTypes.string,
	t: PropTypes.func.isRequired,
	text: PropTypes.string,
	textPendulum: PropTypes.string,
	textLore: PropTypes.string,
	type: PropTypes.string.isRequired
}

Effect.defaultProps = {
	name: null,
	text: '',
	textPendulum: '',
	textLore: ''
}

const StyledEffect = styled.div`
	font-size: 1rem;
	font-style: ${props => props.isNormal ? 'italic' : 'normal'};
	font-weight: normal;
	line-height: 1.5;
	text-align: justify;
	text-align-last: left;
	white-space: pre-line;

	span {
		padding: 1px 0;
	}

	span.summonRequirements {
		color: ${props => props.theme.id === 'dark' ? props.theme.textLight : props.theme.secondary};
		display: block;
		font-weight: bold;
		margin-bottom: 0.25rem;
		text-align: left;

		a {
			color: ${props => props.theme.id === 'dark' ? props.theme.textLight : props.theme.secondary};
		}
	}

	span.activationRequirement {
		background-color: ${props => props.theme.effectActivation};
	}

	span.costs {
		background-color: ${props => props.theme.effectCosts};
	}

	span.highlight {
		background-color: ${props => props.theme.highlight};
		color: ${props => props.theme.black};
	}

	${StyledLink} {
		user-select: auto;
	}
`

const EffectHeading = styled.div`
	color: ${props => props.theme.textSecondary};
	font-size: 0.7rem;
	font-weight: bold;
	margin: 0 0 0.25rem 0;
	text-align: center;
	text-transform: uppercase;

	${StyledEffect} + & {
		margin-top: 1rem;
	}
`

const UnknownEffect = styled.div`
	color: ${props => props.theme.textLight};
	font-style: italic;
	margin-bottom: 1rem;
`

const enhance = translate('yugi')

export default enhance(Effect)
