import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import translate from '../../helper/translate'
import {
	ErrorFullscreen,
	ErrorHeader, ErrorHeaderCode
} from '../../components/general/ErrorFullscreen'
import { time } from '../../helper/date'

class AccountFrozen extends Component {
	static propTypes = {
		until: PropTypes.any.isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { t, until } = this.props

		return (
			<ErrorFullscreen>
				<Helmet>
					<title>{t('general.accountFrozen')}</title>
				</Helmet>

				<ErrorHeader>
					<ErrorHeaderCode>{t('general.accountFrozen')}</ErrorHeaderCode>
					<small>
						{t('general.accountFrozenDescription', {
							until: <strong>{time(until)}</strong>,
							support: <a href="mailto:support@cardcluster.com">Support</a>
						})}
					</small>
				</ErrorHeader>
			</ErrorFullscreen>
		)
	}
}

const enhance = translate('general')

export default enhance(AccountFrozen)
