import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Icon from '../general/Icon'
import DropDown, {
	DropDownLink,
	DropDownLinkCheck,
	DropDownLinkNeedsPro,
	DropDownLinkRight,
	HeaderLinkDropDownHeading
} from './DropDown'
import translate from '../../helper/translate'
import BetterButton from './BetterButton'
import ProTag from '../general/ProTag'
import history from '../../helper/history'
import { VIEWPORT_MOBILE } from '../../styles/media'

const DropDownSelect = ({ button, clearable, clearableLabel, data, dataSecondary, label, labelSecondary, onChange, right, secondaryOnChange, t, top, value, valueSecondary, viewport }) => {
	const renderData = (data, onBeforeNavigate, secondary) => data.map((option) => {
		const optionValue = secondary ? valueSecondary : value

		return (
			<DropDownLink
				onClick={() => {
					if (option.needsPro) {
						history.push('/pricing')
					} else if (secondary) {
						secondaryOnChange(option.value)
					} else {
						onChange(option.value)
					}
				}}
				onBeforeNavigate={!secondary && secondaryOnChange === null ? onBeforeNavigate : undefined}
				active={optionValue === option.value ? 1 : undefined}
				disabledStyle={option.needsPro}
				disabled={option.disabled}
				key={option.value}
			>
				{option.label}
				{option.needsPro ? (
					<DropDownLinkNeedsPro>
						<ProTag />{t('general.needed')}
					</DropDownLinkNeedsPro>
				) : (
					<>
						{option.right}
						<DropDownLinkRight>
							<DropDownLinkCheck active={optionValue === option.value ? 1 : undefined}>
								<Icon name="check" />
							</DropDownLinkCheck>
						</DropDownLinkRight>
					</>
				)}
			</DropDownLink>
		)
	})

	return (
		<DropDown
			isSelect
			button={button}
			top={top}
			right={right ? 0 : undefined}
		>
			{onBeforeNavigate => (
				<>
					{label && <HeaderLinkDropDownHeading>{label}</HeaderLinkDropDownHeading>}
					{clearable && (
						<DropDownLink
							onClick={() => onChange(null)}
							onBeforeNavigate={secondaryOnChange === null ? onBeforeNavigate : undefined}
							active={!value ? 1 : undefined}
							clearOption
						>
							{clearableLabel}
							<DropDownLinkRight>
								<DropDownLinkCheck active={!value ? 1 : undefined}>
									<Icon name="check" />
								</DropDownLinkCheck>
							</DropDownLinkRight>
						</DropDownLink>
					)}
					{renderData(data, onBeforeNavigate)}

					{dataSecondary && secondaryOnChange !== null && (
						<>
							<HeaderLinkDropDownHeading>{labelSecondary}</HeaderLinkDropDownHeading>

							{renderData(dataSecondary, onBeforeNavigate, true)}

							{viewport === VIEWPORT_MOBILE && (
								<ApplyButton>
									<BetterButton onClick={onBeforeNavigate}>{t('general.apply')}</BetterButton>
								</ApplyButton>
							)}
						</>
					)}
				</>
			)}
		</DropDown>
	)
}

DropDownSelect.propTypes = {
	button: PropTypes.any.isRequired,
	clearable: PropTypes.bool,
	clearableLabel: PropTypes.string,
	data: PropTypes.array.isRequired,
	dataSecondary: PropTypes.array,
	label: PropTypes.string,
	labelSecondary: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	right: PropTypes.bool,
	secondaryOnChange: PropTypes.func,
	t: PropTypes.func.isRequired,
	top: PropTypes.number,
	value: PropTypes.any,
	valueSecondary: PropTypes.any,
	viewport: PropTypes.string.isRequired
}

DropDownSelect.defaultProps = {
	clearable: false,
	clearableLabel: null,
	dataSecondary: null,
	label: null,
	labelSecondary: null,
	right: false,
	secondaryOnChange: null,
	top: null,
	value: null,
	valueSecondary: null
}

const ApplyButton = styled.div`
	padding: 1.5rem 1rem 1rem;
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(DropDownSelect)
